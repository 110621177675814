import { useState } from 'react';
import { ReactComponent as InfoIcon } from 'assets/InfoCircle.svg';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import UnderLineTabs from 'ui-new/whitelabel/Tabs/UnderlineTabs/UnderLineTabs';
import { TabPanel, TabPanels } from '@round/ui-kit';
import styles from './SongFormAudios.module.css';
import { Label } from 'ui-new/whitelabel/formik';
import { SongFormAudiosProvider } from './SongFormAudiosContext';
import PlatformIcon from 'Modules/Plans/components/PlatformIcon';
import SongFormTiktokAudios from './TiktokAudios/SongFormTiktokAudios';
import SongFormInstagramAudios from './InstagramAudios/SongFormInstagramAudios';
import ChangedAudios from './components/ChangedAudios';

type Props = {
    staticTiktokAudios?: number[];
    clientSongTiktokAudios?: number[];
    staticInstagramAudios?: number[];
    clientSongInstagramAudios?: number[];
};

const SongFormAudios = ({
    staticTiktokAudios,
    clientSongTiktokAudios,
    staticInstagramAudios,
    clientSongInstagramAudios,
}: Props) => {
    const [audioPlatformMode, setAudioPlatformMode] = useState<'tiktok' | 'instagram'>('tiktok');
    const [audiosTooltipAnchor, setAudiosTooltipAnchor] = useState<SVGSVGElement | null>(null);

    return (
        <section className={styles.container}>
            <Label className={styles.audiosLabel}>
                <span>Add audios</span>
                <InfoIcon ref={setAudiosTooltipAnchor} className={styles.infoCircle} />
                <Tooltip anchorElement={audiosTooltipAnchor}>
                    <Tooltip.Body>
                        Link audios to the song and we'll gather data on them.
                        <br />
                        For released songs, we'll automatically link audios we find.
                    </Tooltip.Body>
                </Tooltip>
            </Label>

            <SongFormAudiosProvider>
                <UnderLineTabs activeTab={audioPlatformMode} onChange={(tab) => setAudioPlatformMode(tab)}>
                    <UnderLineTabs.Menu>
                        <UnderLineTabs.Item name="tiktok">
                            <PlatformIcon
                                platform="tiktok"
                                colored={audioPlatformMode === 'tiktok'}
                                className={styles.platformIcon}
                            />
                            <span>TikTok</span>
                        </UnderLineTabs.Item>
                        <UnderLineTabs.Item name="instagram">
                            <PlatformIcon
                                platform="instagram"
                                colored={audioPlatformMode === 'instagram'}
                                className={styles.platformIcon}
                            />
                            <span>Instagram</span>
                        </UnderLineTabs.Item>
                    </UnderLineTabs.Menu>

                    <TabPanels>
                        <TabPanel name="tiktok">
                            <SongFormTiktokAudios
                                staticTiktokAudios={staticTiktokAudios}
                                clientSongTiktokAudios={clientSongTiktokAudios}
                            />
                        </TabPanel>
                        <TabPanel name="instagram">
                            <SongFormInstagramAudios
                                staticInstagramAudios={staticInstagramAudios || []}
                                clientSongInstagramAudios={clientSongInstagramAudios || []}
                            />
                        </TabPanel>
                    </TabPanels>
                </UnderLineTabs>
                <ChangedAudios />
            </SongFormAudiosProvider>
        </section>
    );
};

export default SongFormAudios;
