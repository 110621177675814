import useAbortableEffect from 'Hooks/useAbortableEffect';
import useTiktokTopAudios from './useTiktokTopAudios';
import styles from './TiktokTopAudios.module.css';
import { useMemo, useState } from 'react';
import { creatorbase, TiktokAudioAsset } from '@round/api';
import Select from 'ui-new/whitelabel/Select/Select';
import { GenericDropdownOption } from 'App.types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import cn from 'classnames';
import Badge from 'ui-new/whitelabel/Badge/Badge';
import capitalize from 'lodash/capitalize';
import { buildTiktokMusicUrl } from 'helpers';
import { getTableMetaHelper, Skeleton, SkeletonTableCell } from '@round/ui-kit';
import TopAudiosTable, { Meta } from '../Table/TopAudiosTable';
import MiniLineChartCell from '../Table/Cells/MiniLineChart/MiniLineChartCell';
import AudioCell, { AudioCellData } from '../Table/Cells/Audio/AudioCell';
import CountCell, { CountCellData } from '../Table/Cells/Count/CountCell';

const orderingOptions: GenericDropdownOption<creatorbase.GetTiktokAudiosOrderingParam>[] = [
    { label: 'Total Videos', value: '-video_count' },
    { label: 'Daily Growth (# videos)', value: '-video_count_daily_change' },
    { label: 'Daily Growth (% increase)', value: '-video_count_daily_change_relative' },
    { label: 'Weekly Growth (# videos)', value: '-video_count_weekly_change' },
    { label: 'Weekly Growth (% increase)', value: '-video_count_weekly_change_relative' },
];

const defaultOrder: creatorbase.GetTiktokAudiosOrderingParam = '-video_count_daily_change';

export type TiktokTopAudiosTableRow = creatorbase.TiktokAudio & {
    timeSeries: creatorbase.TimeSeriesResponse<'posts_daily_change'>[number] | null;
    asset: TiktokAudioAsset | null;
};

type TableCellContext<K extends keyof TiktokTopAudiosTableRow> = CellContext<
    TiktokTopAudiosTableRow,
    TiktokTopAudiosTableRow[K]
>;

const getTableMeta = getTableMetaHelper<Meta>();

const TiktokTopAudios = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState<creatorbase.GetTiktokAudiosOrderingParam>(defaultOrder);

    const { data, status, fetchData, reset } = useTiktokTopAudios();
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData({ page, page_size: pageSize, ordering }, { signal }).catch(() => {});
            }
        },
        [fetchData, isInitialized, page, pageSize, ordering]
    );

    const rows: TiktokTopAudiosTableRow[] =
        data?.audios.results?.map((audio) => ({
            ...audio,
            timeSeries: data.timeSeries?.[audio.id] ?? null,
            asset: data.assets.find((asset) => asset.audio_id === audio.id) ?? null,
        })) ?? [];

    const columns = useMemo<ColumnDef<TiktokTopAudiosTableRow, any>[]>(
        () => [
            {
                header: 'Audio',
                id: 'audio',
                meta: {
                    className: styles.audio,
                },
                accessorFn: (row): AudioCellData => ({
                    songTitle: row.title || row.tiktok_id,
                    audioUrl: row.tiktok_id && row.title ? buildTiktokMusicUrl(row.title, row.tiktok_id) : '',
                    thumbnailUrl: row.asset?.image_thumb.cached_url ?? row.asset?.image_thumb.original_url ?? '',
                }),
                cell: AudioCell,
            },
            {
                header: 'Type',
                accessorKey: 'is_original',
                cell: ({ getValue, table }: TableCellContext<'is_original'>) => {
                    const { isLoading } = getTableMeta(table);
                    const type = getValue() === null ? null : !!getValue() ? 'original' : 'official';

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    if (!type) {
                        return '-';
                    }

                    return <Badge className={cn(styles.badge, styles[type])}>{capitalize(type)}</Badge>;
                },
            },
            {
                header: 'Author',
                accessorFn: (row) => row.author_name || '-',
                cell: SkeletonTableCell,
            },
            {
                header: 'Videos',
                accessorFn: (row): CountCellData => ({
                    ordering: ordering,
                    value: row.video_count,
                    growthValue:
                        ordering && ordering !== '-video_count'
                            ? row[ordering.slice(1) as keyof creatorbase.TiktokAudio]
                            : null,
                }),
                cell: CountCell,
            },
            {
                header: 'Growth',
                id: 'dailyChange',
                accessorKey: 'timeSeries',
                meta: {
                    className: styles.dailyChange,
                },
                cell: MiniLineChartCell,
            },
        ],
        [ordering]
    );

    return (
        <section className={styles.container}>
            <menu>
                <Select
                    value={orderingOptions.find((o) => o.value === ordering)}
                    options={orderingOptions}
                    onChange={(option) => {
                        setOrdering(option?.value ?? defaultOrder);
                        reset();
                    }}
                    className={styles.orderingSelect}
                />
            </menu>

            <TopAudiosTable
                page={page}
                columns={columns}
                setPage={(page) => {
                    setPage(page);
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setPageSize(pageSize);
                    reset();
                }}
                ordering={ordering}
                count={data?.audios.count ?? 0}
                data={rows}
                meta={{ isLoading: !isInitialized }}
                hasError={status === 'error'}
            />
        </section>
    );
};

export default TiktokTopAudios;
