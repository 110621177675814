import { Label } from 'ui-new/whitelabel/formik';
import styles from '../SongFormAudios.module.css';
import { useSongFormInstagramAudios } from './useSongFormInstagramAudios';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import Input from 'ui-new/whitelabel/Input/Input';
import { useState } from 'react';
import { SongFormValues } from '../../SongForm';
import { useFormikContext } from 'formik';
import { showNotification } from 'helpers';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';
import Button from 'ui-new/whitelabel/Button/Button';
import InstagramAudiosTable, { InstagramAudiosTableRow } from './Table/InstagramAudiosTable';

type Props = {
    staticInstagramAudios: number[];
    clientSongInstagramAudios: number[];
};

const SongFormInstagramAudios = ({ staticInstagramAudios, clientSongInstagramAudios }: Props) => {
    const { audios, addAudio, status, fetchAudios } = useSongFormInstagramAudios();
    const { values, setFieldValue } = useFormikContext<SongFormValues>();

    const isInitialized = status === 'success' || status === 'error';
    const hasError = status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchAudios([...staticInstagramAudios, ...clientSongInstagramAudios], { signal });
            }
        },
        [isInitialized, clientSongInstagramAudios, staticInstagramAudios, fetchAudios]
    );

    const [instagramAudioUrl, setInstagramAudioUrl] = useState('');
    const [isAddAudioLoading, setIsAddAudioLoading] = useState(false);

    const handleAddAudio = async () => {
        if (!instagramAudioUrl) {
            return;
        }

        try {
            setIsAddAudioLoading(true);
            const audio = await addAudio(instagramAudioUrl);

            if ([...values.instagram_audios].includes(audio.id)) {
                showNotification('Audio already added', 'error');
                return;
            }

            setInstagramAudioUrl('');
            setFieldValue('instagram_audios', [audio.id, ...values.instagram_audios]);
            showNotification('Audio added successfully', 'info');
        } catch (e) {
            const message = e instanceof Error ? e.message : `Couldn't add audio`;
            showNotification(message, 'error');
        } finally {
            setIsAddAudioLoading(false);
        }
    };

    const handleRemoveAudio = (audioId: number) => {
        setFieldValue(
            'instagram_audios',
            values.instagram_audios.filter((id) => id !== audioId)
        );
    };

    const rows = (() => {
        let rows: InstagramAudiosTableRow[] = [];

        //if the song is changed to unreleased, we will hide the static audios
        if (values.status === 'released') {
            rows =
                staticInstagramAudios?.reduce<InstagramAudiosTableRow[]>((acc, id) => {
                    const audio = audios?.find((audio) => audio.id === id);
                    return audio ? acc.concat({ ...audio, isStatic: true }) : acc;
                }, []) ?? [];
        }

        return rows.concat(
            values.instagram_audios
                .filter((id) => !staticInstagramAudios?.includes(id))
                .reduce<InstagramAudiosTableRow[]>((acc, id) => {
                    const audio = audios?.find((audio) => audio.id === id);
                    return audio ? acc.concat({ ...audio, isStatic: false }) : acc;
                }, []) ?? []
        );
    })();

    return (
        <div className={styles.platformAudiosContainer}>
            <div className={styles.field}>
                <Label>Instagram Audio</Label>
                <div className={styles.audioInputRow}>
                    <Input
                        placeholder="Enter Instagram audio URL"
                        className={styles.audioInput}
                        value={instagramAudioUrl}
                        onChange={(e) => setInstagramAudioUrl(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleAddAudio();
                            }
                        }}
                        disabled={hasError}
                    />
                    <Button
                        appearance="primary"
                        onClick={handleAddAudio}
                        disabled={isAddAudioLoading || !isInitialized || hasError}
                        isLoading={isAddAudioLoading}
                        type="button"
                    >
                        <PlusIcon className={styles.plusIcon} />
                    </Button>
                </div>
            </div>

            <InstagramAudiosTable
                data={rows}
                isLoading={!isInitialized}
                onRemoveAudio={handleRemoveAudio}
                hasError={hasError}
            />
        </div>
    );
};

export default SongFormInstagramAudios;
