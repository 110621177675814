import moment from 'moment';
import styles from './Creations.module.css';
import LineChart, { DataSet } from 'ui-new/whitelabel/Charts/LineChart/LineChart';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { numberWithCommas } from 'helpers';
import { useCreations } from './useCreations';
import { usePlatformFilters } from '../PlatformFilters/usePlatformFilters';
import { creatorbase } from '@round/api';
import pick from 'lodash/pick';
import { useAbortableEffect } from '@round/utils';
import { useMemo } from 'react';
import { timeSeriesFieldLabels } from './helpers';

const today = moment();
const twoWeeksAgo = moment().subtract(2, 'weeks');

const Creations = () => {
    const { activePlatforms } = usePlatformFilters();
    const { data, status, fetchData } = useCreations();

    const isInitialized = status === 'success' || status === 'error';
    const hasError = status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData(
                    { start_date: twoWeeksAgo.format('YYYY-MM-DD'), end_date: today.format('YYYY-MM-DD') },
                    signal
                ).catch(() => {});
            }
        },
        [fetchData, isInitialized]
    );

    const dataSets = useMemo(() => {
        return data
            ? Object.entries(data).reduce((acc, [key, value]) => {
                  if (!!activePlatforms.find((platform) => key.includes(platform)) && !!value.length) {
                      acc[key as creatorbase.TimeSeriesField] = {
                          data: value.map((point) => ({
                              x: point.timestamp,
                              y: point.value,
                          })),
                          borderColor: key.includes('tiktok') ? '#00f2ea' : '#e1306c',
                          label: timeSeriesFieldLabels[key as creatorbase.TimeSeriesField],
                      };
                  }

                  return acc;
              }, {} as Record<creatorbase.TimeSeriesField, DataSet>)
            : null;
    }, [data, activePlatforms]);

    return (
        <div className={styles.container}>
            <div className={styles.chartTile}>
                <p className={styles.title}>Total creations</p>
                <div className={styles.chartContainer}>
                    <LineChart
                        isLoading={!isInitialized}
                        hasError={hasError}
                        dataSets={
                            dataSets
                                ? Object.values(pick(dataSets, ['tiktok_daily_totals', 'instagram_daily_totals']))
                                : []
                        }
                        renderTooltip={({ point, dataset }) => (
                            <>
                                <Tooltip.Title>{moment(point.x).format('D MMM YYYY')}</Tooltip.Title>
                                <Tooltip.Body>
                                    <dl className={styles.chartTooltipStats}>
                                        <div className={styles.chartTooltipStatsItem}>
                                            <dt>{dataset.label}</dt>
                                            <dd>{numberWithCommas(point.y)}</dd>
                                        </div>
                                    </dl>
                                </Tooltip.Body>
                            </>
                        )}
                    />
                </div>
            </div>

            <div className={styles.chartTile}>
                <p className={styles.title}>Daily creations</p>
                <div className={styles.chartContainer}>
                    <LineChart
                        isLoading={!isInitialized}
                        hasError={hasError}
                        dataSets={
                            dataSets
                                ? Object.values(pick(dataSets, ['tiktok_daily_change', 'instagram_daily_change']))
                                : []
                        }
                        renderTooltip={({ point, dataset }) => (
                            <>
                                <Tooltip.Title>{moment(point.x).format('D MMM YYYY')}</Tooltip.Title>
                                <Tooltip.Body>
                                    <dl className={styles.chartTooltipStats}>
                                        <div className={styles.chartTooltipStatsItem}>
                                            <dt>{dataset.label}</dt>
                                            <dd>{numberWithCommas(point.y)}</dd>
                                        </div>
                                    </dl>
                                </Tooltip.Body>
                            </>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default Creations;
