import React from 'react';
import { TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import styles from './Payments.module.css';
import useUrlState from '../../../../Hooks/useUrlState';
import { AccordionTabNav } from 'ui/AccordionTabs/AccordionTabNav/AccordionTabNav';
import { AccordionTabNavItem } from 'ui/AccordionTabs/AccordionTabNavItem/AccordionTabNavItem';
import OutstandingPaypalPayments from './OutstandingPayments/Paypal/OutstandingPaypalPayments';
import OutstandingManualPayments from './OutstandingPayments/Manual/OutstandingManualPayments';
import OutstandingVenmoPayments from './OutstandingPayments/Venmo/OutstandingVenmoPayments';
import PastPaypalPayments from './PastPayments/Paypal/PastPaypalPayments';
import PastManualPayments from './PastPayments/Manual/PastManualPayments';
import PastVenmoPayments from './PastPayments/Venmo/PastVenmoPayments';

type PrimaryTab = 'outstanding' | 'past';
type SubTab = 'paypal' | 'manual' | 'venmo';
type TabString = `${PrimaryTab}-${SubTab}`;

type UrlState = {
    tab: TabString;
};

const initialUrlState: UrlState = {
    tab: 'outstanding-paypal',
};

const Payments = () => {
    const [urlState, setUrlState] = useUrlState(initialUrlState, { shouldSetMissingInitialValues: true });

    return (
        <Tabs
            activeTab={urlState.tab}
            onChange={(tab) => setUrlState({ tab: (tab as UrlState['tab']) || initialUrlState.tab }, { replace: true })}
        >
            <div className={styles.navContainer}>
                <AccordionTabNav label="Outstanding">
                    <AccordionTabNavItem name="outstanding-paypal">Paypal</AccordionTabNavItem>
                    <AccordionTabNavItem name="outstanding-manual">Manual</AccordionTabNavItem>
                    <AccordionTabNavItem name="outstanding-venmo">Venmo</AccordionTabNavItem>
                </AccordionTabNav>

                <AccordionTabNav label="Past">
                    <AccordionTabNavItem name="past-paypal">Paypal</AccordionTabNavItem>
                    <AccordionTabNavItem name="past-manual">Manual</AccordionTabNavItem>
                    <AccordionTabNavItem name="past-venmo">Venmo</AccordionTabNavItem>
                </AccordionTabNav>
            </div>

            <div>
                <TabPanels>
                    <TabPanel name="outstanding-paypal">
                        <OutstandingPaypalPayments />
                    </TabPanel>
                    <TabPanel name="outstanding-manual">
                        <OutstandingManualPayments />
                    </TabPanel>
                    <TabPanel name="outstanding-venmo">
                        <OutstandingVenmoPayments />
                    </TabPanel>

                    <TabPanel name="past-paypal">
                        <PastPaypalPayments />
                    </TabPanel>
                    <TabPanel name="past-manual">
                        <PastManualPayments />
                    </TabPanel>
                    <TabPanel name="past-venmo">
                        <PastVenmoPayments />
                    </TabPanel>
                </TabPanels>
            </div>
        </Tabs>
    );
};

export default Payments;
