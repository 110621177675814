import React, { useState } from 'react';
import styles from './InfluencerPlanGroupBar.module.css';
import cn from 'classnames';
import Toggle from 'ui/DataEntry/Toggle/Toggle';
import Modal, { ModalContent, ModalFooter, ModalTitle } from 'ui/General/Modal/Modal';
import Button from 'ui/Buttons/Button/Button';
import { PatchInfluencerPostGroupResponse, PatchYoutubeInfluencerPostGroupResponse } from '@round/api';

type Props = {
    isShared: boolean;
    onChange: (
        isShared: boolean
    ) => Promise<PatchInfluencerPostGroupResponse | PatchYoutubeInfluencerPostGroupResponse | void>;
};

const ShareWithCreatorbaseToggle = ({ isShared, onChange }: Props) => {
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const isSharedOptimistic = isUpdateLoading ? !isShared : isShared;

    const handleToggleChange = async (isShared: boolean) => {
        setIsUpdateLoading(true);
        await onChange(isShared);

        setIsUpdateLoading(false);
    };

    const [isConfirmUnshareModalOpen, setIsConfirmUnshareModalOpen] = useState(false);

    return (
        <>
            <div className={cn(styles.groupInfoColumn, styles.creatorbaseToggle)}>
                <div className={styles.row}>
                    <Toggle
                        value={isSharedOptimistic}
                        disabled={isUpdateLoading}
                        onChange={(isShared) => {
                            if (!isShared) {
                                setIsConfirmUnshareModalOpen(true);
                                return;
                            }

                            handleToggleChange(isShared);
                        }}
                        className={cn(styles.hiddenToggle, { [styles.hiddenToggleOn]: isSharedOptimistic })}
                    />
                </div>
                <span className={cn(styles.groupInfoColumnValue, styles.hiddenToggleValue)}>Shared</span>
            </div>

            <Modal
                className={styles.alignCenter}
                closeOnOverlayClick
                isOpen={isConfirmUnshareModalOpen}
                onClose={() => setIsConfirmUnshareModalOpen(false)}
            >
                <ModalTitle>Remove campaign from creatorbase?</ModalTitle>
                <ModalContent>This might affect creatorbase users</ModalContent>
                <ModalFooter align="center">
                    <Button onClick={() => setIsConfirmUnshareModalOpen(false)}>Cancel</Button>
                    <Button
                        type="filled"
                        color="black"
                        onClick={() => {
                            handleToggleChange(!isShared);
                            setIsConfirmUnshareModalOpen(false);
                        }}
                    >
                        Remove campaign
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ShareWithCreatorbaseToggle;
