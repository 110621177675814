import { DataState } from '@round/utils';
import { influencer, instagram } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer, mapStringListToArray } from 'helpers';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { Context } from '@fluentui/react-context-selector';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback, useEffect } from 'react';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

type TimeSeriesData<T extends instagram.TimeSeriesField = instagram.TimeSeriesField> = DataState<
    instagram.TimeSeriesResponse<T>[number]
>;

type State<T extends instagram.TimeSeriesField = instagram.TimeSeriesField> = {
    isInitialized: boolean;
    data: {
        [id: number]: TimeSeriesData<T>;
    };
};

export const initialState: State = {
    isInitialized: false,
    data: {},
};

type Actions<T extends instagram.TimeSeriesField = instagram.TimeSeriesField> =
    | ReducerActionWithPayload<'loadInstagramTimeSeriesDataHook', number[]>
    | ReducerActionWithPayload<
          'successInstagramTimeSeriesDataHook',
          { ids: number[]; data: instagram.TimeSeriesResponse<T> }
      >
    | ReducerActionWithPayload<'errorInstagramTimeSeriesDataHook', { ids: number[]; error: string }>
    | ReducerAction<'instagramTimeSeriesDataHookInitialized'>
    | ReducerAction<'instagramTimeSeriesDataHookReset'>;

export const reducer = createReducer<State, Actions>({
    loadInstagramTimeSeriesDataHook: (state, { payload }) => {
        const incoming = makeLoadingStateSlice<number, instagram.TimeSeriesResponse[number]>(payload);
        return { ...state, data: { ...state.data, ...incoming } };
    },
    errorInstagramTimeSeriesDataHook: (state, { payload: { error, ids } }) => {
        const incoming = makeErrorStateSlice<number, instagram.TimeSeriesResponse[number]>(ids, error);
        return { ...state, data: { ...state.data, ...incoming } };
    },
    successInstagramTimeSeriesDataHook: (state, { payload: { ids, data } }) => {
        const incoming = makeSuccessStateSlice(ids, (id) => data[id]);
        return { ...state, data: { ...state.data, ...incoming } };
    },
    instagramTimeSeriesDataHookInitialized: (state) => ({ ...state, isInitialized: true }),
    instagramTimeSeriesDataHookReset: () => initialState,
});

type HookConfig<
    TField extends instagram.TimeSeriesField = instagram.TimeSeriesField
> = instagram.TimeSeriesRequestParams<TField> & {
    isImperative?: boolean;
    publicReportId?: string;
};

export function makeInstagramTimeSeriesDataHook<TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) {
    return function useInstagramTimeSeries<TField extends instagram.TimeSeriesField = instagram.TimeSeriesField>({
        isImperative = false,
        ids,
        type,
        fields,
        start_date,
        end_date,
        publicReportId,
    }: HookConfig<TField>) {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (params: instagram.TimeSeriesRequestParams<TField>) => {
                if (!params.ids.length) {
                    dispatch({ type: 'successInstagramTimeSeriesDataHook', payload: { ids: [], data: {} } });
                    return;
                }

                dispatch({ type: 'loadInstagramTimeSeriesDataHook', payload: params.ids });
                try {
                    const response = await instagram.postTimeSeries(
                        params,
                        publicReportId ? influencer.addPublicReportIdToRequestInit(publicReportId, {}) : {}
                    );

                    if (response.status === 200) {
                        dispatch({
                            type: 'successInstagramTimeSeriesDataHook',
                            payload: {
                                ids: params.ids,
                                data: response.data as instagram.TimeSeriesResponse<instagram.TimeSeriesField>,
                            },
                        });
                        dispatch({ type: 'instagramTimeSeriesDataHookInitialized' });
                        return;
                    }

                    dispatch({
                        type: 'errorInstagramTimeSeriesDataHook',
                        payload: { ids: params.ids, error: response.data.detail },
                    });
                } catch (e) {
                    dispatch({
                        type: 'errorInstagramTimeSeriesDataHook',
                        payload: { ids: params.ids, error: 'Could not load instagram data' },
                    });
                }
            },
            [dispatch, publicReportId]
        );

        const serializedIds = ids.toString();
        const serializedFields = fields.toString();
        useEffect(() => {
            if (isImperative || state.isInitialized) {
                return;
            }

            fetchData({
                ids: mapStringListToArray(serializedIds).map(Number),
                fields: mapStringListToArray(serializedFields) as TField[],
                type,
                start_date,
                end_date,
            });
        }, [end_date, fetchData, isImperative, serializedFields, serializedIds, start_date, state.isInitialized, type]);

        return { state, fetchData, reset: () => dispatch({ type: 'instagramTimeSeriesDataHookReset' }) };
    };
}
