import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { InstagramAudio, PaginatedApiResponseData, TimeSeriesResponse } from '@round/api';
import { createReducer } from 'helpers';

type Payload = {
    audios: PaginatedApiResponseData<InstagramAudio>;
    timeSeries: TimeSeriesResponse | null;
};
export type State = DataState<Payload>;
export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export type Actions =
    | ReducerAction<'instagramTopAudiosIdle'>
    | ReducerAction<'loadInstagramTopAudios'>
    | ReducerActionWithPayload<'instagramTopAudiosSuccess', Payload>
    | ReducerActionWithPayload<'instagramTopAudiosErrorLoading', string>;

export const reducer = createReducer<State, Actions>({
    instagramTopAudiosIdle: (state) => ({
        ...state,
        status: 'idle',
        error: null,
    }),
    loadInstagramTopAudios: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    instagramTopAudiosErrorLoading: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    instagramTopAudiosSuccess: (_, { payload }) => ({
        data: payload,
        error: null,
        status: 'success',
    }),
});
