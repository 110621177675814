import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { PaginatedApiResponseData, instagram } from '@round/api';
import { createReducer } from 'helpers';

type Data = PaginatedApiResponseData<instagram.InstagramPost>;

export type State = DataState<Data>;
export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export type Actions =
    | ReducerAction<'instagramTopPostsIdle'>
    | ReducerAction<'loadInstagramTopPosts'>
    | ReducerActionWithPayload<'instagramTopPostsSuccess', Data>
    | ReducerActionWithPayload<'instagramTopPostsErrorLoading', string>;

export const reducer = createReducer<State, Actions>({
    instagramTopPostsIdle: (state) => ({
        ...state,
        status: 'idle',
        error: null,
    }),
    loadInstagramTopPosts: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    instagramTopPostsErrorLoading: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    instagramTopPostsSuccess: (_, { payload }) => ({
        data: payload,
        error: null,
        status: 'success',
    }),
});
