import { Image } from '@round/ui-kit';
import styles from './ProjectBadge.module.css';
import { ProjectOption } from '../hooks/useProjectSelect';

export const mapOptionToBadgeData = (option: ProjectOption): BadgeData => {
    return {
        name: option.label,
        brandName: option.brandName,
        teamName: option.teamName,
        imageUrl: option.brandImageUrl,
    };
};

export type BadgeData = {
    name: string;
    brandName?: string;
    teamName?: string;
    imageUrl?: string | null;
};

type Props = {
    data: BadgeData;
};

const ProjectBadge = ({ data: { name, brandName, teamName, imageUrl } }: Props) => {
    return (
        <div className={styles.container}>
            <Image className={styles.image} src={imageUrl || ''} alt={name} />
            <div className={styles.details}>
                <p className={styles.name}>{name ?? '-'}</p>
                <div className={styles.subDetails}>
                    <p>{brandName ?? '-'}</p>
                    <p>{teamName ?? '-'}</p>
                </div>
            </div>
        </div>
    );
};

export default ProjectBadge;
