import { AppServer, RouteHandlerContext } from '../mirage';
import { Response } from 'miragejs';
import * as influencerUserMirage from './influencerUser/influencerUser.mirage';
import * as userMirage from './user/tiktokUser.mirage';
import * as songSuggestionMirage from './songSuggestion/songSuggestion.mirage';
import * as tiktokAudioMirage from './audio/audio.mirage';
import * as tiktokAudioStatsMirage from './audioStats/audioStats.mirage';
import * as monitoredAudioMirage from './monitoredAudio/monitoredAudio.mirage';
import * as tiktokUserStatsMirage from './userStats/tiktokUserStats.mirage';
import * as clientMonitoredTiktokAudioRelationshipMirage from './clientMonitoredTiktokAudioRelationship/clientMonitoredTiktokAudioRelationship.mirage';
import * as tiktokAudioDailyStatsMirage from './audioStatsDaily/audioStatsDaily.mirage';
import * as videoMirage from './video/video.mirage';
import * as videoStatsMirage from './videoStats/videoStats.mirage';
import * as hashtagsMirage from './hashtags/hashtags.mirage';
import * as tiktokUserStatsCacheMirage from './userStatsCache/tiktokUserStatsCache.mirage';

export const models = {
    ...influencerUserMirage.models,
    ...userMirage.models,
    ...songSuggestionMirage.models,
    ...tiktokAudioMirage.models,
    ...monitoredAudioMirage.models,
    ...tiktokUserStatsMirage.models,
    ...tiktokAudioStatsMirage.models,
    ...clientMonitoredTiktokAudioRelationshipMirage.models,
    ...tiktokAudioDailyStatsMirage.models,
    ...videoMirage.models,
    ...videoStatsMirage.models,
    ...hashtagsMirage.models,
    ...tiktokUserStatsCacheMirage.models,
};

export const serializers = {
    ...influencerUserMirage.serializers,
    ...userMirage.serializers,
    ...songSuggestionMirage.serializers,
    ...tiktokAudioMirage.serializers,
    ...monitoredAudioMirage.serializers,
    ...tiktokAudioStatsMirage.serializers,
    ...clientMonitoredTiktokAudioRelationshipMirage.serializers,
    ...tiktokAudioDailyStatsMirage.serializers,
    ...videoMirage.serializers,
    ...videoStatsMirage.serializers,
    ...hashtagsMirage.serializers,
    ...tiktokUserStatsCacheMirage.serializers,
};

export const factories = {
    ...influencerUserMirage.factories,
    ...userMirage.factories,
    ...songSuggestionMirage.factories,
    ...tiktokAudioMirage.factories,
    ...monitoredAudioMirage.factories,
    ...tiktokUserStatsMirage.factories,
    ...tiktokAudioStatsMirage.factories,
    ...clientMonitoredTiktokAudioRelationshipMirage.factories,
    ...tiktokAudioDailyStatsMirage.factories,
    ...videoMirage.factories,
    ...videoStatsMirage.factories,
    ...hashtagsMirage.factories,
    ...tiktokUserStatsCacheMirage.factories,
};

export function handleTiktokRequests(server: AppServer) {
    influencerUserMirage.handleInfluencerUserRequests(server);
    userMirage.handleTiktokUserRequests(server);
    songSuggestionMirage.handleSongSuggestionRequests(server);
    tiktokAudioMirage.handleTiktokAudioRequests(server);
    monitoredAudioMirage.handleMonitoredAudioRequests(server);
    tiktokUserStatsMirage.handleTiktokUserStatsRequests(server);
    tiktokAudioStatsMirage.handleRequests(server);
    clientMonitoredTiktokAudioRelationshipMirage.handleRequests(server);
    tiktokAudioDailyStatsMirage.handleRequests(server);
    videoMirage.handleRequests(server);
    videoStatsMirage.handleRequests(server);
    hashtagsMirage.handleRequests(server);
    tiktokUserStatsCacheMirage.handleTiktokUserStatsCacheRequests(server);

    server.post(
        '/api/tiktok/trigger-audio-scraping/:monitoredAudioId/',
        function (this: RouteHandlerContext, schema, request) {
            const monitoredAudio = schema.find('tiktokMonitoredAudio', request.params.monitoredAudioId);
            if (!monitoredAudio) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            return new Response(204);
        }
    );
}
