import useNonNullContext from 'Hooks/useNonNullContext';
import { TiktokUserDataContext } from '../TiktokUserDataContext/TiktokUserDataContext';
import { useCallback } from 'react';
import { getTiktokUser, getTiktokUserImages } from '@round/api';
import useAbortableEffect from 'Hooks/useAbortableEffect';

export function useTiktokAccountData(userId: number | undefined | null) {
    const { user, dispatch } = useNonNullContext(TiktokUserDataContext);
    const isInitialized = user.status === 'success' || user.status === 'error';

    const fetchData = useCallback(
        async (userId: number, requestInit?: RequestInit) => {
            try {
                const [userResponse, userImageResponse] = await Promise.allSettled([
                    getTiktokUser(userId, requestInit),
                    getTiktokUserImages([userId], requestInit),
                ]);

                if (userResponse.status === 'fulfilled' && userImageResponse.status === 'fulfilled') {
                    dispatch({
                        type: 'tiktokUserDataLoaded',
                        payload: {
                            user: userResponse.value,
                            image: userImageResponse.value[0],
                        },
                    });
                    return;
                }

                dispatch({
                    type: 'errorLoadingTiktokUserData',
                    payload: "Couldn't fetch tiktok account data",
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'resetTiktokUserData' });
                    return;
                }

                dispatch({
                    type: 'errorLoadingTiktokUserData',
                    payload: "Couldn't fetch tiktok account data",
                });
            }
        },
        [dispatch]
    );

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && userId) {
                fetchData(userId, { signal });
            }
        },
        [userId, fetchData, isInitialized]
    );

    return {
        user: user,
    };
}
