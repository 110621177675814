import styles from './WhiteLabelPublicLayout.module.css';
import ErrorBoundary from 'utility/ErrorBoundary/ErrorBoundary';
import ErrorFallback from 'ui-new/whitelabel/ErrorFallback/ErrorFallback';
import { CSSProperties, ReactNode } from 'react';

type Props = {
    children?: ReactNode;
    style?: CSSProperties;
};

const WhiteLabelPublicLayout = ({ children, style }: Props) => {
    return (
        <div className={styles.container} style={style}>
            <ErrorBoundary fallbackRender={ErrorFallback}>{children}</ErrorBoundary>
        </div>
    );
};

export default WhiteLabelPublicLayout;
