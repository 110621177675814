import { call } from '../../helpers';
import { ApiResponse, ForbiddenResponse } from '../../types';
import { TimeSeries, TimeSeriesField, TimeSeriesType } from './timeSeries.types';

export type TimeSeriesRequestParams<T extends TimeSeriesField = TimeSeriesField> = {
    type: TimeSeriesType;
    ids: number[];
    fields: T[];
    start_date: string;
    end_date: string;
};

export type TimeSeriesResponse<T extends TimeSeriesField = TimeSeriesField> = {
    [id: number]: {
        [field in T]: TimeSeries[];
    };
};

export async function postTimeSeries<T extends TimeSeriesField>(
    params: TimeSeriesRequestParams<T>,
    signal?: AbortSignal
): Promise<ApiResponse<TimeSeriesResponse<T>, 200> | ForbiddenResponse> {
    const response = await call('/api/creatorbase/time-series/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
        signal,
    });

    if (response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get time series data');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
