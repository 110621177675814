import React from 'react';
import { NavBar } from 'SharedComponents/NavBar/NavBar';
import ApproveMicrowavePosts from 'Modules/Advertising/Microwave/ApprovePosts/ApproveMicrowavePosts';
import PageLayout from 'ui-new/Layout/PageLayout/PageLayout';

const ApproveMicrowavePostsPage = () => (
    <>
        <NavBar />
        <PageLayout>
            <ApproveMicrowavePosts />
        </PageLayout>
    </>
);

export default ApproveMicrowavePostsPage;
