import useNonNullContext from 'Hooks/useNonNullContext';
import { InstagramUserDataContext } from '../InstagramUserDataContext/InstagramUserDataContext';
import { useCallback } from 'react';
import { getInstagramUserAnalytics } from '@round/api';
import useAbortableEffect from 'Hooks/useAbortableEffect';

export function useUserAnalyticsData(userId: number | undefined | null) {
    const { analytics, dispatch } = useNonNullContext(InstagramUserDataContext);
    const isInitialized = analytics.status === 'success' || analytics.status === 'error';

    const fetchUserAnalyticsData = useCallback(
        async (userId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadInstagramUserAnalyticsData' });
                const response = await getInstagramUserAnalytics(userId, requestInit);
                if (response.status === 404) {
                    dispatch({ type: 'errorLoadingInstagramUserAnalyticsData', payload: 'Data not found' });
                    return;
                }

                dispatch({ type: 'instagramUserAnalyticsDataLoaded', payload: response.data.data });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'errorLoadingInstagramUserAnalyticsData',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch]
    );

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && userId) {
                fetchUserAnalyticsData(userId, { signal });
            }
        },
        [fetchUserAnalyticsData, userId, isInitialized]
    );

    return {
        analytics: analytics,
    };
}
