import { createContext, useReducer } from 'react';
import reducer, { initialState, TiktokUserDataActions, TiktokUserDataState } from './reducer';
import { FCWithChildren } from 'utility/utility.types';

type TiktokUserDataContextValues = TiktokUserDataState & TiktokUserDataContextUtils;
type TiktokUserDataContextUtils = {
    dispatch: (action: TiktokUserDataActions) => void;
};

export const TiktokUserDataContext = createContext<TiktokUserDataContextValues | null>(null);
export const TiktokUserDataProvider: FCWithChildren = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const values: TiktokUserDataContextValues = {
        ...state,
        dispatch,
    };

    return <TiktokUserDataContext.Provider value={values}>{children}</TiktokUserDataContext.Provider>;
};
