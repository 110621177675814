import InstagramTopPosts from '../TopPosts/Instagram/InstagramTopPosts';
import { useState } from 'react';
import styles from './InstagramData.module.css';
import { TabNav, TabNavItem, TabPanel, Tabs } from '@round/ui-kit';
import InstagramTopAudios from '../TopAudios/Instagram/InstagramTopAudios';

const InstagramData = () => {
    const [activeTable, setActiveTable] = useState<'top-audios' | 'top-posts'>('top-audios');

    return (
        <>
            <Tabs activeTab={activeTable} onChange={setActiveTable}>
                <TabNav indicatorClassName={styles.activeTabIndicator} trackClassName={styles.activeTabIndicatorTrack}>
                    <TabNavItem name="top-audios" className={styles.tabItem} activeClassName={styles.activeTab}>
                        Top Audios
                    </TabNavItem>
                    <TabNavItem name="top-posts" className={styles.tabItem} activeClassName={styles.activeTab}>
                        Top Posts
                    </TabNavItem>
                </TabNav>

                <TabPanel name="top-audios">
                    <InstagramTopAudios />
                </TabPanel>

                <TabPanel name="top-posts">
                    <InstagramTopPosts />
                </TabPanel>
            </Tabs>
        </>
    );
};

export default InstagramData;
