import { call, encodeUrlSearchParams } from '../../helpers';
import { ApiResponse, ForbiddenResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { InstagramUserStatsCache } from './instagramUserStatsCache.types';

export async function getInstagramUserStatsCache(
    params: Partial<PaginatedRequest> & { user_id: number },
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramUserStatsCache>, 200> | ForbiddenResponse> {
    const response = await call(`/api/instagram/user-stats-cache/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 403) {
        return {
            status: 403,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Couldn't fetch instagram user stats cache for user ${params.user_id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
