import useNonNullContext from 'Hooks/useNonNullContext';
import React, { createContext, useState, PropsWithChildren, SetStateAction, Dispatch } from 'react';

export type Platform = 'tiktok' | 'instagram';
export const ALL_PLATFORMS: Platform[] = ['tiktok', 'instagram'];

type State = Map<Platform, boolean>;
const Context = createContext<[State, Dispatch<SetStateAction<State>>, Platform[]] | null>(null);

export const PlatformFiltersProvider: React.FC<
    PropsWithChildren<{
        availablePlatforms?: Platform[];
    }>
> = ({ children, availablePlatforms = ALL_PLATFORMS }) => {
    const state = useState<Map<Platform, boolean>>(
        new Map<Platform, boolean>(ALL_PLATFORMS.map((platform) => [platform, true]))
    );

    return <Context.Provider value={[...state, availablePlatforms]}>{children}</Context.Provider>;
};

export function usePlatformFilters() {
    const [platformMap, setPlatformMap, availablePlatforms] = useNonNullContext(Context);

    const activePlatforms = Array.from(platformMap.entries())
        .filter(([platform, isActive]) => isActive && availablePlatforms.includes(platform))
        .map(([platform]) => platform);

    return {
        activePlatforms,
        togglePlatform: (platform: Platform): void => {
            const isActive = platformMap.get(platform);

            // Don't allow deactivating the last active platform
            if (isActive && activePlatforms.length === 1) {
                return;
            }

            setPlatformMap((map) => {
                map.set(platform, !isActive);
                return new Map(map);
            });
        },
        availablePlatforms,
    };
}
