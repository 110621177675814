import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TikTokInfluencerPlanHashtag } from './tiktokInfluencerPlanHashtag.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import {
    GetTikTokInfluencerPlanHashtagsParams,
    PostTikTokInfluencerPlanHashtagData,
} from './tiktokInfluencerPlanHashtags.api';

export const models = {
    tiktokInfluencerPlanHashtag: Model as ModelDefinition<TikTokInfluencerPlanHashtag>,
};

export const serializers = {
    tiktokInfluencerPlanHashtag: makeSerializer<TikTokInfluencerPlanHashtag>([]),
};

export const factories = {
    tiktokInfluencerPlanHashtag: createFactory<TikTokInfluencerPlanHashtag>({
        hashtag: (index: number) => `hashtag ${index}`,
        plan: (index: number) => index,
        tiktok_hashtag: (index: number) => index,
    }),
};

export function handleRequests(server: AppServer) {
    const BASE_URL = '/api/advertising/viewsets/tiktok-influencer-plan-hashtag/';

    server.get(BASE_URL, function (this: RouteHandlerContext, schema, request) {
        const { plan_id } = request.queryParams as GetTikTokInfluencerPlanHashtagsParams;
        const hashtags = schema.where('tiktokInfluencerPlanHashtag', { plan: plan_id });
        return buildPaginatedResponse(hashtags, {
            url: BASE_URL,
            queryParams: request.queryParams,
            serialize: () => this.serialize(hashtags, 'tiktokInfluencerPlanHashtag'),
        });
    });

    server.post(BASE_URL, (schema, request) => {
        const data = JSON.parse(request.requestBody) as PostTikTokInfluencerPlanHashtagData;
        const hashtag = schema.create('tiktokInfluencerPlanHashtag', { plan: data.plan, hashtag: data.hashtag });
        return new Response(201, {}, hashtag);
    });

    server.delete(`${BASE_URL}:id`, (schema, request) => {
        const hashtag = schema.find('tiktokInfluencerPlanHashtag', request.params.id);
        if (!hashtag) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        hashtag.destroy();
        return new Response(204);
    });
}
