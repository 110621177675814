import FullscreenModal from 'ui-new/whitelabel/FullscreenModal/FullscreenModal';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import { useProjectSelect } from './useProjectSelect';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import useReportData from '../ReportData/useReportData';
import ProjectSelectTable, { ProjectSelectTableRow } from './ProjectSelectTable/ProjectSelectTable';
import styles from './ProjectSelectModal.module.css';
import { useMemo, useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import Button from 'ui-new/whitelabel/Button/Button';
import { showNotification } from 'helpers';
import { useRefreshReportProjects } from '../hooks/useRefreshReportProjects';
import { useProjectSelectForm } from './useProjectSelectForm';
import SearchInput from 'ui-new/whitelabel/SearchInput/SearchInput';
import Checkbox from 'ui-new/whitelabel/Checkbox/Checkbox';
import { SkeletonizedValue } from '@round/ui-kit';
import { creatorbase } from '@round/api';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: (report: creatorbase.Report) => void;
};

const ProjectSelectModal = ({ isOpen, onClose, onSuccess }: Props) => {
    const [search, setSearch] = useState('');
    const [isShowSelectedOnlyActive, setIsShowSelectedOnlyActive] = useState(false);

    const { data: reportData, status: reportStatus } = useReportData();
    const refreshReportData = useRefreshReportProjects();
    const {
        projectsState,
        teamsData,
        brandsData,
        assigneesData,
        fetchData,
        resetList,
        getIsRowLoading,
    } = useProjectSelect();

    const handleClose = () => {
        resetList();
        onClose();
    };

    const { formik, status: formInitStatus } = useProjectSelectForm({
        onSubmitResponse: (response) => {
            if (response?.status === 200) {
                showNotification('Projects updated', 'info');
                onSuccess(response.data);
                refreshReportData();
                return;
            }

            showNotification('Failed to update projects', 'error');
        },
        onSubmitError: () => {
            showNotification('Failed to update projects', 'error');
        },
        isFormActive: isOpen,
    });

    const isListInitialized = projectsState.status === 'success' || projectsState.status === 'error';
    const isFormInitialized = formInitStatus === 'success' || formInitStatus === 'error';
    const hasError = projectsState.status === 'error' || reportStatus === 'error' || formInitStatus === 'error';

    const isLoading = !isListInitialized || !isFormInitialized;

    useAbortableEffect(
        (signal) => {
            if (
                isListInitialized ||
                !reportData?.team_id ||
                typeof projectsState.data?.lastFetchedPage === 'number' ||
                !isOpen
            ) {
                return;
            }

            fetchData(
                { page: 1, page_size: 25, accessible_by_team_id: reportData.team_id, search },
                { signal }
            ).catch(() => {});
        },
        [fetchData, isListInitialized, projectsState.data?.lastFetchedPage, search, reportData?.team_id, isOpen]
    );

    const fetchNextPage = async () => {
        if (projectsState.status === 'success' && projectsState.data.hasNextPage && reportData?.team_id) {
            fetchData({
                page: (projectsState.data.lastFetchedPage ?? 0) + 1,
                page_size: 25,
                accessible_by_team_id: reportData.team_id,
            }).catch(() => {});
        }
    };

    const listRows = useMemo<ProjectSelectTableRow[]>(() => {
        return (
            projectsState.data?.data.map((p) => {
                const team = teamsData[p.team_id]?.data ?? null;
                const brand = brandsData[p.brand_id]?.data ?? null;
                const users = Object.entries(assigneesData)
                    .filter(([userId]) => p.users_assigned_to_campaigns.includes(Number(userId)))
                    .map(([, data]) => data?.data)
                    .filter((data): data is Exclude<typeof data, undefined | null> => !!data);

                return { ...p, team, brand, users };
            }) ?? []
        );
    }, [assigneesData, brandsData, projectsState.data?.data, teamsData]);

    const selectedProjectCount = formik.values.projects.length;
    const selectedProjectsLabel = `${selectedProjectCount ? selectedProjectCount : 'No'} ${
        selectedProjectCount === 1 ? 'project' : 'projects'
    } selected`;

    return (
        <FullscreenModal
            className={styles.modal}
            isOpen={isOpen}
            onClose={handleClose}
            closeOnEscape
            closeOnOverlayClick
        >
            <Modal.Header>
                <Modal.Title>Edit projects</Modal.Title>
            </Modal.Header>

            <div className={styles.menuContainer}>
                <menu className={styles.filterMenu}>
                    <SearchInput
                        placeholder="Search projects"
                        value={search}
                        onChange={(val) => {
                            setSearch(val);
                            resetList();
                        }}
                        className={styles.searchInput}
                    />

                    <div className={styles.selectedOnlyFilter}>
                        <Checkbox
                            value={isShowSelectedOnlyActive}
                            onChange={() => {
                                setIsShowSelectedOnlyActive((prev) => !prev);
                            }}
                        />
                        <label
                            onClick={() => {
                                setIsShowSelectedOnlyActive((prev) => !prev);
                            }}
                        >
                            Show selected only
                        </label>
                    </div>
                </menu>

                <SkeletonizedValue isInitialized={isFormInitialized} width={'10rem'}>
                    <p className={styles.countLabel}>{selectedProjectsLabel}</p>
                </SkeletonizedValue>
            </div>

            <Modal.Body>
                <ProjectSelectTable
                    containerClassName={styles.table}
                    data={isShowSelectedOnlyActive ? formik.values.projects : listRows}
                    isLoading={isLoading}
                    hasError={hasError}
                    onEndReached={() => {
                        if (isShowSelectedOnlyActive) {
                            return;
                        }

                        fetchNextPage();
                    }}
                    rowSelection={formik.values.projects.reduce((acc, project) => {
                        acc[`${project.id}`] = true;
                        return acc;
                    }, {} as RowSelectionState)}
                    onRowSelectionChange={(selection) => {
                        const persistingSelectedProjects = formik.values.projects.filter(
                            (project) => selection[`${project.id}`]
                        );

                        const newProjects = listRows.filter(
                            (row) =>
                                selection[`${row.id}`] &&
                                !persistingSelectedProjects.find((project) => project.id === row.id)
                        );

                        formik.setFieldValue('projects', [...persistingSelectedProjects, ...(newProjects ?? [])]);
                    }}
                    meta={{ getIsRowLoading }}
                />
            </Modal.Body>
            <Modal.Actions>
                <Button
                    appearance="primary"
                    onClick={() => formik.handleSubmit()}
                    isLoading={formik.isSubmitting}
                    disabled={formik.isSubmitting || !formik.dirty || hasError}
                >
                    Save
                </Button>

                <Button appearance="ghost" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Actions>
        </FullscreenModal>
    );
};

export default ProjectSelectModal;
