import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Campaign } from './campaigns.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { GetCampaignsParams, PostCampaignBody } from './campaigns.api';

export const models = {
    creatorbaseCampaign: Model as ModelDefinition<Campaign>,
};

export const serializers = {
    creatorbaseCampaign: makeSerializer<Campaign>([]),
};

export const factories = {
    creatorbaseCampaign: createFactory<Campaign>({
        cost: 0,
        currency_id: 1,
        instagram_details: null,
        tiktok_details: null,
        youtube_details: null,
        is_post_cost_editable: false,
        name: (index: number) => `campaign name ${index}`,
        notes: '',
        platform: 'youtube',
        project_id: (index: number) => index,
        team_members: [],
        managed_by: 'creatorbase',
    }),
};

export function handleRequests(server: AppServer) {
    const campaignsRoot = '/api/creatorbase/campaigns/';
    server.get(campaignsRoot, function (this: RouteHandlerContext, schema, request) {
        const { id, project_id, platform } = request.queryParams as GetCampaignsParams;
        const ids = id
            ? id
                  .split(',')
                  .filter((v) => !!v)
                  .map(Number)
            : [];

        const campaigns = schema
            .all('creatorbaseCampaign')
            .filter((campaign) => !ids.length || ids.includes(Number(campaign.id)))
            .filter((campaign) => !project_id || Number(campaign.project_id) === Number(project_id))
            .filter((campaign) => !platform || platform === campaign.platform);

        return buildPaginatedResponse(campaigns, {
            url: campaignsRoot,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseCampaign'),
        });
    });

    server.get(`${campaignsRoot}:id`, (schema, request) => {
        const campaign = schema.find('creatorbaseCampaign', request.params.id);
        if (!campaign) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return campaign;
    });

    server.post(campaignsRoot, (schema, request) => {
        const data: PostCampaignBody = JSON.parse(request.requestBody);

        const campaign = schema.create('creatorbaseCampaign', {
            cost: data.cost,
            currency_id: data.currency_id,
            is_post_cost_editable: data.is_post_cost_editable,
            name: data.name,
            platform: data.platform,
            team_members: data.team_members,
        });

        if (data.platform === 'tiktok') {
            const audio = schema.findOrCreateBy('tiktokAudio', { tiktok_id: data.tiktok_details?.audio_identifier });
            campaign.update({ tiktok_details: { audio_id: audio.id } });
        }

        if (data.platform === 'instagram') {
            const audio = schema.findOrCreateBy('instagramAudio', {
                instagram_id: data.instagram_details.audio_identifier,
            });
            campaign.update({ instagram_details: { audio_id: audio.id } });
        }

        return campaign;
    });

    server.patch(`${campaignsRoot}:id/`, (schema, request) => {
        const campaign = schema.find('creatorbaseCampaign', request.params.id);
        if (!campaign) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        campaign.update(JSON.parse(request.requestBody));
        return campaign;
    });

    server.delete(`${campaignsRoot}:id/`, (schema, request) => {
        const campaign = schema.find('creatorbaseCampaign', request.params.id);
        if (!campaign) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        const posts = schema.where('creatorbasePost', { campaign_id: Number(campaign.id) });
        posts.destroy();
        campaign.destroy();
        return new Response(204);
    });
}
