import { applicationManager } from '@round/api';
import { ReactComponent as RoundLogo } from 'assets/brand/LogoShort.svg';
import { ReactComponent as MicroWavLogo } from 'assets/brand/MicrowaveShort.svg';
import Badge from 'ui-new/whitelabel/Badge/Badge';
import styles from './ApplicationBadge.module.css';
import cn from 'classnames';

type Application = Exclude<applicationManager.Application, 'creatorbase'>;

const logos: Record<Application, typeof RoundLogo> = {
    microwave: MicroWavLogo,
    round: RoundLogo,
};

const labels: Record<Application, string> = {
    microwave: 'Micro.wav',
    round: 'Round',
};

type Props = {
    application: Application;
    className?: string;
};

const ApplicationBadge = ({ application, className }: Props) => {
    const AppIcon = logos[application];
    return (
        <Badge className={cn(styles.badge, className)}>
            <span className={styles.iconContainer}>
                <AppIcon className={styles.icon} />
            </span>
            <span>{labels[application]}</span>
        </Badge>
    );
};

export default ApplicationBadge;
