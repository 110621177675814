import HeadlineStatsComponent, { Stats } from 'Modules/Plans/components/HeadlineStats/HeadlineStats';
import { tooltipCopy } from 'Modules/Plans/helpers';
import { DataState, formatNumberToKNotation, toDecimalPoint } from '@round/utils';
import styles from './HeadlineStats.module.css';
import { InstagramUserStatsCache, TiktokUserStatsCache } from '@round/api';

type Props = {
    userStatsCache: DataState<TiktokUserStatsCache> | DataState<InstagramUserStatsCache>;
};

export default function HeadlineStats({ userStatsCache }: Props) {
    const isLoading = userStatsCache.status === 'loading';
    const followerCount = userStatsCache?.data?.follower_count;
    const avgPostViews = userStatsCache?.data?.avg_post_views;

    const stats: Stats = {
        followers: {
            label: 'Followers',
            value: followerCount ? formatNumberToKNotation(followerCount) : '-',
        },
        engagementRate: {
            label: 'Engagement Rate',
            value: userStatsCache?.data?.avg_engagement_rate
                ? `${toDecimalPoint(userStatsCache?.data?.avg_engagement_rate * 100, 2)}%`
                : '-',
            tooltip: tooltipCopy.engagementRate,
        },
        averageVideoPlays: {
            label: 'Average Video Plays',
            value: avgPostViews ? formatNumberToKNotation(avgPostViews) : '-',
        },
    };

    return <HeadlineStatsComponent stats={stats} isLoading={isLoading} className={styles.headlineStats} />;
}
