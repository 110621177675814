import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { creatorbase } from '@round/api';
import { createReducer } from 'helpers';

type CreationsTimeSeries = creatorbase.TimeSeriesResponse<
    'tiktok_daily_change' | 'tiktok_daily_totals' | 'instagram_daily_change' | 'instagram_daily_totals'
>[keyof creatorbase.TimeSeriesResponse];

export type State = DataState<CreationsTimeSeries>;
export const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export type Actions =
    | ReducerAction<'loadCreationsTimeSeries'>
    | ReducerActionWithPayload<'creationsTimeSeriesInitialized', CreationsTimeSeries>
    | ReducerActionWithPayload<'creationsTimeSeriesErrorLoading', string>;

export const reducer = createReducer<State, Actions>({
    loadCreationsTimeSeries: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    creationsTimeSeriesErrorLoading: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    creationsTimeSeriesInitialized: (state, { payload }) => ({
        error: null,
        status: 'success',
        data: payload,
    }),
});
