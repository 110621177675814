import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Response } from 'miragejs';
import { buildPaginatedResponse } from '../../../mirage.helpers';

export function handleRequests(server: AppServer) {
    const baseEndpoint = '/api/apex/admin/creator-agency/';
    server.get(baseEndpoint, function (this: RouteHandlerContext, schema, request) {
        const agencies = schema.all('apexCreatorAgency');
        return buildPaginatedResponse(agencies, {
            url: baseEndpoint,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'apexCreatorAgency'),
        });
    });

    server.get(`${baseEndpoint}:id/`, function (this: RouteHandlerContext, schema, request) {
        const agency = schema.find('apexCreatorAgency', request.params.id);
        if (!agency) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return agency;
    });
}
