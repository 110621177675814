import React from 'react';
import LoadingSpinner from '../../../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import styles from './EmailContent.module.css';
import { microwave } from '@round/api';

type Props = {
    isLoading: boolean;
    error: string[] | null;
    preview: microwave.EmailPreview | null;
};

const EmailContent = (props: Props) => {
    if (props.error?.length) {
        return (
            <>
                {props.error.map((error) => (
                    <p key={error}>{error}</p>
                ))}
            </>
        );
    }

    if (props.isLoading) {
        return <LoadingSpinner containerClassName={styles.loadingSpinner} />;
    }

    return (
        <>
            <div className={styles.subject}>{props.preview?.subject}</div>
            <div className={styles.body} dangerouslySetInnerHTML={{ __html: props.preview?.body ?? '' }} />
        </>
    );
};

export default EmailContent;
