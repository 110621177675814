import { instagram } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { SongDataContext } from '../../SongDataContext';
import { isNumber } from 'utility/utility';
import uniq from 'lodash/uniq';
import makeInstagramAudiosByIdDataHook from 'Modules/Instagram/hooks/dataHooks/useInstagramAudiosByIdDataHook';

const useInstagramAudios = makeInstagramAudiosByIdDataHook(SongDataContext, ([value]) => value.instagram.audios);

export default function useInstagramTopPosts() {
    const songData = useNonNullContextSelector(SongDataContext, (value) => value[0].song);
    const state = useNonNullContextSelector(SongDataContext, (value) => value[0].instagram.topPosts);
    const dispatch = useNonNullContextSelector(SongDataContext, (value) => value[1]);

    const { data: audiosData, fetchData: fetchAudios } = useInstagramAudios({
        isImperative: true,
    });

    const fetchData = useCallback(
        async (params: Omit<instagram.GetInstagramPostsParams, 'audio_id'>, requestInit?: RequestInit) => {
            const audioIds = uniq([
                ...(songData.data?.song.instagram_audios || []),
                ...(songData.data?.musicSong?.instagram_audios || []),
            ]);

            if (!audioIds?.length) {
                dispatch({
                    type: 'instagramTopPostsSuccess',
                    payload: { count: 0, results: [], next: null, previous: null },
                });
                return;
            }

            dispatch({ type: 'loadInstagramTopPosts' });

            try {
                const response = await instagram.getInstagramPosts(
                    {
                        ...params,
                        audio_id: audioIds.join(','),
                    },
                    requestInit
                );

                if (response.status !== 200) {
                    dispatch({
                        type: 'instagramTopPostsErrorLoading',
                        payload: response.data.detail,
                    });
                    return;
                }

                const audioIdsFromPosts = uniq(response.data.results.map((post) => post.audio_id).filter(isNumber));
                if (audioIdsFromPosts.length) {
                    await fetchAudios(audioIdsFromPosts, requestInit).catch(() => {});
                }

                dispatch({
                    type: 'instagramTopPostsSuccess',
                    payload: response.data,
                });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'instagramTopPostsIdle' });
                    throw e;
                }

                dispatch({
                    type: 'instagramTopPostsErrorLoading',
                    payload: e instanceof Error ? e.message : 'Could not load Instagram posts',
                });
                throw e;
            }
        },
        [dispatch, songData.data?.song.instagram_audios, songData.data?.musicSong?.instagram_audios, fetchAudios]
    );

    const reset = useCallback(() => {
        dispatch({ type: 'instagramTopPostsIdle' });
    }, [dispatch]);

    return {
        ...state,
        audiosData,
        fetchData,
        reset,
    };
}
