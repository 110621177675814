import { createContext, useReducer } from 'react';
import reducer, { initialState, InstagramUserDataAction, InstagramUserDataState } from './reducer';
import { FCWithChildren } from 'utility/utility.types';

type InstagramUserDataContextValues = InstagramUserDataState & InstagramUserDataContextUtils;
type InstagramUserDataContextUtils = {
    dispatch: (action: InstagramUserDataAction) => void;
};

export const InstagramUserDataContext = createContext<InstagramUserDataContextValues | null>(null);
export const InstagramUserDataProvider: FCWithChildren = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const values: InstagramUserDataContextValues = {
        ...state,
        dispatch,
    };
    return <InstagramUserDataContext.Provider value={values}>{children}</InstagramUserDataContext.Provider>;
};
