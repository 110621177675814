import React, { useCallback, useContext, useMemo, useState } from 'react';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import PlanForm, { PlanFormValues } from '../components/PlanForm/PlanForm';
import { FormikHelpers, FormikProps } from 'formik';
import { showNotification } from 'helpers';
import Modal, { ModalContent, ModalFooter, ModalTitle } from 'ui/General/Modal/Modal';
import Button from 'ui/Buttons/Button/Button';
import styles from './EditPlanModal.module.css';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import { Banner } from '@round/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { applicationManagerQueries } from '@round/api';
import useNonNullContext from 'Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { TiktokCreatorsContext } from '../../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import { YoutubeCreatorsStateContext } from '../../../contexts/YoutubeCreatorsContext/YoutubeCreatorsContext';

const appManagerOptions = applicationManagerQueries.project.options;

type EditPlanModalProps = {
    isModalOpen: boolean;
    onClose: () => void;
};

const EditPlanModal = ({ isModalOpen, onClose }: EditPlanModalProps) => {
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState<string | null>(null);
    const {
        updateInfluencerPlan,
        deleteInfluencerPlan,
        influencerPlan,
        plannerUsers,
        refreshPlannerUsers,
    } = useContext(InfluencerPlanContext);

    const { instagramInfluencerPostGroups, isPrefetchCompleted: isInstagramPostGroupsInitialized } = useNonNullContext(
        InstagramCreatorsContext
    );

    const {
        tiktokInfluencerPostGroups,
        tiktokInfluencerPostGroupsInitialized,
        tiktokInfluencerPostGroupsLoadingError,
    } = useNonNullContext(TiktokCreatorsContext);

    const {
        youtubeInfluencerPostGroups,
        isYoutubeInfluencerPostGroupsInitialized,
        youtubeInfluencerPostGroupsLoadingError,
    } = useNonNullContext(YoutubeCreatorsStateContext);

    const hasErrorLoadingGroups =
        !isInstagramPostGroupsInitialized ||
        Boolean(tiktokInfluencerPostGroupsLoadingError) ||
        Boolean(youtubeInfluencerPostGroupsLoadingError);

    const areGroupsLoading =
        !isInstagramPostGroupsInitialized ||
        !tiktokInfluencerPostGroupsInitialized ||
        !isYoutubeInfluencerPostGroupsInitialized;

    const hasGroupsSharedWithCreatorbase =
        tiktokInfluencerPostGroups.some((g) => g.is_shared_with_creatorbase) ||
        instagramInfluencerPostGroups.some((g) => g.is_shared_with_creatorbase) ||
        !!youtubeInfluencerPostGroups?.some((g) => g.is_shared_with_creatorbase);

    const { data: appManagerProject, status: appManagerProjectStatus } = useQuery({
        ...appManagerOptions.detail(influencerPlan?.application_manager_project_id),
        enabled: !!influencerPlan?.application_manager_project_id,
    });

    const userHasAdmin = useCheckUserGroupsAccess(['user_admin']);

    const initialValues: PlanFormValues = useMemo(() => {
        return {
            currency: influencerPlan?.currency
                ? { value: influencerPlan.currency.id, label: influencerPlan.currency.name }
                : undefined,
            start_date: influencerPlan?.start_date ?? new Date().toISOString(),
            end_date: influencerPlan?.end_date ?? new Date().toISOString(),
            instagram_budget: influencerPlan?.instagram_budget ?? '0',
            tiktok_budget: influencerPlan?.tiktok_budget ?? '0',
            youtube_budget: influencerPlan?.youtube_budget ?? '0',
            planners: plannerUsers.map((user) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
            })),
        };
    }, [
        influencerPlan?.currency,
        influencerPlan?.end_date,
        influencerPlan?.instagram_budget,
        influencerPlan?.start_date,
        influencerPlan?.tiktok_budget,
        influencerPlan?.youtube_budget,
        plannerUsers,
    ]);

    const handleSubmit = useCallback(
        async (values: PlanFormValues, helpers: FormikHelpers<PlanFormValues>) => {
            if (!influencerPlan || !values.currency || !updateInfluencerPlan) {
                return;
            }

            try {
                helpers.setSubmitting(true);
                const response = await updateInfluencerPlan({
                    release_id: influencerPlan.release.id,
                    currency_id: values.currency.value,
                    tiktok_budget: values.tiktok_budget.toString(),
                    end_date: values.end_date,
                    start_date: values.start_date,
                    instagram_budget: values.instagram_budget.toString(),
                    youtube_budget: values.youtube_budget?.toString(),
                    ...(userHasAdmin && { planners: values.planners ? values.planners.map((val) => val.value) : [] }),
                });

                if (response.status === 404 || response.status === 403) {
                    showNotification(response.data.detail, 'error');
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(
                        mapApiErrorsToFormikErrors({
                            ...response.data,
                            currency: response.data.currency_id,
                        })
                    );
                    return;
                }

                if (values.planners) {
                    refreshPlannerUsers(values.planners.map((planner) => planner.value));
                }

                onClose();
                showNotification('Plan updated', 'info');
            } catch {
                showNotification('Could not update plan', 'error');
            } finally {
                helpers.setSubmitting(false);
            }
        },
        [influencerPlan, updateInfluencerPlan, userHasAdmin, onClose, refreshPlannerUsers]
    );

    const handleDelete = useCallback(async () => {
        if (!influencerPlan) {
            return;
        }

        setDeleteError(null);
        try {
            setDeleteLoading(true);
            const response = await deleteInfluencerPlan?.();
            if (!response) {
                showNotification('Could not delete plan', 'error');
                return;
            }

            if (response.status === 404 || response.status === 403 || response.status === 400) {
                setDeleteError(response.data.detail);
                return;
            }

            showNotification('Plan deleted', 'info');
            setShowDeletePrompt(false);
            onClose();
        } catch {
            showNotification('Could not delete plan', 'error');
        } finally {
            setDeleteLoading(false);
        }
    }, [deleteInfluencerPlan, influencerPlan, onClose]);

    const handleCloseDeleteConfirmation = () => {
        setShowDeletePrompt(false);
        setDeleteError(null);
    };

    return (
        <>
            <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={onClose}>
                <ModalTitle>Edit Plan</ModalTitle>
                <ModalContent className={styles.content}>
                    <PlanForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        renderActions={({ isSubmitting, submitForm, dirty }: FormikProps<PlanFormValues>) => (
                            <div className={styles.renderActions}>
                                <Button
                                    className={styles.submitButton}
                                    type="filled"
                                    color="black"
                                    disabled={!dirty}
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={styles.deleteButton}
                                    disabled={
                                        appManagerProjectStatus !== 'success' ||
                                        areGroupsLoading ||
                                        hasErrorLoadingGroups ||
                                        hasGroupsSharedWithCreatorbase
                                    }
                                    type="filled"
                                    color="negative"
                                    onClick={() => setShowDeletePrompt(true)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    />

                    {(appManagerProjectStatus === 'error' || hasErrorLoadingGroups) && (
                        <Banner appearance="warning" className={styles.warningBanner}>
                            Plan cannot be deleted due to technical issues. <br />
                            Please, contact tech support
                        </Banner>
                    )}

                    {hasGroupsSharedWithCreatorbase && (
                        <Banner appearance="warning" className={styles.warningBanner}>
                            Plan cannot be deleted. <br />
                            Plan has groups shared with creatorbase
                        </Banner>
                    )}
                </ModalContent>
            </Modal>

            <Modal
                className={styles.deletePlan}
                closeOnOverlayClick
                isOpen={showDeletePrompt}
                onClose={handleCloseDeleteConfirmation}
            >
                <ModalTitle>Delete influencer plan?</ModalTitle>
                <ModalContent className={styles.deletePlanContent}>
                    <p>
                        You are going to delete influencer plan for "{influencerPlan?.release.name}". This operation
                        cannot be undone
                    </p>

                    {Boolean(appManagerProject?.creatorbase_project_id) && (
                        <Banner appearance="warning" className={styles.warningBanner}>
                            Plan is linked to creatorbase project. <br />
                            It will not delete creatorbase project but linked data will be removed
                        </Banner>
                    )}

                    {deleteError && (
                        <Banner
                            appearance="danger"
                            displayClose
                            onClose={() => setDeleteError(null)}
                            className={styles.warningBanner}
                        >
                            {deleteError}
                        </Banner>
                    )}
                </ModalContent>
                <ModalFooter align="center">
                    <Button onClick={handleCloseDeleteConfirmation}>Cancel</Button>
                    <Button type="filled" color="black" loading={deleteLoading} onClick={handleDelete}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditPlanModal;
