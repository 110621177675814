import { Form, FormikErrors } from 'formik';
import CampaignFormBase, {
    BaseCampaignFormValues,
    getBaseCampaignFormFields,
    mapCampaignApiErrorsToFormikErrors,
} from '../../components/CampaignFormBase/CampaignFormBase';
import { FormField } from 'ui-new/whitelabel/formik';
import { creatorbase } from '@round/api';
import { mapApiErrorsToFormikErrors } from 'utility/utility';
import styles from './InstagramCampaignForm.module.css';

export type InstagramCampaignFormValues = BaseCampaignFormValues & {
    audio_identifier: string;
};

type Props = {
    readonlyFields?: Array<keyof InstagramCampaignFormValues>;
    teamId: number | undefined;
    isManagedExternally?: boolean;
};

const InstagramCampaignForm = ({ readonlyFields, teamId, isManagedExternally }: Props) => {
    return (
        <Form className={styles.form}>
            <CampaignFormBase
                readonlyFields={getBaseCampaignFormFields(readonlyFields)}
                teamId={teamId}
                isManagedExternally={isManagedExternally}
            />

            <FormField
                name="audio_identifier"
                label="Audio"
                placeholder="Enter audio id or url"
                disabled={readonlyFields?.includes('audio_identifier')}
            />
        </Form>
    );
};

export const mapInstagramCampaignApiErrorsToFormikErrors = (
    apiErrors: creatorbase.PostCampaignErrorResponse
): FormikErrors<InstagramCampaignFormValues> => {
    return {
        ...mapCampaignApiErrorsToFormikErrors(apiErrors),
        ...mapApiErrorsToFormikErrors({
            audio_identifier: apiErrors.instagram_details?.audio_identifier,
        }),
    };
};

export default InstagramCampaignForm;
