import { useTimeSeries } from 'Modules/Plans/hooks/useTimeSeries';
import moment from 'moment';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import SongGrowthChart from 'Modules/Plans/Songs/components/SongGrowthChart/SongGrowthChart';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type Props = {
    songId: number | null | undefined;
} & Pick<DivProps, 'onClick'>;

const ProjectSongChart = ({ songId, onClick }: Props) => {
    const { state, fetchData } = useTimeSeries<'tiktok_daily_change' | 'instagram_daily_change'>();
    const data = songId ? state[songId] : null;
    const status = data?.status;
    const isInitialized = !songId || status === 'success' || status === 'error';

    useAbortableEffect(() => {
        if (songId && !isInitialized) {
            fetchData({
                type: 'song',
                ids: [songId],
                fields: ['tiktok_daily_change', 'instagram_daily_change'],
                start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                end_date: today.format('YYYY-MM-DD'),
            }).catch(() => {});
        }
    }, [songId, isInitialized, fetchData]);

    const timeSeries = data?.data ?? null;

    return (
        <SongGrowthChart
            emptyChartLineColor="#FFFFFF50"
            lineColor="#FFF"
            isLoading={!isInitialized}
            timeSeries={timeSeries}
            hasError={status === 'error'}
            onClick={onClick}
        />
    );
};

export default ProjectSongChart;
