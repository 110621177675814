import useAbortableEffect from 'Hooks/useAbortableEffect';
import useTiktokPostStats from './useTiktokTopPosts';
import { useState } from 'react';
import styles from './TiktokTopPosts.module.css';
import { creatorbase, OrderingValues } from '@round/api';
import TopPostsTable from '../Table/TopPostsTable';
import { ColumnDef } from '@tanstack/react-table';
import { SkeletonTableCell } from '@round/ui-kit';
import { buildTiktokMusicUrl, buildTiktokPostUrl, formatDateObjShort, numberWithCommas } from 'helpers';
import TruncatedLinkCell, { LinkData } from '../Table/Cells/TruncatedLink/TruncatedLinkCell';

export type TiktokTopPostsTableRow = creatorbase.PostStats & {
    audio: creatorbase.TiktokAudio | null;
};

const TiktokTopPosts = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState<OrderingValues<creatorbase.PostStatsSortableKeys>>('-play_count');

    const { data, status, fetchData, reset } = useTiktokPostStats();
    const isInitialized = status === 'success' || status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData(page, pageSize, ordering, signal).catch(() => {});
            }
        },
        [fetchData, isInitialized, page, pageSize, ordering]
    );

    const rows: TiktokTopPostsTableRow[] =
        data?.postsStats.results?.map((post) => ({
            ...post,
            audio: data.audios.find((audio) => audio.id === post.audio_id) ?? null,
        })) ?? [];

    const columns: ColumnDef<TiktokTopPostsTableRow, any>[] = [
        {
            header: 'Username',
            accessorFn: (row) => row.author_username || '-',
            cell: SkeletonTableCell,
        },
        {
            header: 'Followers',
            accessorFn: (row) => (row.author_follower_count ? numberWithCommas(row.author_follower_count) : '-'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Location',
            accessorFn: (row) => row.location || '-',
            cell: SkeletonTableCell,
        },
        {
            header: 'Description',
            id: 'caption',
            accessorFn: (row): LinkData => ({
                href: buildTiktokPostUrl(row.author_username, row.tiktok_id),
                value: row.caption || '-',
            }),
            cell: TruncatedLinkCell,
        },
        {
            header: 'Audio',
            id: 'audio',
            meta: { className: styles.truncatedColumn },
            accessorFn: (row): LinkData => {
                const url =
                    row.audio?.title && row.audio.tiktok_id
                        ? buildTiktokMusicUrl(row.audio.title, row.audio.tiktok_id)
                        : undefined;

                const info = !row.audio
                    ? undefined
                    : `${row.audio.title || row.audio.tiktok_id}${
                          row.audio?.author_name ? ` - ${row.audio?.author_name}` : ''
                      }`;

                return {
                    href: url,
                    value: info || '-',
                };
            },
            cell: TruncatedLinkCell,
        },
        {
            header: 'Audio Type',
            id: 'type',
            accessorFn: (row) => (row.audio?.is_original ? 'Original Audio' : 'Official Sound'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Date Posted',
            accessorFn: (row) => formatDateObjShort(new Date(row.create_time * 1000)),
            cell: SkeletonTableCell,
        },
        {
            header: 'Views',
            id: 'play_count',
            accessorFn: (row) => (row.play_count ? numberWithCommas(row.play_count) : '-'),
            enableSorting: true,
            cell: SkeletonTableCell,
        },
        {
            header: 'Shares',
            accessorFn: (row) => (row.share_count ? numberWithCommas(row.share_count) : '-'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Saves',
            accessorFn: (row) => (row.save_count ? numberWithCommas(row.save_count) : '-'),
            cell: SkeletonTableCell,
        },
    ];

    return (
        <section className={styles.container}>
            <TopPostsTable
                page={page}
                setPage={(page) => {
                    setPage(page);
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setPageSize(pageSize);
                    reset();
                }}
                ordering={ordering}
                onOrderingChange={(ordering) => {
                    setOrdering(ordering[0] || '-play_count');
                    reset();
                }}
                count={data?.postsStats.count ?? 0}
                data={rows ?? []}
                columns={columns}
                meta={{ isLoading: !isInitialized }}
                hasError={status === 'error'}
            />
        </section>
    );
};

export default TiktokTopPosts;
