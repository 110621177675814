import useAbortableEffect from 'Hooks/useAbortableEffect';
import useInstagramTopPosts from './useInstagramTopPosts';
import styles from './InstagramTopPosts.module.css';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { SkeletonTableCell } from '@round/ui-kit';
import { buildInstagramAudioUrl, numberWithCommas } from 'helpers';
import moment from 'moment';
import TopPostsTable from '../Table/TopPostsTable';
import { instagram, OrderingValues } from '@round/api';
import TruncatedLinkCell, { LinkData } from '../Table/Cells/TruncatedLink/TruncatedLinkCell';
import useSongData from '../../SongData/useSongData';

export type InstagramTopPostsTableRow = instagram.InstagramPost & {
    audio: instagram.InstagramAudio | null;
};

const InstagramTopPosts = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState<OrderingValues<instagram.GetInstagramPostsSortableKeys>>('-view_count');

    const { data, audiosData, status, fetchData, reset } = useInstagramTopPosts();
    const isInitialized = status === 'success' || status === 'error';

    const { status: songDataStatus } = useSongData();
    const isSongDataInitialized = songDataStatus === 'success' || songDataStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (isInitialized || !isSongDataInitialized) {
                return;
            }
            fetchData({ page, page_size: pageSize, ordering }, { signal }).catch(() => {});
        },
        [fetchData, isInitialized, isSongDataInitialized, page, pageSize, ordering]
    );

    const rows: InstagramTopPostsTableRow[] =
        data?.results.map((post) => ({
            ...post,
            audio: post.audio_id ? audiosData[post.audio_id]?.data || null : null,
        })) ?? [];

    const columns: ColumnDef<InstagramTopPostsTableRow, any>[] = [
        {
            header: 'Username',
            accessorFn: (row) => row.owner_username || '-',
            cell: SkeletonTableCell,
        },
        {
            header: 'Followers',
            accessorFn: (row) => (row.owner_follower_count ? numberWithCommas(row.owner_follower_count) : '-'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Location',
            accessorFn: (row) => row.location || '-',
            cell: SkeletonTableCell,
        },
        {
            header: 'Description',
            accessorFn: (row): LinkData => ({
                href: row.post_url,
                value: row.text || '-',
            }),
            cell: TruncatedLinkCell,
        },
        {
            header: 'Audio',
            id: 'audio',
            meta: { className: styles.truncatedColumn },
            accessorFn: (row): LinkData => {
                const url = row.audio?.instagram_id ? buildInstagramAudioUrl(row.audio.instagram_id) : undefined;

                const info = !row.audio
                    ? undefined
                    : `${row.audio.title || row.audio.instagram_id}${
                          row.audio.author_name ? ` - ${row.audio?.author_name}` : ''
                      }`;

                return {
                    href: url,
                    value: info || '-',
                };
            },
            cell: TruncatedLinkCell,
        },
        {
            header: 'Date Posted',
            accessorFn: (row) => (row.taken_at ? moment(row.taken_at).format('D MMM YY') : '-'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Plays',
            enableSorting: true,
            id: 'play_count',
            accessorFn: (row) => (row.play_count ? numberWithCommas(row.play_count) : '-'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Views',
            enableSorting: true,
            id: 'view_count',
            accessorFn: (row) => (row.view_count ? numberWithCommas(row.view_count) : '-'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Likes',
            accessorFn: (row) => (row.like_count ? numberWithCommas(row.like_count) : '-'),
            cell: SkeletonTableCell,
        },
        {
            header: 'Comments',
            accessorFn: (row) => (row.comment_count ? numberWithCommas(row.comment_count) : '-'),
            cell: SkeletonTableCell,
        },
    ];

    return (
        <section className={styles.container}>
            <TopPostsTable
                page={page}
                setPage={(page) => {
                    setPage(page);
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setPageSize(pageSize);
                    setPage(1);
                    reset();
                }}
                ordering={ordering}
                onOrderingChange={(ordering) => {
                    setOrdering(ordering[0]);
                    reset();
                }}
                count={data?.count ?? 0}
                data={rows}
                columns={columns}
                meta={{ isLoading: !isInitialized }}
                hasError={status === 'error'}
            />
        </section>
    );
};

export default InstagramTopPosts;
