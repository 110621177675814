import { combineReducers } from 'helpers';
import songReducer, { songDataInitialState } from './SongData/reducer';
import { tiktokReducer, tiktokInitialState } from './Tiktok/reducer';
import { instagramReducer, instagramInitialState } from './Instagram/reducer';
import {
    reducer as creationsTimeSeriesReducer,
    initialState as creationsTimeSeriesInitialState,
} from './Creations/reducer';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

const reducer = combineReducers({
    song: songReducer,
    tiktok: tiktokReducer,
    instagram: instagramReducer,
    creationsTimeSeries: creationsTimeSeriesReducer,
});

export const initialState: State = {
    song: songDataInitialState,
    tiktok: tiktokInitialState,
    instagram: instagramInitialState,
    creationsTimeSeries: creationsTimeSeriesInitialState,
};

export default reducer;
