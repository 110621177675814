import { ScrollView, Table, TableProps } from '@round/ui-kit';
import cn from 'classnames';
import styles from './PostsTable.module.css';
import { useState } from 'react';
import { useRectObserver } from 'Hooks/useRectObserver';
import { creatorbase } from '@round/api';
import { BasePostTableMeta } from '../../types';
import { getApplicationStyleVariables } from 'ui-new/whitelabel/utils/applicationStyles/applicationStyles';
import PostsNoDataLabel from 'Modules/Plans/Project/ProjectDetails/components/PostsNoDataLabel/PostsNoDataLabel';

// for this component to function we only need id
// but it should be tied to a Post id
export type BasePost = Pick<creatorbase.Post, 'id'>;

type Props<T extends BasePost> = Omit<TableProps<T>, 'meta'> & {
    isLoading?: boolean;
    hasError?: boolean;
    meta: BasePostTableMeta<T>;
};

/**
 * A base Posts table component to share styles
 * and some common logic.
 * It should be used to create specific instances of the posts table
 * (e.g. ProjectDetails/TikTokPostsTable, Report/TikTokPostsTable)
 */

const PostsTable = <T extends BasePost>({ isLoading, hasError, className, meta, data, ...props }: Props<T>) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
    const rect = useRectObserver(containerRef);

    // skeletons are rendered in table cells,
    // so we need some rows to display skeletons on initial loading
    const initialLoading = !data.length && isLoading;
    const emptyRows = new Array(6).fill({}) as T[];
    const rows = initialLoading ? emptyRows : data;

    // Since nested tables (like this one) are rendered in a TD with full colspan, it's content dimensions affect the width of the parent table.
    // To avoid that, we are rendering the scrollContainer with absolute positioning. The wrapper div uses rect to ensure the table is rendered with its full height.
    return (
        <div style={{ height: rect?.height, position: 'relative' }}>
            <ScrollView className={styles.scrollView} ref={setContainerRef}>
                <Table
                    shouldDisplayFooter={!!data.length && !isLoading}
                    //in case of loading, we need a fallback row id
                    getRowId={(original, index) => original.id?.toString() ?? index.toString()}
                    className={cn(
                        styles.table,
                        getApplicationStyleVariables(meta.managedBy),
                        {
                            [styles.empty]: !data.length && !isLoading,
                        },
                        className
                    )}
                    noDataLabel={
                        <PostsNoDataLabel
                            hasError={!!hasError}
                            onAddPost={meta.onCreatePost}
                            isReadOnly={meta.managedBy !== 'creatorbase'}
                        />
                    }
                    data={rows}
                    meta={meta}
                    {...props}
                />
            </ScrollView>
        </div>
    );
};

export default PostsTable;
