import { HTMLProps, forwardRef } from 'react';
import styles from './Badge.module.css';
import cn from 'classnames';

type Props = Omit<HTMLProps<HTMLSpanElement>, 'ref'>;

const Badge = forwardRef<HTMLSpanElement, Props>(({ children, className, ...props }, ref) => {
    return (
        <span ref={ref} className={cn(styles.badge, className)} {...props}>
            {children}
        </span>
    );
});

Badge.displayName = 'Badge';

export default Badge;
