import { DataState, useAbortableEffect } from '@round/utils';
import { getInstagramAudios, influencer, InstagramAudio } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { Context } from '@fluentui/react-context-selector';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';

type State = {
    [audioId: number]: DataState<InstagramAudio | null> | undefined;
};

type Actions =
    | ReducerActionWithPayload<'instagramAudiosByIdLoad', number[]>
    | ReducerActionWithPayload<'instagramAudiosByIdError', { audioIds: number[]; message: string }>
    | ReducerActionWithPayload<'instagramAudiosByIdInit', { audioIds: number[]; audios: InstagramAudio[] }>
    | ReducerAction<'instagramAudiosByIdReset'>;

export const initialState: State = {};
export const reducer = createReducer<State, Actions>({
    instagramAudiosByIdLoad: (state, { payload: ids }) => ({
        ...state,
        ...makeLoadingStateSlice(ids),
    }),
    instagramAudiosByIdError: (state, { payload: { audioIds, message } }) => ({
        ...state,
        ...makeErrorStateSlice(audioIds, message),
    }),
    instagramAudiosByIdInit: (state, { payload: { audioIds, audios } }) => ({
        ...state,
        ...makeSuccessStateSlice(audioIds, (id) => audios.find((a) => a.id === id) ?? null),
    }),
    instagramAudiosByIdReset: () => initialState,
});

type HookConfig = {
    ids?: number[];
    publicReportId?: string;
    isImperative?: boolean;
};

export default function makeInstagramAudiosByIdDataHook<TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) {
    return function useInstagramAudiosById({ ids, publicReportId, isImperative }: HookConfig) {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (ids: number[], requestInit?: RequestInit) => {
                dispatch({ type: 'instagramAudiosByIdLoad', payload: ids });
                try {
                    const response = await getInstagramAudios(
                        { id: ids.toString(), page_size: ids.length },
                        publicReportId
                            ? influencer.addPublicReportIdToRequestInit(publicReportId, requestInit ?? {})
                            : requestInit
                    );
                    if (response.status === 404) {
                        dispatch({
                            type: 'instagramAudiosByIdError',
                            payload: { audioIds: ids, message: response.data.detail },
                        });
                        return response;
                    }

                    dispatch({
                        type: 'instagramAudiosByIdInit',
                        payload: { audioIds: ids, audios: response.data.results },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'instagramAudiosByIdError',
                        payload: { audioIds: ids, message: 'Could not get instagram audios' },
                    });
                    throw e;
                }
            },
            [dispatch, publicReportId]
        );

        useAbortableEffect(
            (signal) => {
                if (isImperative || !ids?.length) {
                    return;
                }

                fetchData(ids ?? [], { signal }).catch(() => {});
            },
            [fetchData, ids, isImperative]
        );

        return {
            data: state,
            fetchData,
            reset: () => dispatch({ type: 'instagramAudiosByIdReset' }),
        };
    };
}
