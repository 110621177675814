import { creatorbase } from '@round/api';
import { promise } from '@round/utils';
import { GenericDropdownOption } from 'App.types';
import { UseSingleValueSelectParams, useSelect } from 'Hooks/useSelect';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';

type SupplementaryFields = {
    teamName: string;
    brandName: string;
    brandImageUrl: string | null;
};

export type ProjectOption = GenericDropdownOption<number> &
    Omit<creatorbase.Project, 'id' | 'name'> &
    Partial<SupplementaryFields>;

export const mapProjectToOption = (
    project: creatorbase.Project,
    team: creatorbase.Team | undefined,
    brand: creatorbase.Brand | undefined
): ProjectOption => ({
    value: project.id,
    label: project.name,
    teamName: team?.name,
    brandName: brand?.name,
    brandImageUrl: brand?.image,
    ...project,
});

const supplementaryFields: readonly (keyof SupplementaryFields)[] = ['teamName', 'brandName', 'brandImageUrl'] as const;
export const mapOptionToProject = (option: ProjectOption): creatorbase.Project => ({
    id: option.value,
    name: option.label,
    ...omit(option, ['value', 'label', ...supplementaryFields]),
});

export const fetchOptions = async (params: creatorbase.GetProjectsParams, requestInit?: RequestInit) => {
    const response = await creatorbase.getProjects(
        { page: params.page, page_size: params.page_size, search: params.search, ...params },
        requestInit
    );

    if (response.status === 404 || response.status === 403) {
        throw new Error(response.data.detail);
    }

    const projects = response.data.results;
    const teamIds = uniq(projects.map((p) => p.team_id));
    const brandIds = uniq(projects.map((p) => p.brand_id));

    const [teamsResult, brandsResult] = await Promise.allSettled([
        teamIds.length
            ? creatorbase
                  .getTeams({ id: teamIds.join(','), page_size: teamIds.length, team_only: false })
                  .then((response) => (response.status === 200 ? response.data.results : []))
            : Promise.resolve([]),
        brandIds.length
            ? creatorbase
                  .getBrands({ id: brandIds.join(','), page_size: brandIds.length, team_only: false })
                  .then((response) => (response.status === 200 ? response.data.results : []))
            : Promise.resolve([]),
    ]);

    const [teams, brands] = [promise.getFulfilledResult(teamsResult), promise.getFulfilledResult(brandsResult)];

    return {
        ...response.data,
        results: response.data.results.map((project) =>
            mapProjectToOption(
                project,
                teams?.find((t) => t.id === project.team_id),
                brands?.find((b) => b.id === project.brand_id)
            )
        ),
    };
};

type Params = Omit<UseSingleValueSelectParams<ProjectOption>, 'fetchOptions' | 'isMulti'> & {
    fetchOptions?: typeof fetchOptions;
};

export default function useProjectSelect(params: Params) {
    return useSelect<ProjectOption>({ fetchOptions, isMulti: false, ...params });
}
