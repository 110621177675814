import { AppServer } from '../../../mirage';
import { Response } from 'miragejs';

export function handleRequests(server: AppServer) {
    server.post('/api/apex/campaign-manager/influencer-cost/', function (schema, request) {
        return schema.create('influencerCost', JSON.parse(request.requestBody));
    });

    server.patch('/api/apex/campaign-manager/influencer-cost/:id/', function (schema, request) {
        const cost = schema.find('influencerCost', request.params.id);
        if (!cost) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        cost.update(JSON.parse(request.requestBody));
        return cost;
    });

    server.delete('/api/apex/campaign-manager/influencer-cost/:id/', function (schema, request) {
        const cost = schema.find('influencerCost', request.params.id);
        if (!cost) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        cost.destroy();
        return new Response(204);
    });
}
