import { AppServer } from '../../mirage';
import { EmailPreview } from './emailPreview.types';

export function handleRequests(server: AppServer) {
    server.post('/api/microwave/email-preview/', (schema, request) => {
        const { campaign_id, email_template_id, influencer_id } = JSON.parse(request.requestBody);

        const emailPreview: EmailPreview = {
            subject: 'email subject',
            body: `<div> email body campaign ${campaign_id}, email template ${email_template_id}, influencer ${influencer_id} </div>`,
        };

        return emailPreview;
    });
}
