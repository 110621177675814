import useAbortableEffect from 'Hooks/useAbortableEffect';
import TopAudiosTable from '../Table/TopAudiosTable';
import styles from './InstagramTopAudios.module.css';
import { useMemo, useState } from 'react';
import useInstagramTopAudios from './useInstagramTopAudios';
import { GenericDropdownOption } from 'App.types';
import { GetInstagramAudiosOrderingParam, InstagramAudio, TimeSeriesResponse } from '@round/api';
import Select from 'ui-new/whitelabel/Select/Select';
import { ColumnDef } from '@tanstack/react-table';
import { buildInstagramAudioUrl } from 'helpers';
import { SkeletonTableCell } from '@round/ui-kit';
import MiniLineChartCell from '../Table/Cells/MiniLineChart/MiniLineChartCell';
import AudioCell, { AudioCellData } from '../Table/Cells/Audio/AudioCell';
import CountCell, { CountCellData } from '../Table/Cells/Count/CountCell';
import useSongData from '../../SongData/useSongData';

const orderingOptions: GenericDropdownOption<GetInstagramAudiosOrderingParam>[] = [
    { label: 'Total Reels', value: '-reels_count' },
    { label: 'Daily Growth (# reels)', value: '-reels_count_daily_change' },
    { label: 'Daily Growth (% increase)', value: '-reels_count_daily_change_relative' },
    { label: 'Weekly Growth (# reels)', value: '-reels_count_weekly_change' },
    { label: 'Weekly Growth (% increase)', value: '-reels_count_weekly_change_relative' },
];

export type InstagramTopAudiosTableRow = InstagramAudio & {
    timeSeries: TimeSeriesResponse[number] | null;
};

const InstagramTopAudios = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [ordering, setOrdering] = useState<GetInstagramAudiosOrderingParam>('-reels_count_daily_change');

    const { data, status, fetchData, reset } = useInstagramTopAudios();
    const isInitialized = status === 'success' || status === 'error';

    const songData = useSongData();
    const isSongInitialized = songData.status === 'success' || songData.status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && isSongInitialized) {
                fetchData({ page, page_size: pageSize, ordering }, { signal }).catch(() => {});
            }
        },
        [fetchData, isInitialized, isSongInitialized, page, pageSize, ordering]
    );

    const rows: InstagramTopAudiosTableRow[] =
        data?.audios.results?.map((audio) => ({
            ...audio,
            timeSeries: data.timeSeries?.[audio.id] ?? null,
        })) ?? [];

    const columns = useMemo<ColumnDef<InstagramTopAudiosTableRow, any>[]>(
        () => [
            {
                header: 'Audio',
                id: 'audio',
                meta: {
                    className: styles.audio,
                },
                accessorFn: (row): AudioCellData => ({
                    audioUrl: buildInstagramAudioUrl(row.instagram_id),
                    songTitle: row.title || row.instagram_id,
                    thumbnailUrl: row.thumbnail_url || '',
                }),
                cell: AudioCell,
            },
            {
                header: 'Author',
                accessorFn: (row) => row.author_name || '-',
                cell: SkeletonTableCell,
            },
            {
                header: 'Reels',
                accessorFn: (row): CountCellData => ({
                    ordering: ordering,
                    value: row.reels_count,
                    growthValue:
                        ordering && ordering !== '-reels_count' ? row[ordering.slice(1) as keyof InstagramAudio] : null,
                }),
                cell: CountCell,
            },
            {
                header: 'Growth',
                id: 'dailyChange',
                accessorKey: 'timeSeries',
                meta: {
                    className: styles.dailyChange,
                },
                cell: MiniLineChartCell,
            },
        ],
        [ordering]
    );

    return (
        <section className={styles.container}>
            <menu>
                <Select
                    value={orderingOptions.find((o) => o.value === ordering)}
                    options={orderingOptions}
                    onChange={(option) => {
                        setOrdering(option?.value ?? '-reels_count_daily_change');
                        reset();
                    }}
                    className={styles.orderingSelect}
                />
            </menu>

            <TopAudiosTable
                page={page}
                columns={columns}
                setPage={(page) => {
                    setPage(page);
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setPageSize(pageSize);
                    reset();
                }}
                ordering={ordering}
                count={data?.audios.count ?? 0}
                data={rows}
                meta={{ isLoading: !isInitialized }}
                hasError={status === 'error'}
            />
        </section>
    );
};

export default InstagramTopAudios;
