import { Image, SkeletonizedValue } from '@round/ui-kit';
import styles from './AccountDetail.module.css';
import PlatformIcon from 'Modules/Plans/components/PlatformIcon';
import { ReactComponent as CloseIcon } from 'assets/whitelabel/Cross.svg';
import LocationFlagIcon from 'Modules/Plans/LocationFlag/LocationFlag';
import countries from 'utility/countries';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { buildInstagramUserUrl, buildTiktokUserUrl } from 'helpers';

export type AccountDetailProps = {
    platform: 'instagram' | 'tiktok';
    isAccountDataLoading: boolean;
    isUserStatsCacheLoading: boolean;
    userImage: string | undefined;
    userHandle: string | undefined;
    username: string | undefined | null;
    bio: string | undefined | null;
    location: string | undefined | null;
    onClose: () => void;
};

export default function AccountDetail({
    platform,
    isAccountDataLoading,
    isUserStatsCacheLoading,
    userImage,
    userHandle,
    username,
    bio,
    location,
    onClose,
}: AccountDetailProps) {
    const userUrl = userHandle
        ? platform === 'tiktok'
            ? buildTiktokUserUrl(userHandle)
            : buildInstagramUserUrl(userHandle)
        : '';

    return (
        <>
            <div className={styles.accountInfo}>
                <Image
                    loading={isAccountDataLoading}
                    src={userImage || ''}
                    className={styles.accountImage}
                    alt="user image"
                />
                <div className={styles.accountHeader}>
                    <div className={styles.accountDetails}>
                        <p className={styles.accountNickname}>
                            <SkeletonizedValue
                                containerClassName={styles.accountNickname}
                                isInitialized={!isAccountDataLoading}
                            >
                                {username || userHandle}
                            </SkeletonizedValue>
                        </p>
                        <div className={styles.accountHandle}>
                            <PlatformIcon colored={true} platform={platform} className={styles.platformIcon} />
                            <SkeletonizedValue
                                containerClassName={styles.skeletonize}
                                isInitialized={!isAccountDataLoading}
                            >
                                <a href={userUrl} target="_blank" rel="noopener noreferrer">
                                    @{userHandle}
                                </a>
                            </SkeletonizedValue>
                        </div>
                        <SkeletonizedValue isInitialized={!isAccountDataLoading} className={styles.skeletonize}>
                            <p className={styles.accountType}>{bio}</p>
                        </SkeletonizedValue>
                    </div>

                    <IconButton onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <div className={styles.accountStats}>
                <div className={styles.statsCard}>
                    <p>Platform</p>
                    <div className={styles.platformBadge}>
                        <PlatformIcon colored={true} platform={platform} className={styles.platformIcon} />
                        <span>{platform === 'tiktok' ? 'TikTok' : 'Instagram'}</span>
                    </div>
                </div>
                <div className={styles.statsCard}>
                    <p>Creator Location</p>
                    <div className={styles.locationBadge}>
                        <LocationFlagIcon locationCode={location ?? null} isSquare={true} className={styles.flagIcon} />
                        <SkeletonizedValue className={styles.skeletonize} isInitialized={!isUserStatsCacheLoading}>
                            <span>{countries[location ?? '']}</span>
                        </SkeletonizedValue>
                    </div>
                </div>
            </div>
        </>
    );
}
