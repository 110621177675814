import { creatorbase, TiktokUser, TiktokUserStats } from '@round/api';
import { Skeleton, SkeletonTableCell, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import { usePublicReportTiktokPosts } from './hooks/usePublicReportTiktokPosts';
import { TikTokPublicReportCampaignTableRow } from '../../Campaigns/CampaignsTable/TikTokPublicReportCampaignTable';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PublicPostsTableAccountCell from 'Modules/Plans/Posts/components/PostsTable/cells/PublicPostsTableAccountCell/PublicPostsTableAccountCell';
import PostStatsFooterTotalCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableStatFooterCell';
import usePublicReportCampaignStats from '../../Campaigns/usePublicReportCampaignStats';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';
import { getTiktokEngagementRatePercentageString, getTikTokTotalEngagement } from 'Modules/Plans/Posts/helpers';
import PostsTableEngagementRateFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementRateFooterCell';
import PostsTableEngagementsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementsFooterCell';
import PostsTableUploadedDateCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableUploadedDateCell';

export type TiktokPostsTableRow = creatorbase.PublicTiktokPost & {
    user: TiktokUser | null;
    userStats: TiktokUserStats | null;
    userImageUrl: string | null | undefined;
};

type Props = Pick<TableProps<TiktokPostsTableRow>, 'noDataLabel'> & {
    campaign: TikTokPublicReportCampaignTableRow;
    publicReportId: string | undefined;
};

type TableCellContext<TKey extends keyof TiktokPostsTableRow> = CellContext<
    TiktokPostsTableRow,
    TiktokPostsTableRow[TKey]
>;

type Meta = BasePostTableMeta<TiktokPostsTableRow>;
const getTableMeta = getTableMetaHelper<Meta>();

const PublicReportTiktokPostsTable = ({ campaign, publicReportId }: Props) => {
    const { postsData, accountsData, fetchData } = usePublicReportTiktokPosts(campaign.id);
    const isInitialized = postsData?.status === 'success' || postsData?.status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && publicReportId) {
                fetchData(publicReportId, { signal }).catch(() => {});
            }
        },
        [publicReportId, fetchData, isInitialized]
    );

    const accountsLoading = Object.entries(accountsData)
        .filter(([_, data]) => data?.status === 'loading' || data?.status === 'idle')
        .map(([key]) => Number(key));

    const { data: campaignStats } = usePublicReportCampaignStats();
    const areCampaignStatsLoading = campaignStats[campaign.id]?.status === 'loading';

    const columns = useMemo<ColumnDef<TiktokPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                accessorFn: (row) => ({
                    image: row.userImageUrl,
                    title: row.user?.unique_id || '-',
                    followerCount: row.userStats?.follower_count,
                }),
                id: 'account',
                cell: PublicPostsTableAccountCell,
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    isReadOnly: true,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Uploaded date',
                id: 'uploadedDate',
                cell: PostsTableUploadedDateCell,
            },
            {
                header: 'Views',
                accessorFn: (row) => row.view_count,
                id: 'views',
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Total engagements',
                id: 'totalEngagements',
                accessorFn: (row) => getTikTokTotalEngagement(row),
                cell: PostStatsValueCell,
                footer: PostsTableEngagementsFooterCell,
            },
            {
                header: 'Engagement rate',
                id: 'engagementRate',
                accessorFn: (row) => getTiktokEngagementRatePercentageString(row),
                cell: SkeletonTableCell,
                footer: PostsTableEngagementRateFooterCell,
            },
            {
                header: 'Likes',
                accessorFn: (row) => row.tiktok_details?.like_count,
                id: 'likes',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Shares',
                accessorFn: (row) => row.tiktok_details?.share_count,
                id: 'shares',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Comments',
                accessorFn: (row) => row.tiktok_details?.comment_count,
                id: 'comments',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Saves',
                accessorFn: (row) => row.tiktok_details?.save_count,
                id: 'saves',
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading: !isInitialized,
        getIsAccountDataLoading: (row) => !!accountsLoading.find((id) => row.tiktok_details?.account_id === id),
        campaignStats: campaignStats[campaign.id]?.data || null,
        areCampaignStatsLoading: areCampaignStatsLoading,
        managedBy: campaign.managed_by,
    };

    const rows: TiktokPostsTableRow[] = useMemo(
        () =>
            postsData?.data
                ?.filter((p): p is creatorbase.PublicTiktokPost => p.platform === 'tiktok')
                .map((post) => {
                    const userId = post.tiktok_details?.account_id;
                    const postAccountData = userId ? accountsData?.[userId]?.data : null;
                    const user = postAccountData?.user || null;
                    const userStats = postAccountData?.stats || null;
                    const userImage = postAccountData?.image || null;

                    return {
                        ...post,
                        user,
                        userStats,
                        userImageUrl: userImage?.avatar_thumb.cached_url || userImage?.avatar_thumb.original_url,
                    };
                }) ?? [],
        [postsData, accountsData]
    );

    return (
        <PostsTable
            columns={columns}
            data={rows}
            meta={meta}
            isLoading={!isInitialized}
            hasError={postsData?.status === 'error'}
        />
    );
};

export default PublicReportTiktokPostsTable;
