import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import { TiktokCreatorsDispatchContext, TiktokCreatorsStateContext } from './TiktokCreatorsContext';
import {
    deleteTikTokInfluencerPlanHashtag,
    getTikTokInfluencerPlanHashtags,
    postTikTokInfluencerPlanHashtag,
} from '@round/api';

export default function useCreatorPlanTiktokHashtags() {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(TiktokCreatorsStateContext);
    const dispatch = useNonNullContext(TiktokCreatorsDispatchContext);

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id) {
                return;
            }

            try {
                dispatch({ type: 'loadTiktokHashtags' });
                const response = await getTikTokInfluencerPlanHashtags({ plan_id: influencerPlan.id }, requestInit);
                if (response.status !== 200) {
                    dispatch({ type: 'setTiktokHashtagError', payload: response.data.detail });
                    return;
                }

                dispatch({ type: 'tiktokHashtagsInitialized', payload: response.data.results });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setTiktokHashtagError',
                    payload: e instanceof Error ? e.message : "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch, influencerPlan?.id]
    );

    const createHashtag: typeof postTikTokInfluencerPlanHashtag = async (data) => {
        const response = await postTikTokInfluencerPlanHashtag(data);
        if (response.status === 201) {
            dispatch({ type: 'tiktokHashtagCreated', payload: response.data });
        }

        return response;
    };

    const deleteHashtag: typeof deleteTikTokInfluencerPlanHashtag = async (id) => {
        const response = await deleteTikTokInfluencerPlanHashtag(id);
        if (response.status === 204) {
            dispatch({ type: 'tiktokHashtagDeleted', payload: id });
        }

        return response;
    };

    return {
        init,
        hashtags: state.tiktokHashtags,
        isLoading: state.tiktokHashtagsLoading,
        error: state.tiktokHashtagsLoadingError,
        isInitialized: state.tiktokHashtagsInitialized,
        createHashtag,
        deleteHashtag,
    };
}
