import { NavBar } from 'SharedComponents/NavBar/NavBar';
import MicroCampaigns from 'Modules/Advertising/Microwave/MicroCampaigns/MicroCampaigns';
import PageLayout from 'ui-new/Layout/PageLayout/PageLayout';

const MicroCampaignsPage = () => {
    return (
        <>
            <NavBar />
            <PageLayout>
                <MicroCampaigns />
            </PageLayout>
        </>
    );
};

export default MicroCampaignsPage;
