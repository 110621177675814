import { CellContext } from '@tanstack/react-table';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import styles from './TruncatedLinkCell.module.css';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';

export type LinkData = {
    href: string | undefined;
    value: string;
};

type Props = CellContext<any, LinkData>;

const getTableMeta = getTableMetaHelper<{
    isLoading?: boolean;
}>();

const Text = ({ value }: { value: string }) => (
    <TruncationPopover content={value}>
        {(ref) => (
            <span ref={ref} className={styles.text}>
                {value}
            </span>
        )}
    </TruncationPopover>
);

const TruncatedLinkCell = ({ getValue, table }: Props) => {
    const { isLoading } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    const { href, value } = getValue();

    return href ? (
        <a href={href} className={styles.container} target="_blank" rel="noopener noreferrer">
            <Text value={value} />
        </a>
    ) : (
        <span className={styles.container}>
            <Text value={value} />
        </span>
    );
};

export default TruncatedLinkCell;
