import { creatorbase } from '@round/api';
import { ButtonShell, getTableMetaHelper } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import styles from './PostsTableAccountCell.module.css';
import { ReactComponent as DiagramIcon } from '../../../../../../../assets/Diagram.svg';
import UserBadge, { UserBadgeProps } from 'Modules/Plans/User/UserBadge/UserBadge';

type Row = creatorbase.PublicPost;
export type AccountData = {
    accountId?: number | undefined | null;
    image: string | undefined;
    title: string;
    followerCount?: number | null;
};

type Context<T extends Row> = CellContext<T, AccountData>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostsTableAccountCell = <T extends Row>({ row, table, getValue }: Context<T>) => {
    const { isLoading, getIsAccountDataLoading, setAccountModalUserId } = getTableMeta(table);
    const { accountId, image, title, followerCount } = getValue();

    const userBadgeProps: UserBadgeProps = {
        isLoading: isLoading || getIsAccountDataLoading(row.original),
        image: image,
        title: title,
        followerCount: followerCount,
    };

    return (
        <div className={styles.container}>
            <UserBadge {...userBadgeProps} />

            {setAccountModalUserId && (
                <ButtonShell className={styles.modalButton} onClick={() => setAccountModalUserId(accountId ?? null)}>
                    <DiagramIcon />
                </ButtonShell>
            )}
        </div>
    );
};

export default PostsTableAccountCell;
