import { omit } from 'lodash';
import { SongFormValues } from './SongForm';
import { creatorbase } from '@round/api';

export const formatSongFormValuesToApiData = (
    values: SongFormValues,
    initialValues?: SongFormValues
): creatorbase.PostSongData => {
    if (values.status === 'released') {
        return omit(values, ['artist_name', 'title']);
    }

    if (initialValues?.spotify_url) {
        return { ...values, spotify_url: null };
    }

    return omit(values, ['spotify_url']);
};
