import { AppServer, RouteHandlerContext } from '../../../mirage';
import { Response } from 'miragejs';

export function handleRequests(server: AppServer) {
    server.post('/api/apex/admin/influencer-cost/', function (this: RouteHandlerContext, schema, request) {
        return schema.create('influencerCost', JSON.parse(request.requestBody));
    });

    server.patch('/api/apex/admin/influencer-cost/:id/', function (this: RouteHandlerContext, schema, request) {
        const influencerCost = schema.find('influencerCost', request.params.id);
        if (!influencerCost) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        influencerCost.update(JSON.parse(request.requestBody));
        return influencerCost;
    });

    server.delete('/api/apex/admin/influencer-cost/:id/', function (this: RouteHandlerContext, schema, request) {
        const influencerCost = schema.find('influencerCost', request.params.id);
        if (!influencerCost) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        influencerCost.destroy();
        return new Response(204);
    });
}
