import { microwave } from '@round/api';
import { useDataState } from '@round/utils';
import { Result } from './sendHelpers';

export function useSendInitialEmails() {
    const [state, setState] = useDataState<microwave.PostCampaignInvitesResponseData, string[]>({
        status: 'idle',
        data: null,
        error: null,
    });

    const send = async (
        campaignId: number,
        emailData: microwave.PostCampaignInvitesDataItem[]
    ): Promise<Result<microwave.PostCampaignInvitesResponseData>> => {
        const genericError = ['Could not send invites. Please try again later'];
        try {
            setState('loading');
            const response = await microwave.postCampaignInvites(campaignId, emailData);

            if (response.status === 201) {
                setState('success', { data: response.data });
                return { success: true, data: response.data };
            }

            // If the response is 400, but the response data is an object with success or failure keys, we still consider it a success,
            // as it means that the request was successful, but all emails failed to send.
            if (typeof response.data === 'object' && 'success' in response.data && 'failure' in response.data) {
                setState('success', { data: response.data });
                return { success: true, data: response.data };
            }

            if (Array.isArray(response.data)) {
                setState('error', { error: response.data });
                return { success: false };
            }

            setState('error', { error: genericError });

            return { success: false };
        } catch (e) {
            setState('error', { error: genericError });
            throw e;
        }
    };

    const reset = () => {
        setState('idle', { data: null });
    };

    return {
        ...state,
        send,
        reset,
    };
}
