import styles from './applicationStyles.module.css';
import { applicationManager } from '@round/api';

type ApplicationColors = {
    highlight: string;
};

export const creatorbaseColors: ApplicationColors = {
    highlight: '#55AAFF',
};

export const roundColors: ApplicationColors = {
    highlight: '#0E0E0E',
};

export const microwaveColors: ApplicationColors = {
    highlight: '#FF5DBE',
};

export const getApplicationColors = (application: applicationManager.Application): ApplicationColors => {
    const themes: Record<applicationManager.Application, ApplicationColors> = {
        round: roundColors,
        microwave: microwaveColors,
        creatorbase: creatorbaseColors,
    };

    return themes[application];
};

export const getApplicationStyleVariables = (application: applicationManager.Application | undefined) => {
    const supportedApplications = ['creatorbase', 'round', 'microwave'];
    const defaultApplication = 'creatorbase';

    const applicationValue = supportedApplications.find((app) => app === application) || defaultApplication;

    return styles[`managed-by-${applicationValue}`];
};
