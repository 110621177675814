import { AppServer, RouteHandlerContext } from '../../mirage';
import { InstagramPost } from './posts.types';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const factories = {
    instagramPost: createFactory<InstagramPost>({
        audio_id: (index: number) => index,
        comment_count: (index: number) => index * 10,
        comments_disabled: Math.random() > 0.5 ? true : false,
        is_ad: () => (Math.random() > 0.5 ? true : false),
        is_paid_partnership: () => (Math.random() > 0.5 ? true : false),
        like_count: (index: number) => index * 100,
        location: null,
        owner_follower_count: (index: number) => index * 10,
        owner_id: 1,
        owner_username: (index: number) => `user${index}`,
        play_count: (index: number) => index * 100,
        post_url: (index: number) => `https://www.instagram.com/p/${index}/`,
        taken_at: new Date().toISOString(),
        text: (index: number) => `Post ${index}`,
        view_count: (index: number) => index * 1000,
    }),
};

export const models = {
    instagramPost: Model as ModelDefinition<InstagramPost>,
};

export const serializers = {
    instagramPost: makeSerializer<InstagramPost>([]),
};

export function handleRequests(server: AppServer) {
    const getInstagramPostsUrl = '/api/instagram/posts/';
    server.get('/api/instagram/posts/', function (this: RouteHandlerContext, schema, request) {
        const idParam = request.queryParams.id;
        const idsToFilter = idParam ? idParam.split(',').map(Number) : null;

        const audioIdParam = request.queryParams.audio_id;
        const audioIdsToFilter = audioIdParam ? audioIdParam.split(',').map(Number) : null;

        let posts = schema.all('instagramPost');

        if (idsToFilter) {
            posts = posts.filter((post) => idsToFilter.includes(post.id));
        }

        if (audioIdsToFilter) {
            posts = posts.filter((post) => audioIdsToFilter.find((id) => post.audio_id === id));
        }

        return buildPaginatedResponse(posts, {
            url: getInstagramPostsUrl,
            serialize: (resource) => this.serialize(resource, 'instagramPost'),
            queryParams: request.queryParams,
        });
    });
}
