import { queryOptions, skipToken } from '@tanstack/react-query';
import { buildCoreKeyFactory, buildDetailKeyFactories, buildErrorResponseGetter } from '../../queries.helpers';

import { ExpectedResponseError } from '../../utils';
import { getProject } from './projects.api';

export const CORE_KEY = 'application-manager-project';

export const keys = {
    all: buildCoreKeyFactory(CORE_KEY),
    ...buildDetailKeyFactories<number, typeof CORE_KEY>(CORE_KEY),
};

export const options = {
    detail: (id: number | null | undefined) =>
        queryOptions({
            queryKey: typeof id === 'number' ? keys.detail(id) : keys.details(),
            queryFn:
                typeof id !== 'number'
                    ? skipToken
                    : async ({ signal }) => {
                          const response = await getProject(id, { signal });

                          if (response.status === 200) {
                              return response.data;
                          }

                          throw new ExpectedResponseError('Failed to fetch project', response);
                      },
            staleTime: 1000 * 60 * 10,
        }),
};

export const utils = {
    detail: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getProject>>>(),
    },
};
