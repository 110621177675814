import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TimeSeries, TimeSeriesField } from './timeSeries.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer } from '../../mirage';
import { TimeSeriesRequestParams, TimeSeriesResponse } from './timeSeries.api';

export const models = {
    instagramTimeSeries: Model as ModelDefinition<TimeSeries>,
};

export const serializer = {
    instagramTimeSeries: makeSerializer<TimeSeries>([]),
};

export const factories = {
    instagramTimeSeries: createFactory<TimeSeries>({
        timestamp: new Date().toISOString(),
        value: 0,
    }),
};

export function generateTimeSeries(getValue: (index: number) => number, days = 14) {
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);
    const day = 24 * 60 * 60 * 1000;

    return new Array(days)
        .fill(undefined)
        .map<TimeSeries>((_, i) => ({ value: 0, timestamp: new Date(now.getTime() - i * day).toISOString() }))
        .reverse()
        .map((t, i) => ({ ...t, value: getValue(i) }));
}

export function generateTimeSeriesResponse(
    params: TimeSeriesRequestParams,
    timeSeries: TimeSeries[]
): TimeSeriesResponse {
    return params.ids.reduce((acc, id) => {
        acc[id] = params.fields.reduce((acc, field) => {
            acc[field as TimeSeriesField] = timeSeries;
            return acc;
        }, {} as TimeSeriesResponse[0]);

        return acc;
    }, {} as TimeSeriesResponse);
}

export function handleRequests(server: AppServer) {
    server.post('/api/instagram/time-series/', (schema, request) => {
        const params = JSON.parse(request.requestBody) as TimeSeriesRequestParams;
        const response = generateTimeSeriesResponse(
            params,
            generateTimeSeries((i) => (i + 1) * 100)
        );

        return new Response(200, {}, JSON.stringify(response));
    });
}
