import styles from './UserAudienceBreakdown.module.css';
import { range } from 'lodash';
import { ProgressBar, Skeleton } from '@round/ui-kit';
import cn from 'classnames';
import languages from 'utility/languages';
import { numberWithCommas, roundTo2Dp } from 'helpers';
import countries from 'utility/countries';
import GenderChart from './AudienceCharts/GenderChart';
import AgeChart from './AudienceCharts/AgeChart';
import { colors } from './AudienceCharts/helpers';
import { PhylloUserAnalyticsData } from '@round/api';
import { DataState } from 'App.types';

type Props = {
    analytics: DataState<PhylloUserAnalyticsData>;
};

const UserAudienceBreakdown = ({ analytics }: Props) => {
    const isLoading = analytics.status === 'loading';

    if (!analytics.data && !isLoading) {
        return <div className={styles.messageBox}>No data found</div>;
    }

    if (!isLoading && analytics.error) {
        return <div className={styles.messageBox}>{analytics.error}</div>;
    }

    return (
        <div className={styles.chartsContainer}>
            <section className={styles.chartsSection}>
                <div className={styles.chartContainer}>
                    <p className={styles.chartTitle}>Gender</p>
                    <GenderChart
                        loading={isLoading}
                        className={styles.chart}
                        data={analytics.data?.profile.audience.gender_age_distribution ?? []}
                    />
                </div>

                <div className={styles.chartContainer}>
                    <p className={styles.chartTitle}>Age</p>
                    <AgeChart
                        loading={isLoading}
                        className={styles.chart}
                        data={analytics.data?.profile.audience.gender_age_distribution ?? []}
                    />
                </div>
            </section>

            <section className={styles.chartsSection}>
                <div>
                    <p className={styles.chartTitle}>Top Countries</p>
                    <div className={styles.topChart}>
                        {isLoading
                            ? range(0, 5).map((i) => <Skeleton key={i} className={styles.skeleton} />)
                            : analytics.data?.profile.audience.countries
                                  .sort((a, b) => b.value - a.value)
                                  .slice(0, 5)
                                  .map((country) => (
                                      <div key={country.code}>
                                          <div className={styles.topChartLabels}>
                                              <span>
                                                  <span
                                                      className={cn(
                                                          `fi fi-${country.code.toLowerCase()}`,
                                                          styles.flagIcon
                                                      )}
                                                  />
                                                  {countries[country.code]}
                                              </span>
                                              <span>{numberWithCommas(roundTo2Dp(country.value))}%</span>
                                          </div>

                                          <ProgressBar
                                              barStyles={{ backgroundColor: colors[0] }}
                                              progress={roundTo2Dp(country.value)}
                                          />
                                      </div>
                                  ))}
                    </div>
                </div>

                <div>
                    <p className={styles.chartTitle}>Top Languages</p>
                    <div className={styles.topChart}>
                        {isLoading
                            ? range(0, 5).map((i) => <Skeleton key={i} className={styles.skeleton} />)
                            : analytics.data?.profile.audience.languages
                                  .sort((a, b) => b.value - a.value)
                                  .slice(0, 5)
                                  .map((language) => (
                                      <div key={language.code}>
                                          <div className={styles.topChartLabels}>
                                              <span>{languages[language.code]?.name}</span>
                                              <span>{numberWithCommas(roundTo2Dp(language.value))}%</span>
                                          </div>
                                          <ProgressBar
                                              barStyles={{
                                                  backgroundColor: colors[1],
                                              }}
                                              progress={roundTo2Dp(language.value)}
                                          />
                                      </div>
                                  ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default UserAudienceBreakdown;
