import { useBrands } from 'Modules/Plans/Brand/hooks/useBrands';
import { useProjects } from '../hooks/useProjects';
import { creatorbase } from '@round/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import { useTeams } from 'Modules/Plans/Team/hooks/useTeams';
import { ProjectsTableRow } from './components/ProjectsTable/ProjectsTable';
import { useUsers } from 'Modules/Plans/User/hooks/useUsers';
import moment from 'moment';
import { isNumber } from 'utility/utility';
import { useAbortableEffect } from '@round/utils';
import { mapStringListToArray } from 'helpers';
import { useTimeSeries } from '../../hooks/useTimeSeries';

type Status = 'idle' | 'loading' | 'success' | 'error';

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

export function useProjectsList() {
    const [status, setStatus] = useState<Status>('idle');
    const { fetchData: fetchProjects, reset: resetProjects, data } = useProjects();
    const { fetchData: fetchBrands, ...brandsData } = useBrands();
    const { fetchData: fetchTeams, ...teamsData } = useTeams();
    const { fetchData: fetchUsers, ...usersData } = useUsers();
    const { state: timeSeriesState, fetchData: fetchTimeSeries } = useTimeSeries<
        'tiktok_daily_change' | 'instagram_daily_change'
    >();

    const fetchData = useCallback(
        async (params: creatorbase.GetProjectsParams, requestInit?: RequestInit) => {
            try {
                setStatus('loading');
                const projectsResponse = await fetchProjects(params, requestInit);

                if (projectsResponse.status !== 200) {
                    setStatus('error');
                    return;
                }

                setStatus('success');
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setStatus('error');
                throw e;
            }
        },
        [fetchProjects]
    );

    const fetchBrandsData = useCallback(
        async (brandQuery: string, requestInit: RequestInit) => {
            const response = await fetchBrands(
                { id: brandQuery, page_size: mapStringListToArray(brandQuery).length },
                requestInit
            );

            if (response.status !== 200) {
                setStatus('error');
            }
        },
        [fetchBrands]
    );

    const fetchTeamsData = useCallback(
        async (teamQuery: string, requestInit: RequestInit) => {
            const response = await fetchTeams(
                { id: teamQuery, page_size: mapStringListToArray(teamQuery).length },
                requestInit
            );

            if (response.status !== 200) {
                setStatus('error');
            }
        },
        [fetchTeams]
    );

    const fetchUsersData = useCallback(
        async (userQuery: string, requestInit: RequestInit) => {
            const response = await fetchUsers(
                { id: userQuery, page_size: mapStringListToArray(userQuery).length },
                requestInit
            );

            if (response.status !== 200) {
                setStatus('error');
            }
        },
        [fetchUsers]
    );

    const songIds = uniq(data?.results.map((project) => project.song_id)).filter(isNumber);
    const songIdsToFetch = songIds
        .filter((id) => !Object.keys(timeSeriesState || {}).includes(id.toString()))
        .toString();

    useEffect(() => {
        if (songIdsToFetch.length) {
            fetchTimeSeries({
                type: 'song',
                ids: songIdsToFetch.split(',').map(Number),
                fields: ['tiktok_daily_change', 'instagram_daily_change'],
                start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                end_date: today.format('YYYY-MM-DD'),
            }).catch(() => {});
        }
    }, [fetchTimeSeries, songIdsToFetch]);

    const brandIds = uniq(data?.results.map((project) => project.brand_id));
    const brandIdsToFetch = brandIds
        .filter((id) => !brandsData.data?.results.some((brand) => brand.id === id))
        .toString();

    useAbortableEffect(
        (signal) => {
            if (brandIdsToFetch.length) {
                fetchBrandsData(brandIdsToFetch, { signal }).catch(() => {});
            }
        },
        [brandIdsToFetch, fetchBrandsData]
    );

    const teamIds = uniq(data?.results.map((project) => project.team_id));
    const teamIdsToFetch = teamIds.filter((id) => !teamsData.data?.results.some((team) => team.id === id)).toString();

    useAbortableEffect(
        (signal) => {
            if (teamIdsToFetch.length) {
                fetchTeamsData(teamIdsToFetch, { signal }).catch(() => {});
            }
        },
        [teamIdsToFetch, fetchTeamsData]
    );

    const userIds = uniq(data?.results.map((project) => project.users_assigned_to_campaigns).flat());
    const userIdsToFetch = userIds.filter((id) => !usersData.data?.results.some((user) => user.id === id)).toString();

    useAbortableEffect(
        (signal) => {
            if (userIdsToFetch.length) {
                fetchUsersData(userIdsToFetch, { signal }).catch(() => {});
            }
        },
        [userIdsToFetch, fetchUsersData]
    );

    const isSupplementaryDataLoading =
        brandsData.status === 'loading' ||
        (brandsData.status === 'idle' && brandIdsToFetch.length > 0) ||
        teamsData.status === 'loading' ||
        (teamsData.status === 'idle' && teamIdsToFetch.length > 0) ||
        usersData.status === 'loading' ||
        (usersData.status === 'idle' && userIdsToFetch.length > 0);

    const reset = useCallback(() => {
        resetProjects();
        setStatus('idle');
    }, [resetProjects]);

    const createProject = useCallback(
        async (data: creatorbase.PostProjectData) => {
            const response = await creatorbase.postProject(data);

            if (response.status === 201) {
                reset();
            }

            return response;
        },
        [reset]
    );

    const deleteProject = useCallback(
        async (projectId: creatorbase.Project['id']) => {
            const response = await creatorbase.deleteProject(projectId);
            if (response.status === 204) {
                reset();
            }
            return response;
        },
        [reset]
    );

    const rows: ProjectsTableRow[] = useMemo(
        () =>
            data?.results.map((project) => {
                const brand = brandsData.data?.results.find((brand) => brand.id === project.brand_id) || null;
                const team = teamsData.data?.results.find((team) => team.id === project.team_id) || null;
                const users =
                    usersData.data?.results.filter((user) => project.users_assigned_to_campaigns.includes(user.id)) ||
                    [];
                const songTimeSeries = project.song_id ? timeSeriesState?.[project.song_id] || null : null;

                return {
                    ...project,
                    brand,
                    team,
                    users,
                    songStatsTimeSeries: songTimeSeries,
                };
            }) || [],
        [data, brandsData.data, teamsData.data, usersData.data, timeSeriesState]
    );

    return {
        status,
        fetchData,
        isSupplementaryDataLoading,
        createProject,
        deleteProject,
        reset,
        rows,
        hasNextPage: !!data?.next,
        hasPreviousPage: !!data?.previous,
        count: data?.count,
    };
}
