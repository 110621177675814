import { call, encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit, PublicReportRequestInit } from '../../influencer/utils';
import { Brand, PublicBrand } from './brands.types';

export type GetBrandParams = Partial<{
    // Bypasses the default filter to include items beyond the user's team(s).
    // If absent, defaults to true.
    team_only: false;
}>;
type GetBrandResponse = ApiResponse<Brand, 200> | ApiResponse<{ detail: string }, 403> | NotFoundResponse;

export async function getBrand(
    brandId: number,
    params: GetBrandParams = {},
    requestInit?: RequestInit
): Promise<GetBrandResponse> {
    const response = await call(`/api/creatorbase/brands/${brandId}/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get brand');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetBrandsResponse<T extends Brand | PublicBrand> =
    | ApiResponse<PaginatedApiResponseData<T>, 200>
    | ApiResponse<{ detail: string }, 403>
    | NotFoundResponse;

export type GetBrandsParams = Partial<
    PaginatedRequest & {
        id: string;
        search: string;
        // Bypasses the default filter to include items beyond the user's team(s).
        // If absent, defaults to true.
        team_only: false;
    }
>;

export async function getBrands(
    params: GetBrandsParams,
    requestInit: PublicReportRequestInit
): Promise<GetBrandsResponse<PublicBrand>>;
export async function getBrands(params: GetBrandsParams, requestInit?: RequestInit): Promise<GetBrandsResponse<Brand>>;
export async function getBrands(
    params: GetBrandsParams,
    requestInit?: RequestInit
): Promise<GetBrandsResponse<Brand | PublicBrand>> {
    const response = await call(`/api/creatorbase/brands/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get brands');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostBrandResponse =
    | ApiResponse<Brand, 201>
    | ApiResponse<ApiResponseError<PostBrandData>, 400>
    | ApiResponse<string[], 400>
    | ApiResponse<{ detail: string }, 400>
    | ForbiddenResponse;
export type PostBrandData = Pick<Brand, 'name' | 'team_id'> & {
    image: File | null;
};

export async function postBrand(data: PostBrandData): Promise<PostBrandResponse> {
    //The server expects a FormData object to support image uploads
    const formData = new FormData();

    formData.append('name', data.name);
    if (data.image) {
        formData.append('image', data.image);
    }
    formData.append('team_id', data.team_id.toString());

    const response = await fetchWithToken('/api/creatorbase/brands/', {
        method: 'POST',
        body: formData,
    });

    if (response.status === 400 || response.status === 403) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create brand');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
