import useAbortableEffect from 'Hooks/useAbortableEffect';
import useTiktokIndicators from './Tiktok/useTiktokIndicators';
import useInstagramIndicators from './Instagram/useInstagramIndicators';
import { creatorbase } from '@round/api';

export function useIndicators(platforms: string[]) {
    const { data: tiktokData, status: tiktokStatus, fetchData: fetchTiktokData } = useTiktokIndicators();
    const isTiktokInitialized = tiktokStatus === 'success' || tiktokStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isTiktokInitialized) {
                fetchTiktokData(signal).catch(() => {});
            }
        },
        [isTiktokInitialized, fetchTiktokData]
    );

    const { data: instagramData, status: instagramStatus, fetchData: fetchInstagramData } = useInstagramIndicators();
    const isInstagramInitialized = instagramStatus === 'success' || tiktokStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInstagramInitialized) {
                fetchInstagramData(signal).catch(() => {});
            }
        },
        [isInstagramInitialized, fetchInstagramData]
    );

    const dataArray = [
        platforms.includes('tiktok') ? tiktokData : undefined,
        platforms.includes('instagram') ? instagramData : undefined,
    ].filter(Boolean) as creatorbase.AggregatedAudioStats[];

    const mergedData = dataArray.reduce<creatorbase.AggregatedAudioStats>(
        (result, data) => ({
            audio_count: result.audio_count + (data.audio_count || 0),
            video_count: result.video_count + (data.video_count || 0),
            video_count_daily_change: result.video_count_daily_change + (data.video_count_daily_change || 0),
            video_count_weekly_change: result.video_count_weekly_change + (data.video_count_weekly_change || 0),
        }),
        {
            audio_count: 0,
            video_count: 0,
            video_count_daily_change: 0,
            video_count_weekly_change: 0,
        }
    );

    return {
        isInitialized: isTiktokInitialized && isInstagramInitialized,
        data: mergedData,
    };
}
