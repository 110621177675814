import React, { useCallback, useContext, useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { InfluencerPlanContext } from '../../../InfluencerPlan/contexts/InfluencerPlanContext';
import { Button, FormField, Label } from '@round/ui-kit';
import TextArea from 'SharedComponents/Forms/TextArea/TextArea';
import { showNotification } from 'helpers';
import styles from './EmailConfiguration.module.css';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type FormValues = {
    brief: string;
    micros_payment_request_url: string;
    micros_payment_request_deadline_in_days: number;
};

const defaultDeadlineDays = 2;

const EmailConfiguration = () => {
    const { influencerPlan, updateInfluencerPlan } = useContext(InfluencerPlanContext);

    const initialValues: FormValues = useMemo(
        () => ({
            brief: influencerPlan?.micros_brief ?? '',
            micros_payment_request_url: influencerPlan?.micros_payment_request_url ?? '',
            micros_payment_request_deadline_in_days:
                influencerPlan?.micros_payment_request_deadline_in_days ?? defaultDeadlineDays,
        }),
        [
            influencerPlan?.micros_brief,
            influencerPlan?.micros_payment_request_deadline_in_days,
            influencerPlan?.micros_payment_request_url,
        ]
    );

    const handeSubmit = useCallback(
        async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
            if (!influencerPlan || !updateInfluencerPlan) {
                return;
            }

            try {
                helpers.setSubmitting(true);
                const response = await updateInfluencerPlan({
                    micros_brief: values.brief,
                    micros_payment_request_url: values.micros_payment_request_url,
                    micros_payment_request_deadline_in_days: values.micros_payment_request_deadline_in_days,
                });

                if (response.status === 404 || response.status === 403) {
                    showNotification(response.data.detail, 'error');
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(mapApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Saved!', 'info');
            } catch (e) {
                showNotification('Could not update email configuration', 'error');
            } finally {
                helpers.setSubmitting(false);
            }
        },
        [influencerPlan, updateInfluencerPlan]
    );

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Email configuration</h3>
            <Formik initialValues={initialValues} onSubmit={handeSubmit}>
                {({ isSubmitting, dirty }) => (
                    <Form className={styles.form}>
                        <FormField>
                            <Label htmlFor="micros_payment_request_url">Payment request URL</Label>
                            <Field
                                name="micros_payment_request_url"
                                id="micros_payment_request_url"
                                placeholder="Enter request url..."
                            />
                            <ErrorMessage name="micros_payment_request_url">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>

                        <FormField>
                            <Label htmlFor="micros_payment_request_deadline_in_days">Deadline after (days)</Label>
                            <Field
                                type="number"
                                id="micros_payment_request_deadline_in_days"
                                name="micros_payment_request_deadline_in_days"
                                placeholder="Enter number of days..."
                            />
                            <ErrorMessage name="micros_payment_request_deadline_in_days">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>

                        <FormField>
                            <Label htmlFor="brief">Brief</Label>
                            <TextArea
                                name="brief"
                                id="brief"
                                placeholder="Enter brief..."
                                className={styles.briefField}
                            />
                            <ErrorMessage name="brief">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>
                        <div>
                            <Button
                                type="filled"
                                color="black"
                                disabled={!dirty || !influencerPlan}
                                loading={isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EmailConfiguration;
