import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import cn from 'classnames';
import styles from './ExpandCell.module.css';
import { ReactComponent as ChevronRightIcon } from 'assets/whitelabel/ChevronRight.svg';
import { ButtonShell, getTableMetaHelper } from '@round/ui-kit';

type Row = Pick<creatorbase.Campaign, 'id'>;

type Context<T extends Row> = CellContext<T, T['id']>;

type Meta = {
    isLoading?: boolean;
};

const ExpandCell = <T extends Row, TMeta extends Meta>({ row, table }: Context<T>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading } = getTableMeta(table);

    return (
        <ButtonShell
            onClick={() => row.toggleExpanded()}
            className={cn(styles.expandButton, { [styles.loading]: isLoading })}
        >
            <ChevronRightIcon className={cn({ [styles.expandIconExpanded]: row.getIsExpanded() })} />
        </ButtonShell>
    );
};

export default ExpandCell;
