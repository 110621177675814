import styles from './TiktokMicrosStatsBar.module.css';
import ProgressBar from '../../../../../../ui/ProgressBar/ProgressBar';
import cn from 'classnames';
import { asMoney, formatNumberToKNotation, roundTo2Dp } from 'helpers';
import { TiktokMicroInfluencerPostLiveStats, microwave } from '@round/api';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/ArrowIcon.svg';
import React, { useContext } from 'react';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import { Link } from 'react-router-dom';
import { getPostCountDisplayValue } from 'Modules/Advertising/InfluencerPlan/features/MicrosStatsBar/helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import CountBadge from 'ui-new/Badges/CountBadge/CountBadge';

type TiktokMicrosStatsBarProps = {
    campaign: microwave.Campaign | undefined;
    microsStats: TiktokMicroInfluencerPostLiveStats | null;
    toApprovePostCount: number | null;
    isExpanded: boolean;
    onExpandedChange: (expanded: boolean) => void;
    children?: React.ReactNode | undefined;
    onClickAddMicroPost: () => void;
};

const TiktokMicrosStatsBar = ({
    campaign,
    microsStats,
    toApprovePostCount,
    onExpandedChange,
    isExpanded,
    children,
    onClickAddMicroPost,
}: TiktokMicrosStatsBarProps) => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const { currencies } = useNonNullContext(OptionsContext);
    const campaignCurrency = currencies.find((c) => c.id === campaign?.currency_id);

    const postCountDisplayValue = getPostCountDisplayValue(microsStats?.post_count ?? 0, campaign?.target_post_count);
    const budgetDisplayValue = asMoney(campaign?.budget, campaignCurrency);

    const amountSpentPercent =
        microsStats?.amount_spent && campaign?.budget ? (microsStats.amount_spent / campaign.budget) * 100 : 0;
    const amountSpentPercentDisplayValue = amountSpentPercent ? `${amountSpentPercent.toFixed(2)}%` : '-';

    const engagementRate = microsStats?.engagement_rate ? `${roundTo2Dp(microsStats?.engagement_rate * 100)}%` : '-';

    const totalViews =
        typeof microsStats?.total_views === 'number' ? formatNumberToKNotation(microsStats.total_views) : '-';

    return (
        <>
            <section className={styles.container}>
                <button
                    className={cn(styles.expandButton, { [styles.expanded]: isExpanded })}
                    onClick={() => onExpandedChange(!isExpanded)}
                >
                    <ArrowIcon />
                </button>
                <div className={styles.statsContainer}>
                    <div className={cn(styles.statColumn, styles.name)}>
                        <div className={styles.statHeading}>{campaign?.name ?? 'Micros'}</div>
                        <div className={styles.statValue}>{postCountDisplayValue}</div>
                    </div>

                    <div className={cn(styles.statColumn, styles.costStats)}>
                        <div className={styles.budgetRow}>
                            <div className={cn(styles.statHeading, styles.budget)}>{budgetDisplayValue}</div>
                            <div className={styles.statValue}>{amountSpentPercentDisplayValue}</div>
                        </div>

                        <ProgressBar
                            trackStyles={{
                                width: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                                height: '0.33rem',
                                marginTop: '0.33rem',
                            }}
                            barStyles={{
                                backgroundColor: 'white',
                            }}
                            progress={amountSpentPercent}
                        />
                    </div>

                    <div className={cn(styles.statColumn, styles.views)}>
                        <div className={styles.statHeading}>Total Views</div>
                        <div className={styles.statValue}>{totalViews}</div>
                    </div>

                    <div className={cn(styles.statColumn, styles.engagementRate)}>
                        <div className={styles.statHeading}>Engagement Rate</div>
                        <div className={styles.statValue}>{engagementRate}</div>
                    </div>
                </div>
                <div className={styles.actionsContainer}>
                    <ProtectedByUserGroups groups={['microwave_admin']}>
                        <button className={styles.actionsContainerButton} onClick={onClickAddMicroPost}>
                            Add Post
                        </button>
                    </ProtectedByUserGroups>
                    {influencerPlan ? (
                        <ProtectedByUserGroups groups={['microwave_admin', 'round_planner']} shouldMatch="any">
                            <Link
                                className={styles.actionsContainerButton}
                                to={`/microwave/approve-posts/?plan_id=${influencerPlan.id}`}
                                target="_blank"
                            >
                                <CountBadge
                                    count={toApprovePostCount ?? '-'}
                                    className={cn({ [styles.alert]: Number(toApprovePostCount) > 0 })}
                                />
                                <span>Posts to approve ↗</span>
                            </Link>
                        </ProtectedByUserGroups>
                    ) : null}
                </div>
            </section>
            {isExpanded && children}
        </>
    );
};

export default TiktokMicrosStatsBar;
