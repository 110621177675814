import React, { useState } from 'react';
import { ValueType } from 'react-select';
import { GenericDropdownOption } from 'App.types';
import { formatToIsoDateString } from 'utility/utility';
import { getClients, getPlanReport, GetPlanReportParams } from '@round/api';
import { showNotification } from 'helpers';
import { file } from '@round/utils';
import styles from './Reports.module.css';
import PaginatedSelect from 'ui/DataEntry/PaginatedSelect/PaginatedSelect';
import { Button, DatePickerSelect } from '@round/ui-kit';
import selectStyles from './selectStyles';

const PlansReportDownload = () => {
    const [selectedClients, setSelectedClients] = useState<ValueType<GenericDropdownOption<number>, true>>(null);
    const [dateRange, setDateRange] = useState<[Date, Date] | [Date] | null>();
    const [startDate, endDate] = dateRange ?? [];

    const { isLoading, download } = usePlansReportDownload({
        start_date: startDate ? formatToIsoDateString(startDate) : undefined,
        end_date: endDate ? formatToIsoDateString(endDate) : undefined,
        client_ids: selectedClients?.map((v) => v.value).join(),
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <div>
                    <label>Clients</label>
                    <PaginatedSelect
                        fetchOptions={getClients}
                        mapToOption={(client) => ({
                            label: client.name,
                            value: client.id,
                        })}
                        value={selectedClients}
                        onChange={setSelectedClients}
                        menuPortalTarget={document.body}
                        isMulti
                        placeholder="Select Clients..."
                        closeMenuOnSelect={false}
                        styles={selectStyles}
                    />
                </div>

                <div>
                    <label>Date Range</label>
                    <DatePickerSelect
                        placeholder="Select Date Range..."
                        onChange={(dates) => {
                            setDateRange(dates);
                        }}
                        onClear={() => setDateRange(null)}
                        value={dateRange}
                        className={styles.datePicker}
                        selectRange
                    />
                </div>
            </div>

            <Button type="filled" color="black" loading={isLoading} onClick={download}>
                Download
            </Button>
        </div>
    );
};

function usePlansReportDownload(params: GetPlanReportParams) {
    const [isLoading, setIsLoading] = useState(false);

    const download = async () => {
        try {
            setIsLoading(true);
            const response = await getPlanReport(params);
            if (response.status !== 200) {
                showNotification(response.data.detail, 'error');
                return;
            }

            file.downloadFile(response.data);
        } catch (e) {
            const message = e instanceof Error ? e.message : 'Could not download report';
            showNotification(message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        download,
    };
}

export default PlansReportDownload;
