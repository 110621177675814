import React from 'react';
import styles from './Toggle.module.css';
import cn from 'classnames';
import { ButtonShell } from '@round/ui-kit';

type Props = {
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    className?: string;
    ariaLabel?: string;
};

const Toggle = ({ value, onChange, disabled, className, ariaLabel }: Props) => {
    return (
        <ButtonShell
            role="switch"
            aria-checked={value}
            aria-label={ariaLabel}
            className={cn(styles.track, className, { [styles.active]: value })}
            disabled={disabled}
            onClick={() => onChange(!value)}
        >
            <div className={styles.thumbContainer}>
                <div className={cn(styles.thumb, { [styles.active]: value })} />
            </div>
        </ButtonShell>
    );
};

export default Toggle;
