import { creatorbase } from '@round/api';
import { useState } from 'react';
import { numberWithCommas, showNotification } from 'helpers';
import TableInput from 'ui-new/whitelabel/TableInput/TableInput';

type Props = {
    storyViews: number | null;
    updateStoryViews?: (data: Partial<creatorbase.PatchPostData>) => ReturnType<typeof creatorbase.patchPost>;
    isReadonly?: boolean;
};

const formatStoryViews = (storyViews: number | null) => (storyViews === null ? '-' : numberWithCommas(storyViews));

const StoryViewsCell = ({ storyViews: initialStoryViews, updateStoryViews, isReadonly }: Props) => {
    const [storyViews, setStoryViews] = useState<number | null>(initialStoryViews);
    const [isStoryViewsUpdating, setIsStoryViewsUpdating] = useState(false);

    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleUpdateStoryViews = async () => {
        if (!updateStoryViews || storyViews === initialStoryViews) {
            return;
        }

        let errorMessage = 'Could not update story views';

        try {
            setIsStoryViewsUpdating(true);

            if (typeof storyViews === 'number' && (isNaN(storyViews) || storyViews < 0)) {
                showNotification('Story views must be a valid number', 'error');
                setStoryViews(initialStoryViews);
                return;
            }

            const response = await updateStoryViews({
                instagram_details: { story_view_count: storyViews },
            });

            if (response.status === 200) {
                setStoryViews(response.data.instagram_details?.story_view_count ?? null);
                showNotification('Story views updated', 'info');
                return;
            }

            if (response.status === 400 && 'instagram_details' in response.data) {
                const instagramDetails = response.data.instagram_details ?? {};
                if ('story_view_count' in instagramDetails) {
                    const apiErrorMessage = Array.isArray(instagramDetails?.story_view_count)
                        ? instagramDetails.story_view_count.join('. ')
                        : '';
                    errorMessage = apiErrorMessage || errorMessage;
                }
            }

            showNotification(errorMessage, 'error');
            setStoryViews(initialStoryViews);
        } catch {
            showNotification(errorMessage, 'error');
            setStoryViews(initialStoryViews);
        } finally {
            setIsStoryViewsUpdating(false);
        }
    };

    return (
        <TableInput
            type={isInputFocused ? 'number' : 'text'}
            value={isInputFocused ? storyViews?.toString() || '' : formatStoryViews(storyViews)}
            onChange={(e) => setStoryViews(e.target.value ? parseInt(e.target.value) : null)}
            onFocus={() => setIsInputFocused(true)}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                }
            }}
            onBlur={() => {
                handleUpdateStoryViews();
                setIsInputFocused(false);
            }}
            disabled={!updateStoryViews || isStoryViewsUpdating || isReadonly}
        />
    );
};

export default StoryViewsCell;
