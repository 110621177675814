import { creatorbase, youtube } from '@round/api';
import { Skeleton, SkeletonTableCell, TableProps, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useYoutubeReportCampaignPosts } from './useYoutubeReportCampaignPosts';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useReportCampaignStats } from '../../Campaigns/hooks/useReportCampaignStats';
import { CampaignTableRow } from '../../Campaigns/CampaignsTable/ReportCampaignsTable';
import CostCell from 'Modules/Plans/components/CostCell/CostCell';
import TotalCostFooterCell from 'Modules/Plans/components/TotalCostFooterCell/TotalCostFooterCell';
import NotesCell from 'Modules/Plans/components/NotesCell/NotesCell';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostStatsFooterTotalCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableStatFooterCell';
import PostsTableAccountCell, {
    AccountData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableAccountCell/PostsTableAccountCell';
import PostsTableUploadedDateCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableUploadedDateCell';
import { getYoutubeEngagementRatePercentageString, getYoutubeTotalEngagement } from 'Modules/Plans/Posts/helpers';
import PostsTableEngagementRateFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementRateFooterCell';
import PostsTableEngagementsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementsFooterCell';

export type YoutubeReportPostsTableRow = creatorbase.YoutubePost & {
    stats: youtube.YoutubeVideo | null;
    channel: youtube.Channel | null;
};

type Props = Pick<TableProps<YoutubeReportPostsTableRow>, 'noDataLabel'> & {
    campaign: CampaignTableRow;
};

type TableCellContext<K extends keyof YoutubeReportPostsTableRow> = CellContext<
    YoutubeReportPostsTableRow,
    YoutubeReportPostsTableRow[K]
>;

type Meta = BasePostTableMeta<YoutubeReportPostsTableRow> & {
    campaign: CampaignTableRow;
};
const getTableMeta = getTableMetaHelper<Meta>();

const YoutubeReportPostsTable = ({ campaign }: Props) => {
    const { postsData, channelsData, fetchData, getIsAccountDataLoading } = useYoutubeReportCampaignPosts(campaign.id);
    const isInitialized = postsData?.status === 'success' || postsData?.status === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData({ signal }).catch(() => {});
            }
        },
        [campaign.id, fetchData, isInitialized]
    );

    const { data: campaignStatsData, getAreCampaignStatsLoading } = useReportCampaignStats();

    const columns = useMemo<ColumnDef<YoutubeReportPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                accessorFn: (row): AccountData => ({
                    image: row.channel?.thumbnail || undefined,
                    title: row.channel?.title || '-',
                }),
                id: 'account',
                cell: PostsTableAccountCell,
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    isReadOnly: true,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, table }: TableCellContext<'cost'>) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return <CostCell isReadonly cost={getValue()} currency={campaign?.currency ?? null} />;
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalCostFooterCell
                            costs={table.getRowModel().rows.map((r) => r.original.cost)}
                            currency={campaign.currency}
                        />
                    );
                },
            },
            {
                header: 'Uploaded date',
                id: 'uploadedDate',
                cell: PostsTableUploadedDateCell,
            },
            {
                header: 'Views',
                id: 'views',
                accessorFn: (row) => row.view_count,
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Total engagements',
                id: 'totalEngagements',
                accessorFn: (row) => getYoutubeTotalEngagement(row),
                cell: PostStatsValueCell,
                footer: PostsTableEngagementsFooterCell,
            },
            {
                header: 'Engagement rate',
                id: 'engagementRate',
                accessorFn: (row) => getYoutubeEngagementRatePercentageString(row),
                cell: SkeletonTableCell,
                footer: PostsTableEngagementRateFooterCell,
            },
            {
                header: 'Likes',
                id: 'likes',
                accessorFn: (row) => row.youtube_details?.like_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Comments',
                id: 'comments',
                accessorFn: (row) => row.youtube_details?.comment_count,
                cell: PostStatsValueCell,
                footer: PostStatsFooterTotalCell,
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ getValue, table }: TableCellContext<'notes'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return <NotesCell notes={getValue()} />;
                },
            },
        ],
        []
    );

    const meta: Meta = {
        isLoading: !isInitialized,
        getIsAccountDataLoading: (row) => getIsAccountDataLoading(row.youtube_details?.account_id),
        campaign,
        campaignStats: campaignStatsData[campaign.id]?.data ?? null,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
        managedBy: campaign.managed_by,
    };

    const rows = useMemo(
        () =>
            postsData?.data?.map((post) => {
                return {
                    ...post,
                    channel: post.youtube_details?.account_id
                        ? channelsData[post.youtube_details.account_id].data
                        : null,
                } as YoutubeReportPostsTableRow;
            }) ?? [],
        [postsData?.data, channelsData]
    );

    return (
        <PostsTable
            columns={columns}
            data={rows}
            meta={meta}
            isLoading={!isInitialized}
            hasError={postsData?.status === 'error'}
        />
    );
};

export default YoutubeReportPostsTable;
