import { creatorbase } from '@round/api';
import { Form, FormikProvider, useFormik, yupToFormErrors } from 'formik';
import Modal from 'ui-new/whitelabel/Modal/Modal';
import CampaignFormBase, {
    BaseCampaignFormValues,
    getBaseCampaignFormFields,
    mapCampaignApiErrorsToFormikErrors,
    mapCampaignFormValuesToApiData,
    validationSchema,
} from '../../components/CampaignFormBase/CampaignFormBase';
import { omit } from 'lodash';
import { useMemo } from 'react';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import { GenericDropdownOption } from 'App.types';
import Button from 'ui-new/whitelabel/Button/Button';
import { UseCampaigns, useCampaigns } from '../../hooks/useCampaigns';
import { getObjectDifference, showNotification } from 'helpers';
import { useUsers } from 'Modules/Plans/User/hooks/useUsers';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { mapUserToOption } from 'Modules/Plans/User/hooks/useUsersSelect';
import styles from './UpdateYoutubeCampaignModal.module.css';
import { useProjectDetails } from 'Modules/Plans/Project/contexts/ProjectContext';
import { usePosts } from 'Modules/Plans/Project/contexts/PostsContext';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    campaign: creatorbase.YoutubeCampaign | null;
    updateCampaign: UseCampaigns['updateCampaign'];
};

const UpdateYoutubeCampaignModal = ({ isOpen, closeModal, campaign, updateCampaign }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currency = currencies
        .map<GenericDropdownOption<number>>((c) => ({ value: c.id, label: c.name }))
        .find((o) => o.value === campaign?.currency_id);

    const { data: campaignsData } = useCampaigns();
    const youtubeCampaigns = campaignsData['youtube']?.data?.results;

    const { data: projectData } = useProjectDetails();

    const { data: posts } = usePosts();
    const campaignPosts = posts?.filter((post) => post.campaign_id === campaign?.id) ?? [];

    const { fetchData: fetchUsers, data: users, reset: resetUsers, status: usersStatus } = useUsers();
    const isInitialized = usersStatus === 'success' || usersStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && campaign?.team_members.length) {
                fetchUsers(
                    {
                        id: campaign.team_members.join(),
                        page_size: campaign.team_members.length,
                    },
                    { signal }
                ).catch(() => {});
            }
        },
        [campaign?.team_members, fetchUsers, isInitialized]
    );

    const initialValues = useMemo<BaseCampaignFormValues>(
        () => ({
            cost: String(campaign?.cost ?? ''),
            currency: currency,
            is_post_cost_editable: campaign?.is_post_cost_editable ?? null,
            name: campaign?.name ?? '',
            team_members: users?.results.map(mapUserToOption),
        }),
        [campaign?.cost, campaign?.is_post_cost_editable, campaign?.name, currency, users?.results]
    );

    const formik = useFormik<BaseCampaignFormValues>({
        initialValues,
        onSubmit: async (values, helpers) => {
            if (values.is_post_cost_editable === null || !campaign?.id) {
                return;
            }

            try {
                const mappedFormValues = mapCampaignFormValuesToApiData(values);
                const updatedFields = getObjectDifference(mappedFormValues, campaign);

                const response = await updateCampaign(campaign.id, updatedFields);

                if (response.status === 200) {
                    showNotification('Campaign updated', 'info');
                    helpers.resetForm();
                    resetUsers();
                    closeModal();
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(mapCampaignApiErrorsToFormikErrors(response.data));
                    return;
                }

                showNotification('Could not update campaign', 'error');
            } catch {
                showNotification('Could not udpate campaign', 'error');
            }
        },
        enableReinitialize: true,
        // validationSchema param threw an error on validation
        validate: async (values) => {
            try {
                const transformedValues = values.is_post_cost_editable === false ? values : omit(values, 'cost');
                await validationSchema.validate(transformedValues, { abortEarly: false });
            } catch (e) {
                return yupToFormErrors(e);
            }
        },
    });

    const handleClose = () => {
        formik.resetForm();
        resetUsers();
        closeModal();
    };

    const isSpendingTypeDisabled = campaign?.is_post_cost_editable && campaignPosts.some((post) => post.cost !== null);
    const isNotManagedByCreatorbase = Boolean(campaign?.managed_by && campaign?.managed_by !== 'creatorbase');

    const readonlyFieldConditions: { [k in keyof BaseCampaignFormValues]?: boolean } = {
        is_post_cost_editable: isSpendingTypeDisabled || isNotManagedByCreatorbase,
        cost: isNotManagedByCreatorbase,
        currency: (!!youtubeCampaigns?.length && youtubeCampaigns.length > 1) || isNotManagedByCreatorbase,
    };

    const readonlyFields = Object.entries(readonlyFieldConditions)
        .filter(([, condition]) => !!condition)
        .map(([field]) => field);

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <Modal.Header>
                <Modal.Title>Edit campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormikProvider value={formik}>
                    <Form className={styles.form}>
                        <CampaignFormBase
                            readonlyFields={getBaseCampaignFormFields(readonlyFields)}
                            teamId={projectData?.team?.id}
                            isManagedExternally={isNotManagedByCreatorbase}
                        />
                    </Form>
                </FormikProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    appearance="primary"
                    disabled={!formik.dirty || !formik.isValid}
                    isLoading={formik.isSubmitting}
                    onClick={formik.submitForm}
                >
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default UpdateYoutubeCampaignModal;
