import { createContext, PropsWithChildren, useState, Dispatch, SetStateAction } from 'react';
import { DataState } from 'App.types';
import { InstagramAudio, TiktokAudio } from '@round/api';

type TiktokState = DataState<(TiktokAudio & Partial<{ video_count: number }>)[]>;
type InstagramState = DataState<InstagramAudio[]>;

type ContextData = {
    tiktokState: TiktokState;
    setTiktokState: Dispatch<SetStateAction<TiktokState>>;
    instagramState: InstagramState;
    setInstagramState: Dispatch<SetStateAction<InstagramState>>;
};

const initialState = {
    status: 'idle',
    data: [],
    error: null,
};

export const SongFormAudiosContext = createContext<ContextData | null>(null);

export function SongFormAudiosProvider({ children }: PropsWithChildren) {
    const [tiktokState, setTiktokState] = useState<TiktokState>(initialState as TiktokState);
    const [instagramState, setInstagramState] = useState<InstagramState>(initialState as InstagramState);

    return (
        <SongFormAudiosContext.Provider
            value={{
                tiktokState,
                setTiktokState,
                instagramState,
                setInstagramState,
            }}
        >
            {children}
        </SongFormAudiosContext.Provider>
    );
}
