import { creatorbase, music } from '@round/api';
import { Image, Skeleton, SkeletonizedValue, getTableMetaHelper } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import styles from './SongsTable.module.css';
import Badge from 'ui-new/whitelabel/Badge/Badge';
import { capitalize, uniq } from 'lodash';
import { ReactComponent as InstagramIcon } from 'assets/whitelabel/platform/InstagramColor.svg';
import { ReactComponent as TikTokIcon } from 'assets/whitelabel/platform/TiktokColor.svg';
import { ReactComponent as EditIcon } from 'assets/whitelabel/Edit.svg';
import { ReactComponent as TrashIcon } from 'assets/whitelabel/Trash.svg';
import { numberWithCommas } from 'helpers';
import Dropdown from 'ui-new/whitelabel/Dropdown/Dropdown';
import VirtualizedTable, { Props as VirtualizedTableProps } from 'ui-new/whitelabel/Table/VirtualizedTable';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import SongGrowthChart from '../../components/SongGrowthChart/SongGrowthChart';
import { DataState } from '@round/utils';

export type SongsTableRow = creatorbase.Song & {
    musicSong: music.Song | undefined;
    timeSeries: DataState<
        creatorbase.TimeSeriesResponse<'tiktok_daily_change' | 'instagram_daily_change'>[number]
    > | null;
};

type Props = Pick<VirtualizedTableProps<SongsTableRow>, 'data' | 'onRowClick' | 'onEndReached'> & {
    isLoading?: boolean;
    hasError?: boolean;
    onEditSong: (song: SongsTableRow) => void;
    onDeleteSong: (song: SongsTableRow) => void;
};

type TableCellContext<TKey extends keyof SongsTableRow> = CellContext<SongsTableRow, SongsTableRow[TKey]>;
type Meta = Pick<Props, 'isLoading' | 'onEditSong' | 'onDeleteSong'>;
const getTableMeta = getTableMetaHelper<Meta>();

const SongsTable = ({ data, isLoading, hasError, onRowClick, onEditSong, onDeleteSong, onEndReached }: Props) => {
    const columns: ColumnDef<SongsTableRow, any>[] = [
        {
            header: 'Song',
            id: 'song',
            cell: ({ row: { original }, table }) => {
                const { isLoading } = getTableMeta(table);

                const title = original.musicSong?.title || original.title;
                const artists = original.musicSong?.artists.map((a) => a.name).join(', ') || original.artist_name;
                return (
                    <div className={styles.songContainer}>
                        <Image
                            skeletonCircle={false}
                            className={styles.image}
                            loading={isLoading}
                            src={original.musicSong?.image ?? ''}
                            alt={original.title}
                        />

                        <div className={styles.songTitles}>
                            <SkeletonizedValue width="10rem" isInitialized={!isLoading}>
                                <TruncationPopover content={title}>
                                    {(setRef) => (
                                        <p ref={setRef} className={styles.songTitle}>
                                            {title}
                                        </p>
                                    )}
                                </TruncationPopover>
                            </SkeletonizedValue>

                            <SkeletonizedValue width="7rem" isInitialized={!isLoading}>
                                <TruncationPopover content={artists}>
                                    {(setRef) => (
                                        <p ref={setRef} className={styles.subValue}>
                                            {artists}
                                        </p>
                                    )}
                                </TruncationPopover>
                            </SkeletonizedValue>
                        </div>
                    </div>
                );
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: ({ getValue, table }: TableCellContext<'status'>) => {
                const { isLoading } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton width="10rem" />;
                }

                return <Badge className={styles[getValue()]}>{capitalize(getValue())}</Badge>;
            },
        },
        {
            header: 'Audios',
            id: 'audios',
            meta: {
                className: styles.audiosHeader,
            },
            columns: [
                {
                    header: () => <InstagramIcon className={styles.platformHeaderIcon} />,
                    accessorKey: 'instagram_audios',
                    meta: {
                        className: styles.audiosCell,
                    },
                    cell: ({ getValue, row: { original }, table }: TableCellContext<'instagram_audios'>) => {
                        const { isLoading } = getTableMeta(table);

                        if (isLoading) {
                            return <Skeleton width="1rem" />;
                        }

                        const audios = uniq((original.musicSong?.instagram_audios ?? []).concat(getValue()));
                        return numberWithCommas(audios.length);
                    },
                },
                {
                    header: () => <TikTokIcon className={styles.platformHeaderIcon} />,
                    accessorKey: 'tiktok_audios',
                    meta: {
                        className: styles.audiosCell,
                    },
                    cell: ({ getValue, row: { original }, table }: TableCellContext<'tiktok_audios'>) => {
                        const { isLoading } = getTableMeta(table);

                        if (isLoading) {
                            return <Skeleton width="1rem" />;
                        }

                        const audios = uniq((original.musicSong?.tiktok_audios ?? []).concat(getValue()));
                        return numberWithCommas(audios.length);
                    },
                },
            ],
        },
        {
            header: '',
            id: 'dailyChange',
            accessorKey: 'timeSeries',
            meta: {
                className: styles.dailyChange,
            },
            cell: ({ getValue, table }: TableCellContext<'timeSeries'>) => {
                const { isLoading } = getTableMeta(table);
                const timeSeriesState = getValue();
                const isTimeSeriesLoading = timeSeriesState?.status === 'loading' || timeSeriesState?.status === 'idle';
                if (isLoading) {
                    return <Skeleton width="10rem" />;
                }

                return (
                    <SongGrowthChart
                        timeSeries={timeSeriesState?.data ?? null}
                        isLoading={isTimeSeriesLoading}
                        hasError={timeSeriesState?.status === 'error'}
                    />
                );
            },
        },
        {
            header: '',
            id: 'actions',
            meta: {
                className: styles.actions,
            },
            cell: ({ row: { original }, table }) => {
                const { isLoading, onEditSong } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton width="1rem" />;
                }

                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown>
                            {({ setIsOpen }) => (
                                <>
                                    <Dropdown.Target />
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon={EditIcon}
                                            onClick={() => {
                                                onEditSong(original);
                                                setIsOpen(false);
                                            }}
                                        >
                                            Edit song
                                        </Dropdown.Item>

                                        <Dropdown.Separator />

                                        <Dropdown.Item
                                            icon={() => <TrashIcon className={styles.deleteAction} />}
                                            onClick={() => {
                                                onDeleteSong(original);
                                                setIsOpen(false);
                                            }}
                                            className={styles.deleteAction}
                                        >
                                            Delete song
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </>
                            )}
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const meta: Meta = {
        isLoading,
        onEditSong,
        onDeleteSong,
    };

    const emptyRows = new Array(10).fill({}) as SongsTableRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    return (
        <VirtualizedTable
            containerClassName={styles.tableContainer}
            className={styles.table}
            data={rows}
            columns={columns}
            meta={meta}
            noDataLabel={
                <div aria-label="no songs" className={styles.noDataContainer}>
                    {hasError ? "Couldn't fetch songs" : 'No songs found'}
                </div>
            }
            onRowClick={onRowClick}
            onEndReached={onEndReached}
            virtualizerOptions={{
                count: rows.length,
                estimateSize: () => 50,
                getItemKey: (index) => rows[index]?.id || index,
                overscan: 5,
            }}
        />
    );
};

export default SongsTable;
