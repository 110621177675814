import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse } from '../../mirage.helpers';

export function handleRequests(server: AppServer) {
    const listUrl = '/api/catalogue/viewsets/catalogue/:id/tiktok-audio-stats/';
    server.get(listUrl, function (this: RouteHandlerContext, schema, request) {
        const stats = schema.all('tiktokAudioStats');
        return buildPaginatedResponse(stats, {
            url: listUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'tiktokAudioStats'),
        });
    });
}
