import { ScrollView, TableProps, Table } from '@round/ui-kit';
import styles from './CampaignsTable.module.css';
import cn from 'classnames';
import { creatorbase } from '@round/api';
import { getApplicationStyleVariables } from 'ui-new/whitelabel/utils/applicationStyles/applicationStyles';

export type BaseCampaign = Pick<creatorbase.Campaign, 'id' | 'managed_by'>;

export type Props<TCampaignsTableRow extends BaseCampaign> = TableProps<TCampaignsTableRow> & {
    hasError?: boolean;
    isLoading?: boolean;
};

const CampaignsTable = <TCampaignsTableRow extends BaseCampaign>({
    isLoading,
    hasError,
    className,
    data,
    ...props
}: Props<TCampaignsTableRow>) => {
    const emptyRows = new Array(10).fill({}) as TCampaignsTableRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    return (
        <ScrollView>
            <Table
                enableExpanding
                manualExpanding
                className={cn(styles.table, className, { [styles.empty]: !data.length && !isLoading })}
                getRowClassName={(row) =>
                    cn(getApplicationStyleVariables(row.original.managed_by), {
                        [styles.expandedRow]: row.getIsExpanded(),
                        [styles.managedByRoundExpandedRow]: row.getIsExpanded() && row.original.managed_by === 'round',
                    })
                }
                //in case of empty loading rows, we need a fallback row id
                getRowId={(original, index) => original.id?.toString() ?? index.toString()}
                noDataLabel={
                    isLoading ? undefined : (
                        <div className={styles.noDataContainer} aria-label="no campaigns">
                            {hasError ? 'Could not load campaigns' : 'No campaigns yet'}
                        </div>
                    )
                }
                data={rows}
                {...props}
            />
        </ScrollView>
    );
};

export default CampaignsTable;
