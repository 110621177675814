import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import styles from './EmailPreviewModal.module.css';
import useEmailTemplatesSelect from '../../hooks/useEmailTemplatesSelect';
import { notificationTypeLabels } from '../../MicroCreators/helpers';
import Select from 'ui-new/Select/Select';
import Button from 'ui/Button/Button';
import { useSendChaseEmails } from './hooks/useSendChaseEmails';
import EmailResults from './components/EmailResults/EmailResults';
import { microwave, getPaginatedIdQueryParam } from '@round/api';
import { Banner } from '@round/ui-kit';
import useInviteSelect, { fetchOptions } from '../../hooks/useInviteSelect';
import { useCallback, useState } from 'react';
import EmailContent from '../../MicroCreators/EmailPreview/EmailContent/EmailContent';
import { useEmailPreview } from './useEmailPreview';
import { useEmailAddressOverrides } from './hooks/useEmailAddressOverrides';
import ErrorMessage from 'ui-new/ErrorMessage/ErrorMessage';
import cn from 'classnames';
//TODO: extract input component from FormField.module.css
import inputStyles from 'ui-new/formik/FormField.module.css';
import isString from 'lodash/isString';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    campaignId: number;
    inviteIds: string[];
    onSent?: (results: microwave.PostEmailsResponseData) => void;
};

const NOTIFICATION_TYPE = 'SONG_PROMO_CHASE';

const ChaseEmailPreviewModal = ({ isOpen, onClose, onSent, campaignId, inviteIds }: Props) => {
    //since the initial email value in the input comes from the API, we can assume it's valid
    const [isEmailInputValid, setIsEmailInputValid] = useState<boolean | undefined>(true);

    const {
        selectedTemplate,
        setSelectedTemplate,
        templateOptions,
        status: templatesStatus,
        error: templatesError,
    } = useEmailTemplatesSelect(NOTIFICATION_TYPE);

    const inviteIdStringQuery = inviteIds.join(',');
    const {
        props: inviteSelectProps,
        isInitialValueLoading: isInitialInviteLoading,
        resetOptions: resetInviteOptions,
        resetValue: resetInviteValue,
    } = useInviteSelect({
        fetchOptions: useCallback(
            (params, requestInit) => {
                const ids = inviteIdStringQuery.split(',');
                const paginatedIdQueries = getPaginatedIdQueryParam(ids, params.page_size);

                const pageIdsParam = paginatedIdQueries[params.page - 1];
                // We're including the next page worth of IDs so that the API's "next" response value does not prematurely return false
                const nextPageIdsParam = paginatedIdQueries[params.page] || [];

                return fetchOptions(
                    // Since we're paginating IDs manually, the page should always be 1
                    { ...params, page: 1, id: [...pageIdsParam, ...nextPageIdsParam].join(',') },
                    requestInit
                );
            },
            [inviteIdStringQuery]
        ),
        initialValueId: isOpen ? inviteIds[0] : undefined,
        initOn: 'menuOpen',
    });

    const selectedInvite = inviteSelectProps.value;

    const {
        data: emailPreviewData,
        status: emailPreviewStatus,
        error: emailPreviewError,
        reset: resetEmailPreview,
    } = useEmailPreview({
        params: {
            campaign_id: campaignId,
            email_template_id: selectedTemplate?.value!,
            influencer_id: selectedInvite?.influencerId!,
        },
        isDisabled: !isOpen || !selectedTemplate?.value || !selectedInvite?.value,
    });

    const { selectedOverride, setSelectedOverride, overrides, resetOverrides } = useEmailAddressOverrides<string>(
        selectedInvite?.value
    );
    const currentEmailValue = typeof selectedOverride === 'string' ? selectedOverride : selectedInvite?.emailAddress;

    const {
        send,
        data: sendResults,
        error: sendError,
        status: sendStatus,
        reset: resetSendData,
    } = useSendChaseEmails();

    const handleSend = async () => {
        if (!selectedTemplate?.value) {
            return;
        }

        const emailData = inviteIds.map((id) => {
            const relevantOverride = overrides.find((o) => o.id === id);

            return {
                invite_id: id,
                email_template_id: selectedTemplate.value,
                email_address: relevantOverride?.emailAddress,
            };
        });

        const result = await send(emailData).catch(() => {});
        if (result && result.success) {
            onSent?.(result.data);
        }
    };

    const handleClose = () => {
        onClose();
        resetInviteOptions();
        resetInviteValue();
        resetEmailPreview();
        resetOverrides();
        setIsEmailInputValid(true);
        resetSendData();
    };

    return (
        <FullScreenModal isOpen={isOpen} onClose={handleClose}>
            {sendStatus === 'success' ? (
                <>
                    <FullScreenModal.Header>Results</FullScreenModal.Header>
                    <EmailResults results={sendResults} />
                    <FullScreenModal.Footer>
                        <Button appearance="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </FullScreenModal.Footer>
                </>
            ) : (
                <>
                    <FullScreenModal.Header>Email Preview</FullScreenModal.Header>
                    <FullScreenModal.Content className={styles.container}>
                        <div className={styles.selectContainer}>
                            <label className={styles.selectLabel}>
                                {notificationTypeLabels[NOTIFICATION_TYPE]} Templates
                            </label>
                            <Select
                                isLoading={templatesStatus === 'not-initialized'}
                                options={templateOptions}
                                value={selectedTemplate}
                                onChange={(opt) => {
                                    if (opt?.value === selectedTemplate?.value) {
                                        return;
                                    }

                                    setSelectedTemplate(opt);
                                    resetEmailPreview();
                                }}
                                noOptionsMessage={() => templatesError || 'No options'}
                            />
                        </div>

                        <div className={styles.influencerRow}>
                            <div className={cn(styles.selectContainer, styles.influencerSelectContainer)}>
                                <label className={styles.selectLabel}>Influencer</label>
                                <Select
                                    {...inviteSelectProps}
                                    onChange={(opt, action) => {
                                        inviteSelectProps.onChange?.(opt, action);
                                        resetEmailPreview();
                                    }}
                                    isDisabled={inviteIds.length <= 1 || isEmailInputValid !== true}
                                    placeholder="Select invite..."
                                    formatOptionLabel={(option) => option.instagramUsername || option.emailAddress}
                                />
                            </div>

                            <div className={styles.selectContainer}>
                                <label className={styles.selectLabel}>Email Address</label>
                                <input
                                    type="email"
                                    value={currentEmailValue || ''}
                                    onChange={(e) => {
                                        setSelectedOverride(e.target.value);
                                        setIsEmailInputValid(undefined);
                                    }}
                                    onBlur={(e) => {
                                        setIsEmailInputValid(!!e.target.value && e.target.checkValidity());
                                    }}
                                    className={inputStyles.input}
                                    disabled={!selectedInvite}
                                    placeholder="Enter email address..."
                                />
                                {selectedInvite && isEmailInputValid === false && (
                                    <ErrorMessage>Please enter a valid email address</ErrorMessage>
                                )}
                            </div>
                        </div>

                        <EmailContent
                            isLoading={emailPreviewStatus === 'idle' || emailPreviewStatus === 'loading'}
                            error={[
                                emailPreviewError,
                                templatesError,
                                !selectedInvite && !isInitialInviteLoading ? `No invite selected` : null,
                            ].filter(isString)}
                            preview={emailPreviewData}
                        />
                    </FullScreenModal.Content>
                    <FullScreenModal.Footer>
                        {sendStatus === 'error' && <Banner appearance="danger">{sendError}</Banner>}
                        <Button
                            appearance="primary"
                            onClick={handleSend}
                            disabled={
                                sendStatus === 'loading' ||
                                !selectedTemplate?.value ||
                                isEmailInputValid !== true ||
                                //Though it's not necessary for sending emails, we want to prevent sending if the email preview is not available
                                emailPreviewStatus !== 'success'
                            }
                            isLoading={sendStatus === 'loading'}
                        >
                            Send
                        </Button>
                    </FullScreenModal.Footer>
                </>
            )}
        </FullScreenModal>
    );
};

export default ChaseEmailPreviewModal;
