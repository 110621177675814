import { queryOptions, skipToken } from '@tanstack/react-query';
import {
    buildListKeyFactories,
    buildCoreKeyFactory,
    buildDetailKeyFactories,
    parseQueryKey,
    buildErrorResponseGetter,
} from '../../queries.helpers';
import { ExpectedResponseError } from '../../utils';
import { getBrand, GetBrandParams, getBrands, GetBrandsParams } from './brands.api';

export const CORE_KEY = 'creatorbase-brand';

export const keys = {
    all: buildCoreKeyFactory(CORE_KEY),
    ...buildListKeyFactories<GetBrandsParams, typeof CORE_KEY>(CORE_KEY),
    ...buildDetailKeyFactories<number, typeof CORE_KEY>(CORE_KEY),
};

export const options = {
    list: (params: GetBrandsParams) =>
        queryOptions({
            queryKey: keys.list(params),
            queryFn: async ({ signal, queryKey }) => {
                const { params } = parseQueryKey(queryKey);

                const response = await getBrands(params, { signal });

                if (response.status === 200) {
                    return response.data;
                }

                throw new ExpectedResponseError('Failed to fetch brands', response);
            },
            staleTime: 1000 * 60,
        }),
    detail: (id: number | null | undefined, params: GetBrandParams = {}) =>
        queryOptions({
            queryKey: typeof id === 'number' ? keys.detail(id) : keys.details(),
            queryFn:
                typeof id !== 'number'
                    ? skipToken
                    : async ({ signal }) => {
                          const response = await getBrand(id, params, { signal });

                          if (response.status === 200) {
                              return response.data;
                          }

                          throw new ExpectedResponseError('Failed to fetch brand', response);
                      },
            staleTime: 1000 * 60 * 5,
        }),
};

export const utils = {
    list: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getBrands>>>(),
    },
    detail: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getBrand>>>(),
    },
};
