import { call, encodeUrlSearchParams } from '../../helpers';
import { isPublicReportRequestInit, PublicReportRequestInit } from '../../influencer/utils';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { PublicTeam, Team } from './teams.types';

type GetTeamsResponse<T extends Team | PublicTeam> =
    | ApiResponse<PaginatedApiResponseData<T>, 200>
    | ForbiddenResponse
    | NotFoundResponse;

export type GetTeamsParams = Partial<
    PaginatedRequest & {
        id: string;
        user_with_direct_access_id: number;
        // Bypasses the default filter to include items beyond the user's team(s).
        // If absent, defaults to true.
        team_only: false;
        search: string;
    }
>;

export async function getTeams(
    params: GetTeamsParams,
    requestInit: PublicReportRequestInit
): Promise<GetTeamsResponse<PublicTeam>>;
export async function getTeams(params: GetTeamsParams, requestInit?: RequestInit): Promise<GetTeamsResponse<Team>>;
export async function getTeams(
    params: GetTeamsParams,
    requestInit?: RequestInit | PublicReportRequestInit
): Promise<GetTeamsResponse<Team> | GetTeamsResponse<PublicTeam>> {
    const response = await call(`/api/creatorbase/teams/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get teams');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type BaseGetTeamResponse<T extends Team | PublicTeam> = ApiResponse<T, 200> | ForbiddenResponse | NotFoundResponse;

export type GetTeamParams = Partial<{
    // Bypasses the default filter to include items beyond the user's team(s).
    // If absent, defaults to true.
    team_only: false;
}>;
type GetTeamResponse = BaseGetTeamResponse<Team>;
type GetPublicTeamResponse = BaseGetTeamResponse<PublicTeam>;

export async function getTeam(
    teamId: number,
    params: GetTeamParams,
    requestInit: PublicReportRequestInit
): Promise<GetPublicTeamResponse>;
export async function getTeam(
    teamId: number,
    params: GetTeamParams,
    requestInit?: RequestInit
): Promise<GetTeamResponse>;
export async function getTeam(
    teamId: number,
    params: GetTeamParams = {},
    requestInit?: RequestInit | PublicReportRequestInit
): Promise<GetPublicTeamResponse | GetTeamResponse> {
    const shouldBypassTokens = requestInit && isPublicReportRequestInit(requestInit);
    const response = await call(`/api/creatorbase/teams/${teamId}/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens,
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get team');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
