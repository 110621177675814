import { showNotification } from 'helpers';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { ValueType } from 'react-select';
import { InfluencerPlanOption } from '../components/InfluencerPlanSelectOption/InfluencerPlanSelectOption';
import { InfluencerPlan, getInfluencerPlans } from '@round/api';

const PAGE_SIZE = 10;

type Params<TIsMulti extends boolean> = TIsMulti extends true
    ? {
          initialPlanIdData: number[];
          isMulti: TIsMulti;
      }
    : {
          initialPlanIdData: number | undefined;
          isMulti?: TIsMulti;
      };

const makePlanOption = (plan: InfluencerPlan): InfluencerPlanOption => ({
    value: plan.id,
    label: plan.release.name,
    artistName: plan.release.brand.name,
    releaseId: plan.release.id,
    imageUrl: plan.release.brand.picture,
});

export function usePlanSelect<TIsMulti extends boolean = false>({ initialPlanIdData, isMulti }: Params<TIsMulti>) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [plans, setPlans] = useState<InfluencerPlan[]>([]);

    const [planSelection, setPlanSelection] = useState<ValueType<InfluencerPlanOption, TIsMulti>>(null);

    const fetchData = useCallback(
        async (params: Parameters<typeof getInfluencerPlans>[0], requestInit?: RequestInit) => {
            try {
                setIsLoading(true);
                const response = await getInfluencerPlans(params, requestInit);
                if (response.status !== 200) {
                    throw new Error(response.data.detail);
                }

                const { results, next } = response.data;
                setPlans((prev) => prev.concat(results));
                setHasNextPage(!!next);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                const errorMessage = e instanceof Error ? e.message : 'Could not fetch influencer plans';
                showNotification(errorMessage, 'error');
            } finally {
                setIsLoading(false);
                setIsSearchLoading(false);
            }
        },
        []
    );

    const debouncedFetchData = useMemo(() => debounce(fetchData, 700), [fetchData]);

    useAbortableEffect(
        (signal) => {
            if (!search) {
                return;
            }
            debouncedFetchData({ search: search, page, page_size: PAGE_SIZE }, { signal });
        },
        [debouncedFetchData, page, search]
    );

    const reset = useCallback(() => {
        setPlans([]);
        setPage(1);
        setHasNextPage(false);
    }, []);

    const options = useMemo(() => plans.map((plan) => makePlanOption(plan)), [plans]);

    useAbortableEffect(
        (signal) => {
            async function initData() {
                if (!initialPlanIdData || (Array.isArray(initialPlanIdData) && !initialPlanIdData.length)) {
                    return;
                }

                try {
                    const planData = Array.isArray(initialPlanIdData) ? initialPlanIdData : [initialPlanIdData];
                    let plansToFetch: number[] = [];
                    if (isMulti) {
                        plansToFetch = planData.filter(
                            (id) =>
                                !(planSelection as ValueType<InfluencerPlanOption, true>)?.some(
                                    (option) => option.value === id
                                )
                        );
                    } else {
                        plansToFetch =
                            (planSelection as ValueType<InfluencerPlanOption, false>)?.value === planData[0]
                                ? []
                                : planData;
                    }

                    if (!plansToFetch.length) {
                        return;
                    }

                    const response = await getInfluencerPlans(
                        { id: plansToFetch.join(','), page_size: plansToFetch.length },
                        { signal }
                    );

                    if (response.status !== 200) {
                        throw new Error(response.data.detail);
                    }

                    if (isMulti) {
                        (setPlanSelection as Dispatch<
                            SetStateAction<ValueType<InfluencerPlanOption, true>>
                        >)((options) =>
                            (options ?? []).concat(response.data.results.map((plan) => makePlanOption(plan)))
                        );
                        return;
                    }
                    (setPlanSelection as Dispatch<SetStateAction<ValueType<InfluencerPlanOption, false>>>)(
                        makePlanOption(response.data.results[0])
                    );
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    showNotification('Could not fetch initial plans', 'error');
                }
            }
            initData();
        },
        [planSelection, initialPlanIdData, isMulti]
    );

    return {
        value: planSelection,
        onChange: setPlanSelection,
        options,
        inputValue: search,
        onInputChange: (value: string) => {
            if (value === search) {
                return;
            }

            reset();
            setSearch(value);

            if (value) {
                setIsSearchLoading(true);
            }
        },
        onMenuScrollToBottom: () => {
            if (!hasNextPage) {
                return;
            }
            setPage((prev) => prev + 1);
        },
        isLoading: isLoading || isSearchLoading,
    };
}
