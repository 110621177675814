import { Point } from 'ui-new/whitelabel/Charts/types';
import { creatorbase, instagram } from '@round/api';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import moment from 'moment/moment';
import styles from './SongGrowthTooltipBreakdown.module.css';
import { numberWithCommas } from 'helpers';

type Props = {
    tooltipItem: Point;
    instagramTimeSeries: instagram.TimeSeries[];
    tiktokTimeSeries: creatorbase.TimeSeries[];
};

const SongGrowthTooltipBreakdown = ({ tooltipItem, instagramTimeSeries = [], tiktokTimeSeries = [] }: Props) => {
    const tiktokItem = tiktokTimeSeries.find((tt) => tt.timestamp === tooltipItem.x);
    const instagramItem = instagramTimeSeries.find((ig) => ig.timestamp === tooltipItem.x);

    return (
        <>
            <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
            <Tooltip.Body>
                <dl className={styles.stats}>
                    {tiktokItem && (
                        <div className={styles.statsItem}>
                            <dt>TikTok Growth</dt>
                            <dd>{numberWithCommas(tiktokItem?.value)}</dd>
                        </div>
                    )}
                    {instagramItem && (
                        <div className={styles.statsItem}>
                            <dt>Instagram Growth</dt>
                            <dd>{numberWithCommas(instagramItem?.value)}</dd>
                        </div>
                    )}

                    <hr />

                    <div className={styles.statsItem}>
                        <dt>Total Growth</dt>
                        <dd>{numberWithCommas(tooltipItem.y)}</dd>
                    </div>
                </dl>
            </Tooltip.Body>
        </>
    );
};

export default SongGrowthTooltipBreakdown;
