import React, { useContext, useState } from 'react';
import { showNotification } from 'helpers';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import styles from './BadgeList.module.css';
import useUrlState from 'Hooks/useUrlState';
import Audios from './Audios/Audios';
import Modal, { ModalFooter, ModalTitle } from 'ui/General/Modal/Modal';
import Button from 'ui/Buttons/Button/Button';
import Hashtags from './Hashtags/Hashtags';
import AddHashtagModal from './AddHashtagModal/AddHashtagModal';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import useCreatorPlanTiktokAudios from '../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokAudios';
import useCreatorPlanTiktokHashtags from '../../contexts/TiktokCreatorsContext/useCreatorPlanTiktokHashtags';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { TikTokInfluencerPlanHashtag } from '@round/api';

export const TiktokBadgeList = () => {
    const [addHashtagModalOpen, setAddHashtagModalOpen] = useState(false);
    const [hashtagToDelete, setHashtagToDelete] = useState<TikTokInfluencerPlanHashtag>();
    const [, setUrlState] = useUrlState<{ audioId: number }>();
    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);

    const context = useContext(InfluencerPlanContext);
    const { influencerPlan } = context;

    const {
        init: initTiktokHashtags,
        hashtags: tiktokHashtags,
        isLoading: isTiktokHashtagsLoading,
        isInitialized: isTiktokHashtagsInitialized,
        createHashtag,
        deleteHashtag,
    } = useCreatorPlanTiktokHashtags();

    const {
        influencerPlanAudios: tiktokAudios,
        loading: tiktokAudiosLoading,
        error: tiktokAudiosError,
        initialized: tiktokAudiosInitialized,
    } = useCreatorPlanTiktokAudios();

    useAbortableEffect(
        (signal) => {
            if (!isTiktokHashtagsInitialized) {
                initTiktokHashtags({ signal });
            }
        },
        [initTiktokHashtags, isTiktokHashtagsInitialized]
    );

    const setCurrentTiktokAudio = (audioId: number | undefined) => setUrlState({ audioId });

    const handleDeleteHashtag = async () => {
        if (typeof hashtagToDelete?.id !== 'number') {
            return;
        }

        try {
            const response = await deleteHashtag(hashtagToDelete.id);
            if (response.status === 404 || response.status === 403) {
                showNotification(response.data.detail, 'error');
                return;
            }

            setHashtagToDelete(undefined);
            showNotification('Hashtag deleted', 'info');
        } catch {
            showNotification('Could not delete hashtag', 'error');
        }
    };

    return (
        <div className={styles.badgeContainer}>
            <Hashtags
                disabled={!canEdit && !tiktokHashtags.length}
                loading={!isTiktokHashtagsInitialized || isTiktokHashtagsLoading}
                hashtags={tiktokHashtags}
                readonly={!canEdit}
                onAddHashtag={() => setAddHashtagModalOpen(true)}
                onHashtagDelete={(hashtag) => setHashtagToDelete(hashtag)}
            />

            {influencerPlan && (
                <AddHashtagModal
                    influencerPlanId={influencerPlan.id}
                    isModalOpen={addHashtagModalOpen}
                    closeModal={() => setAddHashtagModalOpen(false)}
                    createHashtag={createHashtag}
                />
            )}

            <Modal
                closeOnOverlayClick
                className={styles.textAlignCenter}
                isOpen={!!hashtagToDelete}
                onClose={() => setHashtagToDelete(undefined)}
            >
                <ModalTitle>Delete #{hashtagToDelete?.hashtag}?</ModalTitle>
                <ModalFooter align="center">
                    <Button onClick={() => setHashtagToDelete(undefined)}>Cancel</Button>
                    <Button type="filled" color="black" onClick={handleDeleteHashtag}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>

            <Audios
                disabled={!tiktokAudios.length}
                loading={tiktokAudiosLoading || (!tiktokAudiosError && !tiktokAudiosInitialized)}
                audios={tiktokAudios}
                onAudioClick={(audio) => setCurrentTiktokAudio(Number(audio.audio_id))}
            />
        </div>
    );
};
