import { OrderingValues } from '@round/api';
import { ScrollView, TableProps, getTableMetaHelper } from '@round/ui-kit';
import styles from './TopPostsTable.module.css';
import Table from 'ui-new/whitelabel/Table/Table';
import { ReactComponent as CrossCircle } from 'assets/whitelabel/CrossCircle.svg';
import PaginationToolbar, { PaginationProps } from 'ui-new/whitelabel/Table/PaginationToolbar/PaginationToolbar';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import { TiktokTopPostsTableRow } from '../Tiktok/TiktokTopPosts';
import { InstagramTopPostsTableRow } from '../Instagram/InstagramTopPosts';

type TopPostsTableRow = TiktokTopPostsTableRow | InstagramTopPostsTableRow;

export type Meta = {
    isLoading?: boolean;
};

export const getTableMeta = getTableMetaHelper<Meta>();

type Props<TRow extends TopPostsTableRow, TOrdering extends string> = Pick<TableProps<TRow>, 'data' | 'columns'> &
    PaginationProps & {
        hasError: boolean;
        ordering?: OrderingValues<TOrdering>;
        onOrderingChange?: (ordering: OrderingValues<TOrdering>[]) => void;
        meta: Meta;
    };

const TopPostsTable = <TRow extends TopPostsTableRow, TOrdering extends string>({
    data,
    columns,
    meta,
    hasError,
    page,
    count,
    setPage,
    pageSize,
    setPageSize,
    ordering,
    onOrderingChange,
}: Props<TRow, TOrdering>) => {
    const initialLoading = !data.length && meta.isLoading;
    const emptyRows = new Array(6).fill({}) as TRow[];
    const rows = initialLoading ? emptyRows : data;

    return (
        <>
            <ScrollView className={styles.scrollWrapper}>
                <Table
                    manualSorting
                    enableSorting
                    enableSortingRemoval
                    sortDescFirst
                    sorting={ordering ? mapOrderingToTableSorting([ordering]) : []}
                    onSortingChange={
                        onOrderingChange
                            ? (sortingState) => onOrderingChange(mapTableSortingToOrdering(sortingState))
                            : undefined
                    }
                    className={styles.table}
                    data={rows}
                    columns={columns}
                    meta={meta}
                    noDataLabel={
                        <div className={styles.noDataLabel}>
                            {hasError ? (
                                <div className={styles.error}>
                                    <CrossCircle />
                                    <p>Failed to load posts</p>
                                </div>
                            ) : (
                                <p>No posts found</p>
                            )}
                        </div>
                    }
                />
            </ScrollView>
            <PaginationToolbar
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                count={count}
                isLoading={meta.isLoading}
            />
        </>
    );
};

export default TopPostsTable;
