import { createContext, ReactNode, useEffect, useRef } from 'react';
import { makeServer, AppServer } from '@round/api';
import { useFeatureFlags } from '../featureFlags/FeatureFlagsContext';

const MockContext = createContext<AppServer | null>(null);

type Props = {
    children?: ReactNode;
};

export const MockProvider = ({ children }: Props) => {
    const mockRef = useRef<AppServer | null>(null);
    const isMockEnabled = useFeatureFlags().isEnabled('mock_enabled');

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            return;
        }

        if (isMockEnabled && !mockRef.current) {
            mockRef.current = makeServer({ environment: 'development' });
        }

        if (!isMockEnabled && mockRef.current) {
            mockRef.current.shutdown();
            mockRef.current = null;
        }
    }, [isMockEnabled]);

    if (process.env.NODE_ENV === 'production') {
        return <>{children}</>;
    }

    return <MockContext.Provider value={mockRef.current}>{children}</MockContext.Provider>;
};
