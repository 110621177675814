import useNonNullContext from 'Hooks/useNonNullContext';
import { useCallback } from 'react';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { getInstagramUserStatsCache } from '@round/api';
import { InstagramUserDataContext } from '../InstagramUserDataContext/InstagramUserDataContext';

export function useUserStatsCacheData(userId: number | undefined | null) {
    const { userStatsCache, dispatch } = useNonNullContext(InstagramUserDataContext);
    const isInitialized = userStatsCache.status === 'success' || userStatsCache.status === 'error';

    const fetchUserStatsCacheData = useCallback(
        async (userId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadInstagramUserStatsCacheData' });
                const response = await getInstagramUserStatsCache({ user_id: userId }, requestInit);
                if (response.status === 403) {
                    dispatch({
                        type: 'errorLoadingInstagramUserStatsCacheData',
                        payload: 'Something unexpected happened',
                    });
                    return;
                }
                if (response.data.results.length === 0) {
                    dispatch({ type: 'errorLoadingInstagramUserStatsCacheData', payload: 'Data not found' });
                    return;
                }

                dispatch({ type: 'instagramUserStatsCacheDataLoaded', payload: response.data.results[0] });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'errorLoadingInstagramUserStatsCacheData',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [dispatch]
    );

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && userId) {
                fetchUserStatsCacheData(userId, { signal });
            }
        },
        [userId, isInitialized, fetchUserStatsCacheData]
    );

    return {
        instagramUserStatsCache: userStatsCache,
    };
}
