import { creatorbase, getTiktokAudios } from '@round/api';
import { useCallback } from 'react';
import { findOrCreateTiktokAudio } from 'Modules/TikTok/TikTok.services';
import useNonNullContext from 'Hooks/useNonNullContext';
import { SongFormAudiosContext } from '../SongFormAudiosContext';

export function useSongFormTiktokAudios() {
    const { tiktokState: state, setTiktokState: setState } = useNonNullContext(SongFormAudiosContext);

    const fetchAudios = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                setState({
                    status: 'success',
                    data: [],
                    error: null,
                });
                return;
            }

            try {
                const response = await getTiktokAudios({ id: ids.toString(), page_size: ids.length }, requestInit);

                if (response.status === 200) {
                    let audioData = response.data.results;

                    const creatorbaseAudioResponse = await creatorbase
                        .getTiktokAudios({
                            id: ids.toString(),
                            page_size: ids.length,
                        })
                        .catch(() => {});

                    if (creatorbaseAudioResponse?.status === 200) {
                        const creatorbaseAudioMap = new Map(
                            creatorbaseAudioResponse.data.results.map((ca) => [ca.id, ca])
                        );

                        audioData = audioData.map((audio) => {
                            const creatorbaseAudio = creatorbaseAudioMap.get(audio.id);
                            return creatorbaseAudio ? { ...audio, video_count: creatorbaseAudio.video_count } : audio;
                        });
                    }

                    setState({
                        status: 'success',
                        data: audioData,
                        error: null,
                    });
                    return;
                }

                setState({
                    status: 'error',
                    data: [],
                    error: "Couldn't fetch audios",
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    return;
                }

                setState({
                    status: 'error',
                    data: [],
                    error: error instanceof Error ? error.message : "Couldn't fetch audios",
                });
            }
        },
        [setState]
    );

    const getOrCreateAudio = useCallback(
        async (url: string) => {
            const result = await findOrCreateTiktokAudio(url);
            if (!result.success) {
                throw new Error(result.error);
            }

            setState((prevState) => ({
                ...prevState,
                data: [...(prevState.data?.filter((a) => a.id !== result.data.id) || []), result.data],
            }));

            return result.data;
        },
        [setState]
    );

    return {
        audios: state.data,
        status: state.status,
        fetchAudios,
        addAudio: getOrCreateAudio,
        filterAudiosByIds: (ids: number[]) => {
            setState((prev) => ({
                ...prev,
                data: (prev.data ?? []).filter((audio) => ids.includes(audio.id)),
            }));
        },
    };
}
