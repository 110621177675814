import Badge from 'ui-new/whitelabel/Badge/Badge';
import { Platform, usePlatformFilters } from './usePlatformFilters';
import Toggle from 'ui-new/whitelabel/Toggle/Toggle';
import PlatformIcon from 'Modules/Plans/components/PlatformIcon';
import styles from './PlatformFilters.module.css';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';

const platforms: Platform[] = ['tiktok', 'instagram'];

const PlatformFilters = () => {
    const { activePlatforms, togglePlatform, availablePlatforms } = usePlatformFilters();

    return (
        <div className={styles.container}>
            {platforms.map((platform) => {
                const isActive = activePlatforms.includes(platform);
                const isAvailable = availablePlatforms.includes(platform);
                const toggle = () => togglePlatform(platform);

                const isLastActiveAndToggleable = activePlatforms.length === 1 && isActive;

                const canToggle = !isLastActiveAndToggleable && isAvailable;

                return (
                    <Tooltip.Root key={platform}>
                        {(ref, setRef) => (
                            <>
                                <Badge
                                    ref={setRef}
                                    className={styles.badge}
                                    onClickCapture={(e) => {
                                        e.stopPropagation();
                                        if (!canToggle) {
                                            return;
                                        }
                                        toggle();
                                    }}
                                    disabled={!canToggle}
                                >
                                    <PlatformIcon className={styles.icon} platform={platform} colored />
                                    <Toggle value={isActive} onChange={toggle} disabled={!canToggle} />
                                </Badge>
                                <Tooltip anchorElement={ref} disabled={isAvailable}>
                                    <Tooltip.Body>We don't have any audios for this platform yet</Tooltip.Body>
                                </Tooltip>
                            </>
                        )}
                    </Tooltip.Root>
                );
            })}
        </div>
    );
};

export default PlatformFilters;
