import { CellContext } from '@tanstack/react-table';
import { Meta, TopAudiosTableRow } from '../../TopAudiosTable';
import { getTableMetaHelper, Image, SkeletonizedValue } from '@round/ui-kit';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import styles from './AudioCell.module.css';

export type AudioCellData = {
    audioUrl: string;
    songTitle: string;
    thumbnailUrl: string;
};
type Context<T extends TopAudiosTableRow> = CellContext<T, AudioCellData>;

const getTableMeta = getTableMetaHelper<Meta>();

const AudioCell = <T extends TopAudiosTableRow>({ row, table, getValue }: Context<T>) => {
    const { isLoading } = getTableMeta(table);
    const { audioUrl, songTitle, thumbnailUrl } = getValue();

    return (
        <div className={styles.audioContainer}>
            <Image src={thumbnailUrl} alt={songTitle} className={styles.audioThumbnail} skeletonCircle={false} />
            <SkeletonizedValue width="10rem" isInitialized={!isLoading}>
                <TruncationPopover content={songTitle}>
                    {(ref) =>
                        audioUrl ? (
                            <a
                                ref={ref}
                                href={audioUrl}
                                rel={'noopener noreferrer'}
                                target={'_blank'}
                                className={styles.audioTitle}
                            >
                                {songTitle}
                            </a>
                        ) : (
                            <span ref={ref} className={styles.audioTitle}>
                                {songTitle}
                            </span>
                        )
                    }
                </TruncationPopover>
            </SkeletonizedValue>
        </div>
    );
};

export default AudioCell;
