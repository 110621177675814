import { creatorbase } from '@round/api';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import styles from './ProjectsTable.module.css';
import PlatformStatsBadge from 'Modules/Plans/Project/components/PlatformStatsBadge/PlatformStatsBadge';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import VirtualizedTable, { Props as VirtualizedTableProps } from 'ui-new/whitelabel/Table/VirtualizedTable';
import ProjectCell from '../../../components/cells/ProjectCell/ProjectCell';
import BrandCell from '../../../components/cells/BrandCell/BrandCell';
import UpdatedCell from '../../../components/cells/UpdatedCell';
import DurationCell from '../../../components/cells/DurationCell';
import UsersCell from '../../../components/cells/UsersCell/UsersCell';
import Dropdown from 'ui-new/whitelabel/Dropdown/Dropdown';
import { ReactComponent as TrashIcon } from 'assets/whitelabel/Trash.svg';
import { ProjectTableMeta } from '../../../components/types';
import SongGrowthChart from 'Modules/Plans/Songs/components/SongGrowthChart/SongGrowthChart';
import { DataState } from '@round/utils';

export type ProjectsTableRow = creatorbase.Project & {
    brand: creatorbase.Brand | null;
    team: creatorbase.Team | null;
    users: creatorbase.User[];
    songStatsTimeSeries: DataState<
        creatorbase.TimeSeriesResponse<'tiktok_daily_change' | 'instagram_daily_change'>[number]
    > | null;
};
type TableCellContext<TKey extends keyof ProjectsTableRow> = CellContext<ProjectsTableRow, ProjectsTableRow[TKey]>;

type Props = Pick<VirtualizedTableProps<ProjectsTableRow>, 'data' | 'onEndReached'> & {
    onSongChartClick: (songId: number) => void;
    isLoading?: boolean;
    hasError?: boolean;
    onDeleteProject: (project: ProjectsTableRow) => void;
    meta: ProjectTableMeta<ProjectsTableRow>;
};

type Meta = Pick<Props, 'onSongChartClick' | 'onDeleteProject'> & ProjectTableMeta<ProjectsTableRow>;

const getTableMeta = getTableMetaHelper<Meta>();

const allPlatforms = ['tiktok', 'instagram', 'youtube'] as const;

const ProjectsTable = ({
    data,
    isLoading,
    hasError,
    onSongChartClick,
    onEndReached,
    onDeleteProject,
    meta: propsMeta,
}: Props) => {
    const navigate = useNavigate();

    const columns: ColumnDef<ProjectsTableRow, any>[] = [
        {
            header: 'Artist',
            accessorKey: 'brand',
            meta: {
                className: styles.brandColumn,
            },
            cell: BrandCell,
        },
        {
            header: 'Project',
            accessorKey: 'name',
            meta: {
                className: styles.projectColumn,
            },
            cell: ProjectCell,
        },
        {
            header: 'Channels',
            accessorKey: 'platforms',
            cell: ({ row: { original }, getValue, table }: TableCellContext<'platforms'>) => {
                const { getIsRowLoading } = getTableMeta(table);

                if (getIsRowLoading(original)) {
                    return <Skeleton width="10rem" />;
                }

                const platforms = getValue() || [];

                return (
                    <div className={styles.channelsContainer}>
                        {allPlatforms.map((platform) => (
                            <PlatformStatsBadge
                                key={platform}
                                projectId={original.id}
                                platform={platform}
                                isActive={platforms.includes(platform)}
                            />
                        ))}
                    </div>
                );
            },
        },
        {
            header: 'Last updated',
            accessorKey: 'associations_updated_at',
            cell: UpdatedCell,
        },
        {
            header: 'Run dates',
            id: 'runDates',
            cell: DurationCell,
        },
        {
            header: 'Team',
            accessorKey: 'users',
            cell: UsersCell,
        },
        {
            header: 'Song',
            id: 'song',
            meta: {
                className: styles.songColumn,
            },
            accessorKey: 'songStatsTimeSeries',
            cell: ({ getValue, row: { original }, table }: TableCellContext<'songStatsTimeSeries'>) => {
                const { getIsRowLoading, onSongChartClick } = getTableMeta(table);
                if (getIsRowLoading(original)) {
                    return <Skeleton width="10rem" />;
                }

                if (original.song_id === null) {
                    return '-';
                }

                const state = getValue();

                return (
                    <SongGrowthChart
                        timeSeries={state?.data ?? null}
                        isLoading={state?.status === 'loading' || state?.status === 'idle'}
                        hasError={state?.status === 'error'}
                        onClick={(e) => {
                            e.stopPropagation();

                            if (!original.song_id) {
                                return;
                            }

                            onSongChartClick(original.song_id);
                        }}
                    />
                );
            },
        },
        {
            header: '',
            id: 'actions',
            meta: {
                className: styles.actions,
            },
            cell: ({ row: { original }, table }) => {
                const { getIsRowLoading, onDeleteProject } = getTableMeta(table);

                if (getIsRowLoading(original)) {
                    return <Skeleton width="10rem" />;
                }

                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown>
                            {({ setIsOpen }) => (
                                <>
                                    <Dropdown.Target />
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon={() => <TrashIcon className={styles.deleteAction} />}
                                            onClick={() => {
                                                onDeleteProject(original);
                                                setIsOpen(false);
                                            }}
                                            className={styles.deleteAction}
                                        >
                                            Delete project
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </>
                            )}
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const meta: Meta = {
        onSongChartClick,
        onDeleteProject,
        ...propsMeta,
    };

    const emptyRows = new Array(10).fill({}) as ProjectsTableRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    return (
        <VirtualizedTable
            containerClassName={styles.tableContainer}
            data={rows}
            columns={columns}
            meta={meta}
            onRowClick={(row) => navigate(`${row.original.id}/`)}
            className={styles.table}
            noDataLabel={
                <div aria-label="no projects" className={styles.noDataContainer}>
                    {hasError ? "Couldn't fetch projects" : 'No projects found'}
                </div>
            }
            virtualizerOptions={{
                count: rows.length,
                estimateSize: () => 50,
                getItemKey: (index) => rows[index]?.id || index,
                overscan: 5,
            }}
            onEndReached={onEndReached}
        />
    );
};

export default ProjectsTable;
