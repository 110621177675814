import useAbortableEffect from 'Hooks/useAbortableEffect';
import useCampaigns from '../useCampaigns';
import useUrlState from 'Hooks/useUrlState';
import { ExpandedState } from '@tanstack/react-table';
import { useMemo } from 'react';
import PublicReportInstagramPostsTable from '../../Posts/InstagramPosts/PublicReportInstagramPostsTable';
import makeInstagramAudiosByIdDataHook from 'Modules/Instagram/hooks/dataHooks/useInstagramAudiosByIdDataHook';
import { PublicReportContext } from '../../PublicReportContext';
import { makeInstagramTimeSeriesDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramTimeSeries';
import { isNumber } from 'utility/utility';
import moment from 'moment/moment';
import InstagramPublicReportCampaignTable, {
    InstagramPublicReportCampaignTableRow,
} from '../CampaignsTable/InstagramPublicReportCampaignTable';

type Props = {
    publicReportId: string | undefined;
};

type UrlState = Partial<{
    expanded: string;
}>;

const useInstagramAudiosById = makeInstagramAudiosByIdDataHook(
    PublicReportContext,
    ([values]) => values.instagram.audios
);
const useInstagramTimeSeries = makeInstagramTimeSeriesDataHook(
    PublicReportContext,
    ([values]) => values.instagram.timeSeries
);

const ReportInstagramCampaigns = ({ publicReportId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const {
        campaignsData,
        campaignStatsData,
        projectsData,
        brandsData,
        teamsData,
        fetchData,
        isProjectLoading,
        isTeamLoading,
        isBrandLoading,
        isCampaignStatsLoading,
    } = useCampaigns('instagram');
    const areCampaignsInitialized = campaignsData.isInitialized || campaignsData.status === 'error';
    const areCampaignsLoading = campaignsData.status !== 'error' && campaignsData.status !== 'success';

    const instagramAudioIds = useMemo(
        () => campaignsData.data?.results?.map((c) => c.instagram_details?.audio_id).filter(isNumber) ?? [],
        [campaignsData.data?.results]
    );

    const { data: instagramAudios } = useInstagramAudiosById({ ids: instagramAudioIds, publicReportId });
    const { state: instagramTimeSeries } = useInstagramTimeSeries({
        ids: instagramAudioIds,
        type: 'audio',
        fields: ['posts_daily_change'],
        start_date: moment().subtract(2, 'weeks').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        publicReportId,
    });

    useAbortableEffect(
        (signal) => {
            if (areCampaignsInitialized || !publicReportId) {
                return;
            }

            fetchData(
                {
                    public_report_id: publicReportId,
                    platform: 'instagram',
                },
                { signal }
            ).catch(() => {});
        },
        [areCampaignsInitialized, publicReportId, fetchData]
    );

    const expandedState = useMemo(() => {
        const expanded = urlState?.expanded;

        if (!expanded) {
            return {};
        }

        return expanded.split(',').reduce((acc, campaignId) => {
            acc[campaignId] = true;
            return acc;
        }, {} as Record<string, boolean>);
    }, [urlState]);

    const setExpandedState = (expanded: ExpandedState) => {
        const expandedKeys = Object.keys(expanded).filter((key) => expanded[key as keyof ExpandedState]);
        setUrlState({ expanded: expandedKeys.join(',') });
    };

    const rows = useMemo(
        () =>
            campaignsData.data?.results.map<InstagramPublicReportCampaignTableRow>((c) => {
                const stats = campaignStatsData[c.id]?.data ?? null;
                const project = projectsData[c.project_id]?.data ?? null;
                const brand = project ? brandsData[project.brand_id]?.data ?? null : null;
                const team = project ? teamsData[project.team_id]?.data ?? null : null;
                const audioId = c.instagram_details?.audio_id;
                const audio =
                    audioId && instagramAudios[audioId]?.status === 'success' ? instagramAudios[audioId]?.data : null;
                const audioTimeSeries =
                    audioId && instagramTimeSeries.data[audioId] ? instagramTimeSeries.data[audioId]?.data : null;

                return { ...c, project, brand, team, stats, audio, audioTimeSeries };
            }) ?? [],
        [
            brandsData,
            campaignStatsData,
            campaignsData.data?.results,
            instagramAudios,
            instagramTimeSeries.data,
            projectsData,
            teamsData,
        ]
    );

    return (
        <InstagramPublicReportCampaignTable
            data={rows}
            isLoading={areCampaignsLoading}
            hasError={campaignsData.status === 'error'}
            expandedState={expandedState}
            onExpandedChange={setExpandedState}
            meta={{
                isLoading: areCampaignsLoading,
                getIsProjectLoading: (row) => isProjectLoading(row.id),
                getIsTeamLoading: (row) => isTeamLoading(row.id),
                getIsBrandLoading: (row) => isBrandLoading(row.id),
                getIsCampaignStatsLoading: (row) => isCampaignStatsLoading(row.id),
                getIsTimeSeriesLoading: (row) => {
                    const audioId = row.instagram_details?.audio_id;
                    const timeSeriesData = audioId ? instagramTimeSeries.data[audioId] : null;
                    return !!timeSeriesData && timeSeriesData.status === 'loading';
                },
                getTimeSeriesHasError: (row) => {
                    const audioId = row.instagram_details?.audio_id;
                    const timeSeriesData = audioId ? instagramTimeSeries.data[audioId] : null;
                    return !!timeSeriesData && timeSeriesData.status === 'error';
                },
            }}
            renderSubComponent={(row) => (
                <PublicReportInstagramPostsTable campaign={row.original} publicReportId={publicReportId} />
            )}
        />
    );
};

export default ReportInstagramCampaigns;
