import styles from './YoutubePostGroupBar.module.css';
import {
    PatchYoutubeInfluencerPostGroupBody,
    YoutubeInfluencerPostGroup,
    YoutubeInfluencerPostGroupStats,
} from '@round/api';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'assets/ArrowIcon.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/InfoCircle.svg';
import { ReactComponent as EditIcon } from 'Modules/Advertising/assets/EditIcon.svg';
import { ReactComponent as TrashIcon } from 'assets/TrashIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/CopyIcon.svg';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { getPostCountText } from 'Modules/Advertising/InfluencerPlan/InfluencerPlan.helpers';
import { displayOptionalNumericTableValue, numberWithCommas, roundTo2Dp, showNotification } from 'helpers';
import { useContext, useState } from 'react';
import { patchYoutubeInfluencerPostGroup } from '@round/api';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import { getPostGroupPatchKeyErrorMessage } from '../../helpers';
import CurrencyInput from 'react-currency-input-field';
import { InfluencerPlanContext } from 'Modules/Advertising/InfluencerPlan/contexts/InfluencerPlanContext';
import Tooltip from 'ui/DataDisplay/Tooltip/Tooltip';
import Toggle from 'ui/DataEntry/Toggle/Toggle';
import ProgressBar from 'ui/ProgressBar/ProgressBar';
import { invoiceRequestStatusOptions } from 'Modules/Finance/InvoiceRequests/helpers';
import InvoiceRequestStatusBadge from 'Modules/Finance/InvoiceRequests/InvoiceRequestStatusBadge/InvoiceRequestStatusBadge';
import Button from 'ui/Buttons/Button/Button';
import { Popover } from '@round/ui-kit';
import Card from 'ui/Card/Card';
import { useLinkedProject } from '../../../Creators/components/CreatorbaseProjectSelect/hooks/useLinkedProject';
import ShareWithCreatorbaseToggle from '../../../InfluencerPlanGroupBar/ShareWithCreatorbaseToggle';

type Props = {
    group: YoutubeInfluencerPostGroup;
    stats: YoutubeInfluencerPostGroupStats | null;
    isExpanded: boolean;
    onToggleExpand: (groupId: number) => void;
    //optional until we have the feature to create a post
    onCreatePost?: (groupId: number) => void;
    onEditBrief: (group: YoutubeInfluencerPostGroup) => void;
    updateGroup: typeof patchYoutubeInfluencerPostGroup;
    updateOrderingIndex: (groupId: number, currentIndex: number, targetIndex: number) => Promise<boolean | undefined>;
    onDeleteGroup: (group: YoutubeInfluencerPostGroup) => void;
    onCopyReportTable: (group: YoutubeInfluencerPostGroup) => void;
    hasLivePosts: boolean;
};

const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long' });
};

const blurOnEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
        e.currentTarget.blur();
    }
};

const YoutubePostGroupBar = ({
    group,
    stats,
    isExpanded,
    onToggleExpand,
    onCreatePost,
    onEditBrief,
    updateGroup,
    updateOrderingIndex,
    onDeleteGroup,
    hasLivePosts,
    onCopyReportTable,
}: Props) => {
    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);
    const { influencerPlan } = useContext(InfluencerPlanContext);

    const { linkedProject } = useLinkedProject(influencerPlan?.application_manager_project_id);

    const [groupName, setGroupName] = useState(group.name);
    const [budget, setBudget] = useState(group.budget);
    const [isHidden, setIsHidden] = useState(group.is_hidden);
    const [isHiddenWarningRef, setIsHiddenWarningRef] = useState<SVGSVGElement | null>(null);

    const [copyToClipboardButtonRef, setCopyToClipboardButtonRef] = useState<HTMLButtonElement | null>(null);

    const handleUpdateKey = async <T extends keyof PatchYoutubeInfluencerPostGroupBody>(
        key: T,
        value: PatchYoutubeInfluencerPostGroupBody[T]
    ) => {
        if (!canEdit) {
            return;
        }

        const response = await updateGroup(group.id, { [key]: value });

        if (response.status === 200) {
            showNotification('Group updated', 'info');
            return;
        }

        const errorMessage = getPostGroupPatchKeyErrorMessage(response, key);
        throw new Error(errorMessage);
    };

    const handleUpdateOrderingIndex = async (targetIndex: number) => {
        try {
            const success = await updateOrderingIndex(group.id, group.ordering_index, targetIndex);

            if (success) {
                showNotification('Group order updated', 'info');
                return;
            }

            if (success === false) {
                showNotification('Failed to update group order', 'error');
                return;
            }
        } catch (e) {
            showNotification('Failed to update group order', 'error');
        }
    };

    const startDate = stats?.first_post_dt ? formatDate(new Date(stats.first_post_dt)) : '-';
    const endDate = stats?.last_post_dt ? formatDate(new Date(stats.last_post_dt)) : '-';
    const showRunDates = stats?.first_post_dt && stats?.last_post_dt;

    const postCountText = getPostCountText(stats?.post_count ?? 0, stats?.live_post_count ?? 0);

    const engagementRate = stats?.engagement_rate ? `${roundTo2Dp(stats.engagement_rate * 100)}%` : '-';

    const amountSpentPercent = stats?.amount_spent ? stats.amount_spent * 100 : 0;

    const cpmValue = stats?.total_cpm ? stats.total_cpm.toFixed(2) : '-';

    const currencySymbol = influencerPlan?.currency.symbol;

    const showIsHiddenWarning = hasLivePosts && isHidden;

    const invoiceRequestStatus = invoiceRequestStatusOptions.find((o) => o.value === group.invoice_request_status);

    return (
        <article className={styles.container}>
            <button
                data-test-id={`expand-group-${group.id}-button`}
                className={cn(styles.groupButton, styles.expandButton, {
                    [styles.expanded]: isExpanded,
                })}
                onClick={() => onToggleExpand(group.id)}
            >
                <ArrowIcon />
            </button>

            <div className={styles.infoColumns}>
                <div className={cn(styles.infoColumn, styles.groupName)}>
                    <input
                        className={cn(styles.heading, styles.input)}
                        type="text"
                        name="group-name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        onBlur={() => {
                            if (groupName === group.name) {
                                return;
                            }
                            handleUpdateKey('name', groupName).catch((e) => {
                                showNotification(e.message, 'error');
                                setGroupName(group.name);
                            });
                        }}
                        onKeyDown={blurOnEnterKey}
                        disabled={!canEdit}
                    />

                    <span className={styles.value}>{postCountText}</span>
                </div>

                <div className={cn(styles.infoColumn, styles.amountSpent)}>
                    <div className={cn(styles.value, styles.amountSpentContainer)}>
                        <CurrencyInput
                            className={styles.input}
                            prefix={currencySymbol}
                            value={budget}
                            onValueChange={(value) => setBudget(value ?? '')}
                            name="group-budget"
                            onBlur={() => {
                                if (budget === group.budget) {
                                    return;
                                }

                                handleUpdateKey('budget', budget).catch((e) => {
                                    showNotification(e.message, 'error');
                                    setBudget(group.budget);
                                });
                            }}
                            onKeyDown={blurOnEnterKey}
                            groupSeparator=","
                            decimalSeparator="."
                            disabled={!canEdit}
                        />
                        <span>{numberWithCommas(roundTo2Dp(amountSpentPercent))}%</span>
                    </div>
                    <ProgressBar
                        trackStyles={{
                            width: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            height: '0.33rem',
                            marginTop: '0.33rem',
                        }}
                        barStyles={{
                            backgroundColor: 'white',
                        }}
                        progress={amountSpentPercent}
                    />
                </div>

                <div className={cn(styles.infoColumn, styles.cpm)}>
                    <span className={styles.heading}>{canEdit ? 'Client CPM' : 'CPM'}</span>
                    <span className={styles.value}>
                        {currencySymbol}
                        {displayOptionalNumericTableValue(cpmValue)}
                    </span>
                </div>

                <div className={styles.infoColumn}>
                    <span className={styles.heading}>Engagement Rate</span>
                    <span className={styles.value}>{engagementRate}</span>
                </div>

                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <div className={cn(styles.infoColumn, styles.invoiceRequestStatus)}>
                        <span className={styles.heading}>Invoice Request Status</span>
                        <span className={styles.value}>
                            {invoiceRequestStatus ? (
                                <InvoiceRequestStatusBadge status={invoiceRequestStatus.value} />
                            ) : (
                                '-'
                            )}
                        </span>
                    </div>
                </ProtectedByUserGroups>

                <div className={cn(styles.infoColumn, styles.duration)}>
                    <span className={styles.heading}>Duration</span>
                    <span className={styles.value}>{showRunDates ? `${startDate} - ${endDate}` : '-'}</span>
                </div>

                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <div className={cn(styles.infoColumn, styles.liveToggleContainer)}>
                        <div className={styles.row}>
                            <Toggle
                                value={!isHidden}
                                onChange={(shouldReveal) => {
                                    setIsHidden(!shouldReveal);
                                    handleUpdateKey('is_hidden', !shouldReveal).catch(() => {
                                        showNotification('Failed to update group visibility', 'error');
                                        setIsHidden(group.is_hidden);
                                    });
                                }}
                                className={cn(styles.liveToggle, {
                                    [styles.live]: !isHidden,
                                    [styles.warning]: showIsHiddenWarning,
                                })}
                            />
                            {showIsHiddenWarning && (
                                <>
                                    <Tooltip
                                        anchorElement={isHiddenWarningRef}
                                        value={
                                            "This group is set to 'In proposal', but contains posts which are live or have been approved."
                                        }
                                    />
                                    <InfoCircleIcon ref={setIsHiddenWarningRef} className={styles.warningIcon} />
                                </>
                            )}
                        </div>
                        <span className={styles.value}>{group.is_hidden ? 'In proposal' : 'Live'}</span>
                    </div>
                </ProtectedByUserGroups>

                {!!linkedProject && (
                    <ProtectedByUserGroups groups={['creatorbase_round_planner']}>
                        <ShareWithCreatorbaseToggle
                            isShared={group.is_shared_with_creatorbase}
                            onChange={(isShared) =>
                                updateGroup(group.id, { is_shared_with_creatorbase: isShared })
                                    .then((res) => {
                                        if (res?.status === 200) {
                                            showNotification('Group updated', 'info');
                                            return res;
                                        }

                                        const message =
                                            res.status === 400 && 'is_shared_with_creatorbase' in res.data
                                                ? String(res.data.is_shared_with_creatorbase)
                                                : 'Failed to update group';
                                        showNotification(message, 'error');
                                    })
                                    .catch((e) => {
                                        showNotification(e.message, 'error');
                                    })
                            }
                        />
                    </ProtectedByUserGroups>
                )}
            </div>

            <div className={styles.actionButtons}>
                <ProtectedByUserGroups groups={['influencer_editor']}>
                    <button className={styles.groupButton} onClick={() => onEditBrief(group)}>
                        <EditIcon />
                        Edit brief
                    </button>

                    <button className={styles.groupButton} onClick={() => onCreatePost?.(group.id)}>
                        + New post
                    </button>

                    <button className={styles.groupButton} onClick={() => onDeleteGroup(group)}>
                        <TrashIcon />
                    </button>

                    <button ref={setCopyToClipboardButtonRef} className={styles.groupButton}>
                        <CopyIcon className={styles.icon} />
                    </button>

                    <Popover anchorElement={copyToClipboardButtonRef} showOn="click">
                        <Card className={styles.copyToTableDropdown}>
                            <Button onClick={() => onCopyReportTable(group)}>Report</Button>
                        </Card>
                    </Popover>

                    <div className={cn(styles.groupButton, styles.reorderingButtons)}>
                        <button
                            className={styles.reorderingButton}
                            onClick={() => handleUpdateOrderingIndex(group.ordering_index - 1)}
                        >
                            <ArrowIcon height={8} />
                        </button>
                        <button
                            className={cn(styles.reorderingButton, styles.increaseOrder)}
                            onClick={() => handleUpdateOrderingIndex(group.ordering_index + 1)}
                        >
                            <ArrowIcon height={8} />
                        </button>
                    </div>
                </ProtectedByUserGroups>
            </div>
        </article>
    );
};

export default YoutubePostGroupBar;
