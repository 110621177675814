import React from 'react';
import { NavBar } from 'SharedComponents/NavBar/NavBar';
import Payments from 'Modules/Advertising/Microwave/Payments/Payments';
import PageLayout from 'ui-new/Layout/PageLayout/PageLayout';

const MicrowavePayments = () => (
    <>
        <NavBar />
        <PageLayout>
            <Payments />
        </PageLayout>
    </>
);

export default MicrowavePayments;
