import styles from './PostsNoDataLabel.module.css';
import Button from 'ui-new/whitelabel/Button/Button';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';

type Props = {
    hasError: boolean;
    isReadOnly?: boolean;
    onAddPost?: () => void;
};

const PostsNoDataLabel = ({ onAddPost, hasError, isReadOnly = false }: Props) => {
    return (
        <div className={styles.container} aria-label="no posts">
            {hasError ? (
                'Could not load posts'
            ) : !isReadOnly && onAddPost ? (
                <Button appearance="primary" onClick={onAddPost} iconLeft={<PlusIcon className={styles.plusIcon} />}>
                    Post
                </Button>
            ) : (
                'No posts yet'
            )}
        </div>
    );
};

export default PostsNoDataLabel;
