import { InstagramPost, InstagramUser, InstagramUserImage, creatorbase } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper, SkeletonTableCell, ButtonShell } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import styles from './PostsTable.module.css';
import CostCell from 'Modules/Plans/components/CostCell/CostCell';
import { UsePostsReturn } from 'Modules/Plans/Project/contexts/PostsContext';
import { CampaignTableRow } from '../ProjectCampaignsTable/ProjectCampaignsTable';
import TotalFooterCell from '../../../components/TotalFooterCell';
import TotalCostFooterCell from 'Modules/Plans/components/TotalCostFooterCell/TotalCostFooterCell';
import ActionsCell from './components/ActionsCell/ActionsCell';
import NotesCell from 'Modules/Plans/components/NotesCell/NotesCell';
import StoryViewsCell from 'Modules/Plans/components/StoryViewsCell/StoryViewsCell';
import { getInstagramEngagementRatePercentageString, getInstagramTotalEngagement } from 'Modules/Plans/Posts/helpers';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import { useCampaignStats } from 'Modules/Plans/Project/contexts/CampaignStatsContext/CampaignsStatsContext';
import { useInstagramAccountsData } from 'Modules/Plans/Project/contexts/AccountDataContext/InstagramAccountsContext/InstagramAccountsContext';
import UpdateInfluencerPopout from './components/UpdateInfluencerPopout/UpdateInfluencerPopout';
import { InstagramUserStats } from 'Modules/Instagram/Instagram.types';
import PostsTableEngagementsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementsFooterCell';
import PostStatsValueCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsStatsValueCell';
import PostsTableEngagementRateFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableEngagementRateFooterCell';
import PostStatsViewsFooterCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostStatsViewsFooterCell';
import PostsTableUploadedDateCell from 'Modules/Plans/Posts/components/PostsTable/cells/PostsTableUploadedDateCell';
import PostsTableStatusCell, {
    StatusData,
} from 'Modules/Plans/Posts/components/PostsTable/cells/StatusCell/PostsTableStatusCell';
import { ReactComponent as DiagramIcon } from '../../../../../../assets/Diagram.svg';
import InstagramUserModal from '../InstagramUserModal/InstagramUserModal';
import { InstagramUserDataProvider } from '../InstagramUserModal/InstagramUserDataContext/InstagramUserDataContext';
import UserBadge, { UserBadgeProps } from 'Modules/Plans/User/UserBadge/UserBadge';

export type InstagramPostsTableRow = creatorbase.InstagramPost & {
    stats?: InstagramPost | null;
    userStats?: InstagramUserStats | null;
    user?: InstagramUser | null;
    image?: InstagramUserImage | null;
};

type Props = Pick<TableProps<InstagramPostsTableRow>, 'data' | 'noDataLabel'> & {
    campaign: CampaignTableRow;
    isLoading?: boolean;
    hasError: boolean;
    statsLoading: string[];
    onCreatePost: (campaign: CampaignTableRow) => void;
    onDeletePost: (post: InstagramPostsTableRow) => void;
    updatePost: UsePostsReturn['updatePost'];
};

type TableCellContext<TKey extends keyof InstagramPostsTableRow> = CellContext<
    InstagramPostsTableRow,
    InstagramPostsTableRow[TKey]
>;

type Meta = BasePostTableMeta<InstagramPostsTableRow> & {
    campaign: CampaignTableRow;
    onDeletePost: (post: InstagramPostsTableRow) => void;
};
const getTableMeta = getTableMetaHelper<Meta>();

const InstagramPostsTable = ({
    data,
    campaign,
    isLoading,
    hasError,
    statsLoading,
    onCreatePost,
    onDeletePost,
    updatePost,
}: Props) => {
    const { getIsLoading: getAreCampaignStatsLoading } = useCampaignStats();
    const { getIsLoading: getIsAccountDataLoading } = useInstagramAccountsData();
    const [accountModalUserId, setAccountModalUserId] = useState<InstagramUser['id'] | null>(null);

    const columns = useMemo<ColumnDef<InstagramPostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                id: 'account',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, getIsAccountDataLoading, updatePost, managedBy } = getTableMeta(table);
                    const isManagedExternally = managedBy !== 'creatorbase';
                    const image =
                        original?.image?.avatar_thumb?.cached_url || original?.image?.avatar_thumb?.original_url || '';
                    const followerCount = original.userStats?.follower_count || null;
                    const accountHandle = original.user?.username ? `@${original.user?.username}` : '-';

                    const userBadgeProps: UserBadgeProps = {
                        isLoading: isLoading || getIsAccountDataLoading(original),
                        image: image,
                        title: accountHandle,
                        followerCount: followerCount,
                    };

                    return (
                        <div className={styles.accountContainer}>
                            <UserBadge {...userBadgeProps} />

                            <ButtonShell
                                className={styles.modalButton}
                                onClick={() => setAccountModalUserId(original.user?.id ?? null)}
                            >
                                <DiagramIcon />
                            </ButtonShell>

                            {!original.post_url && !isManagedExternally && (
                                <UpdateInfluencerPopout
                                    key={accountHandle}
                                    className={styles.editAccountButton}
                                    accountHandle={accountHandle ?? ''}
                                    updateAccountHandle={
                                        updatePost
                                            ? (accountHandle) =>
                                                  updatePost?.(original.id, {
                                                      influencer_account_identifier: accountHandle,
                                                  })
                                            : undefined
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                header: 'Status',
                id: 'status',
                accessorFn: (original): StatusData => ({
                    postUrl: original.post_url,
                    platform: original.platform,
                    adCode: original.instagram_details?.ad_code,
                }),
                cell: PostsTableStatusCell,
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'cost'>) => {
                    const { isLoading, updatePost, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <CostCell
                            cost={getValue()}
                            currency={campaign.currency ?? null}
                            updateCost={
                                updatePost ? (cost: number | null) => updatePost(original.id, { cost }) : undefined
                            }
                            isReadonly={!campaign.is_post_cost_editable}
                        />
                    );
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    return isLoading ? (
                        <Skeleton />
                    ) : (
                        <TotalCostFooterCell
                            costs={table.getRowModel().rows.map((r) => r.original.cost)}
                            currency={campaign.currency}
                        />
                    );
                },
            },
            {
                header: 'Uploaded date',
                id: 'uploadedDate',
                cell: PostsTableUploadedDateCell,
            },
            {
                header: 'Views',
                id: 'views',
                accessorFn: (row) => row.view_count,
                cell: PostStatsValueCell,
                footer: PostStatsViewsFooterCell,
            },
            {
                header: 'Story views',
                accessorKey: 'instagram_details',
                id: 'storyViews',
                cell: ({ row: { original }, table }: TableCellContext<'instagram_details'>) => {
                    const { isLoading, getAreStatsLoading, updatePost, managedBy } = getTableMeta(table);
                    const isManagedExternally = managedBy !== 'creatorbase';

                    if (isLoading || getAreStatsLoading?.(original)) {
                        return <Skeleton />;
                    }

                    return (
                        <StoryViewsCell
                            isReadonly={isManagedExternally || original.status === 'planned'}
                            storyViews={original.instagram_details?.story_view_count || null}
                            updateStoryViews={updatePost ? (data) => updatePost(original.id, data) : undefined}
                        />
                    );
                },
                footer: ({ table }) => {
                    const { isLoading, getAreStatsLoading } = getTableMeta(table);

                    return isLoading || !!table.getRowModel().rows.some((row) => getAreStatsLoading?.(row.original)) ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.instagram_details?.story_view_count)}
                        />
                    );
                },
            },
            {
                header: 'Total engagements',
                id: 'totalEngagements',
                accessorFn: (row) => getInstagramTotalEngagement(row.stats),
                cell: PostStatsValueCell,
                footer: PostsTableEngagementsFooterCell,
            },
            {
                header: 'Engagement rate',
                id: 'engagementRate',
                accessorFn: (row) => getInstagramEngagementRatePercentageString(row, row.stats ?? null),
                cell: SkeletonTableCell,
                footer: PostsTableEngagementRateFooterCell,
            },
            {
                header: 'Likes',
                accessorFn: (row) => row.stats?.like_count,
                id: 'likes',
                cell: PostStatsValueCell,
                footer: ({ table }) => {
                    const { isLoading, getAreStatsLoading } = getTableMeta(table);

                    return isLoading || !!table.getRowModel().rows.some((row) => getAreStatsLoading?.(row.original)) ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell values={table.getRowModel().rows.map((r) => r.original.stats?.like_count)} />
                    );
                },
            },
            {
                header: 'Comments',
                accessorFn: (row) => row.stats?.comment_count,
                id: 'comments',
                cell: PostStatsValueCell,
                footer: ({ table }) => {
                    const { isLoading, getAreStatsLoading } = getTableMeta(table);

                    return isLoading || !!table.getRowModel().rows.some((row) => getAreStatsLoading?.(row.original)) ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.stats?.comment_count)}
                        />
                    );
                },
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'notes'>) => {
                    const { isLoading, updatePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return (
                        <NotesCell
                            notes={getValue()}
                            updateNotes={updatePost ? (notes: string) => updatePost(original.id, { notes }) : undefined}
                        />
                    );
                },
            },
            {
                header: '',
                id: 'actions',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, onDeletePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <ActionsCell onDelete={() => onDeletePost(original)} />;
                },
            },
        ],
        []
    );

    // meta object is passed to every cell component. This allows us to reduce rerenders and prevents unnecessary cell unmounts.
    const meta: Meta = {
        isLoading: !!isLoading,
        getAreStatsLoading: (row) => statsLoading.includes(row.id),
        getIsAccountDataLoading: (row) =>
            row.instagram_details?.account_id ? getIsAccountDataLoading(row.instagram_details.account_id) : false,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
        onDeletePost,
        updatePost,
        onCreatePost: () => onCreatePost(campaign),
        campaign,
        campaignStats: campaign.stats,
        managedBy: campaign.managed_by,
    };

    return (
        <>
            <PostsTable
                className={styles.table}
                columns={columns}
                data={data}
                meta={meta}
                isLoading={isLoading}
                hasError={hasError}
                columnVisibility={{
                    actions: campaign.managed_by === 'creatorbase',
                }}
            />
            <InstagramUserDataProvider key={accountModalUserId}>
                <InstagramUserModal
                    isOpen={typeof accountModalUserId === 'number'}
                    onClose={() => {
                        setAccountModalUserId(null);
                    }}
                    userId={accountModalUserId}
                />
            </InstagramUserDataProvider>
        </>
    );
};

export default InstagramPostsTable;
