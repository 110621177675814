import { ChartDataset, TooltipModel } from 'chart.js';
import { Point } from './types';

/**
 * Get the exponent of a number. Exponent of 0 is -Infinity
 * @param n
 */
export const getExponent = (n: number) => Math.floor(Math.log10(Math.abs(n)));

export function calculateFractionalDigits(values: number[]) {
    if (!values.length) {
        return 0;
    }
    if (new Set(values).size < 2) {
        return 1;
    }

    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);
    const minMaxDiff = maxValue - minValue;

    if (minMaxDiff < 0.0001 * Math.abs(maxValue)) {
        return 2;
    }

    const maxExponent = getExponent(maxValue);
    const diffExponent = getExponent(minMaxDiff);

    let fractionDigits = Math.max(0, maxExponent - diffExponent);

    if (maxExponent % 3 === 0 && fractionDigits === 0) {
        fractionDigits = 1;
    }

    return Math.min(fractionDigits, 5);
}

export const formatYAxisLabels = (n: number, fractionDigits: number = 0) => {
    if (isNaN(n)) {
        console.log('Error: number is NaN');
        return '';
    }

    const exponent = getExponent(n);
    const format = (n: number) => {
        return parseFloat((n / Math.pow(10, exponent - (exponent % 3))).toFixed(fractionDigits));
    };

    const sliceRules = [
        {
            threshold: 3,
            exponentSign: '',
            format: (n: number) => n,
        },
        {
            threshold: 6,
            exponentSign: 'K',
            format,
        },
        {
            threshold: 9,
            exponentSign: 'M',
            format,
        },
        {
            threshold: Infinity,
            exponentSign: 'B',
            format,
        },
    ];

    const rule = sliceRules.find((r) => r.threshold > exponent);
    if (!rule) {
        return n.toString();
    }
    if (`${rule.format(n)}${rule.exponentSign}`.length === n.toString().length + 1) {
        return n.toString();
    }

    return `${rule.format(n)}${rule.exponentSign}`;
};

export type TooltipData<T = Point> = {
    point: T;
    dataset: ChartDataset<'line'>;
};
export const getPointDataFromTooltip = <T = Point>(tooltip: TooltipModel<'line'>): TooltipData<T> => {
    const data = tooltip.dataPoints?.[0];

    const { dataset, raw } = data;

    return {
        point: raw as T,
        dataset: dataset,
    };
};
