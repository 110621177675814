import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import styles from './Tooltip.module.css';
import { useRectObserver } from 'Hooks/useRectObserver';
import cn from 'classnames';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { useTooltipMouseEvents } from './useTooltipMouseEvents';
import { createPortal } from 'react-dom';

type Props = {
    anchorElement: HTMLElement | SVGSVGElement | null;
    placement?: Placement;
    disabled?: boolean;
    className?: string;
    children?: React.ReactNode;
};

const Tooltip = React.forwardRef<HTMLDivElement, Props>(
    ({ anchorElement, placement = 'top', disabled, children, className }, ref) => {
        const [popoverContainer, setPopoverContainer] = useState<HTMLDivElement | null>(null);

        const { show } = useTooltipMouseEvents(anchorElement, disabled);

        const [contentRef, setContentRef] = useState<HTMLElement | null>(null);
        const contentRect = useRectObserver(contentRef);

        const options = {
            placement,
            modifiers: [
                { name: 'offset', options: { offset: [0, 10] } },
                { name: 'recalcDependency', options: contentRect ? [contentRect.width, contentRect.height] : [] },
                { name: 'arrow', options: { element: '[data-popper-arrow]' } },
            ],
        };

        const { attributes, styles: popperStyles } = usePopper(anchorElement, popoverContainer, options);

        return show
            ? createPortal(
                  <div className={styles.overlay} onClick={(e) => e.stopPropagation()}>
                      <div
                          ref={(instance) => {
                              setPopoverContainer(instance);
                              if (typeof ref === 'function') {
                                  ref(instance);
                              } else if (ref) {
                                  ref.current = instance;
                              }
                          }}
                          className={styles.popover}
                          style={popperStyles.popper}
                          {...attributes.popper}
                      >
                          <div className={cn(styles.tooltip, className)} ref={setContentRef}>
                              {children}
                          </div>
                          <div className={styles.arrow} data-popper-arrow style={popperStyles.arrow} />
                      </div>
                  </div>,
                  document.body
              )
            : null;
    }
);

const Root = ({
    children,
}: {
    children: (
        ref: HTMLElement | SVGSVGElement | null,
        setRef: Dispatch<SetStateAction<HTMLElement | SVGSVGElement | null>>,
        underlineClassName: string
    ) => React.ReactNode;
}) => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | SVGSVGElement | null>(null);
    return <>{children(anchorElement, setAnchorElement, styles.underline)}</>;
};

const Title = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
    <div className={cn(styles.title, className)}>{children}</div>
);

const Body = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
    <div className={cn(styles.body, className)}>{children}</div>
);

Object.assign(Tooltip, {
    Root,
    Title,
    Body,
});

export default Tooltip as typeof Tooltip & {
    Root: typeof Root;
    Title: typeof Title;
    Body: typeof Body;
};
