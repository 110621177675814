import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './Header.module.css';

type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

const Header = ({ children, className, ...props }: Props) => (
    <header {...props} className={cn(styles.header, className)}>
        {children}
    </header>
);

type TitleProps = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
const Title = ({ children, className, ...props }: TitleProps) => (
    <h1 {...props} className={cn(styles.title, className)}>
        {children}
    </h1>
);

Header.Title = Title;

export default Header;
