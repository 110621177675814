import { combineReducers } from 'helpers';
import {
    reducer as campaignsReducer,
    initialState as campaignsInitialState,
} from 'Modules/Plans/Campaigns/hooks/dataHooks/useCampaignsDataHook';
import {
    reducer as instagramAudiosReducer,
    initialState as instagramAudiosInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramAudiosByIdDataHook';
import {
    reducer as instagramTimeSeriesReducer,
    initialState as instagramTimeSeriesInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramTimeSeries';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

export const initialState: State = {
    campaigns: campaignsInitialState,
    instagram: {
        audios: instagramAudiosInitialState,
        timeSeries: instagramTimeSeriesInitialState,
    },
};

export const reducer = combineReducers({
    campaigns: campaignsReducer,
    instagram: combineReducers({
        audios: instagramAudiosReducer,
        timeSeries: instagramTimeSeriesReducer,
    }),
});
