import useNonNullContext from 'Hooks/useNonNullContext';
import { useMemo, useState } from 'react';
import { TabsContext } from '@round/ui-kit';
import { getNamesFromChildren } from 'ui/DataDisplay/Tabs/helpers';
import styles from './AccordionTabNav.module.css';
import cn from 'classnames';
import { AccordionTabNavItem } from '../AccordionTabNavItem/AccordionTabNavItem';
import { useRectObserver } from 'Hooks/useRectObserver';

type ChildType = React.ReactElement<typeof AccordionTabNavItem>;

type AccordionTabNavProps = {
    label: React.ReactNode;
    children: ChildType | ChildType[];
    className?: string;
};

export const AccordionTabNav = ({ label, children, className }: AccordionTabNavProps) => {
    const [tabsContainer, setTabsContainer] = useState<HTMLDivElement | null>(null);
    const tabsContainerRect = useRectObserver(tabsContainer);

    const { activeTab, onChange } = useNonNullContext(TabsContext);
    const tabNames = useMemo(() => getNamesFromChildren(children), [children]);

    const isOpen = !!tabNames.find((tab) => tab === activeTab);
    const handleMasterTabClick = () => {
        if (isOpen) {
            return;
        }
        onChange(tabNames[0]);
    };

    return (
        <div className={cn(styles.container, className, { [styles.open]: isOpen })}>
            <button className={cn(styles.masterTab, { [styles.active]: isOpen })} onClick={handleMasterTabClick}>
                {label}
            </button>
            <div
                className={styles.tabsWrapper}
                style={{
                    width: `${tabsContainerRect?.width || 0}px`,
                }}
            >
                {isOpen && (
                    <div className={styles.tabs} ref={setTabsContainer}>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};
