import { getInstagramAudios, GetInstagramAudiosParams, postTimeSeries } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import moment from 'moment';
import uniq from 'lodash/uniq';
import { SongDataContext } from '../../SongDataContext';

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');

export default function useInstagramTopAudios() {
    const songData = useNonNullContextSelector(SongDataContext, (value) => value[0].song);
    const state = useNonNullContextSelector(SongDataContext, (value) => value[0].instagram.topAudios);
    const dispatch = useNonNullContextSelector(SongDataContext, (value) => value[1]);

    const fetchData = useCallback(
        async (params: Omit<GetInstagramAudiosParams, 'identifier_search' | 'id'>, requestInit?: RequestInit) => {
            const audioIds = uniq([
                ...(songData.data?.song.instagram_audios || []),
                ...(songData.data?.musicSong?.instagram_audios || []),
            ]);

            if (!audioIds || audioIds.length === 0) {
                if (audioIds?.length === 0) {
                    dispatch({
                        type: 'instagramTopAudiosSuccess',
                        payload: { audios: { count: 0, results: [], next: null, previous: null }, timeSeries: null },
                    });
                }
                return;
            }

            dispatch({ type: 'loadInstagramTopAudios' });

            try {
                const response = await getInstagramAudios(
                    {
                        ...params,
                        id: audioIds.join(','),
                    },
                    requestInit
                );

                if (response.status !== 200) {
                    dispatch({ type: 'instagramTopAudiosErrorLoading', payload: 'Could not load instagram audios' });
                    return;
                }

                const timeSeriesResponse = await postTimeSeries(
                    {
                        ids: audioIds,
                        type: 'audio',
                        fields: ['posts_daily_change'],
                        start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                        end_date: today.format('YYYY-MM-DD'),
                    },
                    requestInit
                ).catch(() => {});

                const timeSeries = timeSeriesResponse?.status === 200 ? timeSeriesResponse.data : null;

                dispatch({ type: 'instagramTopAudiosSuccess', payload: { audios: response.data, timeSeries } });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'instagramTopAudiosIdle' });
                    throw e;
                }

                dispatch({
                    type: 'instagramTopAudiosErrorLoading',
                    payload: e instanceof Error ? e.message : 'Could not load instagram audios',
                });
                throw e;
            }
        },
        [dispatch, songData.data?.song.instagram_audios, songData.data?.musicSong?.instagram_audios]
    );

    const reset = useCallback(() => {
        dispatch({ type: 'instagramTopAudiosIdle' });
    }, [dispatch]);

    return {
        ...state,
        fetchData,
        reset,
    };
}
