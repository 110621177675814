import React, { useCallback, useContext, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import Modal, { ModalContent, ModalTitle } from '../../../../../../ui/General/Modal/Modal';
import styles from './CreatePlanModal.module.css';
import PlanForm, { PlanFormValues } from '../components/PlanForm/PlanForm';
import useNonNullContext from '../../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../../contexts/OptionsContext/OptionsContext';
import { InfluencerPlanContext } from '../../../contexts/InfluencerPlanContext';
import { showNotification } from '../../../../../../helpers';
import { Release } from '@round/api';
import { useCheckUserGroupsAccess } from '../../../../../Auth/hooks/useCheckUserGroupsAccess';
import { mapApiErrorsToFormikErrors } from 'utility/utility';

type CreatePlanModalProps = {
    isModalOpen: boolean;
    onClose: () => void;
    onCreated: () => void;
    release: Release;
};

const CreatePlanModal = ({ isModalOpen, onClose, onCreated, release }: CreatePlanModalProps) => {
    const { createInfluencerPlan } = useContext(InfluencerPlanContext);
    const { currencies } = useNonNullContext(OptionsContext);

    const userHasAdmin = useCheckUserGroupsAccess(['user_admin']);

    const initialValues: PlanFormValues = useMemo(
        () => ({
            currency: currencies.length > 0 ? { value: currencies[0].id, label: currencies[0].name } : undefined,
            start_date: new Date().toISOString(),
            end_date: new Date().toISOString(),
            instagram_budget: '0',
            tiktok_budget: '0',
            youtube_budget: '0',
            planners: null,
        }),
        [currencies]
    );

    const handleSubmit = useCallback(
        async (values: PlanFormValues, helpers: FormikHelpers<PlanFormValues>) => {
            if (!values.currency || !createInfluencerPlan) {
                return;
            }

            try {
                helpers.setSubmitting(true);
                const response = await createInfluencerPlan({
                    release_id: release.id,
                    currency_id: values.currency.value,
                    tiktok_budget: values.tiktok_budget.toString(),
                    end_date: values.end_date,
                    start_date: values.start_date,
                    instagram_budget: values.instagram_budget.toString(),
                    youtube_budget: values.youtube_budget.toString(),
                    ...(userHasAdmin && { planners: values.planners ? values.planners.map((val) => val.value) : [] }),
                });

                if (response.status === 403) {
                    showNotification('Could not create plan', 'error');
                    return;
                }

                if (response.status === 400) {
                    helpers.setErrors(
                        mapApiErrorsToFormikErrors({
                            ...response.data,
                            currency: response.data.currency_id,
                        })
                    );

                    return;
                }

                onCreated();
                onClose();
                showNotification('Plan created', 'info');
            } catch {
                showNotification('Could not create plan', 'error');
            } finally {
                helpers.setSubmitting(false);
            }
        },
        [createInfluencerPlan, onClose, onCreated, release.id, userHasAdmin]
    );

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={onClose}>
            <ModalTitle>Create Plan</ModalTitle>
            <ModalContent className={styles.content}>
                <PlanForm handleSubmit={handleSubmit} initialValues={initialValues} />
            </ModalContent>
        </Modal>
    );
};

export default CreatePlanModal;
