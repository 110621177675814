import styles from './InstagramCreatorsFilters.module.css';
import { microwave } from '@round/api';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { ContentTagsFilterIcon } from 'Modules/Tags/components/Filters/ContentTagsFilter/ContentTagsFilter';
import { ReactComponent as BaseHashIcon } from 'assets/icons/Hash.svg';
import { ReactComponent as BasePostIcon } from 'assets/icons/Post.svg';
import { ReactComponent as BaseMailIcon } from 'assets/EmailSend.svg';
import { ReactComponent as BaseTickIcon } from 'assets/TickCircle.svg';
import { ReactComponent as BaseGlobeIcon } from 'assets/Globe.svg';
import { ReactComponent as BaseMusicSquareRemoveIcon } from 'assets/icons/MusicSquareRemove.svg';
import { ReactComponent as BaseMusicSquareSearchIcon } from 'assets/icons/MusicSquareSearch.svg';
import { ReactComponent as BaseUserIcon } from 'assets/icons/User.svg';
import { Filter } from 'ui-new/Filters/useFilterManager';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import useTiktokHashtagSelect, { TiktokHashtagOption } from 'Modules/TikTok/hooks/useTiktokHashtagSelect';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { ValueType } from 'react-select';
import RatingFilter, { RatingFilterIcon } from 'ui-new/Filters/RatingFilter/RatingFilter';
import { getRatingOptions } from 'ui/RatingSelect/RatingSelect';
import { GenericDropdownOption } from 'App.types';
import MinMaxFilter from 'ui-new/Filters/MinMaxFilter/MinMaxFilter';
import { DebounceInput } from 'react-debounce-input';
import MicrowaveContentTagsFilter from '../../../components/MicrowaveContentTagsFilter/MicrowaveContentTagsFilter';
import CheckboxFilter from 'ui-new/Filters/CheckboxFilter/CheckboxFilter';
import LocationFilter from 'ui-new/Filters/LocationFilter/LocationFilter';
import { AudioPlayerProvider } from 'Modules/AudioPlayer/AudioPlayerContext';
import InstagramAudioFilter from 'Modules/Instagram/components/Filters/InstagramAudioFilter/InstagramAudioFilter';
import ExpandableSearchInput from 'ui/ExpandableSearchInput/ExpandableSearchInput';

export type FilterParams = Pick<
    microwave.GetMicrowaveInfluencersParams,
    | 'search'
    | 'content_tags'
    | 'hashtag_ids'
    | 'location_id'
    | 'rating'
    | 'min_tiktok_post_count'
    | 'max_tiktok_post_count'
    | 'min_instagram_post_count'
    | 'max_instagram_post_count'
    | 'min_tiktok_post_invite_count'
    | 'max_tiktok_post_invite_count'
    | 'min_instagram_post_invite_count'
    | 'max_instagram_post_invite_count'
    | 'min_tiktok_post_rate'
    | 'min_instagram_post_rate'
    | 'min_instagram_follower_count'
    | 'max_instagram_follower_count'
    | 'exclude_posted_with_instagram_audio'
    | 'posted_with_instagram_audio'
> & {
    exclude_invited_to_campaign?: boolean;
    showOnlySelected?: boolean;
    selected?: number[];
};

const PERMANENT_FILTERS: (keyof FilterParams)[] = ['showOnlySelected', 'exclude_invited_to_campaign'];

type Props = DomainFiltersProps<FilterParams>;

const HashIcon = () => <BaseHashIcon className={styles.filterIcon} />;
const PostIcon = () => <BasePostIcon className={styles.filterIcon} />;
const MailIcon = () => <BaseMailIcon className={styles.filterIcon} />;
const TickIcon = () => <BaseTickIcon className={styles.filterIcon} />;
const GlobeIcon = () => <BaseGlobeIcon className={styles.filterIcon} />;
const MusicSquareRemoveIcon = () => <BaseMusicSquareRemoveIcon className={styles.filterIcon} />;
const MusicSquareSearchIcon = () => <BaseMusicSquareSearchIcon className={styles.filterIcon} />;
const UserIcon = () => <BaseUserIcon className={styles.filterIcon} />;

const InstagramCreatorsFilters = ({ value, onChange }: Props) => {
    const { props: tiktokHashtagSelectProps } = useTiktokHashtagSelect({
        initialValueId: value.hashtag_ids?.length ? value.hashtag_ids.split(',').map(Number) : undefined,
    });

    const filters: Filter<keyof FilterParams>[] = [
        {
            name: 'showOnlySelected',
            label: 'Show only selected',
            icon: () => null,
            render(this) {
                const selectedCount = value.selected?.length ?? 0;
                const label = selectedCount ? `Show ${selectedCount} selected` : this.label;
                return (
                    <CheckboxFilter
                        isDisabled={!selectedCount}
                        label={label}
                        value={!!value.showOnlySelected}
                        onChange={(value) => onChange({ showOnlySelected: value || undefined })}
                    />
                );
            },
        },
        {
            name: 'exclude_invited_to_campaign',
            label: 'Exclude invited',
            icon: () => null,
            render(this, helpers) {
                return (
                    <CheckboxFilter
                        isDisabled={helpers.isDisabled}
                        label={this.label}
                        value={!!value.exclude_invited_to_campaign}
                        onChange={(value) => onChange({ exclude_invited_to_campaign: value || undefined })}
                    />
                );
            },
        },
        {
            name: 'content_tags',
            label: 'Tags',
            icon: () => ContentTagsFilterIcon,
            render(this, helpers) {
                return (
                    <MicrowaveContentTagsFilter
                        isDisabled={helpers.isDisabled}
                        label="Tags"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        onRemove={() => {
                            onChange({
                                content_tags: undefined,
                            });
                            helpers.remove();
                        }}
                        value={value.content_tags}
                        onChange={(tags, shouldRefreshData) => onChange({ content_tags: tags }, shouldRefreshData)}
                    />
                );
            },
        },
        {
            name: 'hashtag_ids',
            label: 'TikTok Hashtags',
            icon: HashIcon,
            render(this, helpers) {
                const clearState = () => {
                    onChange({
                        hashtag_ids: undefined,
                    });
                    tiktokHashtagSelectProps.onChange?.(null, { action: 'clear' });
                };

                return (
                    <SelectFilter
                        isDisabled={helpers.isDisabled}
                        icon={<HashIcon />}
                        {...tiktokHashtagSelectProps}
                        name="TikTok Hashtags"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        value={tiktokHashtagSelectProps.value as ValueType<TiktokHashtagOption, true>}
                        onChange={(options: ValueType<TiktokHashtagOption, true>, action) => {
                            onChange({
                                hashtag_ids: options?.map((option) => option.value).join(','),
                            });
                            tiktokHashtagSelectProps.onChange?.(options, action);
                        }}
                        onRemove={() => {
                            clearState();
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => clearState()}
                        isMulti
                    />
                );
            },
        },
        {
            name: 'location_id',
            label: 'Location',
            icon: GlobeIcon,
            render(this, helpers) {
                return (
                    <LocationFilter
                        isDisabled={helpers.isDisabled}
                        label={this.label}
                        icon={<GlobeIcon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        onRemove={() => {
                            onChange({ location_id: undefined });
                            helpers.remove();
                        }}
                        onClear={() => onChange({ location_id: undefined })}
                        initialValueId={value.location_id}
                        onChange={(locationId) => onChange({ location_id: locationId })}
                    />
                );
            },
        },
        {
            name: 'rating',
            label: 'Rating',
            icon: RatingFilterIcon,
            render(this, helpers) {
                const maxRating = 3;
                return (
                    <RatingFilter
                        isDisabled={helpers.isDisabled}
                        name="Rating"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        value={getRatingOptions(maxRating).filter((o) =>
                            value.rating?.split(',').includes(o.value.toString())
                        )}
                        onChange={(value: ValueType<GenericDropdownOption<number>, true>) => {
                            onChange({ rating: value?.map((o) => o.value).toString() });
                        }}
                        maxRating={maxRating}
                        onRemove={() => {
                            onChange({ rating: undefined });
                            helpers.remove();
                        }}
                        onClear={() => {
                            onChange({ rating: undefined });
                        }}
                        onClose={helpers.close}
                        isSearchable={false}
                        isMulti
                    />
                );
            },
        },
        {
            name: 'min_tiktok_post_count',
            label: 'TikTok Past Posts',
            icon: PostIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        isDisabled={helpers.isDisabled}
                        min={value.min_tiktok_post_count}
                        max={value.max_tiktok_post_count}
                        onChange={({ min, max }) => {
                            onChange({ min_tiktok_post_count: min, max_tiktok_post_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_tiktok_post_count: undefined,
                                max_tiktok_post_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_tiktok_post_count: undefined,
                                max_tiktok_post_count: undefined,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: 'min_instagram_post_count',
            label: 'Instagram Past Posts',
            icon: PostIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        isDisabled={helpers.isDisabled}
                        min={value.min_instagram_post_count}
                        max={value.max_instagram_post_count}
                        onChange={({ min, max }) => {
                            onChange({ min_instagram_post_count: min, max_instagram_post_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_instagram_post_count: undefined,
                                max_instagram_post_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_instagram_post_count: undefined,
                                max_instagram_post_count: undefined,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: 'min_tiktok_post_invite_count',
            label: 'TikTok Past Invites',
            icon: MailIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        isDisabled={helpers.isDisabled}
                        min={value.min_tiktok_post_invite_count}
                        max={value.max_tiktok_post_invite_count}
                        onChange={({ min, max }) => {
                            onChange({ min_tiktok_post_invite_count: min, max_tiktok_post_invite_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_tiktok_post_invite_count: undefined,
                                max_tiktok_post_invite_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_tiktok_post_invite_count: undefined,
                                max_tiktok_post_invite_count: undefined,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: 'min_instagram_post_invite_count',
            label: 'Instagram Past Invites',
            icon: MailIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        isDisabled={helpers.isDisabled}
                        min={value.min_instagram_post_invite_count}
                        max={value.max_instagram_post_invite_count}
                        onChange={({ min, max }) => {
                            onChange({ min_instagram_post_invite_count: min, max_instagram_post_invite_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_instagram_post_invite_count: undefined,
                                max_instagram_post_invite_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_instagram_post_invite_count: undefined,
                                max_instagram_post_invite_count: undefined,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: 'min_tiktok_post_rate',
            label: 'TikTok Post Rate',
            icon: TickIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        isDisabled={helpers.isDisabled}
                        min={value.min_tiktok_post_rate}
                        onChange={({ min }) => {
                            onChange({ min_tiktok_post_rate: min });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_tiktok_post_rate: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_tiktok_post_rate: undefined,
                            });
                        }}
                    >
                        {({ minProps }) => <DebounceInput {...minProps} placeholder="Min %" />}
                    </MinMaxFilter>
                );
            },
        },
        {
            name: 'min_instagram_post_rate',
            label: 'Instagram Post Rate',
            icon: TickIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        isDisabled={helpers.isDisabled}
                        min={value.min_instagram_post_rate}
                        onChange={({ min }) => {
                            onChange({ min_instagram_post_rate: min });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_instagram_post_rate: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_instagram_post_rate: undefined,
                            });
                        }}
                    >
                        {({ minProps }) => <DebounceInput {...minProps} placeholder="Min %" />}
                    </MinMaxFilter>
                );
            },
        },
        {
            name: 'min_instagram_follower_count',
            label: 'Instagram Follower Count',
            icon: UserIcon,
            render(this, helpers) {
                return (
                    <MinMaxFilter
                        isDisabled={helpers.isDisabled}
                        min={value.min_instagram_follower_count}
                        max={value.max_instagram_follower_count}
                        onChange={({ min, max }) => {
                            onChange({ min_instagram_follower_count: min, max_instagram_follower_count: max });
                        }}
                        label={this.label}
                        icon={<this.icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onRemove={() => {
                            onChange({
                                min_instagram_follower_count: undefined,
                                max_instagram_follower_count: undefined,
                            });
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => {
                            onChange({
                                min_instagram_follower_count: undefined,
                                max_instagram_follower_count: undefined,
                            });
                        }}
                    />
                );
            },
        },
        {
            name: 'exclude_posted_with_instagram_audio',
            label: 'Exclude posted with audio',
            icon: MusicSquareRemoveIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <InstagramAudioFilter
                        label={this.label}
                        icon={<Icon />}
                        isDisabled={helpers.isDisabled}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        onRemove={() => {
                            onChange({ exclude_posted_with_instagram_audio: undefined });
                            helpers.remove();
                        }}
                        onClear={() => onChange({ exclude_posted_with_instagram_audio: undefined })}
                        initialValueId={value.exclude_posted_with_instagram_audio}
                        onChange={(value) => onChange({ exclude_posted_with_instagram_audio: value })}
                    />
                );
            },
        },
        {
            name: 'posted_with_instagram_audio',
            label: 'Posted with audio',
            icon: MusicSquareSearchIcon,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <InstagramAudioFilter
                        label={this.label}
                        icon={<Icon />}
                        isDisabled={helpers.isDisabled}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        onRemove={() => {
                            onChange({ posted_with_instagram_audio: undefined });
                            helpers.remove();
                        }}
                        onClear={() => onChange({ posted_with_instagram_audio: undefined })}
                        initialValueId={value.posted_with_instagram_audio}
                        onChange={(value) => onChange({ posted_with_instagram_audio: value })}
                    />
                );
            },
        },
    ];

    const areFiltersDisabled = !!value.showOnlySelected;
    return (
        <div className={styles.container}>
            <ExpandableSearchInput
                persistIfValuePresent
                value={value.search}
                onChange={(search) => onChange({ search })}
                placeholder="Search"
                className={styles.search}
            />

            <AudioPlayerProvider>
                <Filters
                    sessionKey="mw-campaign-details-ig-creators"
                    isDisabled={areFiltersDisabled}
                    hint={areFiltersDisabled ? 'Displaying only selected rows' : undefined}
                    filters={filters}
                    options={{
                        initialFilters: (state) => [
                            ...PERMANENT_FILTERS,
                            ...getInitialFilters(state, getNonEmptyKeys(value)),
                        ],
                    }}
                    getEmptyFilters={(selected) =>
                        selected.filter((filter) => !PERMANENT_FILTERS.includes(filter.name) && !value[filter.name])
                    }
                />
            </AudioPlayerProvider>
        </div>
    );
};

export default InstagramCreatorsFilters;
