import { getTableMetaHelper } from '@round/ui-kit';
import styles from './MiniLineChartCell.module.css';
import { ReactComponent as AudioIcon } from 'assets/whitelabel/Audio.svg';
import moment from 'moment';
import { Meta, TopAudiosTableRow } from '../../TopAudiosTable';
import { CellContext } from '@tanstack/react-table';
import { numberWithCommas } from 'helpers';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import MiniLineChart from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';

type Context<T extends TopAudiosTableRow> = CellContext<T, T['timeSeries']>;

const getTableMeta = getTableMetaHelper<Meta>();

const MiniLineChartCell = <T extends TopAudiosTableRow>({ row, table, getValue }: Context<T>) => {
    const { isLoading } = getTableMeta(table);
    const timeSeries = getValue()?.['posts_daily_change'] || [];
    const shouldDisplayTimeSeriesData = !!timeSeries.length;
    const points = timeSeries.map((s) => ({ x: s.timestamp, y: s.value }));

    return (
        <div className={styles.dailyChangeContainer}>
            <AudioIcon className={styles.audioIcon} />

            <div className={styles.chartContainer}>
                <MiniLineChart
                    flatLineOnEmpty
                    lineColor="#2A94FF"
                    points={points}
                    renderTooltip={
                        shouldDisplayTimeSeriesData
                            ? (tooltipItem) => (
                                  <>
                                      <Tooltip.Title>{moment(tooltipItem.x).format('D MMM YYYY')}</Tooltip.Title>
                                      <Tooltip.Body>
                                          <dl className={styles.chartTooltipStats}>
                                              <div className={styles.chartTooltipStatsItem}>
                                                  <dt>Posts growth</dt>
                                                  <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                              </div>
                                          </dl>
                                      </Tooltip.Body>
                                  </>
                              )
                            : undefined
                    }
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default MiniLineChartCell;
