import { ApiResponse, ForbiddenResponse } from '../../types';
import { call, encodeUrlSearchParams, getFileFromResponse } from '../../helpers';

export type GetPlanReportParams = Partial<{
    start_date: string;
    end_date: string;
    client_ids: string;
}>;

export type GetPlanReportResponse = ApiResponse<File, 200> | ForbiddenResponse;

export async function getPlanReport(params: GetPlanReportParams): Promise<GetPlanReportResponse> {
    const response = await call(`/api/advertising/reports/plans/${encodeUrlSearchParams(params)}`);

    if (response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not download plans report');
    }

    return {
        status: 200,
        data: await getFileFromResponse(response),
    };
}
