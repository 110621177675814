import { creatorbase, music } from '@round/api';
import { DataState } from 'App.types';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { isNumber } from 'utility/utility';
import { promise } from '@round/utils';
import { useTimeSeries } from 'Modules/Plans/hooks/useTimeSeries';

type State = DataState<{
    songs: creatorbase.Song[];
    musicSongs: music.Song[];
}>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

const today = moment();
const twoWeeksAgo = moment().subtract(14, 'days');
export default function useSongsData() {
    const [state, setState] = useState<State>(initialState);
    const { fetchData: fetchTimeSeries, state: timeSeriesState } = useTimeSeries<
        'tiktok_daily_change' | 'instagram_daily_change'
    >();

    const fetchData = useCallback(
        async (params: creatorbase.GetSongsParams, requestInit?: RequestInit) => {
            setState((prev) => ({
                status: 'loading',
                data: prev.data,
                error: null,
            }));

            try {
                const response = await creatorbase.getSongs(params, requestInit);
                if (response.status !== 200) {
                    setState((prev) => ({
                        status: 'error',
                        data: prev.data,
                        error: 'Could not get songs',
                    }));

                    return response;
                }

                const musicSongIds = response.data.results.map((s) => s.music_song_id).filter(isNumber);
                const songIds = response.data.results.map((s) => s.id);
                const [musicSongsResult] = await Promise.allSettled([
                    musicSongIds.length
                        ? music
                              .getSongs(
                                  { id: musicSongIds.toString(), page_size: musicSongIds.length, page: 1 },
                                  requestInit
                              )
                              .then((response) => response.data.results)
                        : Promise.resolve([]),
                    songIds.length
                        ? fetchTimeSeries({
                              type: 'song',
                              ids: songIds,
                              fields: ['tiktok_daily_change', 'instagram_daily_change'],
                              start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                              end_date: today.format('YYYY-MM-DD'),
                          })
                        : Promise.resolve(null),
                ]);

                const musicSongs = promise.getFulfilledResult(musicSongsResult) ?? [];

                setState((prev) => ({
                    status: 'success',
                    error: null,
                    data: {
                        songs: (prev.data?.songs ?? []).concat(response.data.results),
                        musicSongs: (prev.data?.musicSongs ?? []).concat(musicSongs),
                    },
                }));

                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    setState((prev) => ({
                        status: 'idle',
                        data: prev.data,
                        error: null,
                    }));
                    return;
                }

                setState({
                    status: 'error',
                    data: null,
                    error: 'Could not get songs',
                });
            }
        },
        [fetchTimeSeries]
    );

    const createSong = useCallback(
        async (data: creatorbase.PostSongData) => {
            const response = await creatorbase.postSong(data);
            if (response.status !== 201) {
                return response;
            }

            const [musicResponse] = await Promise.all([
                response.data.music_song_id ? music.getSong(response.data.music_song_id) : Promise.resolve(null),
                fetchTimeSeries({
                    type: 'song',
                    ids: [response.data.id],
                    fields: ['tiktok_daily_change', 'instagram_daily_change'],
                    start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                    end_date: today.format('YYYY-MM-DD'),
                }),
            ]);

            setState((prev) => ({
                ...prev,
                data: {
                    songs: [response.data, ...(prev.data?.songs ?? [])],
                    musicSongs: (prev.data?.musicSongs ?? []).concat(
                        musicResponse?.status === 200 ? musicResponse.data : []
                    ),
                },
            }));

            return response;
        },
        [fetchTimeSeries]
    );

    const updateSong = useCallback(
        async (songId: number, data: creatorbase.PatchSongData) => {
            const response = await creatorbase.patchSong(songId, data);
            if (response.status !== 200) {
                return response;
            }

            const hasMusicSongFetched = !!state.data?.musicSongs.some(
                (song) => song.id === response.data.music_song_id
            );
            const [musicResponse] = await Promise.all([
                !hasMusicSongFetched && response.data.music_song_id
                    ? music.getSong(response.data.music_song_id)
                    : Promise.resolve(null),
                fetchTimeSeries({
                    type: 'song',
                    ids: [response.data.id],
                    fields: ['tiktok_daily_change', 'instagram_daily_change'],
                    start_date: twoWeeksAgo.format('YYYY-MM-DD'),
                    end_date: today.format('YYYY-MM-DD'),
                }),
            ]);

            setState((prev) => ({
                ...prev,
                data: {
                    songs: (prev.data?.songs ?? []).map((song) =>
                        song.id === response.data.id ? response.data : song
                    ),
                    musicSongs: (prev.data?.musicSongs ?? []).concat(
                        musicResponse?.status === 200 ? musicResponse.data : []
                    ),
                },
            }));

            return response;
        },
        [fetchTimeSeries, state.data?.musicSongs]
    );

    const deleteSong = useCallback(async (songId: creatorbase.Song['id']) => {
        const response = await creatorbase.deleteSong(songId);
        if (response.status === 204) {
            setState((prev) => ({
                status: 'success',
                error: null,
                data: {
                    songs: prev.data?.songs.filter((s) => s.id !== songId) ?? [],
                    musicSongs: prev.data?.musicSongs ?? [],
                },
            }));
        }
        return response;
    }, []);

    const reset = useCallback(() => setState(initialState), []);

    return {
        ...state,
        timeSeries: timeSeriesState,
        fetchData,
        createSong,
        updateSong,
        deleteSong,
        reset,
    };
}
