import { useState } from 'react';
import { showNotification } from 'helpers';
import Modal, { ModalContent, ModalFooter, ModalTitle } from 'ui/General/Modal/Modal';
import FormField from 'ui/DataEntry/FormField/FormField';
import Label from 'ui/DataEntry/Label/Label';
import Button from 'ui/Buttons/Button/Button';
import { postTikTokInfluencerPlanHashtag } from '@round/api';

type AddHashtagModalProps = {
    influencerPlanId: number;
    isModalOpen: boolean;
    closeModal: () => void;
    createHashtag: typeof postTikTokInfluencerPlanHashtag;
};

const AddHashtagModal = ({ createHashtag, closeModal, isModalOpen, influencerPlanId }: AddHashtagModalProps) => {
    const [hashtag, setHashtag] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleCreate = async () => {
        try {
            setSubmitLoading(true);
            const response = await createHashtag({ plan: influencerPlanId, hashtag });
            if (response.status !== 201) {
                const message =
                    response.status === 403 ? response.data.detail : Object.values(response.data).join('. ');
                showNotification(message, 'error');
                return;
            }

            showNotification('Hashtag created', 'info');
            closeModal();
        } catch {
            showNotification('Could not create hashtag', 'error');
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Modal closeOnOverlayClick isOpen={isModalOpen} onClose={closeModal}>
            <ModalTitle>Add Hashtag</ModalTitle>
            <ModalContent>
                <FormField>
                    <Label htmlFor="hashtag">Hashtag</Label>
                    <input
                        name="hashtag"
                        id="hashtag"
                        placeholder="Enter hashtag"
                        value={hashtag}
                        onChange={(e) => setHashtag(e.target.value)}
                    />
                </FormField>
            </ModalContent>
            <ModalFooter align="right">
                <Button type="filled" color="black" loading={submitLoading} onClick={handleCreate}>
                    Create
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddHashtagModal;
