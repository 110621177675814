import { TiktokAudio, TiktokVideo } from '@round/api';
import {
    ReducerAction,
    ReducerActionWithPayload,
    TiktokInfluencerPlanAudio,
    TiktokInfluencerPostResult,
} from 'App.types';
import { createReducer } from 'helpers';
import {
    TiktokInfluencerUser,
    TiktokUser,
    TiktokUserImage,
    TiktokUserStats,
    InfluencerPostGroup,
    TiktokInfluencerPostGroupStats,
    TiktokInfluencerPlanStats,
    TiktokInfluencerPost,
    TikTokInfluencerPlanHashtag,
} from '@round/api';
import { uniqBy } from 'lodash';

export const initialState: TiktokCreatorsState = {
    // influencer plan groups
    tiktokInfluencerPostGroups: [],
    tiktokInfluencerPostGroupsInitialized: false,
    tiktokInfluencerPostGroupsLoading: false,
    tiktokInfluencerPostGroupsLoadingError: null,
    // influencer plan group stats
    tiktokInfluencerPostGroupStats: [],
    tiktokInfluencerPostGroupStatsInitialized: false,
    tiktokInfluencerPostGroupStatsLoading: false,
    tiktokInfluencerPostGroupStatsLoadingError: null,
    // tiktok influencer post data
    tiktokInfluencerPostDataLoading: false,
    tiktokInfluencerPostDataInitialized: false,
    tiktokInfluencerPostDataLoadingError: null,
    tiktokInfluencerPosts: [],
    tiktokVideos: [],
    tiktokVideoStats: [],
    tiktokUserStats: [],
    tiktokInfluencerUsers: [],
    tiktokUsers: [],
    tiktokUserImages: [],
    // audios
    tiktokInfluencerPlanAudios: [],
    tiktokInfluencerPlanAudiosInitialized: false,
    tiktokInfluencerPlanAudiosLoading: false,
    tiktokInfluencerPlanAudiosLoadingError: null,
    tiktokAudios: [],
    tiktokAudiosInitialized: false,
    tiktokAudiosLoadingError: null,
    tiktokAudiosLoading: false,
    // hashtags
    tiktokHashtags: [],
    tiktokHashtagsInitialized: false,
    tiktokHashtagsLoading: false,
    tiktokHashtagsLoadingError: null,
    // influencer plan stats
    tiktokInfluencerPlanStats: null,
    tiktokInfluencerPlanStatsInitialized: false,
    tiktokInfluencerPlanStatsLoadingError: null,
    tiktokInfluencerPlanStatsLoading: false,
};

export type TiktokInfluencerPostGroupStatsWithId = TiktokInfluencerPostGroupStats & { group_id: number };

export type TiktokCreatorsState = {
    // influencer plan groups
    tiktokInfluencerPostGroups: InfluencerPostGroup[];
    tiktokInfluencerPostGroupsLoading: boolean;
    tiktokInfluencerPostGroupsInitialized: boolean;
    tiktokInfluencerPostGroupsLoadingError: string | null;
    // influencer plan group stats
    tiktokInfluencerPostGroupStats: TiktokInfluencerPostGroupStatsWithId[];
    tiktokInfluencerPostGroupStatsLoading: boolean;
    tiktokInfluencerPostGroupStatsInitialized: boolean;
    tiktokInfluencerPostGroupStatsLoadingError: string | null;
    // influencer post data
    tiktokInfluencerPostDataLoading: boolean;
    tiktokInfluencerPostDataInitialized: boolean;
    tiktokInfluencerPostDataLoadingError: string | null;
    tiktokInfluencerPosts: TiktokInfluencerPost[];
    tiktokVideos: TiktokVideo[];
    tiktokVideoStats: TiktokInfluencerPostResult[];
    tiktokUserStats: TiktokUserStats[];
    tiktokInfluencerUsers: TiktokInfluencerUser[];
    tiktokUsers: TiktokUser[];
    tiktokUserImages: TiktokUserImage[];
    // hashtags
    tiktokHashtags: TikTokInfluencerPlanHashtag[];
    tiktokHashtagsInitialized: boolean;
    tiktokHashtagsLoading: boolean;
    tiktokHashtagsLoadingError: string | null;
    // audios
    tiktokInfluencerPlanAudios: TiktokInfluencerPlanAudio[];
    tiktokInfluencerPlanAudiosInitialized: boolean;
    tiktokInfluencerPlanAudiosLoading: boolean;
    tiktokInfluencerPlanAudiosLoadingError: string | null;
    tiktokAudios: TiktokAudio[];
    tiktokAudiosLoading: boolean;
    tiktokAudiosInitialized: boolean;
    tiktokAudiosLoadingError: string | null;
    // plan live stats
    tiktokInfluencerPlanStats: TiktokInfluencerPlanStats | null;
    tiktokInfluencerPlanStatsLoading: boolean;
    tiktokInfluencerPlanStatsInitialized: boolean;
    tiktokInfluencerPlanStatsLoadingError: string | null;
};

export type Actions =
    // influencer post data
    | ReducerAction<'loadTiktokInfluencerPostData'>
    | ReducerActionWithPayload<
          'tiktokInfluencerPostDataInitialized',
          Pick<
              TiktokCreatorsState,
              | 'tiktokInfluencerPosts'
              | 'tiktokInfluencerUsers'
              | 'tiktokUsers'
              | 'tiktokUserStats'
              | 'tiktokVideos'
              | 'tiktokVideoStats'
          >
      >
    | ReducerActionWithPayload<'setTiktokInfluencerPostLoadingError', string | null>
    | ReducerActionWithPayload<'tiktokUserImagesInitialized', TiktokUserImage[]>
    | ReducerActionWithPayload<'addTiktokInfluencerUser', TiktokInfluencerUser>
    // influencer plan groups
    | ReducerAction<'loadInfluencerPostGroups'>
    | ReducerActionWithPayload<'influencerPostGroupsInitialized', InfluencerPostGroup[]>
    | ReducerActionWithPayload<'setInfluencerPostGroupsLoadingError', string | null>
    | ReducerActionWithPayload<'influencerPostGroupCreated', InfluencerPostGroup>
    | ReducerActionWithPayload<'influencerPostGroupDeleted', number>
    | ReducerActionWithPayload<'influencerPostGroupUpdated', InfluencerPostGroup>
    // influencer plan group stats
    | ReducerAction<'loadInfluencerPostGroupStats'>
    | ReducerActionWithPayload<'influencerPostGroupStatsInitialized', TiktokInfluencerPostGroupStatsWithId[]>
    | ReducerActionWithPayload<'setInfluencerPostGroupStatsLoadingError', string | null>
    | ReducerActionWithPayload<'influencerPostCreated', TiktokInfluencerPost>
    | ReducerActionWithPayload<'influencerPostUpdated', TiktokInfluencerPost>
    | ReducerActionWithPayload<'influencerUserUpdated', TiktokInfluencerUser>
    | ReducerActionWithPayload<'influencerPostDeleted', number>
    // hashtags
    | ReducerAction<'loadTiktokHashtags'>
    | ReducerActionWithPayload<'tiktokHashtagsInitialized', TikTokInfluencerPlanHashtag[]>
    | ReducerActionWithPayload<'setTiktokHashtagError', string | null>
    | ReducerActionWithPayload<'tiktokHashtagCreated', TikTokInfluencerPlanHashtag>
    | ReducerActionWithPayload<'tiktokHashtagDeleted', number>
    | ReducerActionWithPayload<'influencerPostGroupsBatchUpdated', InfluencerPostGroup[]>
    | ReducerActionWithPayload<'influencerUsersFetched', TiktokInfluencerUser[]>
    | ReducerActionWithPayload<'tiktokInfluencerPlanAudiosInitialized', TiktokInfluencerPlanAudio[]>
    | ReducerAction<'loadTiktokInfluencerPlanAudios'>
    | ReducerActionWithPayload<'setTiktokInfluencerPlanAudiosLoadingError', string | null>
    | ReducerAction<'resetTiktokInfluencerPlanAudios'>
    // tiktok audios
    | ReducerActionWithPayload<'tiktokAudiosInitialized', TiktokAudio[]>
    | ReducerAction<'loadTiktokAudios'>
    | ReducerActionWithPayload<'setTiktokAudiosLoadingError', string | null>
    // tiktok influencer plan stats
    | ReducerAction<'loadTiktokInfluencerPlanStats'>
    | ReducerActionWithPayload<'tiktokInfluencerPlanStatsInitialized', TiktokInfluencerPlanStats>
    | ReducerActionWithPayload<'setTiktokInfluencerPlanStatsLoadingError', string | null>;

export default createReducer<TiktokCreatorsState, Actions>({
    // influencer post data
    loadTiktokInfluencerPostData: (state) => ({
        ...state,
        tiktokInfluencerPostDataLoading: true,
        tiktokInfluencerPostDataInitialized: false,
        tiktokInfluencerPostDataLoadingError: null,
    }),
    tiktokInfluencerPostDataInitialized: (state, { payload }) => ({
        ...state,
        ...payload,
        tiktokInfluencerPostDataInitialized: true,
        tiktokInfluencerPostDataLoadingError: null,
        tiktokInfluencerPostDataLoading: false,
    }),
    setTiktokInfluencerPostLoadingError: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostDataLoadingError: payload,
        tiktokInfluencerPostDataLoading: false,
        tiktokInfluencerPostDataInitialized: false,
    }),
    tiktokUserImagesInitialized: (state, { payload }) => ({
        ...state,
        tiktokUserImages: payload,
    }),
    addTiktokInfluencerUser: (state, { payload }) => ({
        ...state,
        tiktokInfluencerUsers: state.tiktokInfluencerUsers.concat(payload),
    }),
    // influencer plan groups
    loadInfluencerPostGroups: (state) => ({
        ...state,
        tiktokInfluencerPostGroupsLoading: true,
        tiktokInfluencerPostGroups: [],
        tiktokInfluencerPostGroupsInitialized: false,
        tiktokInfluencerPostGroupsLoadingError: null,
    }),
    influencerPostGroupsInitialized: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroups: payload,
        tiktokInfluencerPostGroupsLoading: false,
        tiktokInfluencerPostGroupsInitialized: true,
        tiktokInfluencerPostGroupsLoadingError: null,
    }),
    setInfluencerPostGroupsLoadingError: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroupsLoading: false,
        tiktokInfluencerPostGroupsInitialized: false,
        tiktokInfluencerPostGroupsLoadingError: payload,
        tiktokInfluencerPostGroups: [],
    }),
    influencerPostGroupCreated: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroups: state.tiktokInfluencerPostGroups.concat(payload),
    }),
    influencerPostGroupDeleted: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroups: state.tiktokInfluencerPostGroups.filter((group) => group.id !== payload),
        tiktokInfluencerPosts: state.tiktokInfluencerPosts.filter((p) => p.group_id !== payload),
    }),
    influencerPostGroupUpdated: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroups: state.tiktokInfluencerPostGroups.map((group) =>
            group.id === payload.id ? payload : group
        ),
    }),
    // influencer post group stats
    influencerPostGroupStatsInitialized: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroupStats: payload,
        tiktokInfluencerPostGroupStatsInitialized: true,
        tiktokInfluencerPostGroupStatsLoadingError: null,
        tiktokInfluencerPostGroupStatsLoading: false,
    }),
    loadInfluencerPostGroupStats: (state) => ({
        ...state,
        tiktokInfluencerPostGroupStatsLoading: true,
        tiktokInfluencerPostGroupStatsLoadingError: null,
        tiktokInfluencerPostGroupStatsInitialized: false,
        tiktokInfluencerPostGroupStats: [],
    }),
    setInfluencerPostGroupStatsLoadingError: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroupStatsLoadingError: payload,
        tiktokInfluencerPostGroupStatsInitialized: false,
        tiktokInfluencerPostGroupStatsLoading: false,
        tiktokInfluencerPostGroupStats: [],
    }),
    influencerPostCreated: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPosts: state.tiktokInfluencerPosts.concat(payload),
    }),
    influencerPostUpdated: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPosts: state.tiktokInfluencerPosts.map((p) => (p.id === payload.id ? payload : p)),
    }),
    influencerUserUpdated: (state, { payload }) => ({
        ...state,
        tiktokInfluencerUsers: state.tiktokInfluencerUsers.map((influencer) =>
            influencer.id === payload.id ? payload : influencer
        ),
    }),
    influencerPostDeleted: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPosts: state.tiktokInfluencerPosts.filter((post) => post.id !== payload),
    }),
    // hashtags
    loadTiktokHashtags: (state) => ({
        ...state,
        tiktokHashtags: [],
        tiktokHashtagsLoading: true,
        tiktokHashtagsLoadingError: null,
        tiktokHashtagsInitialized: false,
    }),
    tiktokHashtagsInitialized: (state, { payload }) => ({
        ...state,
        tiktokHashtags: payload,
        tiktokHashtagsInitialized: true,
        tiktokHashtagsLoading: false,
        tiktokHashtagsLoadingError: null,
    }),
    setTiktokHashtagError: (state, { payload }) => ({
        ...state,
        tiktokHashtagsLoadingError: payload,
        tiktokHashtags: [],
        tiktokHashtagsInitialized: false,
        tiktokHashtagsLoading: false,
    }),
    tiktokHashtagCreated: (state, { payload }) => ({
        ...state,
        tiktokHashtags: state.tiktokHashtags.concat(payload),
    }),
    tiktokHashtagDeleted: (state, { payload }) => ({
        ...state,
        tiktokHashtags: state.tiktokHashtags.filter((h) => h.id !== payload),
    }),
    influencerPostGroupsBatchUpdated: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPostGroups: state.tiktokInfluencerPostGroups.map(
            (group) => payload.find((updated) => updated.id === group.id) ?? group
        ),
    }),
    influencerUsersFetched: (state, { payload }) => ({
        ...state,
        tiktokInfluencerUsers: uniqBy(state.tiktokInfluencerUsers.concat(payload), 'id'),
    }),
    tiktokInfluencerPlanAudiosInitialized: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPlanAudios: payload,
        tiktokInfluencerPlanAudiosInitialized: true,
        tiktokInfluencerPlanAudiosLoading: false,
        tiktokInfluencerPlanAudiosLoadingError: null,
    }),
    loadTiktokInfluencerPlanAudios: (state) => ({
        ...state,
        tiktokInfluencerPlanAudiosLoading: true,
        tiktokInfluencerPlanAudiosLoadingError: null,
        tiktokInfluencerPlanAudiosInitialized: false,
    }),
    setTiktokInfluencerPlanAudiosLoadingError: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPlanAudiosLoading: false,
        tiktokInfluencerPlanAudiosLoadingError: payload,
        tiktokInfluencerPlanAudiosInitialized: false,
    }),
    resetTiktokInfluencerPlanAudios: (state) => ({
        ...state,
        tiktokInfluencerPlanAudiosLoading: false,
        tiktokInfluencerPlanAudios: [],
        tiktokInfluencerPlanAudiosInitialized: false,
        tiktokInfluencerPlanAudiosLoadingError: null,
    }),
    tiktokAudiosInitialized: (state, { payload }) => ({
        ...state,
        tiktokAudios: state.tiktokAudios.concat(payload),
        tiktokAudiosInitialized: true,
        tiktokAudiosLoading: false,
        tiktokAudiosLoadingError: null,
    }),
    loadTiktokAudios: (state) => ({
        ...state,
        tiktokAudiosLoading: true,
        tiktokAudiosLoadingError: null,
    }),
    setTiktokAudiosLoadingError: (state, { payload }) => ({
        ...state,
        tiktokAudiosLoading: false,
        tiktokAudiosLoadingError: payload,
    }),
    // tiktok influencer plan stats
    loadTiktokInfluencerPlanStats: (state) => ({
        ...state,
        tiktokInfluencerPlanStats: null,
        tiktokInfluencerPlanStatsLoading: true,
        tiktokInfluencerPlanStatsInitialized: false,
        tiktokInfluencerPlanStatsLoadingError: null,
    }),
    tiktokInfluencerPlanStatsInitialized: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPlanStats: payload,
        tiktokInfluencerPlanStatsLoading: false,
        tiktokInfluencerPlanStatsInitialized: true,
        tiktokInfluencerPlanStatsLoadingError: null,
    }),
    setTiktokInfluencerPlanStatsLoadingError: (state, { payload }) => ({
        ...state,
        tiktokInfluencerPlanStatsLoading: false,
        tiktokInfluencerPlanStatsLoadingError: payload,
        tiktokInfluencerPlanStatsInitialized: false,
    }),
});
