import styles from './YoutubeTable.module.css';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useYoutubePostGroups } from '../../contexts/YoutubeCreatorsContext/hooks/useYoutubePostGroups';
import YoutubePostGroupBar from './components/YoutubePostGroupBar/YoutubePostGroupBar';
import { Skeleton } from '@round/ui-kit';
import range from 'lodash/range';
import useUrlState from 'Hooks/useUrlState';
import useYoutubeInfluencerPosts from '../../contexts/YoutubeCreatorsContext/hooks/useYoutubeInfluencerPosts';
import YoutubeInfluencerPostsTable, {
    YoutubeInfluencerPostTableRow,
} from './components/YoutubeInfluencerPostsTable/YoutubeInfluencerPostsTable';
import { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import CreateYoutubeInfluencerPostModal from '../InfluencerPlanItemModal/CreateYoutubeInfluencerPostModal/CreateYoutubeInfluencerPostModal';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import EditBriefModal from '../EditBriefModal/EditBriefModal';
import { YoutubeInfluencerPostGroup } from '@round/api';
import UpdateYoutubeInfluencerPostModal from '../InfluencerPlanItemModal/UpdateYoutubeInfluencerPostModal';
import YoutubePostPaymentRequestModal from '../InstagramTable/PostPaymentRequestModal/YoutubePostPaymentRequestModal';
import { showNotification } from 'helpers';
import ConfirmDeleteGroupModal from '../../components/ConfirmDeleteGroupModal/ConfirmDeleteGroupModal';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import { getTotalEngagements } from '../../InfluencerPlan.helpers';
import copyComponentRenderToClipboard from 'utility/copyToClipboard/copyComponentRenderToClipboard';
import YoutubeReportClipboardTable from '../copyToClipboard/YoutubeReportClipboardTable/YoutubeReportClipboardTable';
import {
    useMicroCampaigns,
    useMicrowaveCampaignStats,
} from 'Modules/Advertising/Microwave/MicroCreators/contexts/MicroCampaign';
import YoutubeMicrosStatsBar from '../MicrosStatsBar/YoutubeMicrosStatsBar/YoutubeMicrosStatsBar';
import CreateMicrowavePostModal from 'Modules/Advertising/Microwave/CreateMicrowavePostModal/CreateMicrowavePostModal';
import { useMicrowaveCampaignPosts } from 'Modules/Advertising/Microwave/MicroCreators/contexts/MIcrowavePosts';
import YoutubeMicrowaveCampaignPosts from '../MicrosStatsBar/MicroCreatorsPostsTable/YoutubeMicrowaveCampaignPosts';

type UrlState = {
    expandedGroups: string;
    isMicrosExpanded: boolean;
};

const YoutubeTable = () => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
    const [createPostGroupId, setCreatePostGroupId] = useState<number | null>(null);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [postToEdit, setPostToEdit] = useState<YoutubeInfluencerPostTableRow | null>(null);

    const [isPaymentRequestModalOpen, setIsPaymentRequestModalOpen] = useState(false);
    const [postToRequestPayment, setPostToRequestPayment] = useState<YoutubeInfluencerPostTableRow | null>(null);

    const expandedGroups = urlState.expandedGroups?.split(',').map((g) => Number(g)) ?? [];
    const toggleIsGroupExpanded = (group: number) => {
        if (expandedGroups.includes(group)) {
            setUrlState({ expandedGroups: expandedGroups.filter((g) => g !== group).join(',') });
            return;
        }

        setUrlState({ expandedGroups: expandedGroups.concat(group).join(',') });
    };

    const [isEditBriefModalOpen, setIsEditBriefModalOpen] = useState(false);
    const [groupToEditBrief, setGroupToEditBrief] = useState<YoutubeInfluencerPostGroup | null>(null);

    const onClickEditBrief = (group: YoutubeInfluencerPostGroup) => {
        setGroupToEditBrief(group);
        setIsEditBriefModalOpen(true);
    };

    const [isDeleteGroupConfirmationModalOpen, setIsDeleteGroupConfirmationModalOpen] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState<YoutubeInfluencerPostGroup | null>(null);

    const resetGroupDeletion = () => {
        setIsDeleteGroupConfirmationModalOpen(false);
        setGroupToDelete(null);
    };

    const {
        postGroups,
        isLoading: isGroupsLoading,
        isInitialized: isGroupsInitialized,
        loadingError: groupsLoadingError,

        updateYoutubeInfluencerPostGroup,
        updateYoutubeInfluencerPostGroupOrderingIndex,
        deleteYoutubeInfluencerPostGroup,

        postGroupStats,
        isStatsLoading,
        statsLoadingError,
        isStatsInitialized,
        initStats,
    } = useYoutubePostGroups();

    useAbortableEffect(
        (signal) => {
            if (isGroupsInitialized && !isStatsInitialized) {
                initStats({ signal });
            }
        },
        [isGroupsInitialized, isStatsInitialized, initStats]
    );

    const updateBrief = async (
        groupId: number,
        data: Partial<Pick<YoutubeInfluencerPostGroup, 'brief' | 'brief_turnaround'>>
    ) => {
        try {
            const res = await updateYoutubeInfluencerPostGroup(groupId, data);

            if (res.status !== 200) {
                throw new Error('Could not update youtube influencer post group');
            }
        } catch (e) {
            throw e;
        }
    };

    const handleDeleteGroup = async (groupId: number) => {
        const result = await deleteYoutubeInfluencerPostGroup(groupId);
        if (!result.success) {
            throw new Error(result.reason);
        }
    };

    const isGroupsDataLoading =
        isGroupsLoading ||
        (!isGroupsInitialized && !groupsLoadingError) ||
        isStatsLoading ||
        (!isStatsInitialized && !statsLoadingError);

    const {
        influencerPosts,
        fetchData: fetchInfluencerPosts,
        isInitialized: areInfluencerPostsInitialized,
        isLoading: areInfluencerPostsLoading,
        error: influencerPostsLoadingError,
    } = useYoutubeInfluencerPosts();

    useAbortableEffect(
        (signal) => {
            if (!areInfluencerPostsInitialized) {
                fetchInfluencerPosts({ signal }).catch(() => {});
            }
        },
        [areInfluencerPostsInitialized, fetchInfluencerPosts]
    );

    const copyReportTableToClipboard = useCallback(
        (group: YoutubeInfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const activeGroupPosts =
                influencerPosts?.filter((p) => p.group_id === group.id).filter((p) => p.status === 'live') ?? [];

            const totals = {
                likes: activeGroupPosts.reduce((acc, post) => acc + (post.video?.like_count ?? 0), 0),
                comments: activeGroupPosts.reduce((acc, post) => acc + (post.video?.comment_count ?? 0), 0),
                postViews: activeGroupPosts.reduce((acc, post) => acc + (post.video?.view_count ?? 0), 0),
            };

            const totalEngagements = getTotalEngagements(totals.likes, totals.comments);

            const items =
                activeGroupPosts
                    .filter((p) => p.group_id === group.id)
                    .map((post) => {
                        const viewCount = post.video?.view_count;

                        const totalEngagement = getTotalEngagements(
                            post.video?.like_count ?? 0,
                            post.video?.comment_count ?? 0
                        );

                        return {
                            status: post.status,
                            postViews: viewCount,
                            likes: post.video?.like_count,
                            comments: post.video?.comment_count,
                            channel: post.channel_title ?? '-',
                            link: post.post_url,
                            engagementRate: viewCount ? Number(totalEngagement) / Number(viewCount) : undefined,
                            videoUploadedDate: post.video?.published_at,
                        };
                    }) ?? [];

            const success = copyComponentRenderToClipboard(
                {
                    release: influencerPlan.release,
                    groupName: group.name,
                    totals: {
                        likes: totals.likes,
                        comments: totals.comments,
                        postViews: totals.postViews,
                        engagementRate: totalEngagements / totals.postViews,
                    },
                    items,
                },
                YoutubeReportClipboardTable
            );

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [influencerPlan, influencerPosts]
    );

    const isInfluencerPostsTableLoading =
        (!areInfluencerPostsInitialized && !influencerPostsLoadingError) || areInfluencerPostsLoading;

    const {
        isInitialized: isMicroCampaignsInitialized,
        campaigns: microCampaigns,
        isLoading: isMicroCampaignsLoading,
        hasError: hasMicroCampaignsLoadingError,
    } = useMicroCampaigns(influencerPlan?.release.id);
    const shouldDisplayMicrosBar = microCampaigns.some((c) => c.platform === 'youtube');
    const youtubeCampaign = useMemo(() => microCampaigns.find((c) => c.platform === 'youtube'), [microCampaigns]);

    const [isCreateMicrowavePostModalOpen, setIsCreateMicrowavePostModalOpen] = useState(false);

    const {
        data: microwavePosts,
        error: microwavePostsError,
        fetchData: fetchMicrowavePosts,
        isInitialized: areMicrowavePostsInitialized,
        isLoading: areMicrowavePostsLoading,
        reset: resetMicrowavePosts,
    } = useMicrowaveCampaignPosts();

    useAbortableEffect(
        (signal) => {
            if (!areMicrowavePostsInitialized && youtubeCampaign) {
                fetchMicrowavePosts(
                    { campaign_id: youtubeCampaign.id, payment_request_status: 'APPROVED' },
                    { signal }
                ).catch(() => {});
            }
        },
        [areMicrowavePostsInitialized, fetchMicrowavePosts, youtubeCampaign]
    );

    const isMicrowaveTableLoading = areMicrowavePostsLoading || (!microwavePostsError && !areMicrowavePostsInitialized);

    const {
        campaignStats,
        isInitialized: areMicrowaveCampaignStatsInitialized,
        isLoading: areMicrowaveCampaignStatsLoading,
        fetchData: fetchMicrowaveCampaignStats,
        reset: resetMicrowaveCampaignStats,
    } = useMicrowaveCampaignStats();

    useEffect(() => {
        if (!areMicrowaveCampaignStatsInitialized && youtubeCampaign) {
            fetchMicrowaveCampaignStats([youtubeCampaign.id]).catch(() =>
                showNotification('Could not fetch youtube microwave data', 'error')
            );
        }
    }, [areMicrowaveCampaignStatsInitialized, fetchMicrowaveCampaignStats, youtubeCampaign]);

    return (
        <>
            <section className={styles.groupsContainer}>
                {isGroupsDataLoading
                    ? range(3).map((i) => <Skeleton key={i} className={styles.groupSkeleton} />)
                    : postGroups
                          ?.sort((a, b) => a.ordering_index - b.ordering_index)
                          .map((postGroup) => {
                              const isExpanded = expandedGroups.includes(postGroup.id);
                              const stats = postGroupStats?.find((s) => s.group_id === postGroup.id) || null;
                              const groupInfluencerPosts =
                                  influencerPosts?.filter((p) => p.group_id === postGroup.id) ?? [];
                              const hasLivePosts = groupInfluencerPosts.some((p) => p.status === 'live');

                              return (
                                  <div key={postGroup.id}>
                                      <YoutubePostGroupBar
                                          group={postGroup}
                                          stats={stats}
                                          isExpanded={isExpanded}
                                          onToggleExpand={toggleIsGroupExpanded}
                                          onEditBrief={onClickEditBrief}
                                          updateGroup={updateYoutubeInfluencerPostGroup}
                                          updateOrderingIndex={updateYoutubeInfluencerPostGroupOrderingIndex}
                                          hasLivePosts={hasLivePosts}
                                          onCreatePost={(groupId) => {
                                              setIsCreatePostModalOpen(true);
                                              setCreatePostGroupId(groupId);
                                          }}
                                          onDeleteGroup={(group) => {
                                              setGroupToDelete(group);
                                              setIsDeleteGroupConfirmationModalOpen(true);
                                          }}
                                          onCopyReportTable={copyReportTableToClipboard}
                                      />
                                      {isExpanded && (
                                          <YoutubeInfluencerPostsTable
                                              renderTotals
                                              group={postGroup}
                                              data={groupInfluencerPosts}
                                              isLoading={isInfluencerPostsTableLoading}
                                              onRowClick={(row) => {
                                                  setIsEditModalOpen(true);
                                                  setPostToEdit(row);
                                              }}
                                              noDataLabel={influencerPostsLoadingError || 'No data found'}
                                              onRequestPayment={(row) => {
                                                  setIsPaymentRequestModalOpen(true);
                                                  setPostToRequestPayment(row);
                                              }}
                                          />
                                      )}
                                  </div>
                              );
                          })}

                {shouldDisplayMicrosBar && (
                    <div>
                        {!isMicroCampaignsInitialized || isMicroCampaignsLoading || areMicrowaveCampaignStatsLoading ? (
                            <Skeleton className={styles.groupSkeleton} />
                        ) : (
                            !hasMicroCampaignsLoadingError && (
                                <YoutubeMicrosStatsBar
                                    isExpanded={urlState.isMicrosExpanded === 'true'}
                                    onExpandedChange={(isExpanded) => {
                                        setUrlState({ isMicrosExpanded: isExpanded || undefined });
                                    }}
                                    campaign={youtubeCampaign}
                                    campaignStats={campaignStats.find((c) => c.id === youtubeCampaign?.id)}
                                    onAddPost={() => {
                                        setIsCreateMicrowavePostModalOpen(true);
                                    }}
                                >
                                    <YoutubeMicrowaveCampaignPosts
                                        isLoading={isMicrowaveTableLoading}
                                        data={microwavePosts}
                                        noDataLabel={
                                            areMicrowavePostsInitialized && microwavePostsError
                                                ? microwavePostsError
                                                : 'No data found'
                                        }
                                    />
                                </YoutubeMicrosStatsBar>
                            )
                        )}
                    </div>
                )}
            </section>

            <ProtectedByUserGroups groups={['influencer_editor']}>
                <EditBriefModal
                    isModalOpen={isEditBriefModalOpen}
                    group={groupToEditBrief}
                    closeModal={() => {
                        setIsEditBriefModalOpen(false);
                        setGroupToEditBrief(null);
                    }}
                    updateBrief={updateBrief}
                />
            </ProtectedByUserGroups>

            <CreateYoutubeInfluencerPostModal
                isOpen={isCreatePostModalOpen}
                closeModal={() => {
                    setIsCreatePostModalOpen(false);
                    setCreatePostGroupId(null);
                }}
                groupId={createPostGroupId}
            />

            <UpdateYoutubeInfluencerPostModal
                isOpen={isEditModalOpen}
                closeModal={() => {
                    setIsEditModalOpen(false);
                    setPostToEdit(null);
                }}
                post={postToEdit}
            />

            <ConfirmDeleteGroupModal
                isOpen={isDeleteGroupConfirmationModalOpen}
                onClose={resetGroupDeletion}
                group={groupToDelete}
                handleDeleteGroup={handleDeleteGroup}
            />

            <YoutubePostPaymentRequestModal
                post={postToRequestPayment}
                isOpen={isPaymentRequestModalOpen}
                onClose={() => {
                    setIsPaymentRequestModalOpen(false);
                    setPostToRequestPayment(null);
                }}
                onSuccess={() => {
                    //todo: replace with refetch post when we have read endpoint
                    fetchInfluencerPosts().catch(() => {});
                }}
            />

            <CreateMicrowavePostModal
                campaignId={youtubeCampaign?.id}
                isOpen={isCreateMicrowavePostModalOpen}
                closeModal={() => {
                    setIsCreateMicrowavePostModalOpen(false);
                }}
                onCreated={() => {
                    setIsCreateMicrowavePostModalOpen(false);
                    resetMicrowavePosts();
                    resetMicrowaveCampaignStats();
                }}
            />
        </>
    );
};

export default YoutubeTable;
