import { AppServer, RouteHandlerContext } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { InstagramAudio } from './audios.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { GetInstagramAudiosParams } from './audios.api';

export const models = {
    instagramAudio: Model as ModelDefinition<InstagramAudio>,
};

export const serializers = {
    instagramAudio: makeSerializer<InstagramAudio>([]),
};

export const factories = {
    instagramAudio: createFactory<InstagramAudio>({
        instagram_id: (index: number) => index.toString(),
        title: (index: number) => `title ${index}`,
        author: (index: number) => index,
        audio_file_url: (index: number) => `audio file url ${index}`,
        thumbnail_url: (index: number) => `audio thumbnail url ${index}`,
        reels_count: () => Math.floor(Math.random() * 100),
        author_name: (index: number) => `author name ${index}`,
        reels_count_daily_change: (index: number) => index,
        reels_count_daily_change_relative: (index: number) => index,
        reels_count_weekly_change: (index: number) => index,
        reels_count_weekly_change_relative: (index: number) => index,
    }),
};

export function handleRequests(server: AppServer) {
    const getInstagramAudios = '/api/instagram/viewsets/audios/';
    server.get(getInstagramAudios, function (this: RouteHandlerContext, schema, request) {
        const { id = '' } = request.queryParams as GetInstagramAudiosParams;
        const ids = id
            .split(',')
            .filter((v) => !!v)
            .map(Number);
        const audios = schema.all('instagramAudio').filter((audio) => !ids.length || ids.includes(Number(audio.id)));
        return buildPaginatedResponse(audios, {
            url: getInstagramAudios,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'instagramAudio'),
        });
    });

    server.get('/api/instagram/viewsets/audios/:id/', function (this: RouteHandlerContext, schema, request) {
        const audio = schema.find('instagramAudio', request.params.id);
        if (!audio) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return audio;
    });
}
