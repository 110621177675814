import { AppServer } from '../mirage';
import { Response } from 'miragejs';
import { AuthTokenPair } from './auth.types';

export function handleAuthRequests(server: AppServer) {
    server.post('/api/token/', (schema, request) => {
        const body = JSON.parse(request.requestBody);
        const user = schema.findBy('user', { username: String(body?.username) });
        if (!user) {
            return new Response(401);
        }

        return new Response(200, undefined, {
            access: 'access token',
            refresh: 'refresh token',
        } as AuthTokenPair);
    });

    server.post('/api/token/refresh/', () => {
        return new Response(200, undefined, {
            access: 'access token',
            refresh: 'refresh token',
        } as AuthTokenPair);
    });

    server.post('/api/password_reset/validate_token/', () => new Response(200));
    server.post('/api/password_reset/confirm/', () => new Response(200));
    server.post('/api/password_reset/', () => new Response(200));
}
