import { combineReducers } from 'helpers';
import { reducer as topAudiosReducer, initialState as topAudiosInitialState } from '../TopAudios/Tiktok/reducer';
import { reducer as indicatorsReducer, initialState as indicatorsInitialState } from '../Indicators/Tiktok/reducer';
import { reducer as topPostsReducer, initialState as topPostsInitialState } from '../TopPosts/Tiktok/reducer';

export const tiktokInitialState = {
    topAudios: topAudiosInitialState,
    indicators: indicatorsInitialState,
    topPosts: topPostsInitialState,
};

export const tiktokReducer = combineReducers({
    topAudios: topAudiosReducer,
    indicators: indicatorsReducer,
    topPosts: topPostsReducer,
});
