import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { createFactory, makeSerializer } from '../../mirage.helpers';
import { Project } from './projects.types';

export const models = {
    applicationManagerProject: Model as ModelDefinition<Project>,
};

export const serializers = {
    applicationManagerProject: makeSerializer<Project>([]),
};

export const factories = {
    applicationManagerProject: createFactory<Project>({
        creatorbase_project_id: null,
        round_influencer_plan_id: null,
    }),
};

export function handleRequests(server: AppServer) {
    const getProjectsUrl = '/api/application_manager/projects/';
    server.get(`${getProjectsUrl}:id/`, function (this: RouteHandlerContext, schema, request) {
        const project = schema.find('applicationManagerProject', request.params.id);
        if (!project) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return this.serialize(project, 'applicationManagerProject');
    });
}
