import { ApiResponse, ApiResponseError, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { AdminInfluencer } from './admin.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

export type GetAdminInfluencersParams = Partial<PaginatedRequest & { search: string }>;

export async function getAdminInfluencers(
    params: GetAdminInfluencersParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<AdminInfluencer>, 200>> {
    const response = await fetchWithToken(
        `/api/microwave/admin/influencers/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get microwave admin influencers ${encodeUrlSearchParams(params)}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type AdminInfluencerAPIBody = {
    tiktok_user_id?: number;
    instagram_user_id?: number;
    youtube_channel_id?: number;
    email: string;
};

type AdminInfluencerResponseErrorBody = ApiResponseError<
    AdminInfluencerAPIBody & {
        non_field_errors: string[];
    }
>;

type PostAdminInfluencerResponse =
    | ApiResponse<AdminInfluencer, 201>
    | ApiResponse<string[], 400>
    | ApiResponse<ApiResponseError<AdminInfluencerResponseErrorBody>, 400>;

export async function postAdminInfluencer(data: AdminInfluencerAPIBody): Promise<PostAdminInfluencerResponse> {
    const response = await fetchWithToken('/api/microwave/admin/influencers/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create microwave influencer');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

type PatchAdminInfluencerResponse =
    | ApiResponse<AdminInfluencer, 200>
    | ApiResponse<string[], 400>
    | ApiResponse<ApiResponseError<AdminInfluencerResponseErrorBody>, 400>;

export async function patchAdminInfluencer(
    id: number,
    data: Partial<AdminInfluencerAPIBody>
): Promise<PatchAdminInfluencerResponse> {
    const response = await fetchWithToken(`/api/microwave/admin/influencers/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update microwave influencer');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
