import styles from './PostsTableStatusCell.module.css';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import PostUrlPopout from 'Modules/Plans/components/PostUrlPopout/PostUrlPopout';
import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import { getTableMetaHelper } from '@round/ui-kit';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';

export type StatusData = {
    postUrl: string | null;
    adCode?: string;
    platform: creatorbase.Platform;
    isReadOnly?: boolean;
};

type Row = creatorbase.PublicPost;

type Context<T extends Row> = CellContext<T, StatusData>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PostsTableStatusCell = <T extends Row>({ table, row, getValue }: Context<T>) => {
    const { updatePost, managedBy } = getTableMeta(table);
    const { postUrl, adCode, platform, isReadOnly } = getValue();
    const isManagedExternally = managedBy !== 'creatorbase';

    const status = row.original.status;

    return (
        <div className={styles.container}>
            <div className={cn(styles.statusBadge, styles[status])}>
                <span>{capitalize(status)}</span>
            </div>

            <PostUrlPopout
                postUrl={postUrl}
                adCode={adCode}
                updatePost={updatePost ? (data) => updatePost(row.original.id, data) : undefined}
                platform={platform}
                status={status}
                isReadOnly={isReadOnly || isManagedExternally}
            />
        </div>
    );
};

export default PostsTableStatusCell;
