import { encodeUrlSearchParams, fetchWithToken, getClients, getPlannerUsers } from '@round/api';
import { Button, DatePickerSelect } from '@round/ui-kit';
import React, { useCallback, useState } from 'react';
import { ValueType } from 'react-select';
import { GenericDropdownOption } from '../../../../App.types';
import { downloadFileFromResponse, showNotification } from '../../../../helpers';
import PaginatedSelect from '../../../../ui/DataEntry/PaginatedSelect/PaginatedSelect';
import styles from './Reports.module.css';
import { formatToIsoDateString } from '../../../../utility/utility';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';
import selectStyles from './selectStyles';

const InfluencerPostReportDownload = () => {
    const [selectedClients, setSelectedClients] = useState<ValueType<GenericDropdownOption<number>, true>>([]);
    const [selectedPlanners, setSelectedPlanners] = useState<ValueType<GenericDropdownOption<number>, true>>([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [dateRange, setDateRange] = useState<[Date, Date] | [Date] | null>();
    const featureFlags = useFeatureFlags();

    const onFileDownload = useCallback(async () => {
        const selectedClientIds = selectedClients?.map((client) => client.value);
        const selectedPlannerIds = selectedPlanners?.map((planner) => planner.value);

        const params = {
            client_ids: selectedClientIds ? selectedClientIds.join(',') : undefined,
            start_date: dateRange?.[0] ? formatToIsoDateString(dateRange[0]) : undefined,
            end_date: dateRange?.[1] ? formatToIsoDateString(dateRange[1]) : undefined,
            planner_ids: selectedPlannerIds ? selectedPlannerIds.join(',') : undefined,
        };

        try {
            setIsDownloading(true);
            const response = await fetchWithToken(
                `/api/advertising/client-influencer-posts-report/${encodeUrlSearchParams(params)}`
            );
            if (response.status === 200) {
                downloadFileFromResponse(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            } else {
                showNotification('Error downloading file', 'error');
            }
        } catch {
            showNotification('Error downloading file', 'error');
        } finally {
            setIsDownloading(false);
        }
    }, [selectedClients, dateRange, selectedPlanners]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <div>
                    <label>Clients</label>
                    <PaginatedSelect
                        fetchOptions={getClients}
                        mapToOption={(client) => ({
                            label: client.name,
                            value: client.id,
                        })}
                        value={selectedClients}
                        onChange={setSelectedClients}
                        menuPortalTarget={document.body}
                        isMulti
                        placeholder="Select Clients..."
                        closeMenuOnSelect={false}
                        styles={selectStyles}
                    />
                </div>

                <div>
                    <label>Date Range</label>
                    <DatePickerSelect
                        placeholder="Select Date Range..."
                        onChange={(dates) => {
                            setDateRange(dates);
                        }}
                        onClear={() => setDateRange(null)}
                        value={dateRange}
                        className={styles.datePicker}
                        selectRange
                    />
                </div>

                {featureFlags.isEnabled('planner_filter') && (
                    <div>
                        <label>Planners</label>
                        <PaginatedSelect
                            fetchOptions={getPlannerUsers}
                            mapToOption={(planner) => ({
                                label: `${planner.first_name} ${planner.last_name}`,
                                value: planner.id,
                            })}
                            value={selectedPlanners}
                            onChange={setSelectedPlanners}
                            menuPortalTarget={document.body}
                            isMulti
                            placeholder="Select Planners..."
                            closeMenuOnSelect={false}
                            styles={selectStyles}
                        />
                    </div>
                )}
            </div>

            <Button type="filled" color="black" loading={isDownloading} onClick={onFileDownload}>
                Download
            </Button>
        </div>
    );
};

export default InfluencerPostReportDownload;
