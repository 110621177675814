import { useCallback, useEffect, useState } from 'react';

export function useTooltipMouseEvents(anchorElement: HTMLElement | SVGSVGElement | null, disabled?: boolean) {
    const [show, setShow] = useState(false);

    const addHoverEvents = useCallback((anchor: HTMLElement | SVGSVGElement) => {
        const handleMouseEnter = () => {
            setShow(true);
        };

        const handleMouseLeave = () => {
            setShow(false);
        };

        anchor.addEventListener('mouseenter', handleMouseEnter);
        anchor.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            anchor.removeEventListener('mouseenter', handleMouseEnter);
            anchor.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    useEffect(() => {
        if (!disabled && anchorElement) {
            return addHoverEvents(anchorElement);
        }
    }, [addHoverEvents, anchorElement, disabled]);

    return {
        show,
        setShow,
    };
}
