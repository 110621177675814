import { TiktokAudio, TiktokTimeSeriesResponse } from '@round/api';
import { CampaignTableRow } from './types';
import { getTableMetaHelper, ProgressBar, TableProps } from '@round/ui-kit';
import { ColumnDef } from '@tanstack/react-table';
import { CampaignsTableMeta } from '../../../Campaigns/types';
import ProjectCell, {
    Meta as ProjectCellMeta,
    ProjectData,
} from '../../../Campaigns/components/cells/ProjectCell/ProjectCell';
import ExpandCell from '../../../Campaigns/components/cells/ExpandCell/ExpandCell';
import styles from './PublicReportCampaignTable.module.css';
import ReportCampaignCell from '../../../Campaigns/components/CampaignsTable/components/ReportCampaignCell/ReportCampaignCell';
import Skeleton from 'react-loading-skeleton';
import { toDecimalPoint } from '@round/utils';
import { buildTiktokMusicUrl, numberWithCommas } from 'helpers';
import CampaignStatsCell from '../../../Campaigns/components/cells/CampaignStatsCell';
import TooltipHeader from 'ui-new/whitelabel/Table/TooltipHeader/TooltipHeader';
import { tooltipCopy } from '../../../helpers';
import DurationCell from '../../../Campaigns/components/cells/DurationCell';
import CampaignsTable from '../../../Campaigns/components/CampaignsTable/CampaignsTable';
import AudioPostGrowthCell, {
    Meta as AudioPostGrowthCellMeta,
} from 'Modules/Plans/Campaigns/components/cells/AudioPostGrowthCell';

export type TikTokPublicReportCampaignTableRow = CampaignTableRow & {
    audioTimeSeries?: TiktokTimeSeriesResponse[number] | null;
    audio?: TiktokAudio | null;
};

type Props = Pick<
    TableProps<TikTokPublicReportCampaignTableRow>,
    'data' | 'expandedState' | 'onExpandedChange' | 'renderSubComponent'
> & {
    hasError?: boolean;
    isLoading: boolean;
    meta: Meta;
};

export type Meta = CampaignsTableMeta &
    AudioPostGrowthCellMeta<TikTokPublicReportCampaignTableRow> &
    Pick<
        ProjectCellMeta<TikTokPublicReportCampaignTableRow>,
        'getIsBrandLoading' | 'getIsProjectLoading' | 'getIsTeamLoading'
    >;

const getTableMeta = getTableMetaHelper<Meta>();

const TikTokPublicReportCampaignTable = ({
    data,
    isLoading,
    hasError,
    expandedState,
    onExpandedChange,
    renderSubComponent,
    meta,
}: Props) => {
    const columns: ColumnDef<TikTokPublicReportCampaignTableRow, any>[] = [
        {
            header: '',
            id: 'expand',
            cell: ExpandCell,
        },
        {
            header: 'Project',
            id: 'project',
            accessorFn: (row): ProjectData => ({
                name: row.project?.name ?? '-',
                brandImage: row.brand?.image,
                brandName: row.brand?.name ?? '-',
                teamName: row.team?.name ?? '-',
            }),
            cell: ProjectCell,
        },
        {
            header: 'Campaign',
            accessorKey: 'name',
            meta: {
                className: styles.campaignColumn,
            },
            cell: ReportCampaignCell,
        },
        {
            header: 'Posts',
            id: 'posts',
            cell: ({ row: { original }, table }) => {
                const { isLoading, getIsCampaignStatsLoading } = getTableMeta(table);
                const stats = original.stats;

                if (isLoading || getIsCampaignStatsLoading?.(original)) {
                    return <Skeleton />;
                }

                if (!stats) {
                    return '-';
                }

                const { post_count: postCount, live_post_count: livePostCount } = stats;

                return postCount === 0 ? 0 : `${livePostCount}/${postCount}`;
            },
        },
        {
            header: 'Progress',
            id: 'progress',
            cell: ({ row: { original }, table }) => {
                const { isLoading, getIsCampaignStatsLoading } = getTableMeta(table);
                const stats = original.stats;

                if (isLoading || getIsCampaignStatsLoading?.(original)) {
                    return <Skeleton />;
                }

                if (!stats) {
                    return '-';
                }

                const { post_count: postCount, live_post_count: livePostCount } = stats;
                const progressPercentage = postCount ? (livePostCount / postCount) * 100 : null;

                return (
                    <div className={styles.progressContainer}>
                        <p className={styles.progressPercentage}>
                            {typeof progressPercentage === 'number' ? `${toDecimalPoint(progressPercentage, 2)}%` : '-'}
                        </p>
                        {typeof progressPercentage === 'number' && (
                            <ProgressBar
                                progress={progressPercentage}
                                trackClassName={styles.progressTrack}
                                barClassName={styles.progressBar}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            header: 'Views',
            id: 'views',
            accessorFn: (row) => (row.stats?.view_count ? numberWithCommas(row.stats.view_count) : '-'),
            cell: CampaignStatsCell,
        },
        {
            header: () => (
                <TooltipHeader header="ER" tooltipTitle="Engagement Rate" tooltipBody={tooltipCopy.engagementRate} />
            ),
            id: 'er',
            accessorFn: (row) =>
                typeof row.stats?.engagement_rate === 'number'
                    ? `${toDecimalPoint(row.stats.engagement_rate * 100, 2)}%`
                    : '-',
            cell: CampaignStatsCell,
        },
        {
            header: 'Duration',
            id: 'duration',
            cell: DurationCell,
        },
        {
            header: 'Audio',
            id: 'audio',
            accessorFn: (row) => ({
                timeSeries: row.audioTimeSeries,
                audioUrl: row.audio ? buildTiktokMusicUrl(row.audio.title, row.audio.tiktok_id) : undefined,
            }),
            meta: { className: styles.audioGraph },
            cell: AudioPostGrowthCell,
        },
    ];

    return (
        <CampaignsTable
            data={data}
            columns={columns}
            meta={meta}
            isLoading={isLoading}
            hasError={hasError}
            expandedState={expandedState}
            onExpandedChange={onExpandedChange}
            renderSubComponent={renderSubComponent}
            columnVisibility={{
                audio: data.some((d) => d.audioTimeSeries),
            }}
        />
    );
};

export default TikTokPublicReportCampaignTable;
