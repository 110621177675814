import { creatorbase, instagram } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import { getTableMetaHelper } from '@round/ui-kit';
import { getApplicationColors } from 'ui-new/whitelabel/utils/applicationStyles/applicationStyles';
import AudioGraph from 'Modules/Plans/components/AudioGraph/AudioGraph';

type Row = creatorbase.Campaign | creatorbase.PublicCampaign;
type TimeSeries =
    | instagram.TimeSeriesResponse<'posts_daily_change'>[number]
    | creatorbase.TimeSeriesResponse<'posts_daily_change'>[number]
    | null;

type Value<TSeries extends TimeSeries = TimeSeries> = {
    timeSeries: TSeries;
    audioUrl: string;
};

type Context<TRow extends Row = Row, TSeries extends TimeSeries = TimeSeries> = CellContext<TRow, Value<TSeries>>;
export type Meta<TRow extends Row = Row> = {
    isLoading?: boolean;
    getIsTimeSeriesLoading?: (row: TRow) => boolean;
    getTimeSeriesHasError?: (row: TRow) => boolean;
};

const getTableMeta = getTableMetaHelper<Meta>();

const AudioPostGrowthCell = <TRow extends Row = Row, TSeries extends TimeSeries = TimeSeries>({
    getValue,
    row: { original },
    table,
}: Context<TRow, TSeries>) => {
    const { isLoading, getIsTimeSeriesLoading, getTimeSeriesHasError } = getTableMeta(table);
    const isDataLoading = isLoading || !!getIsTimeSeriesLoading?.(original);
    const { timeSeries, audioUrl } = getValue();
    const timeSeriesData = timeSeries?.posts_daily_change ?? [];
    const shouldDisplayTimeSeriesData = !!timeSeriesData.length;
    const points = timeSeriesData.map((s) => ({ x: s.timestamp, y: s.value }));
    const lineColor =
        original.managed_by !== 'creatorbase' ? getApplicationColors(original.managed_by).highlight : undefined;

    return (
        <AudioGraph
            audioUrl={audioUrl}
            points={points}
            shouldDisplayTimeSeriesData={shouldDisplayTimeSeriesData}
            isTimeSeriesLoading={isDataLoading}
            lineColor={lineColor}
            hasError={getTimeSeriesHasError?.(original)}
        />
    );
};

export default AudioPostGrowthCell;
