import { ReactNode, useMemo, useState } from 'react';
import { Point } from '../types';
import { ChartProps, Line } from 'react-chartjs-2';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import tooltipStyles from 'ui-new/whitelabel/Tooltip/Tooltip.module.css';
import commonStyles from '../Charts.module.css';
import {
    calculateFractionalDigits,
    formatYAxisLabels,
    getExponent,
    getPointDataFromTooltip,
    TooltipData,
} from '../helpers';
import LoadingSpinner from 'SharedComponents/LoadingSpinner/LoadingSpinner';

export type DataSet = Omit<ChartProps<'line', Point[]>['data']['datasets'][number], 'tension' | 'borderWidth'> & {
    tension?: number;
    borderWidth?: number;
};

const dataSetDefaults: Pick<DataSet, 'tension' | 'borderWidth'> = {
    tension: 0.125,
    borderWidth: 4,
};

type Props = {
    dataSets: DataSet[];
    height?: number | string;
    width?: number | string;
    renderTooltip?: (data: TooltipData) => ReactNode;
    isLoading?: boolean;
    hasError?: boolean;
};

const TOOLTIP_WIDTH_IN_PX = 225;
const LineChart = ({ dataSets, height, width, renderTooltip, isLoading, hasError }: Props) => {
    const [tooltipParams, setTooltipParams] = useState<{ x: number; y: number; transformX: number } | null>(null);
    const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);

    const options = useMemo(() => {
        const yValues = dataSets.flatMap((set) => set.data).map((point) => point.y);
        const maxValue = Math.max(...yValues);
        const minValue = Math.min(...yValues);
        const minMaxDiff = maxValue - minValue;
        const stepSize =
            isFinite(minMaxDiff) && minMaxDiff >= 0 ? undefined : Math.pow(10, getExponent(yValues[0]) - 1);

        return {
            animation: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external({ tooltip, chart }) {
                        if (tooltip.opacity === 0) {
                            setTooltipParams(null);
                            setTooltipData(null);
                        } else {
                            const position = chart.canvas.getBoundingClientRect();
                            let left = position.left + tooltip.caretX;
                            let transform = -75;

                            setTooltipParams({
                                x: left,
                                y: position.top + tooltip.caretY,
                                transformX: transform,
                            });

                            if (Array.isArray(tooltip.dataPoints) && tooltip.dataPoints.length) {
                                setTooltipData(getPointDataFromTooltip(tooltip));
                            }
                        }
                    },
                },
            },
            elements: {
                point: {
                    hoverRadius: 6,
                    hitRadius: 4,
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        color: '#90A0B7',
                        stepSize,
                        callback: (value: number) => {
                            if (new Set(yValues).size < 2) {
                                return formatYAxisLabels(value, 1);
                            }
                            const labelsFD = calculateFractionalDigits(yValues);
                            return formatYAxisLabels(value, labelsFD);
                        },
                        precision: 0,
                    },
                    grid: {
                        display: true,
                        color: '#90A0B7',
                        drawTicks: false,
                    },
                    border: {
                        display: false,
                        color: '#90A0B7',
                        dash: [5, 5],
                    },
                },
                x: {
                    type: 'time',
                    ticks: {
                        color: '#90A0B7',
                        autoSkip: true,
                        count: 30,
                    },
                    time: {
                        unit: 'day',
                        displayFormats: {
                            day: 'MMM D',
                        },
                    },
                    grid: {
                        display: false,
                        color: '#90A0B7',
                    },
                },
            },
            maintainAspectRatio: false,
        } as ChartProps<'line', Point[]>['options'];
    }, [dataSets]);

    if (isLoading) {
        return <LoadingSpinner containerClassName={commonStyles.loadingSpinnerContainer} />;
    }

    if (hasError) {
        return (
            <div className={commonStyles.noDataContainer}>
                <p className={commonStyles.errorLabel}>Error loading data</p>
            </div>
        );
    }

    if (!dataSets || !dataSets.length) {
        return (
            <div className={commonStyles.noDataContainer}>
                <p className={commonStyles.noDataLabel}>No data available</p>
            </div>
        );
    }

    return (
        <>
            <Line
                height={height}
                width={width}
                data={{
                    datasets: dataSets.map((dataSet) => ({ ...dataSetDefaults, ...dataSet })),
                }}
                options={options}
            />
            {tooltipParams &&
                tooltipData &&
                typeof renderTooltip === 'function' &&
                createPortal(
                    <div
                        className={cn(tooltipStyles.tooltip, commonStyles.root)}
                        style={{
                            position: 'fixed',
                            zIndex: 20,
                            left: tooltipParams.x + 'px',
                            top: tooltipParams.y + 'px',
                            transform: `translate(${tooltipParams.transformX}%, -115%)`,
                            minWidth: TOOLTIP_WIDTH_IN_PX,
                            width: 'max-content',
                        }}
                    >
                        {renderTooltip(tooltipData)}
                    </div>,
                    document.body
                )}
        </>
    );
};

export default LineChart;
