import { Model } from 'miragejs';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { TiktokUserStatsCache } from './tiktokUserStatsCache.types';
import { ModelDefinition } from 'miragejs/-types';
import { AppServer, RouteHandlerContext } from '../../mirage';

export const models = {
    tiktokUserStatsCache: Model as ModelDefinition<TiktokUserStatsCache>,
};

export const serializers = {
    tiktokUserStatsCache: makeSerializer<TiktokUserStatsCache>([]),
};

export const factories = {
    tiktokUserStatsCache: createFactory<TiktokUserStatsCache>({
        avg_engagement_rate() {
            return parseFloat(Math.random().toFixed(6));
        },
        avg_post_views(index: number) {
            return index * 1000;
        },
        follower_count(index: number) {
            return index * 100;
        },
        location() {
            const locations = ['US', 'CA', 'GB'];
            return locations[Math.floor(Math.random() * locations.length)];
        },
        user_id(index: number) {
            return index;
        },
    }),
};

export function handleTiktokUserStatsCacheRequests(server: AppServer) {
    server.get(`/api/tiktok/user-stats-cache/`, function (this: RouteHandlerContext, schema, request) {
        const { user_id } = request.queryParams;
        const tiktokUserStatsCache = schema
            .all('tiktokUserStatsCache')
            .filter((cache) => cache.user_id === parseInt(user_id));
        return buildPaginatedResponse(tiktokUserStatsCache, {
            url: `/api/tiktok/user-stats-cache/`,
            serialize: (resource) => this.serialize(resource, 'tiktokUserStatsCache'),
            queryParams: request.queryParams,
        });
    });
}
