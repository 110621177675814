import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import { useCallback, useState } from 'react';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';

type State<TField extends creatorbase.TimeSeriesField = creatorbase.TimeSeriesField> = {
    [id: number]: DataState<creatorbase.TimeSeriesResponse<TField>[number]>;
};

const initialState: State = {};

export function useTimeSeries<TField extends creatorbase.TimeSeriesField = creatorbase.TimeSeriesField>() {
    const [state, setState] = useState<State<TField>>(initialState);

    const fetchData = useCallback(
        async (params: creatorbase.TimeSeriesRequestParams, signal?: AbortSignal) => {
            setState((prev) => ({ ...prev, ...makeLoadingStateSlice(params.ids) }));
            try {
                const response = await creatorbase.postTimeSeries(params, signal);

                if (response.status === 200) {
                    setState((prev) => ({
                        ...prev,
                        ...makeSuccessStateSlice(Object.keys(response.data), (id) => response.data[Number(id)]),
                    }));
                    return response;
                }

                setState((prev) => ({ ...prev, ...makeErrorStateSlice(params.ids, response.data.detail) }));
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setState((prev) => ({ ...prev, ...makeErrorStateSlice(params.ids, 'Could not get time series data') }));
                throw e;
            }
        },
        [setState]
    );

    const reset = useCallback(() => {
        setState(initialState);
    }, [setState]);

    return {
        state,
        fetchData,
        reset,
    };
}
