import { InstagramAudio, Release, TiktokAudio } from '@round/api';
import { buildTiktokMusicUrl, buildInstagramAudioUrl, formatNumberToKNotation } from '../../../../../helpers';

export const getPageText = (account: string, followers: number | undefined | null) => {
    const followersText = typeof followers === 'number' ? `(${formatNumberToKNotation(followers)})` : '';
    const accountText = account ? `@${account}` : '-';
    return `${accountText} ${followersText}`;
};

type BaseCopyBriefToClipboardParams = {
    release: Release;
    brief: string | null | undefined;
    turnaround: string | null | undefined;
};

type CopyTiktokBriefToClipboardParams = BaseCopyBriefToClipboardParams & {
    sound?: TiktokAudio | null;
};

type CopyInstagramBriefToClipboardParams = BaseCopyBriefToClipboardParams & {
    sound?: InstagramAudio | null;
};

const formatReleaseHeader = (release: Release) => {
    return `*${release.name} - ${release.brand.name}* 🎵\n`;
};
const formatBrief = (brief: string | null | undefined) => {
    return `*Brief:*\n${brief ? brief : ''}\n`;
};
const formatTurnaround = (turnaround: string | null | undefined) => {
    return `*Turnaround*:\n${turnaround ? turnaround : ''}`;
};

export const formatTiktokBriefDataToWhatsAppMarkdown = ({
    release,
    sound,
    brief,
    turnaround,
}: CopyTiktokBriefToClipboardParams) => {
    const releaseAndArtistHeader = formatReleaseHeader(release);
    const formattedBrief = formatBrief(brief);
    const formattedTurnaround = formatTurnaround(turnaround);

    const songUrl = sound
        ? `*Sound:*\n${buildTiktokMusicUrl(sound.title ?? 'original-sound', sound.tiktok_id ?? '')}\n`
        : '';

    return `${releaseAndArtistHeader}${songUrl}${formattedBrief}${formattedTurnaround}`;
};

export const formatInstagramBriefDataToWhatsAppMarkdown = ({
    release,
    sound,
    brief,
    turnaround,
}: CopyInstagramBriefToClipboardParams) => {
    const releaseAndArtistHeader = formatReleaseHeader(release);
    const formattedBrief = formatBrief(brief);
    const formattedTurnaround = formatTurnaround(turnaround);

    const songUrl = sound ? `*Sound:*\n${buildInstagramAudioUrl(sound.instagram_id)}\n` : '';

    return `${releaseAndArtistHeader}${songUrl}${formattedBrief}${formattedTurnaround}`;
};
