import { StylesConfig } from 'react-select';

export default {
    container: (provided: any) => ({
        ...provided,
        minWidth: '14rem',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        fontSize: '0.8125rem',
    }),
} as StylesConfig;
