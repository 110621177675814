import useAbortableEffect from 'Hooks/useAbortableEffect';
import useReports from './useReports';
import styles from './ReportsList.module.css';
import { ScrollView } from '@round/ui-kit';
import ReportsTable, { ReportsTableRow } from './ReportsTable/ReportsTable';
import useUrlState from 'Hooks/useUrlState';
import SearchInput from 'ui-new/whitelabel/SearchInput/SearchInput';
import { useMemo, useState } from 'react';
import Button from 'ui-new/whitelabel/Button/Button';
import CreateReportModal from '../CreateReportModal/CreateReportModal';
import { useNavigate } from 'react-router-dom';
import EditReportModal from '../EditReportModal/EditReportModal';
import { creatorbase } from '@round/api';
import { useCurrentCreatorbaseUser } from 'Modules/Auth/hooks/useCurrentCreatorbaseUser';
import Checkbox from 'ui-new/whitelabel/Checkbox/Checkbox';
import DeleteReportModal from '../DeleteReportModal/DeleteReportModal';

type UrlState = {
    search: string;
    //boolean string
    myReports: string;
};

const ReportsList = () => {
    const navigate = useNavigate();
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const [isCreateReportOpen, setIsCreateReportOpen] = useState(false);
    const [isUpdateReportOpen, setIsUpdateReportOpen] = useState(false);
    const [reportToUpdate, setReportToUpdate] = useState<creatorbase.Report | null>(null);
    const [isDeleteReportOpen, setIsDeleteReportOpen] = useState(false);
    const [reportToDelete, setReportToDelete] = useState<creatorbase.Report | null>(null);

    const [lastFetchedPage, setLastFetchedPage] = useState<number | undefined>();
    const [hasNextPage, setHasNextPage] = useState(false);
    const { data, status, fetchData, reset, createReport, updateReport, deleteReport } = useReports();
    const areReportsInitialized = status === 'success' || status === 'error';

    const { creatorbaseUserId } = useCurrentCreatorbaseUser();
    const isMyReportsFilterActive = urlState.myReports === 'true';
    const assigneeIdFilter = isMyReportsFilterActive && !!creatorbaseUserId ? creatorbaseUserId.toString() : undefined;

    useAbortableEffect(
        (signal) => {
            if (!areReportsInitialized && typeof lastFetchedPage !== 'number') {
                fetchData(
                    { search: urlState.search, page: 1, page_size: 25, assignee_ids: assigneeIdFilter },
                    { signal }
                )
                    .then((response) => {
                        if (response?.status === 200) {
                            setLastFetchedPage(1);
                            setHasNextPage(!!response.data.next);
                        }
                    })
                    .catch(() => {});
            }
        },
        [areReportsInitialized, fetchData, lastFetchedPage, urlState.search, assigneeIdFilter]
    );

    const rows: ReportsTableRow[] = useMemo(() => {
        return (
            data?.reports.map((report) => {
                return { ...report, assignees: data.assignees.filter((a) => report.assignee_ids.includes(a.id)) };
            }) ?? []
        );
    }, [data?.assignees, data?.reports]);

    const resetPage = () => {
        reset();
        setHasNextPage(false);
        setLastFetchedPage(undefined);
    };

    const handleToggleMyReportsFilter = () => {
        setUrlState({ myReports: isMyReportsFilterActive ? undefined : 'true' });
        resetPage();
    };

    return (
        <>
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1 className={styles.title}>Reports</h1>

                    <Button onClick={() => setIsCreateReportOpen(true)} appearance="primary">
                        Create Report
                    </Button>
                </header>

                <menu className={styles.menu}>
                    <SearchInput
                        className={styles.searchInput}
                        value={urlState.search}
                        onChange={(val) => {
                            setUrlState({ search: val });
                            resetPage();
                        }}
                    />

                    <div className={styles.myReportsFilter}>
                        <Checkbox value={isMyReportsFilterActive} onChange={handleToggleMyReportsFilter} />
                        <label onClick={handleToggleMyReportsFilter}>Only my reports</label>
                    </div>
                </menu>

                <ScrollView className={styles.tableContainer}>
                    <ReportsTable
                        data={rows}
                        hasError={status === 'error'}
                        isLoading={!areReportsInitialized}
                        onEditReport={(row) => {
                            setReportToUpdate(row);
                            setIsUpdateReportOpen(true);
                        }}
                        onDeleteReport={(row) => {
                            setReportToDelete(row);
                            setIsDeleteReportOpen(true);
                        }}
                        onEndReached={() => {
                            if (status === 'success' && hasNextPage) {
                                fetchData({
                                    page: (lastFetchedPage ?? 0) + 1,
                                    page_size: 25,
                                    search: urlState.search,
                                })
                                    .then((response) => {
                                        if (response?.status === 200) {
                                            setLastFetchedPage((page) => (page ?? 0) + 1);
                                            setHasNextPage(!!response.data.next);
                                        }
                                    })
                                    .catch(() => {});
                            }
                        }}
                    />
                </ScrollView>
            </div>

            <CreateReportModal
                isOpen={isCreateReportOpen}
                onClose={() => {
                    setIsCreateReportOpen(false);
                }}
                createReport={createReport}
                onCreated={(report) => {
                    // isEdit opens a project select modal
                    navigate(`${report.id}/?isEdit=true`);
                }}
            />

            <EditReportModal
                isOpen={isUpdateReportOpen}
                onClose={() => {
                    setIsUpdateReportOpen(false);
                }}
                report={reportToUpdate}
                updateReport={updateReport}
            />

            <DeleteReportModal
                isOpen={isDeleteReportOpen}
                onClose={() => {
                    setIsDeleteReportOpen(false);
                    setReportToDelete(null);
                }}
                report={reportToDelete}
                deleteReport={deleteReport}
            />
        </>
    );
};

export default ReportsList;
