import { combineReducers } from 'helpers';
import {
    reducer as publicReportReducer,
    initialState as publicReportInitialState,
} from 'Modules/Influencer/reducers/publicReportReducer';
import {
    reducer as aggregatedPostStatsByPlatformReducer,
    initialState as aggregatedPostStatsByPlatformInitialState,
} from 'Modules/Influencer/reducers/aggregatedPostStatsByPlatformReducer';
import {
    reducer as aggregatedPostStatsByCampaignIdReducer,
    initialState as aggregatedPostStatsByCampaignIdInitialState,
} from 'Modules/Influencer/reducers/aggregatedPostStatsByCampaignId';
import { reducer as campaignsReducer, initialState as campaignsInitialState } from './Campaigns/reducer';
import { makePostsReducer, initialState as postsInitialState } from '../Posts/reducers/postsByCampaign';
import {
    makeProjectsReducer,
    initialState as projectsInitialState,
} from 'Modules/Plans/Project/hooks/dataHooks/useProjectsDataHook';
import {
    reducer as tiktokAccountsReducer,
    initialState as tiktokAccountsInitialState,
} from 'Modules/TikTok/hooks/dataHooks/useTiktokAccountsDataHook';
import { creatorbase } from '@round/api';
import {
    makeBrandsReducer,
    initialState as brandsInitialState,
} from 'Modules/Plans/Brand/hooks/dataHooks/useBrandsDataHook';
import { reducer as teamReducer, initialState as teamInitialState } from '../Team/reducers/publicReducer';
import { makeTeamsReducer, initialState as teamsInitialState } from '../Team/hooks/dataHooks/useTeamsDataHook';
import {
    reducer as timeSeriesReducer,
    initialState as timeSeriesInitialState,
} from 'Modules/TikTok/hooks/dataHooks/useTiktokTimeSeriesDataHook';
import {
    reducer as tiktokAudiosReducer,
    initialState as tiktokAudiosInitialState,
} from 'Modules/TikTok/hooks/dataHooks/useTiktokAudiosDataHook';
import {
    reducer as instagramPostStatsReducer,
    initialState as instagramPostStatsInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramPostsDataHook';
import {
    reducer as youtubeChannelsReducer,
    initialState as youtubeChannelsInitialState,
} from 'Modules/Youtube/hooks/dataHooks/useYoutubeChannelsDataHook';
import {
    reducer as instagramUserImagesReducer,
    initialState as instagramUserImagesInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramUserImagesDataHook';
import {
    reducer as instagramUsersReducer,
    initialState as instagramUsersInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramUsersDataHook';
import {
    reducer as instagramUserStatsReducer,
    initialState as instagramUserStatsInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramUserStatsDataHook';
import {
    reducer as instagramTimeSeriesReducer,
    initialState as instagramTimeSeriesInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramTimeSeries';
import {
    reducer as instagramAudiosReducer,
    initialState as instagramAudiosInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramAudiosByIdDataHook';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

const postsReducer = makePostsReducer<creatorbase.PublicPost>();
const projectsReducer = makeProjectsReducer<creatorbase.PublicProject>();
const brandsReducer = makeBrandsReducer<creatorbase.PublicBrand>();
const teamsReducer = makeTeamsReducer<creatorbase.PublicTeam>();

export const initialState: State = {
    publicReport: publicReportInitialState,
    platformHeadlineStats: aggregatedPostStatsByPlatformInitialState,
    campaignStats: aggregatedPostStatsByCampaignIdInitialState,
    campaigns: campaignsInitialState,
    projects: projectsInitialState,
    brands: brandsInitialState,
    teams: teamsInitialState,
    posts: postsInitialState,
    team: teamInitialState,
    tiktok: {
        accounts: tiktokAccountsInitialState,
        timeSeries: timeSeriesInitialState,
        audios: tiktokAudiosInitialState,
    },
    instagram: {
        postStats: instagramPostStatsInitialState,
        userImages: instagramUserImagesInitialState,
        users: instagramUsersInitialState,
        userStats: instagramUserStatsInitialState,
        audios: instagramAudiosInitialState,
        timeSeries: instagramTimeSeriesInitialState,
    },
    youtube: {
        channels: youtubeChannelsInitialState,
    },
};

export const reducer = combineReducers({
    publicReport: publicReportReducer,
    platformHeadlineStats: aggregatedPostStatsByPlatformReducer,
    campaignStats: aggregatedPostStatsByCampaignIdReducer,
    campaigns: campaignsReducer,
    projects: projectsReducer,
    brands: brandsReducer,
    teams: teamsReducer,
    posts: postsReducer,
    team: teamReducer,
    tiktok: combineReducers({
        accounts: tiktokAccountsReducer,
        timeSeries: timeSeriesReducer,
        audios: tiktokAudiosReducer,
    }),
    instagram: combineReducers({
        postStats: instagramPostStatsReducer,
        userImages: instagramUserImagesReducer,
        users: instagramUsersReducer,
        userStats: instagramUserStatsReducer,
        audios: instagramAudiosReducer,
        timeSeries: instagramTimeSeriesReducer,
    }),
    youtube: combineReducers({
        channels: youtubeChannelsReducer,
    }),
});
