import { call, encodeUrlSearchParams } from '../../helpers';
import { isPublicReportRequestInit } from '../../influencer';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { InstagramPost } from './posts.types';

export type GetInstagramPostsSortableKeys = keyof Pick<
    InstagramPost,
    'like_count' | 'comment_count' | 'play_count' | 'view_count' | 'owner_follower_count'
>;

export type GetInstagramPostsParams = Partial<
    PaginatedRequest & {
        id: string;
        audio_id: string;
        ordering: OrderingValues<GetInstagramPostsSortableKeys>;
    }
>;

export async function getInstagramPosts(
    params: GetInstagramPostsParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<InstagramPost>, 200> | NotFoundResponse | ForbiddenResponse> {
    const response = await call(`/api/instagram/posts/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch instagram posts`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
