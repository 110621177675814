import { AppServer } from '../mirage';
import * as influencerUserMirage from './influencerUser/instagramInfluencerUser.mirage';
import * as instagramInfluencerUserStatsMirage from './influencerUserStats/instagramInfluencerUserStats.mirage';
import * as instagramUserImageMirage from './userImage/instagramUserImage.mirage';
import * as instagramTopVideoMirage from './topVideo/instagramTopVideo.mirage';
import * as instagramAudioMirage from './audios/audios.mirage';
import * as instagramAudioStatsMirage from './audioStats/audioStats.mirage';
import * as instagramPostsMirage from './posts/posts.mirage';
import * as instagramUserStatsCache from './userStatsCache/instagramUserStatsCache.mirage';
import * as instagramTimeSeriesMirage from './timeSeries/timeSeries.mirage';

export const models = {
    ...influencerUserMirage.models,
    ...instagramInfluencerUserStatsMirage.models,
    ...instagramUserImageMirage.models,
    ...instagramTopVideoMirage.models,
    ...instagramAudioMirage.models,
    ...instagramAudioStatsMirage.models,
    ...instagramPostsMirage.models,
    ...instagramUserStatsCache.models,
    ...instagramTimeSeriesMirage.models,
};

export const serializers = {
    ...influencerUserMirage.serializers,
    ...instagramInfluencerUserStatsMirage.serializers,
    ...instagramUserImageMirage.serializers,
    ...instagramTopVideoMirage.serializers,
    ...instagramAudioMirage.serializers,
    ...instagramAudioStatsMirage.serializers,
    ...instagramPostsMirage.serializers,
    ...instagramUserStatsCache.serializers,
    ...instagramTimeSeriesMirage.serializer,
};

export const factories = {
    ...influencerUserMirage.factories,
    ...instagramInfluencerUserStatsMirage.factories,
    ...instagramUserImageMirage.factories,
    ...instagramTopVideoMirage.factories,
    ...instagramAudioMirage.factories,
    ...instagramAudioStatsMirage.factories,
    ...instagramPostsMirage.factories,
    ...instagramUserStatsCache.factories,
    ...instagramTimeSeriesMirage.factories,
};

export function handleInstagramRequests(server: AppServer) {
    influencerUserMirage.handleInstagramInfluencerUserRequests(server);
    instagramInfluencerUserStatsMirage.handleInstagramInfluencerUserRequests(server);
    instagramUserImageMirage.handleInstagramUserImageRequests(server);
    instagramTopVideoMirage.handleInstagramTopVideoRequests(server);
    instagramAudioMirage.handleRequests(server);
    instagramAudioStatsMirage.handleRequests(server);
    instagramPostsMirage.handleRequests(server);
    instagramUserStatsCache.handleInstagramUserStatsCacheRequests(server);
    instagramTimeSeriesMirage.handleRequests(server);
}
