import { ApiResponse, ForbiddenResponse, NotFoundResponse } from '../../types';
import { ReleaseStats } from './releaseStats.types';
import { call } from '../../helpers';

type GetReleaseStatsResponse =
    | ApiResponse<ReleaseStats, 200>
    | ApiResponse<string[], 400>
    | NotFoundResponse
    | ForbiddenResponse;

export async function getReleaseStats(releaseId: number, requestInit?: RequestInit): Promise<GetReleaseStatsResponse> {
    const response = await call(`/api/microwave/releases/${releaseId}/stats/`, requestInit);

    if (response.status === 400 || response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get release stats');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
