import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
} from '../../types';
import { TikTokInfluencerPlanHashtag } from './tiktokInfluencerPlanHashtag.types';
import { call, encodeUrlSearchParams } from '../../helpers';

export type GetTikTokInfluencerPlanHashtagsParams = Partial<{
    plan_id: number;
}>;

export type GetTikTokInfluencerPlanHashtagsResponse =
    | ApiResponse<PaginatedApiResponseData<TikTokInfluencerPlanHashtag>, 200>
    | NotFoundResponse
    | ForbiddenResponse;

export async function getTikTokInfluencerPlanHashtags(
    params: GetTikTokInfluencerPlanHashtagsParams,
    requestInit?: RequestInit
): Promise<GetTikTokInfluencerPlanHashtagsResponse> {
    const response = await call(
        `/api/advertising/viewsets/tiktok-influencer-plan-hashtag/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch tiktok influencer plan hashtags');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostTikTokInfluencerPlanHashtagData = {
    plan: number;
    hashtag: string;
};

export type PostTikTokInfluencerPlanHashtagResponse =
    | ApiResponse<TikTokInfluencerPlanHashtag, 201>
    | ApiResponse<ApiResponseError<PostTikTokInfluencerPlanHashtagData>, 400>
    | ForbiddenResponse;

export async function postTikTokInfluencerPlanHashtag(
    data: PostTikTokInfluencerPlanHashtagData
): Promise<PostTikTokInfluencerPlanHashtagResponse> {
    const response = await call('/api/advertising/viewsets/tiktok-influencer-plan-hashtag/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create tiktok influencer plan hashtag');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type DeleteTikTokInfluencerPlanHashtagResponse = ApiResponse<null, 204> | NotFoundResponse | ForbiddenResponse;
export async function deleteTikTokInfluencerPlanHashtag(
    id: number
): Promise<DeleteTikTokInfluencerPlanHashtagResponse> {
    const response = await call(`/api/advertising/viewsets/tiktok-influencer-plan-hashtag/${id}/`, {
        method: 'DELETE',
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete tiktok influencer plan hashtag');
    }

    return {
        status: 204,
        data: null,
    };
}
