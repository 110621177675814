import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import {
    Song,
    SongApiModel,
    SongAudioDataRefreshRequest,
    SongTiktokAudioPostStats,
    SongTiktokSearchTerm,
    SongTiktokStats,
} from './song.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { GetSongAudioDataRefreshRequestsParams, GetSongTiktokStatsParams } from './song.api';

export const models = {
    song: Model as ModelDefinition<Song>,
    songTiktokStat: Model.extend({
        song: belongsTo('song'),
    }) as ModelDefinition<SongTiktokStats>,
    songTiktokAudioPostStat: Model as ModelDefinition<SongTiktokAudioPostStats>,
    songAudioDataRefreshRequest: Model as ModelDefinition<SongAudioDataRefreshRequest>,
    songTiktokSearchTerm: Model as ModelDefinition<SongTiktokSearchTerm>,
};

export const factories = {
    song: createFactory<Song>({
        artists() {
            return [];
        },
        image(index: number) {
            return `Song image: ${index}`;
        },
        title(index: number) {
            return `Song title: ${index}`;
        },
        soundcloud_url(index: number) {
            return `Song soundcloud url: ${index}`;
        },
        spotify_song_url(index: number) {
            return `spotify song url: ${index}`;
        },
        tiktok_audios() {
            return [];
        },
        instagram_audios: [],
        spotify_song() {
            return null;
        },
    }),
    songTiktokStat: createFactory<SongTiktokStats>({
        audio_count(index: number) {
            return index;
        },
        total_video_count(index: number) {
            return index;
        },
        total_video_count_daily_change(index: number) {
            return index;
        },
        total_video_count_daily_change_relative(index: number) {
            return `0.${index}`;
        },
        total_video_count_weekly_change(index: number) {
            return index;
        },
        total_video_count_weekly_change_relative(index: number) {
            return `0.${index}`;
        },
        standardised_data() {
            return {
                daily_total_video_counts: [],
            };
        },
        song_collection_ids() {
            return [];
        },
        afterCreate(stats, s) {
            const server = s as AppServer;
            if (!stats.song) {
                stats.update({
                    song: server.create('song'),
                });
            }
        },
    }),
    songTiktokAudioPostStat: createFactory<SongTiktokAudioPostStats>({
        audio_id(index: number) {
            return index;
        },
        audio_tiktok_id(index: number) {
            return `audio tiktok id ${index}`;
        },
        post_id(index: number) {
            return index;
        },
        post_tiktok_id(index: number) {
            return `post tiktok id ${index}`;
        },
        audio_title(index: number) {
            return `audio title ${index}`;
        },
        audio_alternate_name() {
            return '';
        },
        audio_is_original() {
            return false;
        },
        author_name(index: number) {
            return `author name ${index}`;
        },
        audio_author_name(index: number) {
            return `audio author name ${index}`;
        },
        daily_change_absolute() {
            return null;
        },
        author_id(index: number) {
            return index;
        },
        author_follower_count() {
            return 0;
        },
        caption(index: number) {
            return `caption: ${index}`;
        },
        author_username(index: number) {
            return `author username ${index}`;
        },
        create_time() {
            return new Date().getTime() / 1000;
        },
        daily_change_relative() {
            return null;
        },
        weekly_change_absolute() {
            return null;
        },
        daily_change_is_estimate() {
            return false;
        },
        play_count() {
            return 0;
        },
        weekly_change_relative() {
            return null;
        },
        share_count() {
            return 0;
        },
        weekly_change_is_estimate() {
            return false;
        },
    }),
    songAudioDataRefreshRequest: createFactory<SongAudioDataRefreshRequest>({
        song(index: number) {
            return index;
        },
        timestamp() {
            return new Date().toISOString();
        },
    }),
    songTiktokSearchTerm: createFactory<SongTiktokSearchTerm>({
        song(index: number) {
            return index;
        },
        search_term(index: number) {
            return `search term: ${index}`;
        },
    }),
};

export const serializers = {
    song: makeSerializer<Song>([]),
    songTiktokStat: makeSerializer<SongTiktokStats>(['song']),
    songTiktokAudioPostStat: makeSerializer<SongTiktokAudioPostStats>([]),
    songAudioDataRefreshRequest: makeSerializer<SongAudioDataRefreshRequest>([]),
    songTiktokSearchTerm: makeSerializer<SongTiktokSearchTerm>([]),
};

export function handleSongRequests(server: AppServer) {
    server.post('/api/music/viewsets/song/create-from-spotify/', function (this: RouteHandlerContext, schema, request) {
        const { spotify_identifier } = JSON.parse(request.requestBody);
        const song = schema.findBy('song', {
            spotify_song_url: `https://open.spotify.com/track/${spotify_identifier}`,
        });

        if (song) {
            return new Response(400, {}, { error: 'Song already exists' });
        }

        schema.create('song', {
            spotify_song_url: `https://open.spotify.com/track/${spotify_identifier}`,
        });
        return schema.findBy('song', {
            spotify_song_url: `https://open.spotify.com/track/${spotify_identifier}`,
        });
    });

    server.get('/api/music/viewsets/song/:id/', function (this: RouteHandlerContext, schema, request) {
        const { id } = request.params;
        if (!id) {
            return new Response(400);
        }

        const song = schema.find('song', id);
        if (!song) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return song;
    });

    const getSongsPath = '/api/music/viewsets/songs/';
    server.get(getSongsPath, function (this: RouteHandlerContext, schema, request) {
        const tiktokAudioIds = (request.queryParams.tiktok_audios ?? '')
            .split(',')
            .filter((item) => item.length > 0)
            .map((audioId) => Number(audioId));

        const songs = schema
            .all('song')
            .filter(
                (song) =>
                    !request.queryParams.search ||
                    song.title.toLowerCase().includes(request.queryParams.search.toLowerCase())
            )
            .filter((song) => {
                const hasQueriedTiktokAudios =
                    song.tiktok_audios.filter((audioId) => tiktokAudioIds.includes(Number(audioId))).length > 0;

                return tiktokAudioIds.length === 0 || hasQueriedTiktokAudios;
            });

        return buildPaginatedResponse(songs, {
            url: getSongsPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'song'),
        });
    });

    server.post('/api/music/viewsets/song/', function (this: RouteHandlerContext, schema, request) {
        const body = JSON.parse(request.requestBody) as SongApiModel;
        const song = schema.create('song', {
            ...body,
            tiktok_audios: [],
            artists: schema
                .all('artist')
                .filter((artist) => body.artist_ids.includes(Number(artist.id)))
                .models.map((artist) => ({
                    id: Number(artist.id),
                    name: artist.name,
                })),
        });

        return song;
    });

    server.patch('/api/music/viewsets/song/:id/', function (this: RouteHandlerContext, schema, request) {
        const { id } = request.params;
        const song = schema.find('song', id);
        if (!song) {
            return new Response(404);
        }

        const body = JSON.parse(request.requestBody);

        song.update({
            ...body,
            artists: schema
                .all('artist')
                .filter((artist) => body.artist_ids.includes(Number(artist.id)))
                .models.map((artist) => ({
                    id: Number(artist.id),
                    name: artist.name,
                })),
        });

        return song;
    });

    server.post(
        '/api/music/viewsets/song/:id/remove-tiktok-audio/',
        function (this: RouteHandlerContext, schema, request) {
            const song = schema.find('song', request.params.id);
            if (!song) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            return new Response(204);
        }
    );

    const getSongTiktokStatsPath = '/api/music/viewsets/song-tiktok-stats/';
    server.get(getSongTiktokStatsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '', ordering = '' } = request.queryParams as GetSongTiktokStatsParams;
        const stats = schema
            .all('songTiktokStat')
            .filter((stats) => !search || stats.song.title.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
                if (!ordering) {
                    return 0;
                }

                if (ordering.startsWith('-')) {
                    const key = ordering.slice(1) as keyof SongTiktokStats;
                    return (b[key] as number) - (a[key] as number);
                }

                const key = ordering as keyof SongTiktokStats;
                return (a[key] as number) - (b[key] as number);
            });

        return buildPaginatedResponse(stats, {
            url: getSongTiktokStatsPath,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'songTiktokStat'),
        });
    });

    server.get('/api/music/viewsets/song-tiktok-stats/:id/', function (this: RouteHandlerContext, schema, request) {
        const stats = schema
            .all('songTiktokStat')
            .filter((stats) => Number(stats.song.id) === Number(request.params.id));

        if (!stats.length) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return stats.models[0];
    });

    const getSongTiktokAudioPostStatsPath = '/api/music/viewsets/song/:id/tiktok-audio-post-stats/';
    server.get(getSongTiktokAudioPostStatsPath, function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const stats = schema
            .all('songTiktokAudioPostStat')
            .filter((s) => !search || s.audio_title.toLowerCase().includes(search.toLowerCase()));

        return buildPaginatedResponse(stats, {
            url: getSongTiktokAudioPostStatsPath,
            serialize: (resource) => this.serialize(resource, 'songTiktokAudioPostStat'),
            queryParams: request.queryParams,
        });
    });

    server.post(
        '/api/music/viewsets/song/:songId/refresh-audio-data/',
        function (this: RouteHandlerContext, schema, request) {
            const { songId } = request.params;
            const song = schema.find('song', songId);
            if (!song) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            return new Response(200);
        }
    );

    const getSongAudioDataRefreshRequestsListPath = '/api/music/song-audio-data-refresh-requests/';
    server.get(getSongAudioDataRefreshRequestsListPath, function (this: RouteHandlerContext, schema, request) {
        const { song_id } = request.queryParams as GetSongAudioDataRefreshRequestsParams;

        const requests = schema
            .all('songAudioDataRefreshRequest')
            .filter((request) => !song_id || Number(request.song) === Number(song_id));

        return buildPaginatedResponse(requests, {
            url: getSongAudioDataRefreshRequestsListPath,
            serialize: (resource) => this.serialize(resource, 'songAudioDataRefreshRequest'),
            queryParams: request.queryParams,
        });
    });

    const getSongTiktokSearchTermsListPath = '/api/music/viewsets/song/:songId/tiktok-search-term/';
    server.get(getSongTiktokSearchTermsListPath, function (this: RouteHandlerContext, schema, request) {
        const song = schema.find('song', request.params.songId);
        if (!song) {
            return new Response(404, {}, { detail: 'Not found' });
        }

        const searchTerms = schema.all('songTiktokSearchTerm').filter((term) => Number(term.song) === Number(song.id));

        return buildPaginatedResponse(searchTerms, {
            url: getSongTiktokSearchTermsListPath,
            serialize: (resource) => this.serialize(resource, 'songTiktokSearchTerm'),
            queryParams: request.queryParams,
        });
    });

    server.get('/api/music/viewsets/song/:songId/latest-tiktok-search-log/', function () {
        return { created: new Date().toISOString() };
    });

    server.post(
        '/api/music/viewsets/song/:songId/refresh-from-spotify/',
        function (this: RouteHandlerContext, schema, request) {
            const song = schema.find('song', request.params.songId);
            if (!song) {
                return new Response(404, {}, { detail: 'Not found.' });
            }

            const { spotify_identifier = '' } = JSON.parse(request.requestBody);
            if (spotify_identifier && typeof song.spotify_song === 'number') {
                return new Response(400, {}, { error: 'This song has already been associated with another song.' });
            }

            return song;
        }
    );
}
