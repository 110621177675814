import { AppServer, RouteHandlerContext } from '../../../mirage';
import { buildPaginatedResponse } from '../../../mirage.helpers';
import { Response } from 'miragejs';

export function handleRequests(server: AppServer) {
    server.get('/api/apex/finance/managers/', function (this: RouteHandlerContext, schema, request) {
        const managers = schema.all('apexManager');
        return buildPaginatedResponse(managers, {
            url: '/api/apex/finance/manangers/',
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(managers, 'apexManager'),
        });
    });

    server.get(`/api/apex/finance/managers/:id/`, function (this: RouteHandlerContext, schema, request) {
        const manager = schema.find('apexManager', request.params.id);
        if (!manager) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return manager;
    });
}
