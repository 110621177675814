import cn from 'classnames';
import 'flag-icons/css/flag-icons.min.css';
import { ReactComponent as EmptyFlagIcon } from '../../../assets/whitelabel/EmptyLocationFlag.svg';

export type LocationFlagIconProps = {
    locationCode: string | null;
    isSquare?: boolean;
    className?: string;
};

const LocationFlagIcon = ({ locationCode, isSquare, className }: LocationFlagIconProps) => {
    return locationCode ? (
        <div className={cn(`fi fi-${locationCode.toLowerCase()}${isSquare ? ' fis' : ''}`, className)}></div>
    ) : (
        <EmptyFlagIcon />
    );
};

export default LocationFlagIcon;
