import { ApiResponse, NotFoundResponse } from '../../types';
import {
    InstagramInfluencerPlanStats,
    TiktokInfluencerPlanStats,
    YoutubeInfluencerPlanStats,
} from './influencerPlan.types';
import { fetchWithToken } from '../../helpers';

export async function getTiktokInfluencerPlanStats(
    influencerPlanId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<TiktokInfluencerPlanStats, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/influencer-plan/${influencerPlanId}/tiktok-live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get influencer plan ${influencerPlanId} stats.`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInstagramInfluencerPlanStats(
    influencerPlanId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<InstagramInfluencerPlanStats, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/influencer-plan/${influencerPlanId}/instagram-live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get influencer plan ${influencerPlanId} instagram stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getYoutubeInfluencerPlanStats(
    influencerPlanId: number,
    requestInit?: RequestInit
): Promise<ApiResponse<YoutubeInfluencerPlanStats, 200> | NotFoundResponse> {
    const response = await fetchWithToken(
        `/api/advertising/influencer-plan/${influencerPlanId}/youtube-live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get influencer plan youtube stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
