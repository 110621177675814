import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import cn from 'classnames';
import styles from './ExpandOrDragCell.module.css';
import { ReactComponent as ChevronRightIcon } from 'assets/whitelabel/ChevronRight.svg';
import { ReactComponent as DragIcon } from 'assets/whitelabel/Drag.svg';
import { ReactComponent as DragDotsIcon } from 'assets/whitelabel/DragDots.svg';
import useNonNullContext from 'Hooks/useNonNullContext';
import { DraggableContext } from 'ui-new/whitelabel/Table/DragAndDropTable/DragAndDropTable';
import { ButtonShell, getTableMetaHelper } from '@round/ui-kit';

type Row = Pick<creatorbase.Campaign, 'id'>;
type Context<TRow extends Row> = CellContext<TRow, TRow['id']>;

type Meta = {
    isLoading?: boolean;
};

const ExpandOrDragCell = <TRow extends Row, TMeta extends Meta>({ row, table }: Context<TRow>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading } = getTableMeta(table);

    const { provided, snapshot, isDragActive } = useNonNullContext(DraggableContext);

    const onMouseDownBeforeDrag = (e: React.MouseEvent | React.TouchEvent) => {
        //return on right click
        if ('button' in e && e.button === 2) {
            return;
        }

        const container = document.querySelector('[data-draggable-container="true"]');
        if (!container || !(container instanceof HTMLElement)) {
            return;
        }

        // Freeze the height of the container to prevent it from reducing when we collapse rows
        // and causing displacement of the dragged row. This is undone in the onDragEnd event in
        // DragAndDropCampaignsTable.tsx
        const pageHeight = container.offsetHeight;
        container.style.minHeight = `${pageHeight}px`;

        // Set the current row and rows below it to be collapsed because they cause visual issues when dragged over.
        // We cannot do the same to rows above the current row because they would move the rows below them up when they collapse,
        // so they are handled differently in DragAndDropCampaignsTable.tsx
        const rows = table.getRowModel().rows;
        const currentRowIndex = rows.findIndex((r) => r.id === row.id);
        const rowsWithoutPrevious = rows.slice(currentRowIndex);

        table.setExpanded((prevState) => ({
            ...(typeof prevState === 'boolean' ? {} : prevState),
            ...rowsWithoutPrevious.reduce((acc, row) => {
                acc[row.id] = false;
                return acc;
            }, {} as Record<string, boolean>),
        }));
    };

    return (
        <div className={styles.container}>
            {table.getRowModel().rows.length > 1 && (
                <div
                    {...provided.dragHandleProps}
                    className={styles.mobileDragHandle}
                    onPointerDown={onMouseDownBeforeDrag}
                    data-drag-handle
                >
                    <DragDotsIcon data-drag-handle />
                </div>
            )}

            <ButtonShell
                onClick={(e) => {
                    //since the drag handle is a child of the button, we should ignore clicks on it
                    if (e.target instanceof Element && e.target.hasAttribute('data-drag-handle')) {
                        return;
                    }

                    row.toggleExpanded();
                }}
                className={cn(styles.expandButton, { [styles.loading]: isLoading })}
            >
                <ChevronRightIcon className={cn({ [styles.expandIconExpanded]: row.getIsExpanded() })} />
                {table.getRowModel().rows.length > 1 && !isLoading && (
                    <div
                        className={cn(styles.dragHandle, {
                            [styles.isDragging]: snapshot.isDragging,
                            [styles.isOtherDragging]: isDragActive && !snapshot.isDragging,
                        })}
                        {...provided.dragHandleProps}
                        onMouseDown={onMouseDownBeforeDrag}
                        data-drag-handle
                    >
                        <DragIcon className={styles.dragIcon} data-drag-handle />
                    </div>
                )}
            </ButtonShell>
        </div>
    );
};

export default ExpandOrDragCell;
