import { InstagramAudio, microwave, users } from '@round/api';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import {
    asMoney,
    buildInstagramAudioUrl,
    formatNumberToKNotation,
    openInNewTab,
    roundTo2Dp,
    showNotification,
} from 'helpers';
import useNonNullContext from 'Hooks/useNonNullContext';
import { CampaignStats } from '../../helpers';
import styles from './Campaign.module.css';
import cn from 'classnames';
import { ProgressBar, SkeletonizedValue } from '@round/ui-kit';
import { ReactComponent as ArrowIcon } from 'assets/ArrowIcon.svg';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { ReactNode, useState } from 'react';
import { AccordionTabNav } from 'ui/AccordionTabs/AccordionTabNav/AccordionTabNav';
import { AccordionTabNavItem } from 'ui/AccordionTabs/AccordionTabNavItem/AccordionTabNavItem';
import EmailConfigModal from '../../EmailConfig/EmailConfigModal';
import { ReactComponent as EmailEditIcon } from 'assets/icons/EmailEdit.svg';
import useCampaigns from '../useCampaigns';
import { useCounts } from '../../Invites/Counts/useCounts';
import CountBadge from 'ui-new/Badges/CountBadge/CountBadge';
import Badge from 'ui-new/Badges/Badge/Badge';
import { ReactComponent as MusicBoldIcon } from 'assets/icons/MusicBold.svg';
import copyToClipboard from 'copy-to-clipboard';

type Props = {
    campaign: microwave.Campaign;
    stats: CampaignStats | undefined;
    accountTeam: users.UserPlanner[];
    audio: InstagramAudio | null | undefined;
    isAccountTeamLoading?: boolean;
    className?: string;
    isExpanded?: boolean;
    onExpand?: () => void;
    children?: ReactNode;
};

const Campaign = ({
    campaign,
    stats,
    className,
    isExpanded,
    onExpand,
    children,
    accountTeam,
    isAccountTeamLoading,
    audio,
}: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const [isEmailConfigModalOpen, setIsEmailConfigModalOpen] = useState(false);
    const { updateCampaign } = useCampaigns({ isImperative: true });

    const { data: counts, status: countsStatus } = useCounts({ campaignId: campaign.id });
    const areCountsLoading = countsStatus !== 'success' && countsStatus !== 'error';

    const budget = campaign.budget;
    const amountSpent = stats?.amountSpent ?? 0;
    const amountSpentPercentage = roundTo2Dp((amountSpent / budget) * 100);
    const audioLabel = audio?.title ?? audio?.instagram_id;
    const audioUrl = audio?.instagram_id ? buildInstagramAudioUrl(audio.instagram_id) : null;

    return (
        <div className={cn(styles.container, className, { [styles.isExpanded]: isExpanded })}>
            <div className={styles.campaignCard}>
                <div className={styles.header}>
                    <p className={styles.title}>{campaign.name}</p>

                    <div className={styles.headerActions}>
                        <IconButton onClick={() => setIsEmailConfigModalOpen(true)}>
                            <EmailEditIcon />
                        </IconButton>
                        {audioLabel && (
                            <Badge
                                isInteractive={!!audio?.instagram_id}
                                onClick={() => {
                                    if (!audioUrl) {
                                        return;
                                    }

                                    const copied = copyToClipboard(audioUrl);
                                    if (copied) {
                                        showNotification('Audio url copied', 'info');
                                    }
                                }}
                                onAuxClick={(e) => {
                                    // on middle-click
                                    if (e.button === 1 && audioUrl) {
                                        openInNewTab(audioUrl);
                                    }
                                }}
                            >
                                <MusicBoldIcon className={styles.audioIcon} /> {audioLabel}
                            </Badge>
                        )}
                    </div>
                </div>

                <div className={styles.info}>
                    <div className={styles.infoColumn}>
                        <div className={styles.amountSpentValues}>
                            <span>
                                {stats?.amountSpent
                                    ? asMoney(
                                          stats?.amountSpent,
                                          currencies.find((c) => c.id === campaign.currency_id)
                                      )
                                    : '-'}
                            </span>

                            <span>{amountSpentPercentage}%</span>
                        </div>

                        <ProgressBar
                            progress={amountSpentPercentage}
                            barClassName={styles.progressBarThumb}
                            trackClassName={styles.progressBarTrack}
                        />
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnLabel}>Total posts</span>
                        <span className={styles.infoColumnValue}>
                            {stats ? `${stats.postCount}/${campaign.target_post_count}` : '-'}
                        </span>
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnLabel}>Total views</span>
                        <span className={styles.infoColumnValue}>
                            {stats ? formatNumberToKNotation(stats.totalViews) : '-'}
                        </span>
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnLabel}>Engagement Rate</span>
                        <span className={styles.infoColumnValue}>
                            {stats ? `${roundTo2Dp(stats.engagementRate * 100)}%` : '-'}
                        </span>
                    </div>

                    <div className={styles.infoColumn}>
                        <span className={styles.infoColumnLabel}>Account Team</span>
                        <span className={styles.infoColumnValue}>
                            <SkeletonizedValue isInitialized={!isAccountTeamLoading} width="7rem">
                                {accountTeam.length
                                    ? accountTeam.map((u) => `${u.first_name} ${u.last_name}`).join(', ')
                                    : '-'}
                            </SkeletonizedValue>
                        </span>
                    </div>
                </div>

                <div className={styles.actionsRow}>
                    <AccordionTabNav className={styles.tabNav} label="Plans">
                        <AccordionTabNavItem name="creators">Creators</AccordionTabNavItem>
                        <AccordionTabNavItem name="contacted">
                            Contacted <CountBadge count={counts?.contacted ?? '-'} isLoading={areCountsLoading} />
                        </AccordionTabNavItem>
                        <AccordionTabNavItem name="posted">
                            Posted <CountBadge count={counts?.posted ?? '-'} isLoading={areCountsLoading} />
                        </AccordionTabNavItem>
                    </AccordionTabNav>

                    <IconButton onClick={() => onExpand?.()}>
                        <ArrowIcon className={cn(styles.expandIcon, { [styles.isExpanded]: isExpanded })} />
                    </IconButton>
                </div>
            </div>

            {isExpanded && children}

            <EmailConfigModal
                isOpen={isEmailConfigModalOpen}
                onClose={() => setIsEmailConfigModalOpen(false)}
                campaign={campaign}
                updateCampaign={updateCampaign}
            />
        </div>
    );
};

export default Campaign;
