import { InstagramAudio } from '@round/api';
import { ButtonShell, getTableMetaHelper, ScrollView, Skeleton, TableProps } from '@round/ui-kit';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Table } from '@round/ui-kit';
import styles from '../../AudiosTable.module.css';
import { ReactComponent as CrossIcon } from 'assets/whitelabel/Cross.svg';
import { ReactComponent as InfoIcon } from 'assets/InfoCircle.svg';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { formatNumberToKNotation } from 'helpers';
import cn from 'classnames';

export type InstagramAudiosTableRow = InstagramAudio & {
    isStatic: boolean;
};

type Props = Pick<TableProps<InstagramAudiosTableRow>, 'data'> & {
    onRemoveAudio: (audioId: number) => void;
    isLoading?: boolean;
    hasError?: boolean;
};

type Meta = Pick<Props, 'onRemoveAudio' | 'isLoading'>;

const getTableMeta = getTableMetaHelper<Meta>();

const InstagramAudiosTable = ({ data, onRemoveAudio, isLoading, hasError }: Props) => {
    const columns = useMemo<ColumnDef<InstagramAudiosTableRow, any>[]>(
        () => [
            {
                header: 'Audio Name',
                id: 'name',
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const formattedTitle = original.title
                        ? `${original.title} - ${original.instagram_id}`
                        : original.instagram_id;
                    return formattedTitle;
                },
            },
            {
                header: 'Creations',
                accessorKey: 'reels_count',
                cell: ({ getValue, table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return typeof getValue() === 'number' ? formatNumberToKNotation(getValue()) : '-';
                },
            },
            {
                header: '',
                id: 'remove',
                accessorKey: 'isStatic',
                cell: function RemoveCell({ getValue, row: { original }, table }) {
                    const [anchor, setAnchor] = useState<SVGSVGElement | null>(null);
                    const { isLoading, onRemoveAudio } = getTableMeta(table);
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.removeCell}>
                            {!!getValue() ? (
                                <>
                                    <InfoIcon ref={setAnchor} className={styles.staticAudioTooltip} />
                                    <Tooltip anchorElement={anchor}>
                                        <Tooltip.Body>
                                            This audio is linked to the spotify song and cannot be removed
                                        </Tooltip.Body>
                                    </Tooltip>
                                </>
                            ) : (
                                <ButtonShell
                                    onClick={() => onRemoveAudio(original.id)}
                                    className={styles.removeButton}
                                    type="button"
                                >
                                    <CrossIcon />
                                </ButtonShell>
                            )}
                        </div>
                    );
                },
            },
        ],
        []
    );

    const meta: Meta = {
        onRemoveAudio,
        isLoading,
    };

    const initialLoading = !data.length && isLoading;
    const emptyRows = new Array(6).fill({}) as InstagramAudiosTableRow[];
    const rows = initialLoading ? emptyRows : data;

    return (
        <ScrollView className={styles.tableContainer}>
            <Table
                data={rows}
                columns={columns}
                meta={meta}
                className={styles.table}
                getRowClassName={({ original }) => (original.isStatic ? styles.static : '')}
                noDataLabel={
                    <div className={cn(styles.noDataLabel, { [styles.error]: hasError })}>
                        {hasError ? 'Something went wrong' : 'No audios selected'}
                    </div>
                }
            />
        </ScrollView>
    );
};

export default InstagramAudiosTable;
