import styles from './SongGrowthChart.module.css';
import { creatorbase } from '@round/api';
import { uniq } from 'lodash';
import MiniLineChart, { Props as MiniLineChartProps } from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';
import SongGrowthTooltipBreakdown from '../SongGrowthTooltipBreakdown/SongGrowthTooltipBreakdown';
import { ReactComponent as SongIcon } from 'assets/whitelabel/Song.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Spinner from 'ui-new/Loading/Spinner/Spinner';
import cn from 'classnames';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type Props = {
    timeSeries: creatorbase.TimeSeriesResponse<'tiktok_daily_change' | 'instagram_daily_change'>[0] | null;
    isLoading?: boolean;
    hasError?: boolean;
} & Pick<MiniLineChartProps, 'emptyChartLineColor' | 'lineColor'> &
    Pick<DivProps, 'onClick'>;

const SongGrowthChart = ({
    isLoading,
    hasError,
    timeSeries,
    emptyChartLineColor,
    lineColor = '#2A94FF',
    onClick,
}: Props) => {
    const tiktokDailyChange = timeSeries?.['tiktok_daily_change'] ?? [];
    const instagramDailyChange = timeSeries?.['instagram_daily_change'] ?? [];
    const timestamps = uniq(
        tiktokDailyChange.map((t) => t.timestamp).concat(instagramDailyChange.map((t) => t.timestamp))
    ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const aggregated: creatorbase.TimeSeries[] = timestamps.map((t) => {
        const tiktok = tiktokDailyChange.find((tt) => tt.timestamp === t);
        const instagram = instagramDailyChange.find((ig) => ig.timestamp === t);

        return {
            timestamp: t,
            value: (tiktok?.value ?? 0) + (instagram?.value ?? 0),
        };
    });

    const Icon = hasError ? WarningIcon : SongIcon;

    return (
        <Tooltip.Root>
            {(anchor, setRef) => (
                <>
                    <div ref={setRef} className={styles.container} onClick={onClick}>
                        <Icon className={cn(styles.songIcon, { [styles.errorIcon]: hasError })} />

                        <div className={cn(styles.chartContainer, { [styles.spinnerContainer]: isLoading })}>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <MiniLineChart
                                    flatLineOnEmpty
                                    lineColor={lineColor}
                                    emptyChartLineColor={emptyChartLineColor}
                                    points={aggregated.map(
                                        (s) => ({
                                            x: s.timestamp,
                                            y: s.value,
                                        })
                                    )}
                                    renderTooltip={(tooltipItem) => {
                                        return (
                                            <SongGrowthTooltipBreakdown
                                                tooltipItem={tooltipItem}
                                                instagramTimeSeries={instagramDailyChange}
                                                tiktokTimeSeries={tiktokDailyChange}
                                            />
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <Tooltip anchorElement={anchor} disabled={!hasError}>
                        <Tooltip.Title>Failed to load data</Tooltip.Title>
                    </Tooltip>
                </>
            )}
        </Tooltip.Root>
    );
};

export default SongGrowthChart;
