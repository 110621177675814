import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './StatusContainer.module.css';

type Props = {
    type?: 'error' | 'info';
    children?: ReactNode;
};

/**
 * Wrapper container for status messages
 * e.g. error, missing conditions like influencer plan and etc.
 */
const StatusContainer = ({ type = 'info', children }: Props) => (
    <div className={cn(styles.container, styles.error)}>{children}</div>
);

export default StatusContainer;
