import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useTiktokCampaigns } from './useTiktokCampaigns';
import { ExpandedState } from '@tanstack/react-table';
import useUrlState from 'Hooks/useUrlState';
import { useMemo } from 'react';
import PublicReportTiktokPostsTable from 'Modules/Plans/PublicReports/Posts/TiktokPosts/PublicReportTiktokPostsTable';
import TikTokPublicReportCampaignTable, {
    TikTokPublicReportCampaignTableRow,
} from '../CampaignsTable/TikTokPublicReportCampaignTable';

type Props = {
    publicReportId: string | undefined;
};

type UrlState = Partial<{
    expanded: string;
}>;

const ReportTiktokCampaigns = ({ publicReportId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const {
        campaignsData,
        campaignStatsData,
        projectsData,
        brandsData,
        teamsData,
        timeSeriesData,
        audiosData,
        fetchData,
        isProjectLoading,
        isBrandLoading,
        isTeamLoading,
        isCampaignStatsLoading,
        isTimeSeriesLoading,
        getTimeSeriesHasError,
    } = useTiktokCampaigns();
    const areCampaignsInitialized = campaignsData.isInitialized || campaignsData.status === 'error';
    const areCampaignsLoading = campaignsData.status !== 'error' && campaignsData.status !== 'success';

    useAbortableEffect(
        (signal) => {
            if (areCampaignsInitialized || !publicReportId) {
                return;
            }

            fetchData(
                {
                    public_report_id: publicReportId,
                    platform: 'tiktok',
                },
                { signal }
            ).catch(() => {});
        },
        [areCampaignsInitialized, publicReportId, fetchData]
    );

    const expandedState = useMemo(() => {
        const expanded = urlState?.expanded;
        if (!expanded) {
            return {};
        }

        return expanded.split(',').reduce((acc, campaignId) => {
            acc[campaignId] = true;
            return acc;
        }, {} as Record<string, boolean>);
    }, [urlState]);

    const setExpandedState = (expanded: ExpandedState) => {
        const expandedKeys = Object.keys(expanded).filter((key) => expanded[key as keyof ExpandedState]);
        setUrlState({ expanded: expandedKeys.join(',') });
    };

    const rows = useMemo(
        () =>
            campaignsData.data?.results.map<TikTokPublicReportCampaignTableRow>((c) => {
                const stats = campaignStatsData[c.id]?.data ?? null;
                const project = projectsData[c.project_id]?.data ?? null;
                const brand = project ? brandsData[project.brand_id]?.data ?? null : null;
                const team = project ? teamsData[project.team_id]?.data ?? null : null;
                const audioId = c.tiktok_details?.audio_id;
                const audioTimeSeries = audioId ? timeSeriesData?.data?.[audioId].data : null;
                const audio = audioId ? audiosData?.[audioId]?.data : null;

                return { ...c, project, brand, team, stats, audioTimeSeries, audio };
            }) ?? [],
        [
            brandsData,
            campaignStatsData,
            campaignsData.data?.results,
            projectsData,
            teamsData,
            timeSeriesData,
            audiosData,
        ]
    );

    return (
        <TikTokPublicReportCampaignTable
            data={rows}
            meta={{
                isLoading: areCampaignsLoading,
                getIsProjectLoading: (row) => isProjectLoading(row.id),
                getIsTeamLoading: (row) => isTeamLoading(row.id),
                getIsBrandLoading: (row) => isBrandLoading(row.id),
                getIsCampaignStatsLoading: (row) => isCampaignStatsLoading(row.id),
                getIsTimeSeriesLoading: (row) => isTimeSeriesLoading(row.id),
                getTimeSeriesHasError: (row) => getTimeSeriesHasError(row.id),
            }}
            isLoading={areCampaignsLoading}
            hasError={campaignsData.status === 'error'}
            expandedState={expandedState}
            onExpandedChange={setExpandedState}
            renderSubComponent={(row) => (
                <PublicReportTiktokPostsTable campaign={row.original} publicReportId={publicReportId} />
            )}
        />
    );
};

export default ReportTiktokCampaigns;
