import { getTiktokUserImages, microwave, OrderingValues, TiktokUserImage } from '@round/api';
import { useCallback, useMemo, useState } from 'react';
import { CreatorsTableRow } from './CreatorsTable/CreatorsTable';
import { showNotification } from '../../../../../../helpers';
import { mapApiErrorsToFormikErrors } from '../../../../../../utility/utility';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

export default function useCreators({
    page,
    page_size,
    min_tiktok_post_invite_count,
    min_tiktok_post_count,
    exclude_posted_with_audio,
    exclude_invited_to_campaign,
    rating,
    search,
    location_id,
    content_tags,
    posted_with_audio,
    hashtag_ids,
    ordering,
    exclude_content_tags,
    max_tiktok_post_count,
    max_tiktok_post_invite_count,
    min_relevant_post_count,
    min_tiktok_post_rate,
    with_genre_affinity,
    has_instagram_account,
    has_tiktok_account,
    has_youtube_account,
}: microwave.GetMicrowaveInfluencersParams) {
    const featureFlags = useFeatureFlags();
    const [creators, setCreators] = useState<microwave.MicrowaveInfluencer[]>([]);
    const [creatorsCount, setCreatorsCount] = useState(0);

    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [images, setImages] = useState<TiktokUserImage[]>([]);

    const getInfluencers = featureFlags.isEnabled('microwave_materialized_influencers')
        ? microwave.getMaterializedMicrowaveInfluencers
        : microwave.getMicrowaveInfluencers;

    const orderingParam = ordering === undefined ? undefined : String(ordering);

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                setError(null);
                setIsLoading(true);
                const response = await getInfluencers(
                    {
                        page,
                        page_size,
                        min_tiktok_post_count,
                        min_tiktok_post_invite_count,
                        exclude_posted_with_audio,
                        exclude_invited_to_campaign,
                        rating,
                        search,
                        location_id,
                        content_tags,
                        posted_with_audio,
                        hashtag_ids,
                        ordering: orderingParam as OrderingValues<microwave.MicrowaveInfluencersSortingKey>,
                        exclude_content_tags,
                        max_tiktok_post_count,
                        max_tiktok_post_invite_count,
                        with_genre_affinity,
                        min_relevant_post_count,
                        min_tiktok_post_rate,
                        has_instagram_account,
                        has_tiktok_account,
                        has_youtube_account,
                    },
                    requestInit
                );

                setCreators(response.data.results);
                setCreatorsCount(response.data.count);

                const imagesToFetch = response.data.results.map((creator) => creator.tiktok_user_id);
                if (imagesToFetch.length) {
                    const images = await getTiktokUserImages(imagesToFetch, requestInit);
                    setImages(images);
                }

                setIsInitialized(true);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError("Sorry, we're having technical issues");
            } finally {
                setIsLoading(false);
            }
        },
        [
            page,
            page_size,
            min_tiktok_post_count,
            min_tiktok_post_invite_count,
            exclude_posted_with_audio,
            exclude_invited_to_campaign,
            rating,
            search,
            location_id,
            content_tags,
            posted_with_audio,
            hashtag_ids,
            orderingParam,
            exclude_content_tags,
            max_tiktok_post_count,
            max_tiktok_post_invite_count,
            min_tiktok_post_rate,
            with_genre_affinity,
            min_relevant_post_count,
            has_instagram_account,
            has_tiktok_account,
            has_youtube_account,
            getInfluencers,
        ]
    );

    const updateCreator = useCallback(async (id: number, data: Partial<microwave.MicrowaveInfluencerWrite>) => {
        try {
            const response = await microwave.patchMicrowaveInfluencer(id, data);
            if (response.status === 200) {
                setCreators((creators) => creators.map((c) => (c.id === response.data.id ? response.data : c)));
                showNotification('Updated', 'info');
                return;
            }

            const message =
                response.status === 404
                    ? response.data.detail
                    : Object.values(mapApiErrorsToFormikErrors(response.data)).join('. ');
            showNotification(message, 'error');
        } catch (e) {
            if (e instanceof Error) {
                showNotification(e.message, 'error');
                return;
            }

            showNotification('Something went wrong', 'error');
        }
    }, []);

    return {
        creators: useMemo(
            () =>
                creators.map<CreatorsTableRow>((creator) => ({
                    ...creator,
                    image: images.find((image) => image.user_id === creator.tiktok_user_id),
                })),
            [creators, images]
        ),
        creatorsCount,
        isLoading,
        error,
        isInitialized,
        reset: () => {
            setCreators([]);
            setImages([]);
            setCreatorsCount(0);
            setIsLoading(false);
            setError(null);
            setIsInitialized(false);
        },
        init: fetchData,
        updateCreator,
    };
}
