import React, { useMemo, useState } from 'react';
import useUrlState from '../../../../../../Hooks/useUrlState';
import useAbortableEffect from '../../../../../../Hooks/useAbortableEffect';
import useMicrowavePayments from '../../useMicrowavePayments';
import styles from '../OutstandingPayments.module.css';
import OutstandingManualPaymentsTable from './Table/OutstandingManualPaymentsTable';
import { OutstandingPaymentsSortingKey, OutstandingPaymentsTableRow } from '../helpers';
import { Skeleton } from '@round/ui-kit';
import OutstandingPaymentsFilters, { getFilterValues } from '../OutstandingPaymentsFilters/OutstandingPaymentsFilters';
import { microwave } from '@round/api';
import ManualPaymentsModal from '../../PaymentModals/ManualPaymentsModal/ManualPaymentsModal';
import Button from 'ui/Button/Button';
import { RowSelectionState } from '@tanstack/react-table';
import uniqBy from 'lodash/uniqBy';
import Header from 'ui-new/Layout/Header/Header';

type UrlState = microwave.GetPaymentRequestsParams &
    Partial<{
        filters: string;
    }>;

const initialUrlState: Pick<Required<UrlState>, 'page' | 'page_size'> = {
    page: 1,
    page_size: 10,
};

const OutstandingManualPayments = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);
    const [selectedRows, setSelectedRows] = useState<OutstandingPaymentsTableRow[]>([]);

    const [selectedPaymentRows, setSelectedPaymentRows] = useState<OutstandingPaymentsTableRow[]>([]);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;

    const ordering: OutstandingPaymentsSortingKey[] = urlState.ordering
        ? (urlState.ordering.split(',') as OutstandingPaymentsSortingKey[])
        : [];

    const filters = useMemo(() => getFilterValues(urlState), [urlState]);

    const { rows, totals, count, status, fetchData, reset, updatePayment, markAsPaidManually } = useMicrowavePayments();

    const isInitialized = status === 'initialized' || status === 'error';
    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData(
                    {
                        page,
                        page_size: pageSize,
                        preferred_payment_method: 'BANK_TRANSFER',
                        payment_status: 'UNPAID',
                        status: 'APPROVED',
                        ordering: urlState.ordering || 'payment_deadline,-release_id',
                        ...filters,
                    },
                    { signal }
                );
            }
        },
        [fetchData, page, pageSize, urlState.ordering, filters, isInitialized]
    );

    const handleUpdatePayment = async (id: number, data: microwave.PatchPaymentRequestBody) => {
        const response = await updatePayment(id, data);
        if (response.status === 200) {
            setSelectedPaymentRows((prev) => prev.map((row) => (row.id === id ? { ...row, ...response.data } : row)));
        }
        return response;
    };

    const rowsDisplayedText = `displaying ${rows.length} out of ${count || 0} post${count !== 1 ? 's' : ''}`;

    return (
        <div className={styles.container}>
            <Header className={styles.header}>
                <div>
                    <Header.Title>Bank account payment</Header.Title>
                    <p className={styles.rowCountText}>{!isInitialized ? <Skeleton /> : rowsDisplayedText}</p>
                </div>

                <div className={styles.optionsContainer}>
                    <OutstandingPaymentsFilters
                        value={filters}
                        onChange={(filters) => {
                            setUrlState({ ...filters, page: 1 });
                            reset();
                        }}
                    />

                    {selectedRows.length > 0 && (
                        <>
                            <Button
                                onClick={() => {
                                    setIsPaymentModalOpen(true);
                                    setSelectedPaymentRows(Array.from(selectedRows));
                                }}
                                appearance="outlined"
                            >
                                <span>Pay all {selectedRows.length} Selected</span>
                            </Button>

                            <Button onClick={() => setSelectedRows([])} appearance="outlined">
                                Clear
                            </Button>
                        </>
                    )}
                </div>
            </Header>

            <OutstandingManualPaymentsTable
                isLoading={!isInitialized}
                data={rows}
                totals={totals}
                count={count}
                page={page}
                setPage={(page) => {
                    setUrlState({ page });
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setUrlState({ page_size: pageSize || initialUrlState.page_size });
                    reset();
                }}
                manualPagination
                ordering={ordering}
                onOrderingChange={(ordering) => {
                    setUrlState({
                        ordering: ordering.toString() as OutstandingPaymentsSortingKey,
                    });
                    reset();
                }}
                noDataLabel={status === 'error' ? 'Could not load payments' : 'No payments found'}
                updatePayment={handleUpdatePayment}
                onClickMakePayment={(row) => {
                    setSelectedPaymentRows([row]);
                    setIsPaymentModalOpen(true);
                }}
                getRowId={(row, index) => (row.id ? `${row.id}` : `skeleton-${index}`)}
                enableRowSelection
                enableMultiRowSelection
                rowSelection={selectedRows.reduce((acc, row) => {
                    acc[`${row.id}`] = true;
                    return acc;
                }, {} as RowSelectionState)}
                onRowSelectionChange={(selection) => {
                    const incomingRows = rows.filter((row) => selection[`${row.id}`]);

                    setSelectedRows((prev) =>
                        uniqBy([...prev.filter((row) => selection[`${row.id}`]), ...incomingRows], 'id')
                    );
                }}
            />

            <ManualPaymentsModal
                rows={selectedPaymentRows}
                isOpen={isPaymentModalOpen}
                onClose={() => {
                    setSelectedPaymentRows([]);
                    setIsPaymentModalOpen(false);
                }}
                makePayment={markAsPaidManually}
                onComplete={() => {
                    setSelectedPaymentRows([]);
                    setSelectedRows([]);
                    reset();
                }}
            />
        </div>
    );
};

export default OutstandingManualPayments;
