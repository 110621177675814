import { useFormikContext } from 'formik';
import { SongFormValues } from '../../SongForm';
import styles from './ChangedAudios.module.css';
import Button from 'ui-new/whitelabel/Button/Button';

type LabelProps = {
    added: number[];
    removed: number[];
    label: string;
};

const ChangesLabel = ({ added, removed, label }: LabelProps) => {
    return (
        <p>
            {!!added.length && (
                <span>
                    <strong>{added.length}</strong> {label} audio{added.length > 1 ? 's' : ''} added
                </span>
            )}

            {!!added.length && !!removed.length && ' • '}

            {!!removed.length && (
                <span>
                    <strong>{removed.length}</strong>{' '}
                    {added.length ? ' ' : `${label} audio${removed.length > 1 ? 's' : ''}`} removed
                </span>
            )}
        </p>
    );
};

const ChangedAudios = () => {
    const { values, initialValues, setFieldValue } = useFormikContext<SongFormValues>();

    const tiktokAudiosRemoved = initialValues.tiktok_audios.filter((id) => !values.tiktok_audios.includes(id));
    const tiktokAudiosAdded = values.tiktok_audios.filter((id) => !initialValues.tiktok_audios.includes(id));

    const instagramAudiosRemoved = initialValues.instagram_audios.filter((id) => !values.instagram_audios.includes(id));
    const instagramAudiosAdded = values.instagram_audios.filter((id) => !initialValues.instagram_audios.includes(id));

    const handleResetChanges = () => {
        setFieldValue('tiktok_audios', initialValues.tiktok_audios);
        setFieldValue('instagram_audios', initialValues.instagram_audios);
    };

    if (
        [tiktokAudiosAdded, tiktokAudiosRemoved, instagramAudiosAdded, instagramAudiosRemoved].find(
            (arr) => !!arr.length
        )
    ) {
        return (
            <div className={styles.container}>
                <div>
                    <ChangesLabel added={tiktokAudiosAdded} removed={tiktokAudiosRemoved} label="TikTok" />

                    <ChangesLabel added={instagramAudiosAdded} removed={instagramAudiosRemoved} label="Instagram" />
                </div>

                <Button appearance="ghost" className={styles.undoButton} onClick={handleResetChanges}>
                    Reset changes?
                </Button>
            </div>
        );
    }
    return null;
};

export default ChangedAudios;
