import { FunctionComponent } from 'react';
import { Select } from '@round/ui-kit';
import Location from './components/Location';
import { GenericDropdownOption } from 'App.types';
import TablePlayground from './components/TablePlayground';
import useUrlState from '../../Hooks/useUrlState';
import PaginationTablePlayground from './components/PaginationTablePlayground';
import SearchPlayground from './components/SearchPlayground';
import FullScreenModalPlayground from './components/FullScreenModalPlayground';
import ButtonPlayground from './components/ButtonPlayground';
import SelectPlayground from 'Pages/Playground/components/SelectPlayground';
import ExpandingTablePlayground from './components/ExpandingTablePlayground';

const playgrounds: Record<string, FunctionComponent> = {
    location: Location,
    table: TablePlayground,
    expandingTable: ExpandingTablePlayground,
    paginationTable: PaginationTablePlayground,
    search: SearchPlayground,
    fullScreenModal: FullScreenModalPlayground,
    buttons: ButtonPlayground,
    select: SelectPlayground,
};

const options: GenericDropdownOption<string>[] = Object.keys(playgrounds).map((key) => ({
    label: key,
    value: key,
}));

type UrlState = {
    playground: string;
};

const Playground = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>({ playground: options[0].value });

    const PlaygroundComponent = playgrounds[urlState.playground!] ?? (() => null);

    return (
        <div style={{ minHeight: '100vh', backgroundColor: 'white' }}>
            <div
                style={{
                    marginRight: 'auto',
                    width: '450px',
                    marginBottom: '2rem',
                }}
            >
                <Select
                    value={options.find((o) => o.value === urlState.playground)}
                    onChange={(o) => setUrlState({ playground: o?.value })}
                    options={options}
                />
            </div>
            <PlaygroundComponent />
        </div>
    );
};

export default Playground;
