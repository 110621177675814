import { InfluencerPostGroup, YoutubeInfluencerPostGroup } from '@round/api';
import { useState } from 'react';
import Button from 'ui/Buttons/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalTitle } from 'ui/General/Modal/Modal';
import styles from './ConfirmDeleteGroupModal.module.css';
import { showNotification } from 'helpers';
import { Banner } from '@round/ui-kit';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    group: InfluencerPostGroup | YoutubeInfluencerPostGroup | null;
    handleDeleteGroup: (id: number) => Promise<void>;
};

const ConfirmDeleteGroupModal = ({ isOpen, onClose, group, handleDeleteGroup }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        if (!group) {
            return;
        }

        try {
            setIsLoading(true);
            await handleDeleteGroup(group?.id);
            showNotification('Group deleted', 'info');
            onClose();
        } catch {
            showNotification('Could not delete group', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
            <ModalTitle className={styles.header}>Delete group "{group?.name}"?</ModalTitle>
            <ModalContent className={styles.content}>
                <p>
                    You are going to delete {group?.name} group. <br />
                    This will also remove all items in the group
                </p>

                {group?.is_shared_with_creatorbase && (
                    <Banner appearance="warning" className={styles.creatorbaseWarning}>
                        <p>
                            Group is linked to creatorbase campaign. <br />
                            Linked creatorbase campaign data will be removed.
                        </p>
                    </Banner>
                )}
            </ModalContent>
            <ModalFooter align="center">
                <Button onClick={onClose}>Cancel</Button>
                <Button type="filled" color="black" loading={isLoading} onClick={handleConfirm}>
                    Delete group
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmDeleteGroupModal;
