import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useAggregatedPlatformStats } from '../../contexts/PlatformStatsContext';
import { asMoney, formatNumberToKNotation } from 'helpers';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import { useProjectDetails } from '../../contexts/ProjectContext';
import { toDecimalPoint } from '@round/utils';
import { tooltipCopy } from 'Modules/Plans/helpers';
import HeadlineStats, { Stats } from 'Modules/Plans/components/HeadlineStats/HeadlineStats';
import { creatorbase } from '@round/api';

type Props = {
    platform: creatorbase.Platform;
};

const AggregatedPlatformStats = ({ platform }: Props) => {
    const { currencies } = useNonNullContext(OptionsContext);

    const { data: projectData } = useProjectDetails();

    const shouldInitialize =
        !!projectData?.project.platforms.includes(platform) && typeof projectData?.project.id === 'number';

    const { data, status, fetchData } = useAggregatedPlatformStats();

    const isInitialized = status === 'success' || status === 'error';
    const isLoading = !isInitialized && shouldInitialize;

    useAbortableEffect(
        (signal) => {
            if (isInitialized || !shouldInitialize) {
                return;
            }

            fetchData(projectData.project.id, platform, signal).catch(() => {});
        },
        [fetchData, projectData?.project.id, platform, isInitialized, shouldInitialize]
    );

    //there should only be one currency in the data, so we can just take the first entry
    const cpmCurrencyValuePair = Object.entries(data?.cpm || {})[0] || [];
    const cpm = cpmCurrencyValuePair.length
        ? asMoney(
              cpmCurrencyValuePair[1],
              currencies.find((curr) => curr.id === Number(cpmCurrencyValuePair[0]))
          )
        : '-';

    const totalSpentCurrencyValuePair = Object.entries(data?.total_spent || {})[0] || [];
    const totalSpent = totalSpentCurrencyValuePair.length
        ? asMoney(
              totalSpentCurrencyValuePair[1],
              currencies.find((curr) => curr.id === Number(totalSpentCurrencyValuePair[0]))
          )
        : '-';

    const displayValues: Stats = {
        totalAccounts: {
            label: 'Total Posts',
            value: data?.post_count,
        },
        totalFollowers: {
            label: 'Total Followers',
            value: typeof data?.follower_count === 'number' ? formatNumberToKNotation(data?.follower_count) : '-',
        },
        totalViews: {
            label: 'Total Views',
            value: typeof data?.view_count === 'number' ? formatNumberToKNotation(data?.view_count) : '-',
        },
        engagementRate: {
            label: 'Engagement Rate',
            value:
                typeof data?.engagement_rate === 'number' ? `${toDecimalPoint(data?.engagement_rate * 100, 2)}%` : '-',
            tooltip: tooltipCopy.engagementRate,
        },
        totalSpend: {
            label: 'Total spend',
            value: totalSpent,
        },
        cpm: {
            label: 'CPM',
            value: cpm,
            tooltip: tooltipCopy.cpm,
        },
    };

    return <HeadlineStats stats={displayValues} isLoading={isLoading} />;
};

export default AggregatedPlatformStats;
