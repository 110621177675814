import useReleaseStats from './useReleaseStats';
import styles from './ReleaseStats.module.css';
import { ReactComponent as ClipboardTickIcon } from 'assets/icons/ClipboardTick.svg';
import { ReactComponent as DollarSquareIcon } from 'assets/icons/DollarSquare.svg';
import { ReactComponent as EyeBoldIcon } from 'assets/icons/EyeBold.svg';
import { ReactComponent as LikeBoldIcon } from 'assets/icons/LikeBold.svg';
import { ReactComponent as Profile2UsersIcon } from 'assets/icons/Profile2Users.svg';
import HeadlineStatsCard from './HeadlineStatsCard/HeadlineStatsCard';
import useCampaigns from '../../Campaigns/useCampaigns';
import { asMoney } from 'helpers';
import { formatNumberToKNotation, toDecimalPoint } from '@round/utils';
import { isNumber } from 'utility/utility';
import { microwave, users } from '@round/api';
import useTeam from '../../hooks/useTeam';
import cn from 'classnames';
import { Banner } from '@round/ui-kit';
import { useCurrencies } from 'contexts/OptionsContext/useCurrencies';
import isString from 'lodash/isString';

type Props = {
    releaseId?: number;
    className?: string;
};

// so we can destructure and check for number and TS won't complain about possible undefined values
const getStats = (obj: microwave.ReleaseStats | null): Partial<microwave.ReleaseStats> => ({
    cpm: obj?.cpm,
    engagement_rate: obj?.engagement_rate,
    amount_spent: obj?.amount_spent,
    total_views: obj?.total_views,
    post_count: obj?.post_count,
    currency_id: obj?.currency_id,
});

const ReleaseStats = ({ releaseId, className }: Props) => {
    const { currencies } = useCurrencies();
    const { state } = useReleaseStats({ releaseId });
    const { data: campaigns, status: campaignStatus, error: campaignsError } = useCampaigns({ isImperative: true });
    const { data: teamState } = useTeam({ isImperative: true });

    const { cpm, amount_spent, total_views, post_count, engagement_rate, currency_id } = getStats(state.data);
    const currency = currencies.find((c) => c.id === currency_id);

    const totalTargetPostCount = campaigns?.reduce((count, campaign) => count + campaign.target_post_count, 0);
    const totalPostsValue =
        totalTargetPostCount && isNumber(post_count) ? `${post_count}/${totalTargetPostCount}` : '-';

    const amountSpentValue = isNumber(amount_spent) ? asMoney(amount_spent, currency, 2) : '-';
    const totalViewsValue = isNumber(total_views) ? formatNumberToKNotation(total_views) : '-';
    const totalEngagementRateValue = isNumber(engagement_rate) ? `${toDecimalPoint(engagement_rate * 100, 2)}%` : '-';
    const cpmValue = isNumber(cpm) ? asMoney(cpm, currency, 3) : '-';
    const teamValue = Object.values(teamState)
        .map((t) => t?.data)
        .filter((t): t is users.UserPlanner => !!t)
        .map((t) => `${t.first_name} ${t.last_name}`)
        .join(', ');

    const isReleaseStatsLoading = state.status === 'loading' || state.status === 'idle';
    const isTeamDataLoading = Object.values(teamState).some((t) => t?.status === 'loading' || t?.status === 'idle');

    const hasErrorLoadingStatsData = state.status === 'error' || campaignStatus === 'error';
    const releaseStatsErrorMessages = [state.error, campaignsError].filter(isString);

    return (
        <div className={cn(styles.wrapper, className)}>
            <div className={styles.statsContainer}>
                <HeadlineStatsCard
                    isLoading={campaignStatus === 'loading' && isReleaseStatsLoading}
                    title="Total posts"
                    value={totalPostsValue}
                    icon={<ClipboardTickIcon className={styles.totalPostsIcon} />}
                />

                <HeadlineStatsCard
                    isLoading={isReleaseStatsLoading}
                    title="Total spend"
                    value={amountSpentValue}
                    icon={<DollarSquareIcon className={styles.totalSpendIcon} />}
                />

                <HeadlineStatsCard
                    isLoading={isReleaseStatsLoading}
                    title="Total views"
                    value={totalViewsValue}
                    icon={<EyeBoldIcon className={styles.totalViewsIcon} />}
                />

                <HeadlineStatsCard
                    isLoading={isReleaseStatsLoading}
                    title="Engagement rate"
                    value={totalEngagementRateValue}
                    icon={<LikeBoldIcon className={styles.engagementRateIcon} />}
                />

                <HeadlineStatsCard
                    isLoading={isReleaseStatsLoading}
                    title="CPM"
                    value={cpmValue}
                    icon={<DollarSquareIcon className={styles.cpmIcon} />}
                />

                <HeadlineStatsCard
                    isLoading={isTeamDataLoading || isReleaseStatsLoading}
                    title="Account team"
                    value={teamValue}
                    icon={<Profile2UsersIcon className={styles.accountTeamIcon} />}
                />
            </div>
            {hasErrorLoadingStatsData && (
                <div className={styles.errorsContainer}>
                    {releaseStatsErrorMessages.map((error) => (
                        <Banner key={error} className={styles.errorBanner} appearance="warning">
                            {error}
                        </Banner>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ReleaseStats;
