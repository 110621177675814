import { formatNumberToKNotation } from 'helpers';
import { Image, SkeletonizedValue } from '@round/ui-kit';
import styles from './UserBadge.module.css';

export type UserBadgeProps = {
    isLoading: boolean;
    image: string | undefined;
    title: string;
    followerCount?: number | null;
};

const UserBadge = ({ isLoading, image, title, followerCount }: UserBadgeProps) => {
    return (
        <div className={styles.container}>
            <Image loading={isLoading} src={image} className={styles.image} />
            <div className={styles.details}>
                <p>
                    <SkeletonizedValue isInitialized={!isLoading}>{title}</SkeletonizedValue>
                </p>
                {followerCount === undefined ? null : (
                    <p className={styles.followerCount}>
                        <SkeletonizedValue isInitialized={!isLoading}>
                            {typeof followerCount === 'number' ? formatNumberToKNotation(followerCount) : '-'}
                        </SkeletonizedValue>
                    </p>
                )}
            </div>
        </div>
    );
};

export default UserBadge;
