import { SelectProps } from '@round/ui-kit';
import { GenericDropdownOption, PaginatedApiResponseData } from 'App.types';
import { GroupedOptionsType, OptionsType, ValueType } from 'react-select';
import { PaginatedRequest } from '@round/api';
import { useCallback, useMemo, useState } from 'react';
import useAbortableEffect from './useAbortableEffect';
import { debounce } from 'lodash';

type UseSelectReturn<
    TOption extends GenericDropdownOption<any>,
    TIsMulti extends boolean = false,
    TIsGroup extends boolean = false
> = {
    props: Pick<
        SelectProps<TOption, TIsMulti>,
        | 'filterOption'
        | 'onMenuOpen'
        | 'onMenuClose'
        | 'onMenuScrollToBottom'
        | 'inputValue'
        | 'onInputChange'
        | 'isLoading'
        | 'value'
        | 'onChange'
        | 'isMulti'
        | 'noOptionsMessage'
    > & {
        options: TIsGroup extends true ? GroupedOptionsType<TOption> : OptionsType<TOption>;
    };
    hasError: boolean;
    hasInitialValueError: boolean;
    isInitialValueLoading: boolean;
    resetValue: () => void;
    resetOptions: () => void;
};

export type OptionsParams = PaginatedRequest & Partial<{ search: string }>;

export type UseSelectParams<
    TOption extends GenericDropdownOption<any>,
    TIsMulti extends boolean = false,
    TIdentifier extends number | string = number
> = TIsMulti extends true
    ? UseMultiValueSelectParams<TOption, TIdentifier>
    : UseSingleValueSelectParams<TOption, TIdentifier>;

export type UseSingleValueSelectParams<
    TOption extends GenericDropdownOption<any>,
    TIdentifier extends number | string = number
> = FetchOptionsParams<TOption> & {
    fetchInitialValue?: (initialValueId: TIdentifier, requestInit?: RequestInit) => Promise<TOption>;
    initialValueId?: TIdentifier;
    isMulti: false;
};

export type UseMultiValueSelectParams<
    TOption extends GenericDropdownOption<any>,
    TIdentifier extends number | string = number
> = FetchOptionsParams<TOption> & {
    fetchInitialValue?: (initialValueId: TIdentifier[], requestInit?: RequestInit) => Promise<TOption[]>;
    initialValueId?: TIdentifier[];
    isMulti: true;
};

type FetchOptionsParams<TOption extends GenericDropdownOption<any>> = {
    fetchOptions: (params: OptionsParams, requestInit?: RequestInit) => Promise<PaginatedApiResponseData<TOption>>;
    initOn?: 'menuOpen' | 'mount';
    pageSize?: number;
};

export function useSelect<
    TOption extends GenericDropdownOption<any>,
    TIsMulti extends boolean = false,
    TIdentifier extends number | string = number
>({
    fetchOptions,
    pageSize = 25,
    ...props
}: UseSelectParams<TOption, TIsMulti, TIdentifier>): UseSelectReturn<TOption, TIsMulti> {
    const [value, setValue] = useState<ValueType<TOption, TIsMulti>>(null);
    const [isValueInitialized, setIsValueInitialized] = useState(false);
    const [hasInitialValueError, setHasInitialValueError] = useState(false);

    useAbortableEffect(
        (signal) => {
            if (
                isValueInitialized ||
                !props.fetchInitialValue ||
                !props.initialValueId ||
                (Array.isArray(props.initialValueId) && !props.initialValueId.length)
            ) {
                return;
            }

            props
                .fetchInitialValue(props.initialValueId as TIdentifier[] & TIdentifier, { signal })
                .then((value) => {
                    setValue(value as ValueType<TOption, TIsMulti>);
                    setIsValueInitialized(true);
                })
                .catch((e) => {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    setIsValueInitialized(true);
                    setHasInitialValueError(true);
                });
        },
        [isValueInitialized, props]
    );

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [areOptionsInitialized, setAreOptionsInitialized] = useState(false);
    const [areOptionsLoading, setAreOptionsLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const [hasNextPage, setHasNextPage] = useState(false);
    const [options, setOptions] = useState<TOption[]>([]);
    const [error, setError] = useState<string | null>(null);

    const loadOptions = useCallback(
        async (params: OptionsParams, requestInit?: RequestInit) => {
            try {
                const response = await fetchOptions(params, requestInit);
                setHasNextPage(Boolean(response.next));
                setOptions((prev) => [...prev, ...response.results]);
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError('Could not load options');
            } finally {
                setAreOptionsLoading(false);
            }
        },
        [fetchOptions]
    );

    useAbortableEffect(
        (signal) => {
            if (!areOptionsInitialized && (props.initOn !== 'menuOpen' || isMenuOpen)) {
                setAreOptionsLoading(true);
                loadOptions({ page: 1, page_size: pageSize, search }, { signal }).finally(() => {
                    setAreOptionsInitialized(true);
                });
            }
        },
        [areOptionsInitialized, loadOptions, isMenuOpen, search, props.initOn, pageSize]
    );

    const loadNextPage = () => {
        setAreOptionsLoading(true);
        loadOptions({ page: page + 1, page_size: pageSize, search }).then(() => {
            setPage((page) => page + 1);
        });
    };

    const debouncedLoadOptions = useMemo(() => debounce(loadOptions, 700), [loadOptions]);

    return {
        props: {
            isMulti: (props.isMulti || false) as TIsMulti,
            value,
            onChange: (value: ValueType<TOption, TIsMulti>) => {
                setValue(value);
                setHasInitialValueError(false);
            },
            options,
            inputValue: search,
            onInputChange: (value: string) => {
                if (value === search) {
                    return;
                }

                setOptions([]);
                setHasNextPage(false);

                setPage(1);
                setSearch(value);
                setAreOptionsLoading(true);
                debouncedLoadOptions({ page: 1, page_size: pageSize, search: value });
            },
            onMenuScrollToBottom: () => {
                if (!areOptionsInitialized || !hasNextPage || Boolean(error)) {
                    return;
                }

                loadNextPage();
            },
            isLoading: areOptionsLoading,
            onMenuOpen: () => setIsMenuOpen(true),
            onMenuClose: () => setIsMenuOpen(false),
            noOptionsMessage: () => (areOptionsLoading ? 'Loading...' : error || 'No options'),
            filterOption: null,
        },
        hasError: Boolean(error),
        isInitialValueLoading: !!props.initialValueId && !isValueInitialized,
        hasInitialValueError,
        resetValue: () => {
            setValue(null);
            setIsValueInitialized(false);
            setHasInitialValueError(false);
        },
        resetOptions: () => {
            setOptions([]);
            setHasNextPage(false);
            setError(null);
            setPage(1);
            setAreOptionsInitialized(false);
        },
    };
}
