import ApproveTiktokPosts from './ApproveTiktokPosts/ApproveTiktokPosts';
import { TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/platform/TikTok.svg';
import styles from './ApproveMicrowavePosts.module.css';
import UnderLineTabs from 'ui-new/Tabs/UnderlineTabs/UnderLineTabs';
import ApproveInstagramPosts from './ApproveInstagramPosts/ApproveInstagramPosts';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from 'ui-new/Layout/Header/Header';

const tabOptions = ['tiktok', 'instagram'] as const;
const isTabOption = (value: string): value is typeof tabOptions[number] => !!tabOptions.find((opt) => opt === value);

const ApproveMicrowavePosts = () => {
    // We'll use router-dom hooks to manage hash state until we can migrate tiktok tab to use updated url state
    // and investigate potential interferences between parent and child components
    const location = useLocation();
    const navigate = useNavigate();
    const selectedTab = location.hash.replace('#', '') || tabOptions[0];

    return (
        <Tabs
            activeTab={selectedTab}
            onChange={(tab) => {
                if (isTabOption(tab)) {
                    navigate(`#${tab}`);
                }
            }}
        >
            <Header>
                <Header.Title>Approve Posts</Header.Title>
            </Header>

            <UnderLineTabs.Bar className={styles.tabBar}>
                <UnderLineTabs.Item name="tiktok">
                    <TikTokIcon className={styles.platformIcon} /> TikTok
                </UnderLineTabs.Item>
                <UnderLineTabs.Item name="instagram">
                    <InstagramIcon className={styles.platformIcon} /> Instagram
                </UnderLineTabs.Item>
            </UnderLineTabs.Bar>

            <TabPanels>
                <TabPanel name="tiktok">
                    <ApproveTiktokPosts />
                </TabPanel>
                <TabPanel name="instagram">
                    <ApproveInstagramPosts />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default ApproveMicrowavePosts;
