import { getInstagramAudios } from '@round/api';
import { useCallback } from 'react';
import useNonNullContext from 'Hooks/useNonNullContext';
import { SongFormAudiosContext } from '../SongFormAudiosContext';
import { findOrCreateInstagramAudio } from 'Modules/Instagram/Instagram.helpers';

export function useSongFormInstagramAudios() {
    const { instagramState: state, setInstagramState: setState } = useNonNullContext(SongFormAudiosContext);

    const fetchAudios = useCallback(
        async (ids: number[], requestInit?: RequestInit) => {
            if (!ids.length) {
                setState({
                    status: 'success',
                    data: [],
                    error: null,
                });
                return;
            }

            try {
                const response = await getInstagramAudios({ id: ids.toString(), page_size: ids.length }, requestInit);

                if (response.status === 200) {
                    setState({
                        status: 'success',
                        data: response.data.results,
                        error: null,
                    });
                    return;
                }

                setState({
                    status: 'error',
                    data: [],
                    error: "Couldn't fetch audios",
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    return;
                }

                setState({
                    status: 'error',
                    data: [],
                    error: error instanceof Error ? error.message : "Couldn't fetch audios",
                });
            }
        },
        [setState]
    );

    const getOrCreateAudio = useCallback(
        async (url: string) => {
            const result = await findOrCreateInstagramAudio(url);
            if (!result.success) {
                throw new Error(result.error);
            }

            setState((prevState) => ({
                ...prevState,
                //ensure no duplicate audios
                data: [...(prevState.data?.filter((a) => a.id !== result.data.id) || []), result.data],
            }));

            return result.data;
        },
        [setState]
    );

    return {
        audios: state.data,
        status: state.status,
        fetchAudios,
        addAudio: getOrCreateAudio,
    };
}
