import { PhylloUserAnalyticsData, TiktokUser, TiktokUserImage, TiktokUserStatsCache } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type TiktokUserDataState = {
    analytics: DataState<PhylloUserAnalyticsData>;
    userStatsCache: DataState<TiktokUserStatsCache>;
    user: DataState<{
        user: TiktokUser | null;
        image: TiktokUserImage | null;
    }>;
};

export const initialState: TiktokUserDataState = {
    analytics: {
        data: null,
        error: null,
        status: 'idle',
    },
    userStatsCache: {
        data: null,
        error: null,
        status: 'idle',
    },
    user: {
        data: {
            user: null,
            image: null,
        },
        error: null,
        status: 'idle',
    },
};

export type TiktokUserDataActions =
    | ReducerAction<'loadTiktokUserAnalyticsData'>
    | ReducerActionWithPayload<'tiktokUserAnalyticsDataLoaded', PhylloUserAnalyticsData>
    | ReducerActionWithPayload<'errorLoadingTiktokUserAnalyticsData', string>
    | ReducerAction<'resetTiktokUserAnalytics'>
    | ReducerAction<'loadTiktokUserStatsCacheData'>
    | ReducerActionWithPayload<'tiktokUserStatsCacheDataLoaded', TiktokUserStatsCache>
    | ReducerActionWithPayload<'errorLoadingTiktokUserStatsCacheData', string>
    | ReducerAction<'resetTiktokUserStatsCacheData'>
    | ReducerAction<'loadTiktokUserData'>
    | ReducerActionWithPayload<'tiktokUserDataLoaded', { user: TiktokUser; image: TiktokUserImage }>
    | ReducerActionWithPayload<'errorLoadingTiktokUserData', string>
    | ReducerAction<'resetTiktokUserData'>;

export default createReducer<TiktokUserDataState, TiktokUserDataActions>({
    loadTiktokUserAnalyticsData: (state) => ({
        ...state,
        analytics: {
            ...state.analytics,
            status: 'loading',
            error: null,
        },
    }),
    tiktokUserAnalyticsDataLoaded: (state, { payload }) => ({
        ...state,
        analytics: {
            status: 'success',
            data: payload,
            error: null,
        },
    }),
    errorLoadingTiktokUserAnalyticsData: (state, { payload }) => ({
        ...state,
        analytics: {
            ...state.analytics,
            status: 'error',
            error: payload,
        },
    }),
    resetTiktokUserAnalytics: (state) => ({
        ...state,
        analytics: {
            data: null,
            error: null,
            status: 'idle',
        },
    }),
    loadTiktokUserStatsCacheData: (state) => ({
        ...state,
        userStatsCache: {
            ...state.userStatsCache,
            status: 'loading',
            error: null,
        },
    }),
    tiktokUserStatsCacheDataLoaded: (state, { payload }) => ({
        ...state,
        userStatsCache: {
            status: 'success',
            data: payload,
            error: null,
        },
    }),
    errorLoadingTiktokUserStatsCacheData: (state, { payload }) => ({
        ...state,
        userStatsCache: {
            ...state.userStatsCache,
            status: 'error',
            error: payload,
        },
    }),
    resetTiktokUserStatsCacheData: (state) => ({
        ...state,
        userStatsCache: {
            data: null,
            error: null,
            status: 'idle',
        },
    }),
    loadTiktokUserData: (state) => ({
        ...state,
        user: {
            ...state.user,
            status: 'loading',
            error: null,
        },
    }),
    tiktokUserDataLoaded: (state, { payload: { user, image } }) => ({
        ...state,
        user: {
            status: 'success',
            error: null,
            data: {
                user: user,
                image: image,
            },
        },
    }),
    errorLoadingTiktokUserData: (state, { payload }) => ({
        ...state,
        user: {
            ...state.user,
            status: 'error',
            error: payload,
        },
    }),
    resetTiktokUserData: (state) => ({
        ...state,
        user: {
            data: null,
            error: null,
            status: 'idle',
        },
    }),
});
