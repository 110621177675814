import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { PublicReportContext } from '../PublicReportContext';
import { useCallback } from 'react';
import { creatorbase, influencer } from '@round/api';
import { omit } from 'lodash';

export default function usePublicReportData() {
    const reportState = useNonNullContextSelector(PublicReportContext, ([values]) => values.publicReport);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);
    const teamState = useNonNullContextSelector(PublicReportContext, ([values]) => values.team);

    const fetchTeam = useCallback(
        async (publicReportId: string, requestInit?: RequestInit) => {
            dispatch({ type: 'loadTeam' });
            try {
                const publicReportRequestInit = influencer.addPublicReportIdToRequestInit(
                    publicReportId,
                    requestInit || {}
                );
                const teamId = await fetchTeamId(publicReportId, omit(publicReportRequestInit, ['signal']));

                if (teamId === undefined) {
                    dispatch({ type: 'teamLoadingError', payload: 'Could not get team ID' });
                    return;
                }

                const response = await creatorbase.getTeam(teamId, {}, omit(publicReportRequestInit, ['signal']));
                if (response.status === 200) {
                    dispatch({ type: 'teamLoadSuccess', payload: response.data });
                    return response;
                }

                dispatch({ type: 'teamLoadingError', payload: response.data.detail });
                return response;
            } catch {
                throw new Error('Error fetching team data');
            }
        },
        [dispatch]
    );

    const fetchTeamId = async (
        id: string,
        requestInit: influencer.PublicReportRequestInit
    ): Promise<number | undefined> => {
        try {
            const creatorbasePublicReportResponse = await creatorbase.getReports({ public_report_id: id }, requestInit);
            if (creatorbasePublicReportResponse.status === 200) {
                return creatorbasePublicReportResponse.data.results[0].team_id;
            }
        } catch {
            throw new Error('Error fetching team ID');
        }
    };

    const fetchData = useCallback(
        async (id: string, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadPublicReport' });
                const response = await influencer.getPublicReport(
                    id,
                    influencer.addPublicReportIdToRequestInit(id, requestInit || {})
                );
                if (response.status === 200) {
                    dispatch({
                        type: 'publicReportInitialized',
                        payload: response.data,
                    });
                    fetchTeam(id, requestInit).catch(() => {});

                    return response;
                }

                dispatch({ type: 'errorLoadingPublicReport', payload: response });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingPublicReport',
                    payload: e instanceof Error ? e.message : 'Could not get report',
                });
                throw e;
            }
        },
        [dispatch, fetchTeam]
    );

    return {
        ...reportState,
        team: teamState,
        fetchData,
    };
}
