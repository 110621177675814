import { AppServer, RouteHandlerContext } from '../../mirage';
import { buildPaginatedResponse } from '../../mirage.helpers';

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/content-tags/', function (this: RouteHandlerContext, schema, request) {
        const { search = '' } = request.queryParams;
        const tags = schema
            .all('contentTag')
            .filter((t) => !search || t.name.toLowerCase().includes(search.toLowerCase()));
        return buildPaginatedResponse(tags, {
            url: '/api/microwave/content-tags/',
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'contentTag'),
        });
    });
}
