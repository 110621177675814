import { combineReducers } from 'helpers';
import { reducer as indicatorsReducer, initialState as indicatorsInitialState } from '../Indicators/Instagram/reducer';
import { reducer as topAudiosReducer, initialState as topAudiosInitialState } from '../TopAudios/Instagram/reducer';
import { reducer as topPostsReducer, initialState as topPostsInitialState } from '../TopPosts/Instagram/reducer';
import {
    reducer as instagramAudiosReducer,
    initialState as instagramAudiosInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramAudiosByIdDataHook';

export const instagramInitialState = {
    indicators: indicatorsInitialState,
    topAudios: topAudiosInitialState,
    topPosts: topPostsInitialState,
    audios: instagramAudiosInitialState,
};

export const instagramReducer = combineReducers({
    indicators: indicatorsReducer,
    topAudios: topAudiosReducer,
    topPosts: topPostsReducer,
    audios: instagramAudiosReducer,
});
