import { ReactComponent as LinkIcon } from 'assets/whitelabel/Link.svg';
import { useEffect, useState } from 'react';
import { ButtonShell, ControlledPopover } from '@round/ui-kit';
import styles from './PostUrlPopout.module.css';
import { openInNewTab, showNotification } from 'helpers';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { creatorbase } from '@round/api';
import cn from 'classnames';
import Input from 'ui-new/whitelabel/Input/Input';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';
import { ReactComponent as CodeIcon } from 'assets/whitelabel/Code.svg';
import { ReactComponent as CopyIcon } from 'assets/whitelabel/Copy.svg';
import copy from 'copy-to-clipboard';

type Props = {
    postUrl: string | null | undefined;
    adCode: string | null | undefined;
    updatePost?: (data: Partial<creatorbase.PatchPostData>) => ReturnType<typeof creatorbase.patchPost>;
    isReadOnly?: boolean;
    platform: 'tiktok' | 'instagram' | 'youtube';
    status: creatorbase.Post['status'];
};

const PostUrlPopout = ({
    postUrl: initalPostUrl,
    adCode: initialAdCode,
    updatePost,
    platform,
    status,
    isReadOnly,
}: Props) => {
    const [targetRef, setTargetRef] = useState<HTMLButtonElement | null>(null);
    const [popoutRef, setPopoutRef] = useState<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);

    const [postUrl, setPostUrl] = useState(initalPostUrl || '');

    const [adCode, setAdCode] = useState(initialAdCode || '');
    const [isAdCodeEditMode, setIsAdCodeEditMode] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const isDisabled = !updatePost || isReadOnly;

    useEffect(() => {
        if (isOpen || isSubmitting) {
            return;
        }

        setPostUrl(initalPostUrl || '');
        setAdCode(initialAdCode || '');
        setIsAdCodeEditMode(false);
    }, [isOpen, isSubmitting, initalPostUrl, initialAdCode]);

    const handleSubmitPostUrl = async () => {
        if (isDisabled || postUrl === initalPostUrl) {
            return;
        }

        if (!postUrl) {
            showNotification('Post URL is required', 'error');
            return;
        }

        try {
            setIsSubmitting(true);

            const response = await updatePost({ identifier: postUrl });

            if (response.status === 200) {
                showNotification('Post URL updated', 'info');
                return;
            }

            const errorMessage =
                response.status === 400 && response.data.identifier
                    ? Array.isArray(response.data.identifier)
                        ? response.data.identifier.join('. ')
                        : response.data.identifier
                    : 'Failed to update post URL';
            showNotification(errorMessage, 'error');
            setPostUrl(initalPostUrl || '');
        } catch (e) {
            showNotification('Failed to update post URL', 'error');
            setPostUrl(initalPostUrl || '');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmitAdcode = async () => {
        if (isDisabled || adCode === initialAdCode) {
            return;
        }

        const errorMessage = `Couldn't update ad code`;
        const isAdCodeEmpty = adCode === '';

        try {
            setIsSubmitting(true);

            const response = await updatePost({
                [`${platform}_details`]: {
                    ad_code: adCode,
                },
            });

            if (response.status === 200) {
                showNotification('Ad code updated', 'info');

                if (isAdCodeEmpty) {
                    setIsAdCodeEditMode(false);
                }
                return;
            }

            showNotification(errorMessage, 'error');
            setAdCode(initialAdCode || '');
        } catch (e) {
            showNotification(errorMessage, 'error');
            setAdCode(initialAdCode || '');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClickOutside = () => {
        if (!isOpen) {
            return;
        }

        if (popoutRef?.contains(document.activeElement) && document.activeElement instanceof HTMLInputElement) {
            document.activeElement?.blur();
            return;
        }

        setIsOpen(false);
    };

    useOnClickOutside([popoutRef], handleClickOutside);

    const shouldShowAdCodeInput = !!initialAdCode || isAdCodeEditMode;

    return (
        <>
            <ButtonShell
                ref={setTargetRef}
                onClick={() => setIsOpen((prev) => !prev)}
                className={cn(styles.linkButton, { [styles.active]: isOpen || postUrl })}
                disabled={isReadOnly && !postUrl}
            >
                <LinkIcon />
                {!!initialAdCode && <CodeIcon className={styles.adCodeIcon} />}
            </ButtonShell>

            <ControlledPopover
                anchorElement={targetRef}
                isOpen={isOpen}
                options={{ placement: 'top', modifiers: [{ name: 'offset', options: { offset: [0, 12] } }] }}
                overlayClassName={styles.overlay}
            >
                <div className={styles.container} ref={setPopoutRef}>
                    <div className={styles.field}>
                        <label htmlFor="postUrl" className={styles.label}>
                            Post URL
                        </label>
                        <Input
                            disabled={isDisabled}
                            type="text"
                            id="postUrl"
                            name="postUrl"
                            value={postUrl}
                            onChange={(e) => setPostUrl(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    e.currentTarget.blur();
                                }
                            }}
                            onBlur={handleSubmitPostUrl}
                            renderRight={() => (
                                <div className={styles.postUrlActions}>
                                    <button
                                        className={styles.postUrlButton}
                                        onClick={() => {
                                            const success = copy(postUrl);
                                            if (success) {
                                                showNotification('Copied!', 'info');
                                            }
                                        }}
                                    >
                                        <CopyIcon />
                                    </button>

                                    <button
                                        className={styles.postUrlButton}
                                        onClick={() => {
                                            if (postUrl) {
                                                openInNewTab(postUrl);
                                            }
                                        }}
                                    >
                                        <LinkIcon />
                                    </button>
                                </div>
                            )}
                        />
                    </div>

                    {platform !== 'youtube' &&
                        status === 'live' &&
                        (shouldShowAdCodeInput ? (
                            <div className={styles.field}>
                                <label htmlFor="adCode" className={styles.label}>
                                    Ad code
                                </label>
                                <Input
                                    disabled={isDisabled}
                                    type="text"
                                    id="adCode"
                                    name="adCode"
                                    value={adCode}
                                    onChange={(e) => setAdCode(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            e.currentTarget.blur();
                                        }
                                    }}
                                    onBlur={handleSubmitAdcode}
                                />
                            </div>
                        ) : !isReadOnly ? (
                            <ButtonShell
                                className={styles.trackingCodeButton}
                                onClick={() => setIsAdCodeEditMode(true)}
                            >
                                <PlusIcon />
                                Ad code
                            </ButtonShell>
                        ) : null)}
                </div>
            </ControlledPopover>
        </>
    );
};

export default PostUrlPopout;
