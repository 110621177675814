import { AppServer } from '../mirage';
import * as projectsMirage from './projects/projects.mirage';

export const models = {
    ...projectsMirage.models,
};

export const serializers = {
    ...projectsMirage.serializers,
};

export const factories = {
    ...projectsMirage.factories,
};

export function handleRequests(server: AppServer) {
    projectsMirage.handleRequests(server);
}
