import { useQuery } from '@tanstack/react-query';
import { applicationManagerQueries, creatorbaseQueries } from '@round/api';

const appManagerOptions = applicationManagerQueries.project.options;
const appManagerUtils = applicationManagerQueries.project.utils;

const creatorbaseUtils = creatorbaseQueries.project.utils;

export function useLinkedProject(projectId: number | null | undefined, options: { isDisabled?: boolean } = {}) {
    const { data: appManagerProject, status: appManagerProjectStatus, error: appManagerProjectError } = useQuery({
        ...appManagerOptions.detail(projectId),
        enabled: !!projectId && !options.isDisabled,
    });

    const { data: linkedProject, status: linkedProjectStatus, error: linkedProjectError } = useQuery({
        ...creatorbaseQueries.project.options.detail(appManagerProject?.creatorbase_project_id, { team_only: false }),
        enabled: !!appManagerProject?.creatorbase_project_id && !options.isDisabled,
    });

    const { data: team, status: teamStatus, error: teamError } = useQuery({
        ...creatorbaseQueries.team.options.detail(linkedProject?.team_id, { team_only: false }),
        enabled: !!linkedProject?.team_id && !options.isDisabled,
    });

    const { data: brand, status: brandStatus, error: brandError } = useQuery({
        ...creatorbaseQueries.brand.options.detail(linkedProject?.brand_id, { team_only: false }),
        enabled: !!linkedProject?.brand_id && !options.isDisabled,
    });

    const hasErrorLoadingSupplementaryData = !!teamError || !!brandError;

    return {
        appManagerProject,
        appManagerProjectStatus,
        appManagerProjectError: appManagerUtils.detail.getErrorResponse(appManagerProjectError),
        linkedProject,
        linkedProjectStatus,
        linkedProjectError: creatorbaseUtils.detail.getErrorResponse(linkedProjectError),
        team,
        brand,

        isLinkedProjectLoading:
            appManagerProjectStatus === 'pending' ||
            (!!appManagerProject?.creatorbase_project_id && linkedProjectStatus === 'pending') ||
            (!!linkedProject?.brand_id && brandStatus === 'pending') ||
            (!!linkedProject?.team_id && teamStatus === 'pending'),
        hasErrorLoadingLinkedProject: !!appManagerProjectError || !!linkedProjectError,
        hasErrorLoadingSupplementaryData,
    };
}
