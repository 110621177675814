import { NavBar } from 'SharedComponents/NavBar/NavBar';
import CampaignDetails from 'Modules/Advertising/Microwave/CampaignDetails/CampaignDetails';
import { useParams } from 'react-router-dom';
import { ReleaseProvider } from 'Modules/Advertising/Microwave/CampaignDetails/ReleaseContext';
import PageLayout from 'ui-new/Layout/PageLayout/PageLayout';

const CampaignDetailsPage = () => {
    const { releaseId } = useParams<{ releaseId: string }>();
    return (
        <>
            <NavBar />
            <PageLayout>
                <ReleaseProvider>
                    <CampaignDetails releaseId={Number(releaseId)} />
                </ReleaseProvider>
            </PageLayout>
        </>
    );
};

export default CampaignDetailsPage;
