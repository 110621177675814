import { InstagramUser, InstagramUserImage, InstagramUserStatsCache, PhylloUserAnalyticsData } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type InstagramUserDataState = {
    analytics: DataState<PhylloUserAnalyticsData>;
    userStatsCache: DataState<InstagramUserStatsCache>;
    user: DataState<{
        user: InstagramUser | null;
        image: InstagramUserImage | null;
    }>;
};

export type InstagramUserDataAction =
    | ReducerAction<'loadInstagramUserAnalyticsData'>
    | ReducerActionWithPayload<'instagramUserAnalyticsDataLoaded', PhylloUserAnalyticsData>
    | ReducerActionWithPayload<'errorLoadingInstagramUserAnalyticsData', string>
    | ReducerAction<'loadInstagramUserStatsCacheData'>
    | ReducerActionWithPayload<'instagramUserStatsCacheDataLoaded', InstagramUserStatsCache>
    | ReducerActionWithPayload<'errorLoadingInstagramUserStatsCacheData', string>
    | ReducerAction<'loadInstagramUserData'>
    | ReducerActionWithPayload<'instagramUserDataLoaded', { user: InstagramUser; image: InstagramUserImage }>
    | ReducerActionWithPayload<'errorLoadingInstagramUserData', string>
    | ReducerAction<'resetInstagramUserData'>;
export const initialState: InstagramUserDataState = {
    analytics: {
        data: null,
        error: null,
        status: 'idle',
    },
    userStatsCache: {
        data: null,
        error: null,
        status: 'idle',
    },
    user: {
        data: null,
        error: null,
        status: 'idle',
    },
};

export default createReducer<InstagramUserDataState, InstagramUserDataAction>({
    loadInstagramUserAnalyticsData: (state) => ({
        ...state,
        analytics: {
            ...state.analytics,
            status: 'loading',
            error: null,
        },
    }),
    instagramUserAnalyticsDataLoaded: (state, { payload }) => ({
        ...state,
        analytics: {
            data: payload,
            status: 'success',
            error: null,
        },
    }),
    errorLoadingInstagramUserAnalyticsData: (state, { payload }) => ({
        ...state,
        analytics: {
            ...state.analytics,
            status: 'error',
            error: payload,
        },
    }),
    loadInstagramUserStatsCacheData: (state) => ({
        ...state,
        userStatsCache: {
            ...state.userStatsCache,
            status: 'loading',
            error: null,
        },
    }),
    instagramUserStatsCacheDataLoaded: (state, { payload }) => ({
        ...state,
        userStatsCache: {
            data: payload,
            status: 'success',
            error: null,
        },
    }),
    errorLoadingInstagramUserStatsCacheData: (state, { payload }) => ({
        ...state,
        userStatsCache: {
            ...state.userStatsCache,
            status: 'error',
            error: payload,
        },
    }),
    loadInstagramUserData: (state) => ({
        ...state,
        user: {
            ...state.user,
            status: 'loading',
            error: null,
        },
    }),
    instagramUserDataLoaded: (state, { payload: { user, image } }) => ({
        ...state,
        user: {
            status: 'success',
            error: null,
            data: {
                user: user,
                image: image,
            },
        },
    }),
    errorLoadingInstagramUserData: (state, { payload }) => ({
        ...state,
        user: {
            ...state.user,
            status: 'error',
            error: payload,
        },
    }),
    resetInstagramUserData: (state) => ({
        ...state,
        user: {
            data: null,
            error: null,
            status: 'idle',
        },
    }),
});
