import { ReactNode } from 'react';
import styles from './PageLayout.module.css';

type Props = {
    children?: ReactNode;
};

const PageLayout = ({ children }: Props) => <div className={styles.content}>{children}</div>;

export default PageLayout;
