import { useState } from 'react';
import { TabNav, TabNavItem, TabPanel, Tabs } from '@round/ui-kit';
import TiktokTopPosts from '../TopPosts/Tiktok/TiktokTopPosts';
import styles from './TiktokData.module.css';
import TiktokTopAudios from '../TopAudios/Tiktok/TiktokTopAudios';

type TableTab = 'top-audios' | 'top-posts';

const TiktokData = () => {
    const [activeTable, setActiveTable] = useState<TableTab>('top-audios');

    return (
        <>
            <Tabs activeTab={activeTable} onChange={setActiveTable}>
                <TabNav indicatorClassName={styles.activeTabIndicator} trackClassName={styles.activeTabIndicatorTrack}>
                    <TabNavItem name="top-audios" className={styles.tabItem} activeClassName={styles.activeTab}>
                        Top Audios
                    </TabNavItem>
                    <TabNavItem name="top-posts" className={styles.tabItem} activeClassName={styles.activeTab}>
                        Top Posts
                    </TabNavItem>
                </TabNav>

                <TabPanel name="top-audios">
                    <TiktokTopAudios />
                </TabPanel>

                <TabPanel name="top-posts">
                    <TiktokTopPosts />
                </TabPanel>
            </Tabs>
        </>
    );
};

export default TiktokData;
