import { ModalBaseProps } from '@round/ui-kit';
import FullscreenModal from 'ui-new/whitelabel/FullscreenModal/FullscreenModal';
import UserAudienceBreakdown from '../Audience/UserAudienceBreakdown';
import { useUserAnalyticsData } from './Hooks/useUserAnalyticsData';
import { useUserStatsCacheData } from './Hooks/useUserStatsCacheData';
import HeadlineStats from '../HeadlineStats/HeadlineStats';
import { useInstagramAccountsData } from './Hooks/useInstagramAccountData';
import AccountDetail, { AccountDetailProps } from '../AccountDetail/AccountDetail';

type Props = Pick<ModalBaseProps, 'isOpen' | 'onClose'> & {
    userId: number | null;
};

export default function InstagramUserModal({ isOpen, onClose, userId }: Props) {
    const { analytics } = useUserAnalyticsData(userId);
    const { instagramUserStatsCache } = useUserStatsCacheData(userId);
    const { user } = useInstagramAccountsData(userId);

    const accountDetailProps: AccountDetailProps = {
        platform: 'instagram',
        isAccountDataLoading: user.status === 'loading',
        isUserStatsCacheLoading: instagramUserStatsCache.status === 'loading',
        userImage: user.data?.image?.avatar_thumb?.cached_url || user.data?.image?.avatar_thumb?.original_url,
        userHandle: user.data?.user?.username,
        username: user.data?.user?.name,
        bio: user.data?.user?.bio,
        location: instagramUserStatsCache.data?.location,
        onClose: onClose,
    };

    return (
        <FullscreenModal isOpen={isOpen} onClose={onClose} closeOnOverlayClick closeOnEscape>
            <AccountDetail {...accountDetailProps} />
            <HeadlineStats userStatsCache={instagramUserStatsCache} />
            <UserAudienceBreakdown analytics={analytics} />
        </FullscreenModal>
    );
}
