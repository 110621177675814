import { Skeleton } from '@round/ui-kit';
import styles from './ReleaseCell.module.css';
import ReleaseBadge from 'Modules/Advertising/components/ReleaseBadge/ReleaseBadge';
import { microwave, Release } from '@round/api';
import { buildInfluencerPlanUrl } from 'Modules/Advertising/InfluencerPlan/InfluencerPlan.helpers';

type Props = {
    release: Release | undefined;
    platform?: microwave.Platform;
    isLoading?: boolean;
};

const ReleaseCell = ({ release, isLoading, platform }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    if (!release) {
        return <>-</>;
    }

    return (
        <div className={styles.container}>
            <ReleaseBadge
                releaseName={release.name}
                artistName={release.brand.name}
                imageUrl={release.brand.picture}
                isLoading={!!isLoading}
                linkUrl={`${buildInfluencerPlanUrl(release.id)}${platform ? `#${platform}` : ''}`}
            />
        </div>
    );
};

export default ReleaseCell;
