import { CellContext } from '@tanstack/react-table';
import { Meta, TopAudiosTableRow } from '../../TopAudiosTable';
import { getTableMetaHelper, SkeletonizedValue } from '@round/ui-kit';
import { formatNumberToKNotation } from 'helpers';
import { toDecimalPoint } from '@round/utils';
import styles from './CountCell.module.css';
import cn from 'classnames';
import { creatorbase, GetInstagramAudiosOrderingParam } from '@round/api';

type TopAudioTableOrdering = creatorbase.GetTiktokAudiosOrderingParam | GetInstagramAudiosOrderingParam;

export type CountCellData = {
    ordering: TopAudioTableOrdering;
    value: number | null;
    growthValue: string | number | boolean | null;
};
type Context<T extends TopAudiosTableRow> = CellContext<T, CountCellData>;

const getTableMeta = getTableMetaHelper<Meta>();

const formatVideoCountBadgeValue = (
    n: number,
    ordering: creatorbase.GetTiktokAudiosOrderingParam | GetInstagramAudiosOrderingParam
) => {
    if (ordering.includes('relative')) {
        return `${toDecimalPoint(n * 100, 2)}%`;
    }

    return formatNumberToKNotation(n);
};

const CountCell = <T extends TopAudiosTableRow>({ row, table, getValue }: Context<T>) => {
    const { isLoading } = getTableMeta(table);
    const { ordering, value, growthValue } = getValue();

    return (
        <div className={styles.countContainer}>
            <SkeletonizedValue isInitialized={!isLoading}>
                <span>{typeof value === 'number' ? formatNumberToKNotation(value) : '-'}</span>
            </SkeletonizedValue>
            <SkeletonizedValue isInitialized={!isLoading}>
                {typeof growthValue === 'number' && (
                    <span
                        className={cn(styles.growthValue, {
                            [styles.positive]: growthValue >= 0,
                            [styles.negative]: growthValue < 0,
                        })}
                    >
                        {growthValue > 0 && '+'}
                        {formatVideoCountBadgeValue(growthValue, ordering)}
                    </span>
                )}
            </SkeletonizedValue>
        </div>
    );
};

export default CountCell;
