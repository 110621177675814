import { Banner, ControlledPopover, Popover, Select } from '@round/ui-kit';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styles from './CreatorbaseProjectLinkDropdown.module.css';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import useProjectSelect, { mapOptionToProject, ProjectOption } from './hooks/useProjectSelect';
import { StylesConfig } from 'react-select';
import { useLinkedProject } from './hooks/useLinkedProject';
import Button from 'ui/Buttons/Button/Button';
import { showNotification } from 'helpers';
import { isArray } from 'lodash';
import { MergeData, useMergeProjects } from './hooks/useMergeProjects';
import { fetchOptions } from './hooks/useProjectSelect';
import ProjectBadge, { mapOptionToBadgeData } from './components/ProjectBadge';
import cn from 'classnames';
import Modal, { ModalContent, ModalFooter, ModalTitle } from 'ui/General/Modal/Modal';

type TargetProps = {
    ref: Dispatch<SetStateAction<HTMLElement | null>>;
    onClick: () => void;
};

type Props = {
    projectId: number | null | undefined;
    children: (targetProps: TargetProps) => React.ReactNode;
    className?: string;
};

const selectStyles: StylesConfig = {
    container: (base) => ({
        ...base,
        width: '100%',
    }),
    control: (base) => ({
        ...base,
        margin: '0 0.5rem',
    }),
    menu: (base) => ({
        ...base,
        boxShadow: 'none',
        position: 'static',
        border: 'none',
        borderTop: '1px solid #D1D5DB',
        borderBottom: '1px solid #D1D5DB',
        borderRadius: '0',
        width: '100%',
        marginBottom: 0,
    }),
};

const CreatorbaseProjectLinkDropdown = ({ projectId, children, className }: Props) => {
    const { props: selectProps, resetValue: resetSelectedProject, resetOptions } = useProjectSelect({
        fetchOptions: useCallback<typeof fetchOptions>((params, requestInit) => {
            return fetchOptions({ ...params, team_only: false }, requestInit);
        }, []),
    });

    const {
        linkedProject,
        isLinkedProjectLoading,
        hasErrorLoadingLinkedProject,
        brand,
        team,
        hasErrorLoadingSupplementaryData,
    } = useLinkedProject(projectId);

    const { mutate: mergeProjects, status: mergeStatus, reset: resetMerge, data: mergeData } = useMergeProjects();

    const errorResponseMessage = (() => {
        if (mergeStatus === 'error') {
            return 'Something went wrong. Please contact support.';
        }

        if (mergeData?.status === 200 || !mergeData) {
            return null;
        }

        if (isArray(mergeData?.data)) {
            return mergeData.data.join('. ');
        }

        return Object.values(mergeData?.data).join('. ');
    })();

    const handleMerge = (data: MergeData) =>
        mergeProjects(data, {
            onSuccess: (response) => {
                if (response.status !== 200) {
                    return;
                }
                showNotification('Project linked successfully', 'info');
            },
        });

    const [targetRef, setTargetRef] = useState<HTMLElement | null>(null);
    const [popoutRef, setPopoutRef] = useState<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const handleClose = () => {
        if (isConfirmationOpen || mergeStatus === 'pending') {
            return;
        }
        setIsOpen(false);
        resetMerge();
        resetSelectedProject();
        resetOptions();
    };

    useOnClickOutside(popoutRef, handleClose);

    return (
        <>
            {children({ ref: setTargetRef, onClick: () => setIsOpen(!isOpen) })}

            {!isLinkedProjectLoading && !hasErrorLoadingLinkedProject && !linkedProject ? (
                <ControlledPopover
                    ref={setPopoutRef}
                    anchorElement={targetRef}
                    isOpen={isConfirmationOpen ? false : isOpen}
                    options={{ placement: 'bottom', modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
                >
                    <div className={styles.container}>
                        <Select
                            {...selectProps}
                            onChange={(value, meta) => {
                                resetMerge();
                                selectProps.onChange?.(value, meta);
                            }}
                            styles={selectStyles}
                            placeholder="Search projects..."
                            isSearchable
                            menuPortalTarget={null}
                            menuPlacement="bottom"
                            components={{ DropdownIndicator: null }}
                            formatOptionLabel={(option, meta) =>
                                meta.context === 'value' ? (
                                    option.label
                                ) : (
                                    <ProjectBadge data={mapOptionToBadgeData(option as ProjectOption)} />
                                )
                            }
                            menuIsOpen
                        />

                        {errorResponseMessage && (
                            <Banner appearance="danger" className={styles.error}>
                                {errorResponseMessage}
                            </Banner>
                        )}

                        <div className={styles.actions}>
                            <Button onClick={handleClose} disabled={mergeStatus === 'pending'}>
                                Cancel
                            </Button>
                            <Button
                                type="filled"
                                onClick={() => setIsConfirmationOpen(true)}
                                loading={mergeStatus === 'pending'}
                                disabled={
                                    !selectProps.value ||
                                    !!linkedProject ||
                                    mergeStatus === 'pending' ||
                                    !!errorResponseMessage
                                }
                            >
                                Link
                            </Button>
                        </div>
                    </div>
                </ControlledPopover>
            ) : (
                <Popover
                    showOn="hover"
                    anchorElement={targetRef}
                    options={{ placement: 'bottom', modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
                    disabled={isLinkedProjectLoading}
                >
                    {hasErrorLoadingLinkedProject ? (
                        <Banner appearance="danger" className={styles.error}>
                            Something went wrong checking linked project
                        </Banner>
                    ) : (
                        <div className={cn(styles.container, styles.badge)}>
                            <ProjectBadge
                                data={{
                                    name: linkedProject?.name ?? '-',
                                    brandName: brand?.name ?? '-',
                                    teamName: team?.name ?? '-',
                                    imageUrl: brand?.image,
                                }}
                            />
                            {hasErrorLoadingSupplementaryData && (
                                <Banner appearance="danger" className={styles.error}>
                                    We had trouble fetching some of the data
                                </Banner>
                            )}
                        </div>
                    )}
                </Popover>
            )}
            <Modal isOpen={isConfirmationOpen} onClose={() => setIsConfirmationOpen(false)}>
                <ModalTitle>Confirm link to this project?</ModalTitle>
                <ModalContent className={styles.confirmationContent}>
                    {selectProps.value && (
                        <ProjectBadge data={mapOptionToBadgeData(selectProps.value as ProjectOption)} />
                    )}
                </ModalContent>
                <ModalFooter align="right">
                    <Button onClick={() => setIsConfirmationOpen(false)}>Cancel</Button>
                    <Button
                        type="filled"
                        onClick={() => {
                            if (!selectProps.value || !projectId) {
                                return;
                            }

                            handleMerge({
                                planProjectId: projectId,
                                data: { project_id: selectProps.value.application_manager_project_id },
                                creatorbaseProject: mapOptionToProject(selectProps.value),
                            });
                            setIsConfirmationOpen(false);
                        }}
                    >
                        Link
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default CreatorbaseProjectLinkDropdown;
