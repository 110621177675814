import { createContext, Dispatch, useCallback, useContext, useMemo, useReducer } from 'react';
import reducer, { initialState, TiktokCreatorsState, Actions } from './reducer';
import { FCWithChildren } from 'utility/utility.types';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import {
    CreateTiktokInfluencerPostBody,
    CreateTiktokInfluencerPostResponse,
    fetchCreateTiktokInfluencerPost,
} from '../../InfluencerPlan.api';
import * as TiktokApi from '../../api/Tiktok.api';
import {
    deleteInfluencerPostGroup,
    getTiktokInfluencerUser,
    InfluencerPostGroup,
    patchInfluencerPostGroup,
    postInfluencerPostGroup,
    TiktokInfluencerUser,
    getTiktokInfluencerPost,
    PostInfluencerPostGroupData,
    ApiResponse,
    PatchInfluencerPostGroupData,
    PatchInfluencerPostGroupResponse,
} from '@round/api';

type TiktokCreatorsContextValues = TiktokCreatorsState & {
    createTiktokInfluencerPostGroup: (
        data?: Partial<Pick<PostInfluencerPostGroupData, 'audio_id'>>
    ) => Promise<ApiResponse<InfluencerPostGroup, 201> | void>;
    deleteTiktokInfluencerPostGroup: (groupId: number) => Promise<void>;
    updateTiktokInfluencerPostGroup: (
        groupId: number,
        data: PatchInfluencerPostGroupData
    ) => Promise<PatchInfluencerPostGroupResponse>;
    increaseTiktokInfluencerPostGroupOrder: (groupId: number) => Promise<void>;
    decreaseTiktokInfluencerPostGroupOrder: (groupId: number) => Promise<void>;
    createTiktokInfluencerPost: (
        groupId: number,
        post: CreateTiktokInfluencerPostBody
    ) => Promise<CreateTiktokInfluencerPostResponse | void>;
    updateTiktokInfluencerPost: typeof TiktokApi.patchTiktokInfluencerPost;
    updateTiktokInfluencerUser: (influencer: TiktokInfluencerUser) => void;
    refreshTiktokInfluencerPost: (postId: number) => void;
    deleteTiktokInfluencerPost: (postId: number) => Promise<void>;
};

export const TiktokCreatorsStateContext = createContext<TiktokCreatorsState | null>(null);
export const TiktokCreatorsDispatchContext = createContext<Dispatch<Actions> | null>(null);

export const TiktokCreatorsContext = createContext<TiktokCreatorsContextValues | null>(null);
export const TiktokCreatorsContextProvider: FCWithChildren = ({ children }) => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const createTiktokInfluencerPostGroup = useCallback<TiktokCreatorsContextValues['createTiktokInfluencerPostGroup']>(
        async (data) => {
            if (!influencerPlan) {
                return;
            }

            const groupResponse = await postInfluencerPostGroup('tiktok', {
                influencer_plan: influencerPlan.id,
                name: `Influencer Group ${state.tiktokInfluencerPostGroups.length + 1}`,
                ordering_index: state.tiktokInfluencerPostGroups.length,
                budget: '1.00',
                is_hidden: true,
                ...data,
            });

            if (groupResponse.status === 400) {
                throw new Error('Couldnt create post group');
            }

            if (groupResponse.status === 201) {
                dispatch({ type: 'influencerPostGroupCreated', payload: groupResponse.data });
            }

            return groupResponse;
        },
        [influencerPlan, state.tiktokInfluencerPostGroups.length]
    );

    const deleteTiktokInfluencerPostGroup = useCallback(
        async (groupId: number) => {
            const groupToDelete = state.tiktokInfluencerPostGroups.find((g) => g.id === groupId);
            if (!groupToDelete) {
                return;
            }

            const groupPosts = state.tiktokInfluencerPosts.filter((p) => p.group_id === groupId);
            await Promise.all(groupPosts.map((p) => TiktokApi.deleteTiktokInfluencerPost(p.id)));
            const groupsToIncreaseInOrder = state.tiktokInfluencerPostGroups.filter(
                (g) => g.ordering_index > groupToDelete.ordering_index
            );

            const deleteResponse = await deleteInfluencerPostGroup('tiktok', groupId);
            if (deleteResponse.status === 404) {
                throw new Error(`Could not delete group ${groupId}`);
            }
            if (deleteResponse.status === 204) {
                dispatch({ type: 'influencerPostGroupDeleted', payload: groupId });

                const responses = await Promise.all(
                    groupsToIncreaseInOrder.map((group) =>
                        patchInfluencerPostGroup('tiktok', group.id, { ordering_index: group.ordering_index - 1 })
                    )
                );

                const updated = responses.filter((r) => r.status === 200).map((r) => r.data as InfluencerPostGroup);
                dispatch({ type: 'influencerPostGroupsBatchUpdated', payload: updated });
            }
        },
        [state.tiktokInfluencerPostGroups, state.tiktokInfluencerPosts]
    );

    const updateTiktokInfluencerPostGroup: TiktokCreatorsContextValues['updateTiktokInfluencerPostGroup'] = useCallback(
        async (groupId, data) => {
            const response = await patchInfluencerPostGroup('tiktok', groupId, data);
            if (response.status === 200) {
                dispatch({ type: 'influencerPostGroupUpdated', payload: response.data });
            }

            return response;
        },
        []
    );

    const increaseTiktokInfluencerPostGroupOrder = useCallback(
        async (groupId: number) => {
            const group = state.tiktokInfluencerPostGroups.find((g) => g.id === groupId);
            if (!group) {
                return;
            }

            const response = await patchInfluencerPostGroup('tiktok', group.id, {
                ordering_index: group.ordering_index - 1,
            });

            if (response.status === 200) {
                dispatch({ type: 'influencerPostGroupUpdated', payload: response.data });
            }

            if (response.status === 400) {
                throw new Error('Group not found');
            }
        },
        [state.tiktokInfluencerPostGroups]
    );

    const decreaseTiktokInfluencerPostGroupOrder = useCallback(
        async (groupId: number) => {
            const group = state.tiktokInfluencerPostGroups.find((g) => g.id === groupId);
            if (!group) {
                return;
            }

            const response = await patchInfluencerPostGroup('tiktok', group.id, {
                ordering_index: group.ordering_index + 1,
            });

            if (response.status === 200) {
                dispatch({ type: 'influencerPostGroupUpdated', payload: response.data });
            }

            if (response.status === 400) {
                throw new Error('Group not found');
            }
        },
        [state.tiktokInfluencerPostGroups]
    );

    const createTiktokInfluencerPost = useCallback(
        async (groupId: number, post: CreateTiktokInfluencerPostBody) => {
            if (!influencerPlan) {
                return;
            }

            const response = await fetchCreateTiktokInfluencerPost(influencerPlan.id, groupId, post);
            if (response.status === 201) {
                dispatch({ type: 'influencerPostCreated', payload: response.data });

                const influencer = state.tiktokInfluencerUsers.find(
                    (influencer) => influencer.id === response.data.influencer_id
                );

                if (!influencer && response.data.influencer_id) {
                    const influencerUser = await getTiktokInfluencerUser(response.data.influencer_id);
                    dispatch({ type: 'addTiktokInfluencerUser', payload: influencerUser });
                }

                return response;
            }

            return response;
        },
        [influencerPlan, state.tiktokInfluencerUsers]
    );

    const updateTiktokInfluencerPost: TiktokCreatorsContextValues['updateTiktokInfluencerPost'] = useCallback(
        async (postId, post) => {
            const response = await TiktokApi.patchTiktokInfluencerPost(postId, post);
            if (response.status === 200) {
                dispatch({ type: 'influencerPostUpdated', payload: response.data });

                const influencer = state.tiktokInfluencerUsers.find(
                    (influencer) => influencer.id === response.data.influencer_id
                );

                if (!influencer && response.data.influencer_id) {
                    const influencerUser = await getTiktokInfluencerUser(response.data.influencer_id);
                    dispatch({ type: 'addTiktokInfluencerUser', payload: influencerUser });
                }
            }

            return response;
        },
        [state.tiktokInfluencerUsers]
    );

    const refreshTiktokInfluencerPost = useCallback(async (postId: number) => {
        const response = await getTiktokInfluencerPost(postId);
        if (response.status === 200) {
            dispatch({ type: 'influencerPostUpdated', payload: response.data });
            return;
        }
        throw new Error('Could not refresh post');
    }, []);

    const deleteTiktokInfluencerPost = useCallback(async (postId: number) => {
        await TiktokApi.deleteTiktokInfluencerPost(postId);
        dispatch({ type: 'influencerPostDeleted', payload: postId });
    }, []);

    const values: TiktokCreatorsContextValues = useMemo(
        () => ({
            ...state,
            createTiktokInfluencerPostGroup,
            deleteTiktokInfluencerPostGroup,
            updateTiktokInfluencerPostGroup,
            increaseTiktokInfluencerPostGroupOrder,
            decreaseTiktokInfluencerPostGroupOrder,
            createTiktokInfluencerPost,
            updateTiktokInfluencerPost,
            refreshTiktokInfluencerPost,
            deleteTiktokInfluencerPost,
            updateTiktokInfluencerUser: (influencer) =>
                dispatch({ type: 'influencerUserUpdated', payload: influencer }),
        }),
        [
            createTiktokInfluencerPost,
            createTiktokInfluencerPostGroup,
            decreaseTiktokInfluencerPostGroupOrder,
            deleteTiktokInfluencerPost,
            deleteTiktokInfluencerPostGroup,
            increaseTiktokInfluencerPostGroupOrder,
            refreshTiktokInfluencerPost,
            state,
            updateTiktokInfluencerPost,
            updateTiktokInfluencerPostGroup,
        ]
    );

    return (
        <TiktokCreatorsContext.Provider value={values}>
            <TiktokCreatorsStateContext.Provider value={state}>
                <TiktokCreatorsDispatchContext.Provider value={dispatch}>
                    {children}
                </TiktokCreatorsDispatchContext.Provider>
            </TiktokCreatorsStateContext.Provider>
        </TiktokCreatorsContext.Provider>
    );
};
