import { Response } from 'miragejs';
import { AppServer } from '../../mirage';
import { PostEmailsDataItem } from './emails.api';

export function handleRequests(server: AppServer) {
    server.post('/api/microwave/emails/', (schema, request) => {
        const emailRequests = JSON.parse(request.requestBody) as PostEmailsDataItem[];

        return new Response(201, {}, { success: emailRequests.map((request) => request.invite_id), failure: [] });
    });
}
