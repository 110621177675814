import useNonNullContext from 'Hooks/useNonNullContext';
import { getInstagramUser, getInstagramUserImages } from '@round/api';
import { InstagramUserDataContext } from '../InstagramUserDataContext/InstagramUserDataContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useCallback } from 'react';

export function useInstagramAccountsData(userId: number | undefined | null) {
    const { user, dispatch } = useNonNullContext(InstagramUserDataContext);
    const isInitialized = user.status === 'success' || user.status === 'error';

    const fetchData = useCallback(
        async (userId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadInstagramUserData' });
                const [userResponse, userImageResponse] = await Promise.allSettled([
                    getInstagramUser(userId, requestInit),
                    getInstagramUserImages([userId], requestInit),
                ]);

                if (
                    userResponse.status === 'fulfilled' &&
                    userImageResponse.status === 'fulfilled' &&
                    userResponse.value.status === 200
                ) {
                    dispatch({
                        type: 'instagramUserDataLoaded',
                        payload: {
                            user: userResponse.value.data,
                            image: userImageResponse.value.data[0],
                        },
                    });
                    return;
                }

                dispatch({
                    type: 'errorLoadingInstagramUserData',
                    payload: "Couldn't fetch instagram user data",
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    dispatch({ type: 'resetInstagramUserData' });
                    return;
                }
                dispatch({
                    type: 'errorLoadingInstagramUserData',
                    payload: "Couldn't fetch instagram user data",
                });
            }
        },
        [dispatch]
    );

    useAbortableEffect(
        (signal) => {
            if (!isInitialized && userId) {
                fetchData(userId, { signal });
            }
        },
        [userId, fetchData, isInitialized]
    );

    return {
        user: user,
    };
}
