import { creatorbase } from '@round/api';
import * as yup from 'yup';
import styles from './SongForm.module.css';
import { FormField } from 'ui-new/whitelabel/formik';
import RadioOptionCard from 'ui-new/whitelabel/Radio/RadioOptionCard/RadioOptionCard';
import { useFormikContext } from 'formik';
import { ReactComponent as InfoIcon } from 'assets/InfoCircle.svg';
import SongFormAudios from './audios/SongFormAudios';

export type SongFormValues = Pick<
    creatorbase.Song,
    'title' | 'artist_name' | 'status' | 'tiktok_audios' | 'instagram_audios'
> & {
    spotify_url: string;
};

export const validationSchema = yup.object().shape({
    title: yup.string().when('status', {
        is: (status: string) => status === 'unreleased',
        then: (schema) => schema.required('Title is required'),
    }),
    artist_name: yup.string().when('status', {
        is: (status: string) => status === 'unreleased',
        then: (schema) => schema.required('Artist name is required'),
    }),
    status: yup.string().required('Song status is required'),
    spotify_url: yup.string().when('status', {
        is: (status: string) => status === 'released',
        then: (schema) => schema.required('Spotify URL is required'),
    }),
});

type Props = {
    staticTiktokAudios?: number[];
    clientSongTiktokAudios?: number[];
    staticInstagramAudios?: number[];
    clientSongInstagramAudios?: number[];
};

const SongForm = ({
    staticTiktokAudios,
    clientSongTiktokAudios,
    staticInstagramAudios,
    clientSongInstagramAudios,
}: Props) => {
    const { values, setFieldValue } = useFormikContext<SongFormValues>();

    const combinedStaticAudios = [...(staticTiktokAudios ?? []), ...(staticInstagramAudios ?? [])];

    return (
        <form className={styles.form}>
            <section className={styles.songSection}>
                <FormField
                    name="status"
                    label="Song"
                    component={() => (
                        <div className={styles.statusOptions}>
                            <RadioOptionCard
                                name="status"
                                label="Released"
                                subLabel="Link to a song on Spotify"
                                onClick={() => setFieldValue('status', 'released')}
                                isSelected={values.status === 'released'}
                            />
                            <RadioOptionCard
                                name="status"
                                label="Unreleased"
                                subLabel="Enter song details manually, link to a Spotify song later"
                                onClick={() => setFieldValue('status', 'unreleased')}
                                isSelected={values.status === 'unreleased'}
                            />
                            {values.status === 'unreleased' && !!combinedStaticAudios.length && (
                                <div className={styles.audioLinkWarning}>
                                    <InfoIcon className={styles.infoCircle} />
                                    <span>
                                        {`${combinedStaticAudios.length} audio link${
                                            combinedStaticAudios.length > 1 ? 's' : ''
                                        } will be lost when unreleasing this song`}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                />

                {values.status === 'unreleased' && (
                    <>
                        <FormField name="title" label="Song name" placeholder="Enter the song name" />
                        <FormField name="artist_name" label="Artist name" placeholder="Enter the artist name" />
                    </>
                )}

                {values.status === 'released' && (
                    <FormField name="spotify_url" label="Spotify URL" placeholder="Enter Spotify URL" />
                )}
            </section>

            <SongFormAudios
                staticTiktokAudios={staticTiktokAudios}
                clientSongTiktokAudios={clientSongTiktokAudios}
                staticInstagramAudios={staticInstagramAudios}
                clientSongInstagramAudios={clientSongInstagramAudios}
            />
        </form>
    );
};

export default SongForm;
