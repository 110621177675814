import React from 'react';
import BadgeDropdown from '../../../components/BadgeDropdown/BadgeDropdown';
import { ReactComponent as HashtagIcon } from 'assets/Hashtag.svg';
import styles from './Hashtags.module.css';
import Button from 'ui/Buttons/Button/Button';
import { ReactComponent as TrashIcon } from 'assets/TrashIcon.svg';
import { buildTiktokHashtagUrl } from 'helpers';
import { TikTokInfluencerPlanHashtag } from '@round/api';

type HashtagsProps = {
    loading?: boolean;
    hashtags: TikTokInfluencerPlanHashtag[];
    readonly?: boolean;
    onAddHashtag?: () => void;
    onHashtagDelete?: (audio: TikTokInfluencerPlanHashtag) => void;
    disabled?: boolean;
};

const Hashtags = ({ hashtags, onHashtagDelete, onAddHashtag, readonly, loading, disabled }: HashtagsProps) => {
    const handleAddHashtag = () => {
        if (typeof onAddHashtag === 'function') {
            onAddHashtag();
        }
    };

    const handleDeleteClick = (hashtag: TikTokInfluencerPlanHashtag) => {
        if (typeof onHashtagDelete === 'function') {
            onHashtagDelete(hashtag);
        }
    };

    return (
        <BadgeDropdown
            disabled={disabled}
            loading={loading}
            value={hashtags.length}
            label="Hashtags"
            Icon={HashtagIcon}
        >
            {(setShow) => (
                <>
                    {!readonly && (
                        <Button
                            className={styles.addHashtagButton}
                            type="link"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleAddHashtag();
                                setShow(false);
                            }}
                        >
                            + Add hashtag
                        </Button>
                    )}
                    <ul className={styles.list}>
                        {hashtags.map((hashtag) => (
                            <li key={hashtag.id}>
                                <a
                                    className={styles.listItem}
                                    href={buildTiktokHashtagUrl(hashtag.hashtag)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span className={styles.audio}>#{hashtag.hashtag}</span>
                                    {!readonly && (
                                        <Button
                                            className={styles.deleteButton}
                                            color="negative"
                                            type="link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShow(false);
                                                handleDeleteClick(hashtag);
                                            }}
                                        >
                                            <TrashIcon className={styles.deleteIcon} />
                                        </Button>
                                    )}
                                </a>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </BadgeDropdown>
    );
};

export default Hashtags;
