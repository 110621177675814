import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { ApiResponse, ApiResponseError, NotFoundResponse } from '../../types';
import { YoutubeInfluencerPostGroup, YoutubeInfluencerPostGroupStats } from './youtubeInfluencerPostGroups.types';

export type GetYoutubeInfluencerPostGroupParams = {
    influencer_plan_id: number;
};

export async function getAllYoutubeInfluencerPostGroups(
    params: GetYoutubeInfluencerPostGroupParams,
    requestInit?: RequestInit
): Promise<YoutubeInfluencerPostGroup[]> {
    return await fetchAll(
        `/api/advertising/youtube-influencer-post-groups/${encodeUrlSearchParams(params)}`,
        requestInit
    );
}

export type GetYoutubeInfluencerPostGroupStatsParams = {
    id: string;
};

type GetYoutubeInfluencerPostGroupStatsResponse = ApiResponse<YoutubeInfluencerPostGroupStats[], 200>;

export async function getYoutubeInfluencerPostGroupLiveStats(
    params: GetYoutubeInfluencerPostGroupStatsParams,
    requestInit?: RequestInit
): Promise<GetYoutubeInfluencerPostGroupStatsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/youtube-influencer-post-groups-live-stats/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (!response.ok) {
        throw new Error(`Could not get youtube influencer post group stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostYoutubeInfluencerPostGroupBody = Pick<
    YoutubeInfluencerPostGroup,
    'name' | 'influencer_plan' | 'ordering_index' | 'budget' | 'is_hidden'
>;
type PostYoutubeInfluencerPostGroupResponse =
    | ApiResponse<YoutubeInfluencerPostGroup, 201>
    | ApiResponse<ApiResponseError<PostYoutubeInfluencerPostGroupBody>, 400>
    | ApiResponse<string[], 400>;

export async function postYoutubeInfluencerPostGroup(
    data: PostYoutubeInfluencerPostGroupBody
): Promise<PostYoutubeInfluencerPostGroupResponse> {
    const response = await fetchWithToken('/api/advertising/youtube-influencer-post-groups/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create youtube influencer post group');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchYoutubeInfluencerPostGroupBody = Partial<
    Pick<
        YoutubeInfluencerPostGroup,
        'brief' | 'brief_turnaround' | 'budget' | 'is_hidden' | 'name' | 'ordering_index' | 'is_shared_with_creatorbase'
    >
>;

export type PatchYoutubeInfluencerPostGroupResponse =
    | ApiResponse<YoutubeInfluencerPostGroup, 200>
    | ApiResponse<string[], 400>
    | ApiResponse<ApiResponseError<YoutubeInfluencerPostGroup>, 400>
    | NotFoundResponse;

export async function patchYoutubeInfluencerPostGroup(
    id: number,
    data: PatchYoutubeInfluencerPostGroupBody
): Promise<PatchYoutubeInfluencerPostGroupResponse> {
    const response = await fetchWithToken(`/api/advertising/youtube-influencer-post-groups/${id}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update youtube influencer post group');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type DeleteYoutubeInfluencerPostGroupResponse = ApiResponse<null, 204> | NotFoundResponse;

export async function deleteYoutubeInfluencerPostGroup(id: number): Promise<DeleteYoutubeInfluencerPostGroupResponse> {
    const response = await fetchWithToken(`/api/advertising/youtube-influencer-post-groups/${id}/`, {
        method: 'DELETE',
    });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete youtube influencer post group');
    }

    return {
        status: 204,
        data: null,
    };
}
