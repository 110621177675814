import MetricCardWithChart from 'ui/DataDisplay/Statistics/MetricCardWithChart/MetricCardWithChart';
import styles from './Indicators.module.css';
import MusicNoteIcon from '../../../../../SharedComponents/svg/Icons/MusicNoteIcon';
import VideoCameraIcon from '../../../../../SharedComponents/svg/Icons/VideoCameraIcon';
import CalendarMonthIcon from '../../../../../SharedComponents/svg/Icons/CalendarMonthIcon';
import { usePlatformFilters } from '../PlatformFilters/usePlatformFilters';
import { useIndicators } from './useIndicators';

const Indicators = () => {
    const { activePlatforms } = usePlatformFilters();
    const { data, isInitialized } = useIndicators(activePlatforms);

    return (
        <div className={styles.headlineCalculations}>
            <MetricCardWithChart
                loading={!isInitialized}
                baseColor="#0090FF"
                label="Total Audios"
                value={data?.audio_count}
                Icon={MusicNoteIcon}
            />
            <MetricCardWithChart
                loading={!isInitialized}
                baseColor="#F7685B"
                label="Total video creations"
                value={data?.video_count}
                Icon={VideoCameraIcon}
            />
            <MetricCardWithChart
                loading={!isInitialized}
                baseColor="#FFA114"
                label="creations in the last 7 days"
                value={data?.video_count_weekly_change}
                Icon={CalendarMonthIcon}
            />
            <MetricCardWithChart
                loading={!isInitialized}
                baseColor="#48D98A"
                label="daily creations"
                value={data?.video_count_daily_change}
                Icon={CalendarMonthIcon}
            />
        </div>
    );
};

export default Indicators;
