import { call, encodeUrlSearchParams } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { InfluencerUserCampaigns, InfluencerPlan } from './influencerPlan.types';

export type GetInfluencerPlansParams = Partial<
    PaginatedRequest & {
        search: string;
        release_id: number;
        id: string;
    }
>;

type GetInfluencerPlansResponse = ApiResponse<PaginatedApiResponseData<InfluencerPlan>, 200> | NotFoundResponse;

export async function getInfluencerPlans(
    params: GetInfluencerPlansParams,
    requestInit?: RequestInit
): Promise<GetInfluencerPlansResponse> {
    const response = await call(
        `/api/advertising/viewsets/influencer-plan/${encodeUrlSearchParams(params)}`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not fetch influencer plans');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInfluencerUserInfluencerPlans(
    params: Partial<{ tiktok_influencer_users: string; instagram_influencer_users: string }>,
    requestInit?: RequestInit
): Promise<ApiResponse<InfluencerUserCampaigns[], 200> | ApiResponse<string, 400>> {
    const response = await call(`/api/advertising/influencer-plans/${encodeUrlSearchParams(params)}`, requestInit);
    if (response.status === 400) {
        return {
            status: 400,
            data: await response.text(),
        };
    }

    if (!response.ok) {
        throw new Error(
            `Could not get influencer user campaigns.
            influencer user ids: ${params.instagram_influencer_users || ''} ${params.tiktok_influencer_users || ''}`
        );
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostInfluencerPlanData = Pick<
    InfluencerPlan,
    'start_date' | 'end_date' | 'instagram_budget' | 'tiktok_budget' | 'youtube_budget' | 'planners'
> & {
    release_id: number;
    currency_id: number;
};

export type PostInfluencerPlanResponse =
    | ApiResponse<InfluencerPlan, 201>
    | ApiResponse<ApiResponseError<PostInfluencerPlanData>, 400>
    | ForbiddenResponse;

export async function postInfluencerPlan(data: PostInfluencerPlanData): Promise<PostInfluencerPlanResponse> {
    const response = await call('/api/advertising/viewsets/influencer-plan/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create influencer plan');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchInfluencerPlanData = Partial<
    Pick<
        InfluencerPlan,
        | 'start_date'
        | 'end_date'
        | 'instagram_budget'
        | 'tiktok_budget'
        | 'youtube_budget'
        | 'planners'
        | 'micros_brief'
        | 'micros_payment_request_url'
        | 'micros_payment_request_deadline_in_days'
    > & {
        release_id: number;
        currency_id: number;
    }
>;

export type PatchInfluencerPlanResponse =
    | ApiResponse<InfluencerPlan, 200>
    | ApiResponse<ApiResponseError<PatchInfluencerPlanData>, 400>
    | NotFoundResponse
    | ForbiddenResponse;

export async function patchInfluencerPlan(
    planId: number,
    data: PatchInfluencerPlanData
): Promise<PatchInfluencerPlanResponse> {
    const response = await call(`/api/advertising/viewsets/influencer-plan/${planId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not update influencer plan: ${planId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type DeleteInfluencerPlanResponse =
    | ApiResponse<null, 204>
    | ApiResponse<{ detail: string }, 400>
    | NotFoundResponse
    | ForbiddenResponse;
export async function deleteInfluencerPlan(planId: number): Promise<DeleteInfluencerPlanResponse> {
    const response = await call(`/api/advertising/viewsets/influencer-plan/${planId}/`, {
        method: 'DELETE',
    });

    if (response.status === 404 || response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete influencer plan');
    }

    return {
        status: 204,
        data: null,
    };
}
