import { createServer, Model } from 'miragejs';
import { createFactory, makeSerializer } from './mirage.helpers';
import * as authMirage from './auth/auth.mirage';
import * as advertisingMirage from './advertising/advertising.mirage';
import * as tiktokMirage from './tiktok/tiktok.mirage';
import * as musicMirage from './music/music.mirage';
import * as facebookMirage from './facebook/facebook.mirage';
import * as spotifyMirage from './spotify/spotify.mirage';
import * as xeroMirage from './xero/xero.mirage';
import * as instagramMirage from './instagram/instagram.mirage';
import * as apexMirage from './apex/apex.mirage';
import { ModelDefinition } from 'miragejs/-types';
import { CachedAsset } from './types';
import * as trndsMirage from './trnds/trnds.mirage';
import * as usersMirage from './users/users.mirage';
import * as collectionMirage from './collection/collection.mirage';
import * as creatorbaseMirage from './creatorbase/creatorbase.mirage';
import * as microwaveMirage from './microwave/microwave.mirage';
import * as contactMirage from './contact/contact.mirage';
import * as influencerMirage from './influencer/influencer.mirage';
import * as genreAffinityMirage from './genreAffinity/genreAffinity.mirage';
import * as youtubeMirage from './youtube/youtube.mirage';
import * as applicationManagerMirage from './applicationManager/applicationManager.mirage';

const models = {
    cachedImage: Model as ModelDefinition<CachedAsset>,
    cachedAudio: Model as ModelDefinition<CachedAsset>,
    ...advertisingMirage.models,
    ...tiktokMirage.models,
    ...musicMirage.models,
    ...facebookMirage.models,
    ...spotifyMirage.models,
    ...xeroMirage.models,
    ...instagramMirage.models,
    ...apexMirage.models,
    ...trndsMirage.models,
    ...usersMirage.models,
    ...collectionMirage.models,
    ...creatorbaseMirage.models,
    ...microwaveMirage.models,
    ...contactMirage.models,
    ...influencerMirage.models,
    ...genreAffinityMirage.models,
    ...youtubeMirage.models,
    ...applicationManagerMirage.models,
};

const factories = {
    cachedImage: createFactory<CachedAsset>({
        cached_url(index: number) {
            return `cached image url: ${index}`;
        },
        original_url(index: number) {
            return `cached image original url: ${index}`;
        },
        last_updated() {
            return new Date().toISOString();
        },
    }),
    cachedAudio: createFactory<CachedAsset>({
        cached_url(index: number) {
            return `cached audio url: ${index}`;
        },
        original_url(index: number) {
            return `cached audio original url: ${index}`;
        },
        last_updated() {
            return new Date().toISOString();
        },
    }),
    ...advertisingMirage.factories,
    ...tiktokMirage.factories,
    ...musicMirage.factories,
    ...facebookMirage.factories,
    ...spotifyMirage.factories,
    ...xeroMirage.factories,
    ...instagramMirage.factories,
    ...apexMirage.factories,
    ...trndsMirage.factories,
    ...usersMirage.factories,
    ...collectionMirage.factories,
    ...creatorbaseMirage.factories,
    ...microwaveMirage.factories,
    ...contactMirage.factories,
    ...influencerMirage.factories,
    ...genreAffinityMirage.factories,
    ...youtubeMirage.factories,
    ...applicationManagerMirage.factories,
};

const serializers = {
    application: makeSerializer([]),
    ...advertisingMirage.serializers,
    ...tiktokMirage.serializers,
    ...musicMirage.serializers,
    ...facebookMirage.serializers,
    ...spotifyMirage.serializers,
    ...xeroMirage.serializers,
    ...instagramMirage.serializers,
    ...apexMirage.serializers,
    ...trndsMirage.serializers,
    ...usersMirage.serializers,
    ...collectionMirage.serializers,
    ...creatorbaseMirage.serializers,
    ...microwaveMirage.serializers,
    ...contactMirage.serializers,
    ...influencerMirage.serializers,
    ...genreAffinityMirage.serializers,
    ...youtubeMirage.serializers,
    ...applicationManagerMirage.serializers,
};

export type RouteHandlerContext = {
    serialize: (resource: any, serializer?: keyof typeof serializers) => any;
};

export type AppServer = ReturnType<typeof makeServer>;

type MakeServerParams = { environment?: string };
export default function makeServer({ environment = 'test' }: MakeServerParams = {}) {
    return createServer<typeof models, typeof factories>({
        environment,
        factories,
        models,
        serializers,
        routes() {
            authMirage.handleAuthRequests(this);
            advertisingMirage.handleAdvertisingRequests(this);
            tiktokMirage.handleTiktokRequests(this);
            musicMirage.handleMusicRequests(this);
            facebookMirage.handleFacebookRequests(this);
            spotifyMirage.handleSpotifyRequests(this);
            xeroMirage.handleXeroRequests(this);
            instagramMirage.handleInstagramRequests(this);
            apexMirage.handleApexRequests(this);
            trndsMirage.handleRequests(this);
            usersMirage.handleRequests(this);
            collectionMirage.handleRequests(this);
            creatorbaseMirage.handleRequests(this);
            microwaveMirage.handleRequests(this);
            contactMirage.handleRequests(this);
            influencerMirage.handleRequests(this);
            genreAffinityMirage.handleRequests(this);
            youtubeMirage.handleRequests(this);
            applicationManagerMirage.handleRequests(this);
        },
    });
}
