import { call } from '../../helpers';
import { ApiResponse, ApiResponseError, ForbiddenResponse, NotFoundResponse } from '../../types';
import { Project } from './projects.types';

type GetProjectResponse = ApiResponse<Project, 200> | NotFoundResponse | ForbiddenResponse;

export async function getProject(id: number, requestInit?: RequestInit): Promise<GetProjectResponse> {
    const response = await call(`/api/application_manager/projects/${id}/`, requestInit);

    if (response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get project');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
export type PostMergeProjectsData = {
    project_id: number;
};
type PostMergeProjectsResponse =
    | ApiResponse<Project, 200>
    | ApiResponse<string[], 400>
    | ApiResponse<ApiResponseError<PostMergeProjectsData>, 400>
    | NotFoundResponse
    | ForbiddenResponse;
export async function postMergeProjects(id: number, data: PostMergeProjectsData): Promise<PostMergeProjectsResponse> {
    const response = await call(`/api/application_manager/projects/${id}/merge/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 403 || response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not merge projects');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
