import React from 'react';
import { PhylloUserAnalyticsData } from '@round/api';
import { Doughnut } from 'react-chartjs-2';
import capitalize from 'lodash/capitalize';
import { Chart, LegendItem } from 'chart.js';
import { colors } from './helpers';
import LoadingSpinner from 'SharedComponents/LoadingSpinner/LoadingSpinner';
import { numberWithCommas, roundTo2Dp } from 'helpers';

type Props = {
    data: PhylloUserAnalyticsData['profile']['audience']['gender_age_distribution'];
    className?: string;
    loading?: boolean;
};

const GenderChart = ({ data, className, loading }: Props) => {
    const genders = Array.from(new Set(data.map((item) => item.gender)));
    const values = genders.map((gender) => {
        return data.reduce((acc, item) => {
            if (item.gender === gender) {
                return acc + item.value;
            }

            return acc;
        }, 0);
    });

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={className}>
            <Doughnut
                data={{
                    labels: genders.map((gender) => capitalize(gender)),
                    datasets: [
                        {
                            data: values,
                            backgroundColor: colors,
                        },
                    ],
                }}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'bottom',
                            labels: {
                                generateLabels(chart: Chart): LegendItem[] {
                                    const dataset = chart.data.datasets[0];
                                    return dataset.data.map((value, index) => {
                                        return {
                                            text: `${chart.data.labels![index]} ${numberWithCommas(
                                                roundTo2Dp(Number(value))
                                            )}%`,
                                            fillStyle: colors[index],
                                            strokeStyle: colors[index],
                                        };
                                    });
                                },
                                usePointStyle: true,
                                pointStyle: 'circle',
                                boxWidth: 8,
                                boxHeight: 8,
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default GenderChart;
