import { creatorbase } from '@round/api';
import { getTableMetaHelper } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import UserBadge, { UserBadgeProps } from 'Modules/Plans/User/UserBadge/UserBadge';

type Row = creatorbase.PublicPost;
export type AccountData = {
    image: string | undefined;
    title: string;
    followerCount?: number | null;
};

type Context<T extends Row> = CellContext<T, AccountData>;

const getTableMeta = getTableMetaHelper<BasePostTableMeta<Row>>();

const PublicPostsTableAccountCell = <T extends Row>({ row, table, getValue }: Context<T>) => {
    const { isLoading, getIsAccountDataLoading } = getTableMeta(table);
    const { image, title, followerCount } = getValue();

    const userBadgeProps: UserBadgeProps = {
        isLoading: isLoading || getIsAccountDataLoading(row.original),
        image: image,
        title: title,
        followerCount: followerCount,
    };

    return <UserBadge {...userBadgeProps} />;
};

export default PublicPostsTableAccountCell;
