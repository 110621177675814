import { ModalBaseProps, TabPanel } from '@round/ui-kit';
import FullscreenModal from 'ui-new/whitelabel/FullscreenModal/FullscreenModal';
import SongData from './SongData/SongData';
import styles from './SongDataModal.module.css';
import { ReactComponent as CloseIcon } from 'assets/whitelabel/Cross.svg';
import { useState } from 'react';
import TiktokData from './Tiktok/TiktokData';
import Indicators from './Indicators/Indicators';
import Creations from './Creations/Creations';
import PlatformFilters from './PlatformFilters/PlatformFilters';
import { Platform, PlatformFiltersProvider } from './PlatformFilters/usePlatformFilters';
import PlatformIcon from 'Modules/Plans/components/PlatformIcon';
import UnderLineTabs from 'ui-new/whitelabel/Tabs/UnderlineTabs/UnderLineTabs';
import InstagramData from './Instagram/InstagramData';
import useSongData from './SongData/useSongData';
import { getMergedAudioIdsFromSongData } from './helpers';

type Props = Pick<ModalBaseProps, 'isOpen' | 'onClose'> & {
    songId: number | undefined | null;
};

type PlatformTabs = 'tiktok' | 'instagram';

const SongDataModal = ({ isOpen, onClose, songId }: Props) => {
    const [activePlatform, setActivePlatform] = useState<PlatformTabs>('tiktok');

    const { data: songData, status } = useSongData();
    const isSongDataInitialized = status === 'success' || status === 'error';

    const { tiktokAudioIds, instagramAudioIds } = getMergedAudioIdsFromSongData(songData?.song, songData?.musicSong);

    return (
        <FullscreenModal isOpen={isOpen} onClose={onClose} closeOnOverlayClick closeOnEscape>
            <div className={styles.header}>
                <SongData key={songId} songId={songId} />

                <button className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>

            <section className={styles.content}>
                <PlatformFiltersProvider
                    availablePlatforms={
                        !isSongDataInitialized
                            ? []
                            : ([
                                  ...(tiktokAudioIds.length ? ['tiktok'] : []),
                                  ...(instagramAudioIds.length ? ['instagram'] : []),
                              ] as Platform[])
                    }
                >
                    <PlatformFilters />
                    <div className={styles.statsContainer}>
                        <Indicators />
                        <Creations />
                    </div>
                </PlatformFiltersProvider>
            </section>

            <UnderLineTabs activeTab={activePlatform} onChange={(platform) => setActivePlatform(platform)}>
                    <>
                        <UnderLineTabs.Menu className={styles.platformTabContainer}>
                            <UnderLineTabs.Item name="tiktok">
                                <PlatformIcon
                                    platform="tiktok"
                                    colored={activePlatform === 'tiktok'}
                                    className={styles.platformIcon}
                                />
                                <span>TikTok Audio</span>
                            </UnderLineTabs.Item>
                            <UnderLineTabs.Item name="instagram">
                                <PlatformIcon
                                    platform="instagram"
                                    colored={activePlatform === 'instagram'}
                                    className={styles.platformIcon}
                                />
                                <span>Instagram Audio</span>
                            </UnderLineTabs.Item>
                        </UnderLineTabs.Menu>

                        <div className={styles.content}>
                            <TabPanel name="tiktok">
                                <TiktokData />
                            </TabPanel>
                            <TabPanel name="instagram">
                                <InstagramData />
                            </TabPanel>
                        </div>
                    </>
            </UnderLineTabs>
        </FullscreenModal>
    );
};

export default SongDataModal;
