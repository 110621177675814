import React, { useState } from 'react';
import useUrlState from 'Hooks/useUrlState';
import useAdminMicrowaveInfluencers from './useAdminMicrowaveInfluencers';
import MicrowaveInfluencersTable from './MicrowaveInfluencersTable/MicrowaveInfluencersTable';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import styles from './MicrowaveInfluencers.module.css';
import Button from 'ui/Button/Button';
import CreateMicrowaveInfluencer from './CreateMicrowaveInfluencer/CreateMicrowaveInfluencer';
import { MicrowaveInfluencersTableRow } from './MicrowaveInfluencersTable/MicrowaveInfluencersTable';
import EditMicrowaveInfluencer from './EditMicrowaveInfluencer/EditMicrowaveInfluencer';
import ExpandableSearchInput from 'ui/ExpandableSearchInput/ExpandableSearchInput';
import Header from 'ui-new/Layout/Header/Header';

type UrlState = { page: number; page_size: number } & Partial<{ search: string }>;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 25,
};

const MicrowaveInfluencers = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);
    const [isCreateInfluencerModalOpen, setIsCreateInfluencerModalOpen] = useState(false);
    const [isEditInfluencerModalOpen, setIsEditInfluencerModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<MicrowaveInfluencersTableRow | null>(null);

    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;

    const { rows, count, init, reset, error, isInitialized } = useAdminMicrowaveInfluencers({
        page,
        page_size: pageSize,
        search: urlState.search,
    });

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [init, isInitialized]
    );

    const isTableLoading = !isInitialized && !error;
    return (
        <>
            <Header className={styles.header}>
                <Header.Title>Microwave Influencers</Header.Title>
                <div className={styles.optionsContainer}>
                    <div className={styles.toolbar}>
                        <ExpandableSearchInput
                            persistIfValuePresent
                            value={urlState.search}
                            onChange={(search) => {
                                setUrlState({ search, page: 1 });
                                reset();
                            }}
                            debounceTimeout={700}
                        />

                        <Button appearance="primary" onClick={() => setIsCreateInfluencerModalOpen(true)}>
                            Add influencer
                        </Button>
                    </div>
                </div>
            </Header>

            <MicrowaveInfluencersTable
                isLoading={isTableLoading}
                data={rows}
                count={count}
                page={page}
                setPage={(page) => {
                    setUrlState({ page });
                    reset();
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                    setUrlState({ page_size: pageSize ?? initialUrlState.page_size });
                    reset();
                }}
                onRowClick={(row) => {
                    setSelectedRow(row.original);
                    setIsEditInfluencerModalOpen(true);
                }}
                manualPagination
            />

            <CreateMicrowaveInfluencer
                isOpen={isCreateInfluencerModalOpen}
                closeModal={() => setIsCreateInfluencerModalOpen(false)}
                onCreated={() => reset()}
            />

            <EditMicrowaveInfluencer
                isOpen={isEditInfluencerModalOpen}
                closeModal={() => {
                    setSelectedRow(null);
                    setIsEditInfluencerModalOpen(false);
                }}
                influencer={selectedRow}
                onUpdated={() => {
                    setSelectedRow(null);
                    reset();
                }}
            />
        </>
    );
};

export default MicrowaveInfluencers;
