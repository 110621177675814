import { NavBar } from 'SharedComponents/NavBar/NavBar';
import PageLayout from 'ui-new/Layout/PageLayout/PageLayout';
import MicrowaveInfluencers from 'Modules/Advertising/Microwave/MicrowaveInfluencers/MicrowaveInfluencers';

const MicrowaveInfluencersPage = () => (
    <>
        <NavBar />
        <PageLayout>
            <MicrowaveInfluencers />
        </PageLayout>
    </>
);

export default MicrowaveInfluencersPage;
