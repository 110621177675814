import { creatorbase, Song } from '@round/api';
import uniq from 'lodash/uniq';

export const getMergedAudioIdsFromSongData = (
    clientSong: creatorbase.Song | undefined,
    musicSong: Song | undefined
): { tiktokAudioIds: number[]; instagramAudioIds: number[] } => {
    const tiktokAudioIds = uniq([...(clientSong?.tiktok_audios || []), ...(musicSong?.tiktok_audios || [])]);
    const instagramAudioIds = uniq([...(clientSong?.instagram_audios || []), ...(musicSong?.instagram_audios || [])]);

    return {
        tiktokAudioIds,
        instagramAudioIds,
    };
};
