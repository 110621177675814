import { ReactComponent as AudioIcon } from 'assets/whitelabel/Audio.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import { numberWithCommas, openInNewTab } from 'helpers';
import styles from './AudioGraph.module.css';
import cn from 'classnames';
import MiniLineChart from 'ui-new/whitelabel/Charts/MiniLineChart/MiniLineChart';
import moment from 'moment';
import Tooltip from 'ui-new/whitelabel/Tooltip/Tooltip';
import { Point } from 'ui-new/whitelabel/Charts/types';
type Props = {
    audioUrl?: string;
    points: Point[];
    shouldDisplayTimeSeriesData: boolean;
    isTimeSeriesLoading: boolean;
    lineColor?: string;
    hasError?: boolean;
};

const AudioGraph = ({
    audioUrl,
    points,
    shouldDisplayTimeSeriesData,
    isTimeSeriesLoading,
    lineColor = '#2A94FF',
    hasError,
}: Props) => {
    const Icon = hasError ? WarningIcon : AudioIcon;

    return (
        <Tooltip.Root>
            {(anchor, setRef) => (
                <>
                    <div
                        ref={setRef}
                        className={cn(styles.dailyChangeContainer, { [styles.pointer]: !!audioUrl })}
                        onClick={() => {
                            if (!audioUrl) {
                                return;
                            }
                            openInNewTab(audioUrl);
                        }}
                    >
                        <Icon className={cn(styles.audioIcon, { [styles.errorIcon]: hasError })} />

                        <div className={styles.chartContainer}>
                            <MiniLineChart
                                flatLineOnEmpty
                                lineColor={lineColor}
                                points={points}
                                renderTooltip={
                                    shouldDisplayTimeSeriesData
                                        ? (tooltipItem) => (
                                              <>
                                                  <Tooltip.Title>
                                                      {moment(tooltipItem.x).format('D MMM YYYY')}
                                                  </Tooltip.Title>
                                                  <Tooltip.Body>
                                                      <dl className={styles.chartTooltipStats}>
                                                          <div className={styles.chartTooltipStatsItem}>
                                                              <dt>Posts growth</dt>
                                                              <dd>{numberWithCommas(tooltipItem.y)}</dd>
                                                          </div>
                                                      </dl>
                                                  </Tooltip.Body>
                                              </>
                                          )
                                        : undefined
                                }
                                isLoading={isTimeSeriesLoading}
                            />
                        </div>
                    </div>

                    <Tooltip anchorElement={anchor} disabled={!hasError}>
                        <Tooltip.Title>Failed to load data</Tooltip.Title>
                    </Tooltip>
                </>
            )}
        </Tooltip.Root>
    );
};

export default AudioGraph;
