import styles from './CampaignDetails.module.css';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { TabPanel, TabPanels, Tabs } from '@round/ui-kit';
import { microwave } from '@round/api';
import { ReactComponent as InstagramIcon } from 'assets/icons/platform/Instagram.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/platform/TikTok.svg';
import useMicrowaveContentTags from './hooks/useMicrowaveContentTags';
import { useAbortableEffect } from '@round/utils';
import InstagramCampaigns from './Campaigns/InstagramCampaigns/InstagramCampaigns';
import TiktokCampaigns from './Campaigns/TiktokCampaigns/TiktokCampaigns';
import UnderLineTabs from 'ui-new/Tabs/UnderlineTabs/UnderLineTabs';
import Release from './Release/Release';
import useRelease from './Release/useRelease';
import CampaignDetailsLayout from './components/CampaignDetailsLayout/CampaignDetailsLayout';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';
import { useInfluencerPlan } from '../../Projects/hooks/useInfluencerPlan';
import { buildInfluencerPlanUrl } from 'Modules/Advertising/InfluencerPlan/InfluencerPlan.helpers';
import StatusContainer from './components/StatusContainer/StatusContainer';

type Props = {
    releaseId: number;
};

const getPlatform = (hash: string): microwave.Platform | undefined => {
    if (!hash) {
        return;
    }

    const platform = hash.slice(1);
    return microwave.isPlatform(platform) ? platform : undefined;
};

const CampaignDetails = ({ releaseId }: Props) => {
    const isTikTokCampaignsManagementEnabled = useFeatureFlags().isEnabled('microwave_tiktok_campaigns_management');
    const navigate = useNavigate();
    const location = useLocation();

    const activePlatform = getPlatform(location.hash);
    const setActivePlatform = (platform: microwave.Platform | undefined) => {
        navigate({ hash: `#${platform}` }, { replace: true });
    };

    const { data: influencerPlan, status: influencerPlanStatus } = useInfluencerPlan(releaseId);

    const { data: release, status: releaseStatus, error: releaseError } = useRelease(releaseId);
    const isReleaseLoading = releaseStatus === 'idle' || releaseStatus === 'loading';

    const { status: microwaveContentTagsStatus, fetchData: fetchMicrowaveContentTags } = useMicrowaveContentTags();
    const areMicrowaveContentTagsInitialized =
        microwaveContentTagsStatus === 'error' || microwaveContentTagsStatus === 'success';
    useAbortableEffect(
        (signal) => {
            if (!areMicrowaveContentTagsInitialized) {
                fetchMicrowaveContentTags({ signal }).catch(() => {});
            }
        },
        [fetchMicrowaveContentTags, areMicrowaveContentTagsInitialized]
    );

    if (releaseStatus === 'error') {
        return (
            <CampaignDetailsLayout>
                <StatusContainer type="error">{releaseError}</StatusContainer>
            </CampaignDetailsLayout>
        );
    }

    if (!activePlatform) {
        return <Navigate to="#instagram" replace />;
    }

    return (
        <CampaignDetailsLayout>
            <Release isLoading={isReleaseLoading} release={release} />

            {influencerPlanStatus === 'error' ? (
                <StatusContainer type="error">Could not get influencer plan</StatusContainer>
            ) : influencerPlanStatus === 'success' && !influencerPlan ? (
                <StatusContainer>
                    <div>
                        <strong>A related influencer plan is necessary for campaign management</strong>
                    </div>
                    <div>
                        You can create one <a href={buildInfluencerPlanUrl(releaseId)}>here</a>
                    </div>
                </StatusContainer>
            ) : (
                <section className={styles.campaignsSection}>
                    <Tabs activeTab={activePlatform} onChange={setActivePlatform}>
                        <UnderLineTabs.Bar className={styles.tabNavigation}>
                            <UnderLineTabs.Item name="instagram">
                                <InstagramIcon className={styles.platformIcon} /> Instagram
                            </UnderLineTabs.Item>
                            {isTikTokCampaignsManagementEnabled && (
                                <UnderLineTabs.Item name="tiktok">
                                    <TikTokIcon className={styles.platformIcon} /> TikTok
                                </UnderLineTabs.Item>
                            )}
                        </UnderLineTabs.Bar>

                        <TabPanels>
                            <TabPanel name="instagram">
                                <InstagramCampaigns releaseId={releaseId} />
                            </TabPanel>
                            {isTikTokCampaignsManagementEnabled && (
                                <TabPanel name="tiktok">
                                    <TiktokCampaigns releaseId={releaseId} />
                                </TabPanel>
                            )}
                        </TabPanels>
                    </Tabs>
                </section>
            )}
        </CampaignDetailsLayout>
    );
};

export default CampaignDetails;
