import { call } from '../../helpers';
import { ApiResponse, ApiResponseError } from '../../types';
import { PaymentMethod } from '../microwave.types';
import { InviteForm } from './inviteForms.types';

export async function getInviteForm(
    uuid: string,
    requestInit?: RequestInit
): Promise<ApiResponse<InviteForm, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await call(`/api/microwave/invite-forms/${uuid}/`, requestInit, { shouldBypassTokens: true });

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to fetch Invite Form');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostInviteFormApiBody = {
    post_url: string;
    preferred_payment_method: Exclude<PaymentMethod, 'BANK_TRANSFER'>;
    paypal_email_address: string;
    venmo_email_address: string;
} & Partial<{
    location_id: number;
}>;

type PostInviteFormResponseData = Omit<InviteForm, 'payment_deadline'>;

type PostInviteFormResponse =
    | ApiResponse<PostInviteFormResponseData, 201>
    | ApiResponse<string[] | ApiResponseError<PostInviteFormApiBody>, 400>;

export async function postInviteForm(
    uuid: string,
    data: PostInviteFormApiBody,
    requestInit?: RequestInit
): Promise<PostInviteFormResponse> {
    const response = await call(
        `/api/microwave/invite-forms/${uuid}/submit/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            ...requestInit,
        },
        { shouldBypassTokens: true }
    );

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to submit Invite Form');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
