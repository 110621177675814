import { TabList, TabsContext, TabsContextState, Tabs } from '@round/ui-kit';
import styles from './UnderlineTabs.module.css';
import cn from 'classnames';
import useNonNullContext from 'Hooks/useNonNullContext';
import { PropsWithChildren } from 'react';

type MenuProps = { children?: React.ReactNode; className?: string };
const Menu = ({ children, className }: MenuProps) => {
    return <TabList className={cn(styles.menu, className)}>{children}</TabList>;
};

export type ItemProps = PropsWithChildren<{
    name: TabsContextState['activeTab'];
    className?: string;
}>;

const Item = ({ name, children, className }: ItemProps) => {
    const { activeTab, onChange } = useNonNullContext(TabsContext);

    return (
        <li
            className={cn(styles.item, className, {
                [styles.active]: activeTab === name,
            })}
            onClick={() => onChange(name)}
        >
            {children}
        </li>
    );
};

const UnderLineTabs = Object.assign(Tabs.bind({}), {
    Menu,
    Item,
});

export default UnderLineTabs;
