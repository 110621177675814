import { getTableMetaHelper } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import Skeleton from 'react-loading-skeleton';
import CampaignName from '../CampaignName/CampaignName';
import { creatorbase } from '@round/api';
import ApplicationBadge from 'Modules/Plans/components/ApplicationBadge/ApplicationBadge';

type BaseMeta = {
    isLoading: boolean | undefined;
};

const ReportCampaignCell = <TMeta extends BaseMeta, TRow extends creatorbase.Campaign | creatorbase.PublicCampaign>({
    table,
    getValue,
    row: { original: campaign },
}: CellContext<TRow, string | null | undefined>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading } = getTableMeta(table);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <div>
            <CampaignName name={getValue()} />

            {campaign.managed_by !== 'creatorbase' && <ApplicationBadge application={campaign.managed_by} />}
        </div>
    );
};

export default ReportCampaignCell;
