import { applicationManager, applicationManagerQueries, creatorbase, creatorbaseQueries } from '@round/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const appManagerProjectKeys = applicationManagerQueries.project.keys;
const creatorbaseProjectKeys = creatorbaseQueries.project.keys;

export type MergeData = {
    planProjectId: number;
    data: applicationManager.PostMergeProjectsData;
    creatorbaseProject?: creatorbase.Project;
};
export function useMergeProjects() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ planProjectId, data }: MergeData) => applicationManager.postMergeProjects(planProjectId, data),
        onMutate: ({ creatorbaseProject }: MergeData) => {
            if (!creatorbaseProject) {
                return;
            }

            //assuming a successful mutation, this will prevent a refetch of the creatorbase project
            queryClient.setQueryData(creatorbaseProjectKeys.detail(creatorbaseProject.id), creatorbaseProject);
        },
        onSuccess: (response, { planProjectId }) => {
            if (response.status !== 200) {
                return;
            }

            queryClient.setQueryData(appManagerProjectKeys.detail(planProjectId), response.data);
        },
    });
}
