import React, { useMemo } from 'react';
import { Form, Field, FieldProps, ErrorMessage, FormikProps, FormikHelpers, Formik } from 'formik';
import styles from './PlanForm.module.css';
import { StylesConfig, ValueType } from 'react-select';
import { GenericDropdownOption } from '../../../../../../../App.types';
import FormField from '../../../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../../../ui/DataEntry/Label/Label';
import DatePicker from '../../../../../../../ui/DataEntry/DatePicker/DatePicker';
import Select from '../../../../../../../ui/DataEntry/Select/Select';
import useNonNullContext from '../../../../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../../../../contexts/OptionsContext/OptionsContext';
import PaginatedSelect from '../../../../../../../ui/DataEntry/PaginatedSelect/PaginatedSelect';
import { getPlannerUsers, PostInfluencerPlanData } from '@round/api';
import { ProtectedByUserGroups } from '../../../../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import Button from '../../../../../../../ui/Buttons/Button/Button';
import { useCheckUserGroupsAccess } from '../../../../../../Auth/hooks/useCheckUserGroupsAccess';
import * as Yup from 'yup';

export type PlanFormValues = {
    currency: GenericDropdownOption<number> | undefined;
    planners: ValueType<GenericDropdownOption<number>, true> | null;
} & Omit<PostInfluencerPlanData, 'currency_id' | 'release_id' | 'planners'>;

type PlanFormProps = {
    handleSubmit: (values: PlanFormValues, helpers: FormikHelpers<PlanFormValues>) => void;
    initialValues: PlanFormValues;
    renderActions?: (props: FormikProps<PlanFormValues>) => React.ReactNode;
};

const selectStyles: StylesConfig = {
    control: (provided, props) => ({
        ...provided,
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        fontWeight: 400,
        fontSize: '0.8125rem',
        backgroundColor: props.isDisabled ? '#C2CFE050' : provided.backgroundColor,
        borderColor: '#C2CFE0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.625rem 1rem',
    }),
    singleValue: (provided, props) => ({
        ...provided,
        color: props.isDisabled ? '#323C4780' : '#323C47',
    }),
    input: (provided) => ({
        ...provided,
        height: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }),
};

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/London',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
};

const defaultRenderActions = ({ isSubmitting, submitForm }: FormikProps<PlanFormValues>) => (
    <div className={styles.defaultRenderActions}>
        <Button
            className={styles.defaultSubmitButton}
            type="filled"
            color="black"
            loading={isSubmitting}
            onClick={submitForm}
        >
            Create
        </Button>
    </div>
);

const PlanForm = ({ handleSubmit, initialValues, renderActions = defaultRenderActions }: PlanFormProps) => {
    const { currencies } = useNonNullContext(OptionsContext);
    const currencyOptions: GenericDropdownOption<number>[] = useMemo(
        () =>
            currencies.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        [currencies]
    );

    const userHasAdmin = useCheckUserGroupsAccess(['user_admin']);

    const validationSchema = Yup.object({
        instagram_budget: Yup.number().required('this field cannot be empty'),
        tiktok_budget: Yup.number().required('this field cannot be empty'),
        youtube_budget: Yup.number().required('this field cannot be empty'),
        ...(userHasAdmin && {
            planners: Yup.array()
                .min(1, 'this field cannot be empty')
                .required('this field cannot be empty')
                .nullable(),
        }),
    });

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {(props) => (
                <>
                    <Form className={styles.form}>
                        <div className={styles.row}>
                            <FormField>
                                <Label htmlFor="start_date">Start Date</Label>
                                <Field name="start_date" id="start_date">
                                    {(props: FieldProps<PlanFormValues['start_date'], PlanFormValues>) => (
                                        <DatePicker
                                            dateTimeFormatOptions={dateTimeFormatOptions}
                                            className={styles.dateInput}
                                            value={props.field.value}
                                            onChange={(value) => props.form.setFieldValue('start_date', value)}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="start_date">
                                    {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                </ErrorMessage>
                            </FormField>

                            <FormField>
                                <Label htmlFor="end_date">End Date</Label>
                                <Field name="end_date" id="end_date">
                                    {(props: FieldProps<PlanFormValues['end_date'], PlanFormValues>) => (
                                        <DatePicker
                                            dateTimeFormatOptions={dateTimeFormatOptions}
                                            className={styles.dateInput}
                                            value={props.field.value}
                                            onChange={(value) => props.form.setFieldValue('end_date', value)}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="end_date">
                                    {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                </ErrorMessage>
                            </FormField>
                        </div>

                        <FormField>
                            <Label htmlFor="currency">Currency</Label>
                            <Field name="currency">
                                {(props: FieldProps<PlanFormValues['currency'], PlanFormValues>) => (
                                    <Select
                                        styles={selectStyles}
                                        value={props.field.value}
                                        onChange={(value) => props.form.setFieldValue('currency', value)}
                                        options={currencyOptions}
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="currency">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>

                        <FormField>
                            <Label htmlFor="instagram_budget">Instagram Budget</Label>
                            <Field
                                name="instagram_budget"
                                id="instagram_budget"
                                type="number"
                                placeholder="Enter instagram budget"
                            />
                            <ErrorMessage name="instagram_budget">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>

                        <FormField>
                            <Label htmlFor="tiktok_budget">TikTok Budget</Label>
                            <Field
                                name="tiktok_budget"
                                id="tiktok_budget"
                                type="number"
                                placeholder="Enter tiktok budget"
                            />
                            <ErrorMessage name="tiktok_budget">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>

                        <FormField>
                            <Label htmlFor="youtube_budget">YouTube Budget</Label>
                            <Field
                                name="youtube_budget"
                                id="youtube_budget"
                                type="number"
                                placeholder="Enter youtube budget"
                            />

                            <ErrorMessage name="youtube_budget">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>

                        <ProtectedByUserGroups groups={['user_admin']}>
                            <FormField>
                                <Label htmlFor="planners">Account Team</Label>
                                <Field name="planners">
                                    {(props: FieldProps<PlanFormValues['planners'], PlanFormValues>) => (
                                        <PaginatedSelect
                                            isMulti
                                            styles={selectStyles}
                                            placeholder="Select planners"
                                            value={props.field.value}
                                            fetchOptions={getPlannerUsers}
                                            mapToOption={(plannerUser) => ({
                                                value: plannerUser.id,
                                                label: `${plannerUser.first_name} ${plannerUser.last_name}`,
                                            })}
                                            onChange={(value) => props.form.setFieldValue(props.field.name, value)}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage name="planners">
                                    {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                                </ErrorMessage>
                            </FormField>
                        </ProtectedByUserGroups>
                    </Form>
                    {renderActions(props)}
                </>
            )}
        </Formik>
    );
};

export default PlanForm;
