import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import UpdateInstagramInfluencerPostModal from '../InfluencerPlanItemModal/UpdateInstagramInfluencerPostModal';
import CreateInstagramInfluencerPostModal from '../InfluencerPlanItemModal/CreateInstagramInfluencerPostModal/CreateInstagramInfluencerPostModal';
import styles from './InstagramTable.module.css';
import { showNotification } from 'helpers';
import InstagramTableRow from './InstagramTableRow/InstagramTableRow';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { InfluencerPlanGroupBar } from '../InfluencerPlanGroupBar/InfluencerPlanGroupBar';
import {
    EDITABLE_STATUSES,
    getInstagramPostsStatsTotals,
    getInstagramPostStats,
    postInactiveStatuses,
    statusOrder,
} from '../../InfluencerPlan.helpers';
import InstagramTotalsRow from './InstagramTotalsRow/InstagramTotalsRow';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import useUrlState from '../../../../../Hooks/useUrlState';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import Skeleton from '../../../../../ui/Skeleton/Skeleton';
import {
    InstagramInfluencerPost,
    InstagramInfluencerPostResult,
    InstagramTablePostStats,
} from '../../types/Instagram.types';
import { InfluencerPostGroup, InstagramInfluencerUserStats } from '@round/api';
import { FCWithChildren } from 'utility/utility.types';
import ExpandBar from '../../components/ExpandBar/ExpandBar';
import { setManualStatusOnInstagramInfluencerPost } from '../../InfluencerPlan.api';
import cn from 'classnames';
import copyComponentRenderToClipboard from 'utility/copyToClipboard/copyComponentRenderToClipboard';
import InstagramReportClipboardTable from '../copyToClipboard/InstagramReportClipboardTable/InstagramReportClipboardTable';
import InstagramProposalClipboardTable, {
    InstagramProposalClipboardTableRow,
} from '../copyToClipboard/InstagramProposalClipboardTable/InstagramProposalClipboardTable';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import InstagramInfluencerUserModal from '../../../../Influencer/components/InstagramInfluencerUserModal/InstagramInfluencerUserModal';
import { AddInfluencerToCampaignOptionsProvider } from 'Modules/Influencer/containers/AddInfluencerToCampaign/AddInfluencerToCampaignContext';
import { useContentTags } from 'contexts/ContentTagsContext';
import EditBriefModal from '../EditBriefModal/EditBriefModal';
import useInstagramInfluencerPlanAudios from '../../contexts/InstagramCreatorsContext/useInstagramInfluencerPlanAudios';
import { formatInstagramBriefDataToWhatsAppMarkdown } from '../copyToClipboard/copyToClipboard.helpers';
import copy from 'copy-to-clipboard';
import { useMicroCampaigns } from 'Modules/Advertising/Microwave/MicroCreators/contexts/MicroCampaign';
import InstagramMicrosStatsBar from 'Modules/Advertising/InfluencerPlan/features/MicrosStatsBar/InstagramMicrosStatsBar/InstagramMicrosStatsBar';
import {
    useInstagramMicroCampaignPosts,
    useInstagramMicroCampaignStats,
} from 'Modules/Advertising/Microwave/MicroCreators/contexts/InstagramMicroCampaign';
import InstagramMicroCampaignPostsTable from 'Modules/Advertising/InfluencerPlan/features/MicrosStatsBar/MicroCreatorsPostsTable/InstagramMicroCampaignPostsTable';
import CreateMicrowavePostModal from 'Modules/Advertising/Microwave/CreateMicrowavePostModal/CreateMicrowavePostModal';
import InstagramAudioModal from '../../../../Instagram/InstagramAudioDetails/InstagramAudioModal';
import InfluencerPostGroupAudioModal from '../InfluencerPostGroupAudioModal/InfluencerPostGroupAudioModal';
import { isNumber } from '../../../../../utility/utility';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import ConfirmDeleteGroupModal from '../../components/ConfirmDeleteGroupModal/ConfirmDeleteGroupModal';

type UrlState = {
    expandedGroups: string;
    expandedBackupTables: string;
    postId: string;
    isMicrosExpanded: boolean;
    audioId: number;
};

const InstagramTable: FCWithChildren = () => {
    const { initialized, influencerPlan } = useContext(InfluencerPlanContext);
    const {
        isInitCompleted,
        init,
        instagramInfluencerPosts,
        instagramInfluencerPostGroups,
        instagramInfluencerPostResults,
        instagramInfluencerPostGroupStats,
        instagramPosts,
        instagramUserStats,
        instagramUserImages,
        instagramInfluencerUsers,
        deleteInstagramInfluencerPostGroup,
        updateInstagramInfluencerPostGroup,
        increaseInstagramInfluencerPostGroupsOrderingIndex,
        decreaseInstagramInfluencerPostGroupsOrderingIndex,
        updateInstagramInfluencerPost,
        refreshInstagramInfluencerPost,
        setInstagramInfluencerPost,
        setInstagramInfluencerPostResult,
        instagramInfluencerPostResultDeleted,
    } = useNonNullContext(InstagramCreatorsContext);

    const { tags: contentTags, init: initContentTags, isInitialized } = useContentTags();

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                initContentTags({ signal });
            }
        },
        [isInitialized, initContentTags]
    );

    const {
        audios: planAudios,
        init: initPlanAudios,
        isInitialized: arePlanAudiosInitialized,
        refreshAudios,
    } = useInstagramInfluencerPlanAudios();

    useAbortableEffect(
        (signal) => {
            if (!arePlanAudiosInitialized) {
                initPlanAudios({ signal });
            }
        },
        [arePlanAudiosInitialized, initPlanAudios]
    );

    const {
        isInitialized: isMicroCampaignsInitialized,
        campaigns: microCampaigns,
        isLoading: isMicroCampaignsLoading,
        hasError: hasMicroCampaignsLoadingError,
    } = useMicroCampaigns(influencerPlan?.release.id);
    const shouldDisplayMicrosBar = microCampaigns.some((c) => c.platform === 'instagram');
    const instagramCampaign = microCampaigns.find((c) => c.platform === 'instagram');

    const {
        init: initInstagramMicroCampaignStats,
        isInitialized: isInstagramMicroCampaignStatsInitialized,
        stats: instagramMicroCampaignStats,
        isLoading: isInstagramMicroCampaignStatsLoading,
        reset: resetInstagramMicroCampaignStats,
    } = useInstagramMicroCampaignStats(instagramCampaign?.instagram_campaign_id);

    useAbortableEffect(
        (signal) => {
            if (!isInstagramMicroCampaignStatsInitialized && instagramCampaign) {
                initInstagramMicroCampaignStats({ signal });
            }
        },
        [initInstagramMicroCampaignStats, instagramCampaign, isInstagramMicroCampaignStatsInitialized]
    );

    const {
        init: initInstagramMicroCampaignPosts,
        posts: instagramMicroCampaignPosts,
        hasError: hasErrorLoadingInstagramCampaignPosts,
        arePostsLoading: areInstagramMicroCampaignPostsLoading,
        arePostsInitialized: areInstagramMicroCampaignPostsInitialized,
        reset: resetInstagramMicroCampaignPosts,
    } = useInstagramMicroCampaignPosts(instagramCampaign?.instagram_campaign_id);

    useAbortableEffect(
        (signal) => {
            if (!areInstagramMicroCampaignPostsInitialized && instagramCampaign) {
                initInstagramMicroCampaignPosts({ signal });
            }
        },
        [areInstagramMicroCampaignPostsInitialized, initInstagramMicroCampaignPosts, instagramCampaign]
    );

    const [isCreateMicrowavePostModalOpen, setIsCreateMicrowavePostModalOpen] = useState(false);

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
    const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
    const [isInfluencerModalOpen, setIsInfluencerModalOpen] = useState(false);
    const [isEditBriefModalOpen, setIsEditBriefModalOpen] = useState(false);

    const [influencerModalInfluencerId, setInfluencerModalInfluencerId] = useState<number | null>(null);
    const [
        instagramInfluencerUserStats,
        setInstagramInfluencerUserStats,
    ] = useState<InstagramInfluencerUserStats | null>(null);

    const [postResultsToUpdate, setPostResultsToUpdate] = useState<InstagramInfluencerPostResult | null>(null);
    const [postToBeUpdated, setPostToBeUpdated] = useState<InstagramInfluencerPost | null>(null);
    const [groupToEditBrief, setGroupToEditBrief] = useState<InfluencerPostGroup | null>(null);
    const [groupToAddPost, setGroupToAddPost] = useState<number | null>(null);
    const [groupToDelete, setGroupToDelete] = useState<InfluencerPostGroup | null>(null);
    const showDeleteGroupConfirmationDialog = useMemo(() => !!groupToDelete, [groupToDelete]);
    const [groupToUpdateAudio, setGroupToUpdateAudio] = useState<InfluencerPostGroup | null>(null);
    const canEdit = useCheckUserGroupsAccess(['influencer_editor']);

    const [urlState, setUrlState] = useUrlState<UrlState>();
    const expandedGroups = urlState.expandedGroups?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedGroups = (group: number) => {
        if (expandedGroups.includes(group)) {
            setUrlState({ expandedGroups: expandedGroups.filter((g) => g !== group).join(',') });
            return;
        }

        setUrlState({ expandedGroups: expandedGroups.concat(group).join(',') });
    };

    const getInstagramPostStatsForPost = useCallback(
        (post: InstagramInfluencerPost) => {
            const postsInstagramUserId =
                instagramInfluencerUsers.find((influencer) => influencer.id === post.influencer_id)?.user ??
                post.instagram_user;
            const results = instagramInfluencerPostResults.find((r) => r.post === post.id);
            const scrapedPost = instagramPosts.find((p) => p.id === post.post);
            const userStats = instagramUserStats.find((s) => s.instagram_user === postsInstagramUserId);

            return getInstagramPostStats(results, scrapedPost, userStats);
        },
        [instagramInfluencerPostResults, instagramPosts, instagramUserStats, instagramInfluencerUsers]
    );

    const activeInfluencerPosts = useMemo(
        () =>
            instagramInfluencerPosts
                .filter((post) => !postInactiveStatuses.includes(post.status))
                .sort((a, b) => {
                    const orderByStatus = statusOrder[a.status] - statusOrder[b.status];
                    const totalViewsDescending =
                        (getInstagramPostStatsForPost(b).totalViews ?? 0) -
                        (getInstagramPostStatsForPost(a).totalViews ?? 0);
                    const estimatedViewsDescending =
                        (b.estimated_views_override ?? b.estimated_views ?? 0) -
                        (a.estimated_views_override ?? a.estimated_views ?? 0);

                    if (orderByStatus !== 0) {
                        return orderByStatus;
                    }

                    if (totalViewsDescending !== 0) {
                        return totalViewsDescending;
                    }

                    return estimatedViewsDescending;
                }),
        [getInstagramPostStatsForPost, instagramInfluencerPosts]
    );

    const expandedBackupTables = urlState.expandedBackupTables?.split(',').map((g) => Number(g)) ?? [];
    const setExpandedBackupTables = (group: number) => {
        if (expandedBackupTables.includes(group)) {
            setUrlState({ expandedBackupTables: expandedBackupTables.filter((g) => g !== group).join(',') });
            return;
        }

        setUrlState({ expandedBackupTables: expandedBackupTables.concat(group).join(',') });
    };

    const totalsByGroup = useMemo(() => {
        const totalsByGroup: { [groupId: string]: InstagramTablePostStats } = {};

        if (!instagramInfluencerPostGroups.length || !activeInfluencerPosts.length) {
            return totalsByGroup;
        }

        for (const group of instagramInfluencerPostGroups) {
            const influencerPosts = activeInfluencerPosts.filter((post) => post.group_id === group.id);
            const scrapedPosts = instagramPosts.filter((p) => influencerPosts.find((post) => post.post === p.id));
            const results = instagramInfluencerPostResults.filter(
                (r) => !!influencerPosts.find((p) => p.id === r.post)
            );
            const userStats = instagramUserStats.filter(
                (s) =>
                    !!influencerPosts.find((p) => {
                        const instagramUserId =
                            instagramInfluencerUsers.find((i) => i.id === p.influencer_id)?.user ?? p.instagram_user;
                        return instagramUserId === s.instagram_user;
                    })
            );
            const influencers = instagramInfluencerUsers.filter((influencer) =>
                Boolean(influencerPosts.find((p) => p.influencer_id === influencer.id))
            );

            const groupStats = getInstagramPostsStatsTotals(
                influencerPosts,
                results,
                userStats,
                scrapedPosts,
                influencers
            );
            totalsByGroup[group.id] = {
                ...groupStats,
                engagementRate: Number(groupStats.totalEngagements) / Number(groupStats.engagementRateViews),
                cost: influencerPosts.reduce((acc, p) => acc + Number(p.cost), 0),
                liveCost: influencerPosts
                    .filter((post) => post.status === 'live')
                    .reduce((acc, p) => acc + Number(p.cost), 0),
                clientCost: influencerPosts.reduce((acc, p) => acc + Number(p.client_cost), 0),
                estimatedViews: influencerPosts.reduce(
                    (acc, post) => acc + Number(post.estimated_views_override ?? post.estimated_views ?? 0),
                    0
                ),
            };
        }

        return totalsByGroup;
    }, [
        instagramInfluencerPostGroups,
        activeInfluencerPosts,
        instagramInfluencerPostResults,
        instagramPosts,
        instagramUserStats,
        instagramInfluencerUsers,
    ]);

    const openInstagramInfluencerPostUpdateModal = useCallback(
        (post: InstagramInfluencerPost) => {
            if (canEdit) {
                const result = instagramInfluencerPostResults.find((r) => r.post === post.id);
                setPostToBeUpdated(post);
                setPostResultsToUpdate(result ?? null);
                setIsUpdateModalOpen(true);
            }
        },
        [canEdit, instagramInfluencerPostResults]
    );

    const closeInstagramInfluencerPostUpdateModal = useCallback(() => {
        setIsUpdateModalOpen(false);
        setPostToBeUpdated(null);
    }, []);

    const handleOpenInfluencerModal = (influencerId: number, stats: InstagramInfluencerUserStats | null) => {
        setInfluencerModalInfluencerId(influencerId);
        setInstagramInfluencerUserStats(stats);

        setIsInfluencerModalOpen(true);
    };

    const handleCloseInfluencerModal = () => {
        setIsInfluencerModalOpen(false);

        setInstagramInfluencerUserStats(null);
        setInfluencerModalInfluencerId(null);
    };

    const askDeleteGroupConfirmation = useCallback((groupToDelete: InfluencerPostGroup) => {
        setGroupToDelete(groupToDelete);
    }, []);

    const resetGroupDeletion = useCallback(() => {
        setGroupToDelete(null);
    }, []);

    const onDeleteGroup = useCallback(async () => {
        if (!groupToDelete) {
            return;
        }

        await deleteInstagramInfluencerPostGroup(groupToDelete.id);
        resetGroupDeletion();
        if (groupToDelete.audio_id) {
            refreshAudios();
        }
    }, [deleteInstagramInfluencerPostGroup, groupToDelete, refreshAudios, resetGroupDeletion]);

    const onDragEnd = useCallback(
        async (dropResult: DropResult) => {
            const { source, destination } = dropResult;
            const post = instagramInfluencerPosts?.find((post) => post.id === source.index);

            const sourceGroup = source.droppableId.split('-')[0];
            const destinationGroup = destination?.droppableId.split('-')[0];

            if (!destinationGroup || !post || !destination) {
                return;
            }

            if (sourceGroup !== destinationGroup) {
                await updateInstagramInfluencerPost(post.id, { group_id: Number(destinationGroup) });
            }

            if (!EDITABLE_STATUSES.includes(post.status)) {
                return;
            }

            const toActive = source.droppableId.includes('backup') && !destination.droppableId.includes('backup');
            if (toActive) {
                await setManualStatusOnInstagramInfluencerPost({
                    status: 'planned',
                    influencer_post_id: post.id,
                    draft_expected_by: null,
                });

                await refreshInstagramInfluencerPost(post.id);
                return;
            }

            const toBackup = destination.droppableId.includes('backup') && !source.droppableId.includes('backup');
            if (toBackup) {
                await setManualStatusOnInstagramInfluencerPost({
                    status: 'backup',
                    influencer_post_id: post.id,
                    draft_expected_by: null,
                });

                await refreshInstagramInfluencerPost(post.id);
                return;
            }
        },
        [instagramInfluencerPosts, refreshInstagramInfluencerPost, updateInstagramInfluencerPost]
    );

    const copyReportTableToClipboard = useCallback(
        (group: InfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const totals = totalsByGroup[group.id];
            const items =
                activeInfluencerPosts
                    .filter((itemRow) => itemRow.group_id === group.id)
                    .map((post) => {
                        const stats = getInstagramPostStatsForPost(post);
                        const instagramPost = instagramPosts.find((i) => i.id === post.post);
                        const influencer = instagramInfluencerUsers.find(
                            (influencer) => influencer.id === post.influencer_id
                        );
                        return {
                            status: post.status,
                            postViews: stats.totalViews,
                            likes: stats.likes,
                            comments: stats.comments,
                            account: influencer?.username ?? '',
                            accountFollowers: stats.followers,
                            link: post.post_url,
                            engagementRate: stats.engagementRate,
                            videoUploadedDate: instagramPost?.taken_at,
                        };
                    }) ?? [];

            const success = copyComponentRenderToClipboard(
                {
                    release: influencerPlan.release,
                    groupName: group.name,
                    totals: {
                        likes: totals.likes,
                        comments: totals.comments,
                        postViews: totals.totalViews,
                        engagementRate: totals.engagementRate,
                    },
                    items,
                },
                InstagramReportClipboardTable
            );

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [
            getInstagramPostStatsForPost,
            influencerPlan,
            activeInfluencerPosts,
            instagramPosts,
            totalsByGroup,
            instagramInfluencerUsers,
        ]
    );

    const copyProposalTableToClipboard = useCallback(
        (group: InfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const items: InstagramProposalClipboardTableRow[] =
                activeInfluencerPosts
                    .filter((p) => p.status !== 'live')
                    .filter((p) => p.group_id === group.id)
                    .map<InstagramProposalClipboardTableRow>((post) => {
                        const stats = getInstagramPostStatsForPost(post);
                        const influencer = instagramInfluencerUsers.find(
                            (influencer) => influencer.id === post.influencer_id
                        );
                        return {
                            account: influencer?.username ?? '',
                            followerCount: stats.followers ?? null,
                            contentTags: contentTags.filter((tag) => influencer?.content_tags.includes(tag.id)) ?? [],
                            locations: influencer?.tags?.filter((t) => t.type === 'LOCATION') ?? [],
                            estimatedViews: post.estimated_views_override ?? post.estimated_views,
                        };
                    }) ?? [];

            const success = copyComponentRenderToClipboard(
                {
                    release: influencerPlan.release,
                    groupName: group.name,
                    items,
                    brief: group.brief ?? '',
                },
                InstagramProposalClipboardTable
            );

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [getInstagramPostStatsForPost, influencerPlan, activeInfluencerPosts, instagramInfluencerUsers, contentTags]
    );

    const copyBriefToClipboard = useCallback(
        (group: InfluencerPostGroup) => {
            if (!influencerPlan) {
                return;
            }

            const formattedBriefData = formatInstagramBriefDataToWhatsAppMarkdown({
                release: influencerPlan.release,
                sound: planAudios.find((a) => a.id === group.audio_id),
                brief: group.brief,
                turnaround: group.brief_turnaround,
            });

            const success = copy(formattedBriefData, { format: 'text/plain' });

            if (success) {
                showNotification('Copied!', 'info');
            } else {
                showNotification('Could not copy', 'error');
            }
        },
        [influencerPlan, planAudios]
    );

    useEffect(() => {
        if (urlState.postId && urlState.expandedGroups && initialized) {
            const id = urlState.postId;
            document.getElementById(id)?.scrollIntoView();
        }
    }, [initialized, urlState.postId, urlState.expandedGroups]);

    const postHighlightedId = Number(urlState.postId);

    useAbortableEffect(() => {
        if (isInitCompleted) {
            return;
        }

        init();
    }, [isInitCompleted, init]);

    const onCreatePost = useCallback((groupId: number) => {
        setGroupToAddPost(groupId);
        setIsCreatePostModalOpen(true);
    }, []);

    const onClickEditBriefModal = (group: InfluencerPostGroup) => {
        setGroupToEditBrief(group);
        setIsEditBriefModalOpen(true);
    };

    const updateBrief = (groupId: number, data: Partial<Pick<InfluencerPostGroup, 'brief' | 'brief_turnaround'>>) =>
        updateInstagramInfluencerPostGroup(groupId, data);

    return (
        <>
            <ConfirmDeleteGroupModal
                isOpen={showDeleteGroupConfirmationDialog}
                onClose={resetGroupDeletion}
                group={groupToDelete}
                handleDeleteGroup={onDeleteGroup}
            />

            {canEdit && (
                <>
                    <UpdateInstagramInfluencerPostModal
                        closeModal={closeInstagramInfluencerPostUpdateModal}
                        isModalOpen={isUpdateModalOpen}
                        post={postToBeUpdated}
                        postResults={postResultsToUpdate}
                        onUpdated={(updated) => {
                            if (updated?.post) {
                                setInstagramInfluencerPost(updated.post);
                            }

                            if (updated?.results) {
                                setInstagramInfluencerPostResult(updated.results);
                            }

                            if (updated?.resultsIdToDelete) {
                                instagramInfluencerPostResultDeleted(updated.resultsIdToDelete);
                            }

                            closeInstagramInfluencerPostUpdateModal();
                        }}
                        onDeleted={closeInstagramInfluencerPostUpdateModal}
                    />

                    <CreateInstagramInfluencerPostModal
                        isOpen={isCreatePostModalOpen}
                        onClose={() => {
                            setIsCreatePostModalOpen(false);
                            setGroupToAddPost(null);
                        }}
                        onCreated={() => {
                            setIsCreatePostModalOpen(false);
                            setGroupToAddPost(null);
                            showNotification('Post created', 'info');
                        }}
                        groupId={groupToAddPost}
                    />

                    <EditBriefModal
                        isModalOpen={isEditBriefModalOpen}
                        group={groupToEditBrief}
                        closeModal={() => {
                            setIsEditBriefModalOpen(false);
                            setGroupToEditBrief(null);
                        }}
                        updateBrief={updateBrief}
                    />

                    <InfluencerPostGroupAudioModal
                        platform="instagram"
                        group={groupToUpdateAudio}
                        isOpen={Boolean(groupToUpdateAudio)}
                        onClose={() => setGroupToUpdateAudio(null)}
                        onSuccess={(updatedGroup) => {
                            // in case influencer plan audios and group audios are not in sync
                            // we take a sum of all related audios
                            const existingGroupAudioIds = instagramInfluencerPostGroups
                                .map((g) => g.audio_id)
                                .filter(isNumber);
                            const outOfGroupsPlanAudioIds =
                                influencerPlan?.instagram_audios.filter((id) => !existingGroupAudioIds.includes(id)) ??
                                [];

                            const existingAudioIds = uniq(existingGroupAudioIds.concat(outOfGroupsPlanAudioIds));

                            const updatedGroupAudioIds = instagramInfluencerPostGroups
                                .map((g) => (g.id === updatedGroup.id ? updatedGroup : g))
                                .map((g) => g.audio_id)
                                .filter(isNumber);
                            const updatedAudioIds = uniq(updatedGroupAudioIds.concat(outOfGroupsPlanAudioIds));

                            if (!isEqual(updatedAudioIds, existingAudioIds)) {
                                refreshAudios();
                            }
                        }}
                    />
                </>
            )}

            <section className={styles.groupsContainer}>
                {!isInitCompleted && (
                    <>
                        <Skeleton className={styles.groupSkeleton} />
                        <Skeleton className={styles.groupSkeleton} />
                        <Skeleton className={styles.groupSkeleton} />
                    </>
                )}

                {isInitCompleted && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        {instagramInfluencerPostGroups
                            .sort((a, b) => a.ordering_index - b.ordering_index)
                            .map((group, i) => {
                                const groupBackups = instagramInfluencerPosts.filter(
                                    (post) => postInactiveStatuses.includes(post.status) && post.group_id === group.id
                                );
                                return (
                                    <div key={group.id} className={styles.groupItem}>
                                        <InfluencerPlanGroupBar
                                            platform="instagram"
                                            name={group.name}
                                            index={i}
                                            group={group}
                                            groups={instagramInfluencerPostGroups}
                                            groupStats={instagramInfluencerPostGroupStats.find(
                                                (g) => g.group_id === group.id
                                            )}
                                            groupQuantity={instagramInfluencerPostGroups.length}
                                            items={instagramInfluencerPosts.filter((row) => row.group_id === group.id)}
                                            expandedGroups={expandedGroups}
                                            checkGroup={setExpandedGroups}
                                            addPost={() => onCreatePost(group.id)}
                                            updateGroup={(platform, data) =>
                                                updateInstagramInfluencerPostGroup(group.id, data)
                                                    .then((res) => {
                                                        if (res.status === 400 && !Array.isArray(res.data)) {
                                                            showNotification(
                                                                Object.values(res.data).join('. '),
                                                                'error'
                                                            );
                                                        }

                                                        if (res.status === 400 && Array.isArray(res.data)) {
                                                            showNotification(res.data.join('. '), 'error');
                                                        }

                                                        return res;
                                                    })
                                                    .catch(() => showNotification('Could not update group', 'error'))
                                            }
                                            deleteGroup={askDeleteGroupConfirmation}
                                            increaseGroupOrder={(platform, group) =>
                                                increaseInstagramInfluencerPostGroupsOrderingIndex(group.id)
                                            }
                                            decreaseGroupOrder={(platform, group) =>
                                                decreaseInstagramInfluencerPostGroupsOrderingIndex(group.id)
                                            }
                                            currencySymbol={influencerPlan?.currency.symbol}
                                            copyReportTable={() => copyReportTableToClipboard(group)}
                                            copyProposalTable={() => copyProposalTableToClipboard(group)}
                                            copyBrief={() => copyBriefToClipboard(group)}
                                            onClickEditBrief={onClickEditBriefModal}
                                            onViewAudio={(group) =>
                                                setUrlState({ audioId: group.audio_id ?? undefined })
                                            }
                                            onAudioUpdate={setGroupToUpdateAudio}
                                        />
                                        {expandedGroups.includes(group.id) && (
                                            <div className={styles.tableContainer}>
                                                <div className={styles.tableContainerContent}>
                                                    <Droppable key={group.id} droppableId={String(group.id)}>
                                                        {(provided, { isDraggingOver }) => (
                                                            <table
                                                                className={styles.table}
                                                                data-test-id={`instagram-posts-group-table-${group.id}`}
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                <thead>
                                                                    <tr className={styles.tableHeading}>
                                                                        <th>ID</th>
                                                                        <th>Account</th>
                                                                        <th>Status</th>
                                                                        <ProtectedByUserGroups
                                                                            groups={['influencer_editor']}
                                                                        >
                                                                            <th className={styles.editorCell}>
                                                                                Contact
                                                                            </th>
                                                                            <th className={styles.editorCell}>Cost</th>
                                                                            <th className={styles.editorCell}>
                                                                                Client Cost
                                                                            </th>
                                                                            <th className={styles.editorCell}>
                                                                                Payment
                                                                            </th>
                                                                        </ProtectedByUserGroups>

                                                                        <ProtectedByUserGroups
                                                                            groups={['round_planner']}
                                                                        >
                                                                            <th className={styles.editorCell}>
                                                                                Payment Request
                                                                            </th>
                                                                        </ProtectedByUserGroups>

                                                                        <ProtectedByUserGroups
                                                                            groups={['influencer_editor']}
                                                                        >
                                                                            <th className={styles.editorCell}>
                                                                                Est. Views
                                                                            </th>
                                                                            <th className={styles.editorCell}>
                                                                                Est. CPM
                                                                            </th>
                                                                            <th className={styles.editorCell}>CPM</th>
                                                                            <th className={styles.editorCell}>Notes</th>
                                                                        </ProtectedByUserGroups>
                                                                        <th>Upload Date</th>
                                                                        <th>Total views</th>
                                                                        <th>Feed views</th>
                                                                        <th>Reel views</th>
                                                                        <th>Story views</th>
                                                                        <th>Total engagements</th>
                                                                        <th>Engagement rate</th>
                                                                        <th>Likes</th>
                                                                        <th>Comments</th>
                                                                        <th>Ad code</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody
                                                                    className={cn({
                                                                        [styles.dropActive]: isDraggingOver,
                                                                    })}
                                                                >
                                                                    {expandedGroups.includes(group.id) &&
                                                                        instagramInfluencerPosts &&
                                                                        activeInfluencerPosts
                                                                            .filter((row) => row.group_id === group.id)
                                                                            .map((post) => {
                                                                                return (
                                                                                    <Draggable
                                                                                        key={post.id}
                                                                                        draggableId={`${post.id}`}
                                                                                        index={post.id}
                                                                                        isDragDisabled={!canEdit}
                                                                                    >
                                                                                        {(provided) => {
                                                                                            const instagramUserId =
                                                                                                instagramInfluencerUsers.find(
                                                                                                    (influencer) =>
                                                                                                        influencer.id ===
                                                                                                        post.influencer_id
                                                                                                )?.user ??
                                                                                                post.instagram_user;
                                                                                            const image = instagramUserImages.find(
                                                                                                (i) =>
                                                                                                    i.user_id ===
                                                                                                    instagramUserId
                                                                                            );
                                                                                            const stats = getInstagramPostStatsForPost(
                                                                                                post
                                                                                            );
                                                                                            const scrapedPost = instagramPosts.find(
                                                                                                (p) =>
                                                                                                    p.id === post.post
                                                                                            );

                                                                                            return (
                                                                                                <InstagramTableRow
                                                                                                    row={{
                                                                                                        post,
                                                                                                        stats,
                                                                                                        profileImage: image,
                                                                                                        videoUploadedDate:
                                                                                                            scrapedPost?.taken_at ??
                                                                                                            null,
                                                                                                    }}
                                                                                                    isHighlighted={
                                                                                                        post.id ===
                                                                                                        postHighlightedId
                                                                                                    }
                                                                                                    onRowClick={
                                                                                                        openInstagramInfluencerPostUpdateModal
                                                                                                    }
                                                                                                    onOpenInfluencerModal={
                                                                                                        handleOpenInfluencerModal
                                                                                                    }
                                                                                                    key={`instagram${post.id}`}
                                                                                                    {...provided}
                                                                                                />
                                                                                            );
                                                                                        }}
                                                                                    </Draggable>
                                                                                );
                                                                            })}
                                                                </tbody>
                                                                <tfoot className={styles.tableFooter}>
                                                                    <InstagramTotalsRow
                                                                        stats={totalsByGroup[group.id]}
                                                                        group={group}
                                                                        currency={influencerPlan?.currency}
                                                                    />
                                                                </tfoot>
                                                                {provided.placeholder}
                                                            </table>
                                                        )}
                                                    </Droppable>

                                                    <ProtectedByUserGroups groups={['influencer_editor']}>
                                                        <Droppable
                                                            key={`${group.id}-backup`}
                                                            droppableId={`${group.id}-backup`}
                                                        >
                                                            {(provided, { isDraggingOver }) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.droppableProps}
                                                                >
                                                                    <ExpandBar
                                                                        isOpen={expandedBackupTables.includes(group.id)}
                                                                        title={`${groupBackups.length} backup creator${
                                                                            groupBackups.length !== 1 ? 's' : ''
                                                                        }`}
                                                                        onExpandClicked={() =>
                                                                            setExpandedBackupTables(group.id)
                                                                        }
                                                                    >
                                                                        <table className={styles.table}>
                                                                            <thead>
                                                                                <tr className={styles.tableHeading}>
                                                                                    <th>ID</th>
                                                                                    <th>Account</th>
                                                                                    <th>Status</th>
                                                                                    <ProtectedByUserGroups
                                                                                        groups={['influencer_editor']}
                                                                                    >
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Contact
                                                                                        </th>
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Cost
                                                                                        </th>
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Client Cost
                                                                                        </th>
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Payment
                                                                                        </th>
                                                                                    </ProtectedByUserGroups>

                                                                                    <ProtectedByUserGroups
                                                                                        groups={['round_planner']}
                                                                                    >
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Payment Request
                                                                                        </th>
                                                                                    </ProtectedByUserGroups>

                                                                                    <ProtectedByUserGroups
                                                                                        groups={['round_planner']}
                                                                                    >
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Est. Views
                                                                                        </th>
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Est. CPM
                                                                                        </th>
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            CPM
                                                                                        </th>
                                                                                        <th
                                                                                            className={
                                                                                                styles.editorCell
                                                                                            }
                                                                                        >
                                                                                            Notes
                                                                                        </th>
                                                                                    </ProtectedByUserGroups>
                                                                                    <th>Upload Date</th>
                                                                                    <th>Total views</th>
                                                                                    <th>Feed views</th>
                                                                                    <th>Reel views</th>
                                                                                    <th>Story views</th>
                                                                                    <th>Total engagements</th>
                                                                                    <th>Engagement rate</th>
                                                                                    <th>Likes</th>
                                                                                    <th>Comments</th>
                                                                                    <th>Ad code</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                className={cn({
                                                                                    [styles.dropActive]: isDraggingOver,
                                                                                })}
                                                                            >
                                                                                {groupBackups
                                                                                    .sort(
                                                                                        (a, b) =>
                                                                                            statusOrder[a.status] -
                                                                                            statusOrder[b.status]
                                                                                    )
                                                                                    .map((post) => {
                                                                                        return (
                                                                                            <Draggable
                                                                                                key={post.id}
                                                                                                draggableId={`${post.id}`}
                                                                                                index={post.id}
                                                                                                isDragDisabled={
                                                                                                    !canEdit
                                                                                                }
                                                                                            >
                                                                                                {(provided) => {
                                                                                                    const instagramUserId =
                                                                                                        instagramInfluencerUsers.find(
                                                                                                            (
                                                                                                                influencer
                                                                                                            ) =>
                                                                                                                influencer.id ===
                                                                                                                post.influencer_id
                                                                                                        )?.user ??
                                                                                                        post.instagram_user;
                                                                                                    const image = instagramUserImages.find(
                                                                                                        (i) =>
                                                                                                            i.user_id ===
                                                                                                            instagramUserId
                                                                                                    );
                                                                                                    const stats = getInstagramPostStatsForPost(
                                                                                                        post
                                                                                                    );
                                                                                                    const scrapedPost = instagramPosts.find(
                                                                                                        (p) =>
                                                                                                            p.id ===
                                                                                                            post.post
                                                                                                    );

                                                                                                    return (
                                                                                                        <InstagramTableRow
                                                                                                            row={{
                                                                                                                post,
                                                                                                                stats,
                                                                                                                profileImage: image,
                                                                                                                videoUploadedDate:
                                                                                                                    scrapedPost?.taken_at ??
                                                                                                                    null,
                                                                                                            }}
                                                                                                            onRowClick={
                                                                                                                openInstagramInfluencerPostUpdateModal
                                                                                                            }
                                                                                                            onOpenInfluencerModal={
                                                                                                                handleOpenInfluencerModal
                                                                                                            }
                                                                                                            key={`instagram${post.id}`}
                                                                                                            {...provided}
                                                                                                        />
                                                                                                    );
                                                                                                }}
                                                                                            </Draggable>
                                                                                        );
                                                                                    })}
                                                                                {provided.placeholder}
                                                                            </tbody>
                                                                        </table>
                                                                    </ExpandBar>
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </ProtectedByUserGroups>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </DragDropContext>
                )}
                {shouldDisplayMicrosBar && (
                    <>
                        {!isMicroCampaignsInitialized ||
                        isMicroCampaignsLoading ||
                        !isInstagramMicroCampaignStatsInitialized ||
                        isInstagramMicroCampaignStatsLoading ? (
                            <Skeleton className={styles.groupSkeleton} />
                        ) : (
                            !hasMicroCampaignsLoadingError && (
                                <div>
                                    <InstagramMicrosStatsBar
                                        isExpanded={urlState.isMicrosExpanded === 'true'}
                                        onExpandedChange={(isExpanded) =>
                                            setUrlState({ isMicrosExpanded: isExpanded || undefined })
                                        }
                                        campaign={microCampaigns.find((c) => c.platform === 'instagram')}
                                        stats={instagramMicroCampaignStats}
                                        onAddPost={() => {
                                            setIsCreateMicrowavePostModalOpen(true);
                                        }}
                                    >
                                        <InstagramMicroCampaignPostsTable
                                            isLoading={
                                                !areInstagramMicroCampaignPostsInitialized ||
                                                areInstagramMicroCampaignPostsLoading
                                            }
                                            noDataLabel={
                                                hasErrorLoadingInstagramCampaignPosts
                                                    ? "Sorry, we're experiencing technical issues"
                                                    : 'No data found'
                                            }
                                            data={instagramMicroCampaignPosts}
                                        />
                                    </InstagramMicrosStatsBar>
                                </div>
                            )
                        )}
                    </>
                )}

                <AddInfluencerToCampaignOptionsProvider>
                    <InstagramInfluencerUserModal
                        isModalOpen={isInfluencerModalOpen}
                        closeModal={handleCloseInfluencerModal}
                        influencerId={influencerModalInfluencerId}
                        monitoredUserStats={instagramInfluencerUserStats}
                        userId={instagramInfluencerUserStats?.user_id}
                        profileImage={instagramUserImages.find(
                            (i) => i.user_id === instagramInfluencerUserStats?.user_id
                        )}
                    />
                </AddInfluencerToCampaignOptionsProvider>

                <CreateMicrowavePostModal
                    campaignId={instagramCampaign?.id}
                    isOpen={isCreateMicrowavePostModalOpen}
                    closeModal={() => {
                        setIsCreateMicrowavePostModalOpen(false);
                    }}
                    onCreated={() => {
                        setIsCreateMicrowavePostModalOpen(false);
                        resetInstagramMicroCampaignPosts();
                        resetInstagramMicroCampaignStats();
                    }}
                />

                <InstagramAudioModal
                    isModalOpen={!!urlState.audioId}
                    closeModal={() => setUrlState({ audioId: undefined })}
                    audioId={Number(urlState.audioId)}
                />
            </section>
        </>
    );
};

export default InstagramTable;
